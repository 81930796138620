import React, { useState, useContext } from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Memberlist from "./Memberlist/Memberlist";
import Grouplist from "./Grouplist/Grouplist";
import Select from "react-select";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Role from "./role.json";
import { getGroupDataApi } from "../../BackendApi/Api/groupApi";
import { useEffect } from 'react';
import { UserRegistereApi, sendMailApi, memberRegistereApi } from "../../BackendApi/Api/userApi";
import { UserContext } from '../../UserContext';
import { getmemberbranding } from "../../BackendApi/Api/brandApi";
import Spinner from 'react-bootstrap/Spinner';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// import { alert, success, defaultModules, PNotify } from '@pnotify/core';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';


function TabData3(getGroupData) {
    const { groupdata, setGroupData, groupaddstatus, setGroupAddStatus, memberaddstatus, setMemberAddStatus } = useContext(UserContext);

    const [email, setEmail] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    // const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [groupId, setGroupId] = useState('');
    const [loading, setLoading] = useState(false);


    const [radioclass, setRadioClass] = useState(false);

    const [value, setValue] = useState([2, 3]);
    const handleChange = (val) => setValue(val);

    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('');
    const [brandData, setBrandData] = useState([]);

    const [chooseBrand, setChooseBrand] = useState([]);

    console.log('choosebrand-----------', chooseBrand);

    // const [groupdata, setGroupData] = useState([]);
    console.log('groupdata', groupdata);



    const radioStyle = [
        { className: 'radio-btn01' },
        { className: 'radio-btn02' },
        { className: 'radio-btn03' },
        { className: 'radio-btn04' },
    ];


    const changeRadio = (value) => {
        setRadioValue(value);
        setGroupId(value);
    };

    //generate random password
    const randomPassword = () => {
        var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
        var pass = "";
        for (var x = 0; x < 8; x++) {
            var i = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i);
        }
        console.log('passsssswwwwooorrrrdaaaaaaaa===========', pass);
        // setPassword(pass);
        return pass;


    }

    const handleInvite = (e) => {
        e.preventDefault();
        setLoading(true);

        const pass = randomPassword();
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const owner_id = user._id;
        const userData = {
            email: email,
            fname: fname,
            lname: lname,
            password: pass,
            role: role,
            group_id: groupId,
            owner_id: owner_id,
            brands: chooseBrand
        }

        memberRegistereApi(userData).then(response => {
            if (response.code === 200) {
                setMemberAddStatus(!memberaddstatus);
                PNotify.success({
                    title: 'Success',
                    // delay: 200000,
                    text: 'Member added successfully',
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                    hide: true,
                });
            }
            else {
                console.log(response);
                PNotify.error({
                    title: 'Error',
                    text: response.data.message,
                });
            }
        })
            .catch(err => {
                console.log(err);
            }
            ).finally(() => {
                setLoading(false);
            }
            )
    }


    const transformedBrandData = brandData.map((item) => ({
        company_name: item.company_name,
        brand_id: item._id,
    }));

    const handleBrandSelect = (selected) => {
        // const selectedBrands = selected;

        setChooseBrand(selected);
    }

    // fuction to pnotify success message 2 seconds



    return (
        <div>

            <div className="row">
                <div className="col-md-12">
                    <Tabs
                        defaultActiveKey="profile"
                        id="justify-tab-example"
                        className="mb-3"
                        justify
                    >
                        <Tab eventKey="profile" title="Members">
                            <Memberlist />
                        </Tab>
                        <Tab eventKey="longer-tab" title="Groups">
                            <Grouplist />
                        </Tab>
                    </Tabs>
                </div>
            </div>

        </div>
    );
}

export default TabData3;