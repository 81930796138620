import React,{useContext, useEffect} from  "react";
import { Form } from "react-bootstrap";
import { SmartLinkContext } from "../../Components/CreateSmartlink/SmartLinkcontext";
import { updateiostargatingAPI } from "../../BackendApi/Api/smartsLinkApi";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';

function AndroidTargeting() {
    const [smartlinkeditdata, setSmartlinkEditData, IosTargeting, setIosTargeting,AndroidTargeting, setAndroidTargeting] = useContext(SmartLinkContext);


    const updateandroidtargeting = () => {
        const AndroidTargetingData = {
            "id": smartlinkeditdata._id,
            "android_targeting": AndroidTargeting,
            
        }

        updateiostargatingAPI(AndroidTargetingData).then((res) => {
            if (res.data.status == true) {
                PNotify.success({
                    title: 'Success',
                    text: 'Android Targeting Updated Successfully',
                });
                setAndroidTargeting(res.data.data.android_targeting);
            }
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        if(smartlinkeditdata){
            setAndroidTargeting(smartlinkeditdata.android_targeting);   
        }
    }, [smartlinkeditdata])




    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="booking-custom-lable mb-3" controlId="formBasicEmail">
                            <Form.Label>Destination URL For Android Devices</Form.Label>
                            <Form.Control type="text" value={AndroidTargeting} onChange={
                                (e) => {
                                    setAndroidTargeting(e.target.value);
                                }
                            } placeholder="Enter long URL" />
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            <button type="button" onClick={updateandroidtargeting} className="btn bg-green-dark action-btn btn-green">
                                Update Url
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AndroidTargeting;