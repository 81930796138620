import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { SmartLinkContext } from "../../Components/CreateSmartlink/SmartLinkcontext";
import { updatecloakingdataAPI } from "../../BackendApi/Api/smartsLinkApi";

function Vanitytags() {

    const [smartlinkeditdata, setSmartlinkEditData, schedulesdata, setSchedulesData, linkcloakingdata, setLinkCloakingData] = React.useContext(SmartLinkContext);


    const [cloaking, setCloaking] = useState('');

    const [switchStatus, setSwitchStatus] = useState(false);

    useEffect(() => {
        if (linkcloakingdata && linkcloakingdata.length > 0) {
            setCloaking(linkcloakingdata[0].link_cloaking);
            // setShowText(true ? true : false);
            setSwitchStatus(true);
        }
    }, [linkcloakingdata])

    const updatecloakingdata = () => {
        const data = {
            "id": smartlinkeditdata._id,
            "cloaking": cloaking
        }

        updatecloakingdataAPI(data).then((res) => {
            if (res.data.status == true) {
                setLinkCloakingData(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }



    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Hide original URL so that your customers can see only the short URL in their browser's address bar.</Form.Label>
                        </Form.Group>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="Cloaking Enabled"
                                checked={switchStatus}
                                onChange={(e) =>
                                    setSwitchStatus(e.target.checked)
                                }
                            />
                        </Form.Group>
                    </div>
                </div>

                {switchStatus &&
                    <>
                        {/* <div className="col-md-12">
                            <div className="content-body-form Link-Cloaking-input" >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <div className="link-cloaking-form-group">
                                        <Form.Control type="text" value={cloaking} onChange={
                                            (e) => {
                                                setCloaking(e.target.value);
                                            }
                                        } placeholder="bxr.gg/abcshort" />
                                        <Form.Text className="positions01" >
                                            <span className="input-text">Visitor will only see the following url in their browser address bar</span>
                                        </Form.Text>
                                    </div>
                                </Form.Group>
                            </div>
                        </div> */}
                        <div className="col-md-12 mt-4">
                            <div className='form-btn'>
                                <div className='form-btn-right'>
                                    <button type="button" onClick={updatecloakingdata} className="btn bg-green-dark action-btn btn-green">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default Vanitytags;