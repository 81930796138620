export function completedPostsReducer(state, action) {
    switch (action.type) {
        case 'SET_COMPLETED_POSTS':
            return {
                ...state,
                posts: [...action.payload],
            }


        default:

            return state

    }
}
