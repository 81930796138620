import React from "react";
import { useState } from "react";
import { updateUserPasswordApi } from "../../BackendApi/Api/userApi";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';

function TabData2() {

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const changePassword = async () => {

        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);

        if (currentPassword === '') {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter current password',
            });
            return;
        }
        if (newPassword === '') {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter new password',
            });
            return;
        }

        if (newPassword.length < 8) {
            PNotify.error({
                title: 'Oh No!',
                text: 'New password must be minimum 8 characters long',
            });
            return;
        }

        if (confirmNewPassword === '') {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter confirm new password',
            });
            return;
        }
        if (newPassword !== confirmNewPassword) {
            PNotify.error({
                title: 'Oh No!',
                text: 'New password and confirm new password does not match',
            });
            return;
        }

        var lowerCaseLetters = /[a-z]/g;
        if (!newPassword.match(lowerCaseLetters)) {
            PNotify.error({
                title: 'Oh No!',
                text: 'New password must contain at least one lowercase character',
            });
            return;
        }

        var numbers = /[0-9]/g;
        if (!newPassword.match(numbers)) {
            PNotify.error({
                title: 'Oh No!',
                text: 'New password must contain at least one number, symbol, or whitespace character',
            });
            return;
        }




        const data = {
            password: currentPassword,
            newPassword: newPassword,
            email: user.email,
            id: user._id
        }
        setLoading(true);
        updateUserPasswordApi(data).then((response) => {

            if (response.data.status === true) {
                PNotify.success({
                    title: 'Success',
                    text: response.data.message,
                });
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
                setLoading(false);

            } else {
                PNotify.error({
                    title: 'error',
                    text: response.data.message,
                });
                setLoading(false);
            }
        }).catch((error) => {
            PNotify.error({
                title: 'Oh No!',
                text: error,
            });
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });


    }





    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-12">
                    <div className="content-body-form">
                        <form>
                            <div className="form-group did-floating-label-content">
                                <input type="Password" className="form-control did-floating-input" id="exampleFormControlInput1" placeholder="********" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                                <label for="exampleFormControlInput1" className="form-label did-floating-label">Current Password</label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-12">
                    <div className="content-body-form">
                        <form>
                            <div className="form-group did-floating-label-content">
                                <input type="Password" className="form-control did-floating-input" id="exampleFormControlInput2" placeholder="********" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                <label for="exampleFormControlInput2" className="form-label did-floating-label">New Password</label>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-12">
                    <div className="content-body-form">
                        <form>
                            <div className="form-group did-floating-label-content">
                                <input type="Password" className="form-control did-floating-input" id="exampleFormControlInput1" placeholder="********" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                <label for="exampleFormControlInput1" className="form-label did-floating-label">Confirm New Password</label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="password-recet-content">
                <h6>Password Requirements:</h6>
                <ul>
                    <li>Minimum 8 characters long - the more, the better</li>
                    <li>At least one lowercase character</li>
                    <li>At least one number, symbol, or whitespace character</li>
                </ul>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className='form-btn'>
                        <div className='form-btn-right'>


                            {
                                loading ?
                                    <button className="btn bg-green-dark action-btn btn-green" >
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        Loading...
                                    </button> :
                                    <button type="button" className="btn bg-green-dark action-btn btn-green me-3" onClick={changePassword}>
                                        Change Password
                                    </button>

                            }

                            <button type="button" className="btn bg-cancel-btn">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="password-footer">
                <p className="password-footer-text">If you are facing problem in changing the password then contact on the given email.</p>
            </div> */}
        </>
    );
}

export default TabData2;