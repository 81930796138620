import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Slider from 'react-rangeslider';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import 'react-rangeslider/lib/index.css';
import { GrStatusGoodSmall } from "react-icons/gr";
import { saverRedirectionApi, getRedirectionApi } from '../../BackendApi/Api/smartsLinkApi';
import { API_URL } from "../../BackendApi/Api/config";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { BsSkipEndFill, BsArrowsFullscreen } from "react-icons/bs";
import Accordion from 'react-bootstrap/Accordion';
import { FaHeading, FaFacebookF, FaTabletAlt, FaMobileAlt } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
import { BiBookContent } from "react-icons/bi";
import { MdColorLens, MdDevices } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { MdOutlineDesktopMac } from "react-icons/md";
import { RiFontSansSerif } from "react-icons/ri";
import Spinner from 'react-bootstrap/Spinner';
import { SmartLinkContext } from "../CreateSmartlink/SmartLinkcontext";



function Redirection() {
    const [smartlinkeditdata, setSmartlinkEditData, IosTargeting, setIosTargeting, latest, setLatest,] = React.useContext(SmartLinkContext);

    const [loading, setLoading] = useState(false);

    const [redirectionStatus, setRedirectionStatus] = useState(false);

    const [duration, setDuration] = useState(0);

    const [contentOption, setContentOption] = useState(1);

    const [headerOption, setHeaderOption] = useState(1);

    const [footerOption, setFooterOption] = useState(1);

    const [redirectionId, setRedirectionId] = useState(0);




    const [backgroundType, setBackgroundType] = useState(1);
    //backgroundType
    // 1 for single color
    // 2 for gradient

    const [BackgroundColor, setBackgroundColor] = useState('#e6e5eb');
    const [backgroundColor2, setBackgroundColor2] = useState('#ffffff');

    const [collapse, setCollapse] = useState(false);

    const [showText, setShowText] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [redirectioData, setRedirectioData] = useState(null);


    //contentOption type 
    // 0 for none
    // 1 for image
    // 2 for video
    // 3 for iframe

    const [video, setVideo] = useState(null);
    const [image, setImage] = useState(null);
    const [adImage, setAdImage] = useState(null);
    const [adVideo, setAdVideo] = useState(null);
    let [iframeContent, setIframeContent] = useState("");
    let [embedUrl, setEmbedUrl] = useState("");


    const [redirectionType, setRedirectionType] = useState(2);
    const [auto, setAuto] = useState(false);
    //redirectionType
    // 1 for automatic
    // 2 for call to action

    const [buttontext, setButtontext] = useState('Button');
    const [ButtonBackgroundColor, setButtonBackgroundColor] = useState('#000000');
    const [TextColor, setTextColor] = useState('#ffffff');
    const [buttonPosition, setButtonPosition] = useState('Right');
    const [fontFamily, setFontFamily] = useState('Arial');
    const [colorPosition, setColorPosition] = useState('leftright');
    const [imagePosition, setImagePosition] = useState('contain');
    const [headerPosition, setHeaderPosition] = useState('left');
    const [footerPosition, setFooterPosition] = useState('left');

    const [footerText, setFooterText] = useState('All Rights Reserved');
    const [footerTextColor, setFooterTextColor] = useState('#7367f0');

    const [header_brand_logo, setHeaderBrand_logo] = useState('');
    const [footer_brand_logo, setFooterBrand_logo] = useState('');

    const [customHeaderLogoUrl, setCustomHeaderLogoUrl] = useState('');
    const [customFooterLogoUrl, setCustomFooterLogoUrl] = useState('');



    const [logo_light, setLogo_light] = useState('');
    const [logo_dark, setLogo_dark] = useState('');
    const [logo_full, setLogo_full] = useState('');

    const [brandHeaderLogoOption, setBrandHeaderLogoOption] = useState(2);
    const [brandFooterLogoOption, setBrandFooterLogoOption] = useState(2);

    const [customHeaderLogo, setCustomHeaderLogo] = useState('')
    const [customFooterLogo, setCustomFooterLogo] = useState('')


    const [customHeaderLogoSize, setCustomHeaderLogoSize] = useState(32);

    const [customFooterLogoSize, setCustomFooterLogoSize] = useState(32);

    const [deviceView, setDeviceView] = useState('view-desktop');


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleFooterPosition = (e) => {
        const selectedPosition = e.target.value;
        setFooterPosition(selectedPosition);
    }

    const handleImagePositionChange = (e) => {
        const selectedPosition = e.target.value;
        setImagePosition(selectedPosition);
    }

    const handleHeaderPosition = (e) => {
        const selectedPosition = e.target.value;
        setHeaderPosition(selectedPosition);
    }



    const handleValueChange = (e) => {
        const selectedValue = e.target.value;
        setColorPosition(selectedValue);
    };


    const gradientDirectionMap = {
        leftright: 'to right',
        rightleft: 'to left',
        topbottom: 'to bottom',
        bottomtop: 'to top',
    };

    const handlePositionChange = (e) => {
        const selectedPosition = e.target.value;
        setButtonPosition(selectedPosition);
    };


    const handleImageChange = (e) => {
        setAdImage(e.target.files[0]);
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleVideoChange = (e) => {
        setAdVideo(e.target.files[0]);
        const file = e.target.files[0];
        if (file) {
            const videoUrl = URL.createObjectURL(file); // Create a URL for the chosen video
            setVideo(videoUrl); // Set the video URL to the state variable using setVideo
        }
    };


    const handleSelectHeaderCustomLogo = (e) => {
        setCustomHeaderLogo(e.target.files[0]);
        const file = e.target.files[0];

        // Check if a file was selected
        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setCustomHeaderLogoUrl(reader.result);
                setHeaderBrand_logo(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelectFooterCustomLogo = (e) => {
        setCustomFooterLogo(e.target.files[0]);
        const file = e.target.files[0];

        // Check if a file was selected
        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setCustomFooterLogoUrl(reader.result);
                setFooterBrand_logo(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const saveRedirection = async () => {

        console.log("redirection data", redirectioData)


        if (redirectionId == 0 && contentOption == 1 && adImage == null && redirectionStatus == true) {
            PNotify.error({
                title: 'Error',
                text: "Please select image",
            });
            return;
        }
        if (contentOption == 4 && embedUrl == "") {
            const regex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+/;
            if (!regex.test(embedUrl)) {

                return PNotify.error({
                    title: 'Error',
                    text: 'Please enter a valid YouTube link',
                });
            } else {
                embedUrl = embedUrl?.replace("watch?v=", "embed/");
            }
        }


        if (redirectionId !== 0 && contentOption == 1 && adImage == null && redirectionStatus == true && !redirectioData.image) {
            PNotify.error({
                title: 'Error',
                text: "Please select image",
            });
            return;
        }

        if (redirectionId == 0 && contentOption == 2 && adVideo == null && redirectionStatus == true) {
            PNotify.error({
                title: 'Error',
                text: "Please select video",
            });
            return;
        }

        if (redirectionId !== 0 && contentOption == 2 && adVideo == null && redirectionStatus == true && !redirectioData.video) {
            PNotify.error({
                title: 'Error',
                text: "Please select video",
            });
            return;
        }

        if (redirectionId == 0 && contentOption == 3 && (iframeContent == "undefined" || iframeContent == "" || iframeContent == null || iframeContent == undefined) && redirectionStatus == true) {
            PNotify.error({
                title: 'Error',
                text: "Please enter iframe content",
            });
            return;
        }

        if (duration < 5 && redirectionStatus == true) {
            PNotify.error({
                title: 'Error',
                text: "time should be 5 secs or more",
            });
            return;
        }




        const url = window.location.href;
        const id = url.substring(url.lastIndexOf('/') + 1);

        const localData = JSON.parse(localStorage.getItem('user'));



        const formData = new FormData();

        if (redirectionStatus == true) {

            formData.append('header_logo_position', headerPosition);
            formData.append('footer_logo_position', footerPosition);
            formData.append('footer_text', footerText);
            formData.append('footer_text_color', footerTextColor);

            formData.append('header_logo_option', headerOption);
            formData.append('footer_logo_option', footerOption);

            formData.append('header_brand_logo', brandHeaderLogoOption);
            formData.append('footer_brand_logo', brandFooterLogoOption);

            if (headerOption == 2) {

                formData.append('header_custom_logo', customHeaderLogo);
            }
            if (footerOption == 2) {

                formData.append('footer_custom_logo', customFooterLogo);
            }

            formData.append('header_logo_size', customHeaderLogoSize);
            formData.append('footer_logo_size', customFooterLogoSize);


            formData.append('smartlink_id', id);
            formData.append('duration', duration);
            if (contentOption !== 0) {
                formData.append('content_type', contentOption);
            }
            formData.append('redirection_type', redirectionType);
            formData.append('button_text', buttontext);
            formData.append('button_background_color', ButtonBackgroundColor);
            formData.append('button_text_color', TextColor);
            formData.append('button_position', buttonPosition);
            formData.append('button_font_family', fontFamily);

            formData.append('background_type', backgroundType);
            if (backgroundType == 1) {
                formData.append('bgcolor1', BackgroundColor);
            }
            if (backgroundType == 2) {
                formData.append('bgcolor1', BackgroundColor);
                formData.append('bgcolor2', backgroundColor2);
                formData.append('gradient_colorPosition', colorPosition);
                formData.append('gradient_gradientDirectionMap', gradientDirectionMap[colorPosition]);
            }
            formData.append("owner_id", localData.owner_id);
            formData.append("created_by", localData._id);
            formData.append('redirection_status', redirectionStatus);

            formData.append("updator_image", localData.profile_image);
            formData.append("updator_name", localData.fname + " " + localData.lname)
            if (contentOption == 1) {
                // formData.append('image', "image");
                formData.append('image', adImage);
                formData.append("image_position", imagePosition);
            }
            if (contentOption == 2) {
                // formData.append('video', "video");
                formData.append('video', adVideo);
                formData.append('autoPlay', auto);

            }
            if (contentOption == 3) {
                formData.append('iframe', iframeContent);
            }
            if (contentOption == 4) {
                formData.append("youtube_embed_url", embedUrl);
            }
        }

        if (redirectionStatus == false) {


            const hummata = {

                redirection_status: redirectionStatus,
                smartlink_id: id
            }

            const response = await fetch(`${API_URL}smartlink/saveredirection`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(hummata)

            })

            const data = await response.json();

            if (data.status == true) {
                PNotify.success({
                    title: 'Success',
                    text: 'Content Waypoint Added',
                });

            } else {
                PNotify.success({
                    title: 'success',
                    text: "redirection status updated successfully",
                });
            }


        }
        else {

            setLoading(true);
            saverRedirectionApi(formData).then((res) => {

                if (res.code == 200) {
                    if (res.data.status == true) {
                        PNotify.success({
                            title: 'Success',
                            text: 'Content Waypoint Update',
                        });
                        setLatest(!latest);
                    } else if (res.data?.success == true) {
                        PNotify.success({
                            title: 'Success',
                            text: res.data.message,
                        });
                    } else {
                        PNotify.error({
                            title: 'Error',
                            text: res.data.message,
                        });
                    }

                } else {
                    PNotify.error({
                        title: 'Error',
                        text: res.data.message,
                    });
                }
            }
            ).catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            })

        }


    }

    const getRedirection = () => {

        const url = window.location.href;
        const id = url.substring(url.lastIndexOf('/') + 1);

        const localData = JSON.parse(localStorage.getItem('user'));

        const formData = {
            id: id,
        }

        getRedirectionApi(formData).then((res) => {
            if (res.code == 200) {


                if (res.data.status == true) {

                    if (res.data.data.length > 0) {
                        const redirdata = res.data.data[0];

                        if (redirdata.type == "existing") {


                            console.log("redirdata========>", redirdata);
                            setRedirectioData(redirdata);

                            setDuration(redirdata.duration);
                            setContentOption(redirdata.content_type);
                            setRedirectionType(redirdata.redirection_type);
                            setButtontext(redirdata.button_text);
                            setButtonBackgroundColor(redirdata.button_background_color);
                            setBackgroundType(redirdata.background_type);
                            if (redirdata.background_type == 2) {
                                setCollapse(true);
                            }

                            setBackgroundColor(redirdata.bgcolor1);
                            setBackgroundColor2(redirdata.bgcolor2);
                            setRedirectionStatus(redirdata.redirection_status);
                            setRedirectionId(redirdata._id);
                            setIframeContent(redirdata.iframe);
                            setEmbedUrl(redirdata.youtube_embed_url);
                            setImage(API_URL + 'redirection/' + redirdata.image);
                            setVideo(API_URL + 'redirection/' + redirdata.video);

                            setTextColor(redirdata.button_text_color);
                            setFontFamily(redirdata.button_font_family);

                            setColorPosition(redirdata.gradient_colorPosition);

                            // setFooterPosition(redirdata.footer_logo_position);

                            setButtonPosition(redirdata.button_position);
                            setFooterPosition(redirdata.footer_logo_position);
                            setFooterText(redirdata.footer_text);
                            setFooterTextColor(redirdata.footer_text_color);
                            setHeaderPosition(redirdata.header_logo_position);
                            setImagePosition(redirdata.image_position);
                            if (redirdata.logo_light.startsWith('http') || redirdata.logo_light.startsWith('https')) {
                                setLogo_light(redirdata.logo_light)
                            } else {

                                setLogo_light(API_URL + 'brands/' + redirdata.logo_light);;
                            }
                            if (redirdata.logo_dark.startsWith('http') || redirdata.logo_dark.startsWith('https')) {
                                setLogo_dark(redirdata.logo_dark)
                            } else {

                                setLogo_dark(API_URL + 'brands/' + redirdata.logo_dark);;
                            }
                            if (redirdata.logo_full.startsWith('http') || redirdata.logo_full.startsWith('https')) {
                                setLogo_full(redirdata.logo_full)
                            } else {

                                setLogo_full(API_URL + 'brands/' + redirdata.logo_full);;
                            }
                            // setLogo_light(API_URL + 'brands/' + redirdata.logo_light);
                            // setLogo_dark(API_URL + 'brands/' + redirdata.logo_dark);
                            // setLogo_full(API_URL + 'brands/' + redirdata.logo_full);

                            setHeaderOption(redirdata.header_logo_option);

                            setFooterOption(redirdata.footer_logo_option);

                            setBrandHeaderLogoOption(redirdata.header_brand_logo);
                            setBrandFooterLogoOption(redirdata.footer_brand_logo);

                            setCustomHeaderLogoUrl(API_URL + 'redirection/' + redirdata.header_custom_logo)
                            setCustomFooterLogoUrl(API_URL + 'redirection/' + redirdata.footer_custom_logo);

                            setCustomHeaderLogoSize(redirdata.header_logo_size);
                            setCustomFooterLogoSize(redirdata.footer_logo_size);

                            if (redirdata.header_logo_option == 1) {

                                console.log("i am here dont worry")
                                console.log("logo option", redirdata.header_brand_logo)
                                if (redirdata.header_brand_logo == 1) {
                                    console.log("i am here  1")
                                    if (redirdata.logo_light.startsWith('http') || redirdata.logo_light.startsWith('https')) {
                                        setHeaderBrand_logo(redirdata.logo_light)
                                    } else {

                                        setHeaderBrand_logo(API_URL + 'brands/' + redirdata.logo_light);;
                                    }
                                    // setHeaderBrand_logo(API_URL + 'brands/' + redirdata.logo_light);
                                }
                                else if (redirdata.header_brand_logo == 2) {
                                    console.log("i am here 2")
                                    if (redirdata.logo_dark.startsWith('http') || redirdata.logo_dark.startsWith('https')) {
                                        setHeaderBrand_logo(redirdata.logo_dark)
                                    } else {

                                        setHeaderBrand_logo(API_URL + 'brands/' + redirdata.logo_dark);;
                                    }
                                    // setHeaderBrand_logo(API_URL + 'brands/' + redirdata.logo_dark);
                                }
                                else if (redirdata.header_brand_logo == 3) {
                                    console.log("i am here 3")
                                    if (redirdata.logo_full.startsWith('http') || redirdata.logo_full.startsWith('https')) {
                                        setHeaderBrand_logo(redirdata.logo_full)
                                    } else {

                                        setHeaderBrand_logo(API_URL + 'brands/' + redirdata.logo_full);;
                                    }
                                    // setHeaderBrand_logo(API_URL + 'brands/' + redirdata.logo_full);
                                }
                            }

                            if (redirdata.footer_logo_option == 1) {
                                if (redirdata.footer_brand_logo == 1) {
                                    if (redirdata.logo_light.startsWith('http') || redirdata.logo_light.startsWith('https')) {
                                        setFooterBrand_logo(redirdata.logo_light)
                                    } else {

                                        setFooterBrand_logo(API_URL + 'brands/' + redirdata.logo_light);;
                                    }
                                    // setFooterBrand_logo(API_URL + 'brands/' + redirdata.logo_light);
                                }

                                else if (redirdata.footer_brand_logo == 2) {
                                    if (redirdata.logo_dark.startsWith('http') || redirdata.logo_dark.startsWith('https')) {
                                        setFooterBrand_logo(redirdata.logo_dark)
                                    } else {

                                        setFooterBrand_logo(API_URL + 'brands/' + redirdata.logo_dark);;
                                    }
                                    // setFooterBrand_logo(API_URL + 'brands/' + redirdata.logo_dark);
                                }
                                else if (redirdata.footer_brand_logo == 3) {
                                    if (redirdata.logo_full.startsWith('http') || redirdata.logo_full.startsWith('https')) {
                                        setFooterBrand_logo(redirdata.logo_full)
                                    } else {

                                        setFooterBrand_logo(API_URL + 'brands/' + redirdata.logo_full);;
                                    }
                                    // setFooterBrand_logo(API_URL + 'brands/' + redirdata.logo_full);

                                }
                            }

                            if (redirdata.header_logo_option == 2) {
                                if (redirdata.header_custom_logo.startsWith('http') || redirdata.header_custom_logo.startsWith('https')) {
                                    setHeaderBrand_logo(redirdata.header_custom_logo)
                                } else {

                                    setHeaderBrand_logo(API_URL + 'redirection/' + redirdata.header_custom_logo);;
                                }
                                // setHeaderBrand_logo(API_URL + 'redirection/' + redirdata.header_custom_logo);
                            }

                            if (redirdata.footer_logo_option == 2) {
                                if (redirdata.footer_custom_logo.startsWith('http') || redirdata.footer_custom_logo.startsWith('https')) {
                                    setFooterBrand_logo(redirdata.footer_custom_logo)
                                } else {

                                    setFooterBrand_logo(API_URL + 'redirection/' + redirdata.footer_custom_logo);;
                                }
                                // setFooterBrand_logo(API_URL + 'redirection/' + redirdata.footer_custom_logo);
                            }





                        } else if (redirdata.type == "new") {
                            if (redirdata.logo_light.startsWith('http') || redirdata.logo_light.startsWith('https')) {
                                setLogo_light(redirdata.logo_light)
                            } else {

                                setLogo_light(API_URL + 'brands/' + redirdata.logo_light);;
                            }
                            if (redirdata.logo_dark.startsWith('http') || redirdata.logo_dark.startsWith('https')) {
                                setLogo_dark(redirdata.logo_dark)
                            } else {

                                setLogo_dark(API_URL + 'brands/' + redirdata.logo_dark);;
                            }
                            if (redirdata.logo_full.startsWith('http') || redirdata.logo_full.startsWith('https')) {
                                setLogo_full(redirdata.logo_full)
                            } else {

                                setLogo_full(API_URL + 'brands/' + redirdata.logo_full);;
                            }
                            // setLogo_light(API_URL + 'brands/' + redirdata.logo_light);
                            // setLogo_dark(API_URL + 'brands/' + redirdata.logo_dark);
                            // setLogo_full(API_URL + 'brands/' + redirdata.logo_full);
                            if (redirdata.logo_dark.startsWith('http') || redirdata.logo_dark.startsWith('https')) {
                                setHeaderBrand_logo(redirdata.logo_dark)
                            } else {

                                setHeaderBrand_logo(API_URL + 'brands/' + redirdata.logo_dark);;
                            }
                            if (redirdata.logo_dark.startsWith('http') || redirdata.logo_dark.startsWith('https')) {
                                setFooterBrand_logo(redirdata.logo_dark)
                            } else {

                                setFooterBrand_logo(API_URL + 'brands/' + redirdata.logo_dark);;
                            }
                            // setHeaderBrand_logo(API_URL + 'brands/' + redirdata.logo_dark);
                            // setFooterBrand_logo(API_URL + 'brands/' + redirdata.logo_dark);

                            setBrandHeaderLogoOption(2);
                            setBrandFooterLogoOption(2);


                            setHeaderOption(1);
                            setFooterOption(1);


                        }


                    }

                } else {
                    console.log(res.data.message);
                }

            } else {
                console.log(res.message);
            }
        }
        ).catch((err) => {
            console.log(err);
        }
        )

    }

    embedUrl = embedUrl?.replace("watch?v=", "embed/");
    useEffect(() => {
        getRedirection();
    }, [])


    const showContentOption = (value) => {
        setContentOption(value);
    }

    const showHeaderOption = (value) => {
        setHeaderOption(value);
        if (value == 1) {
            if (brandHeaderLogoOption == 1) {
                setHeaderBrand_logo(logo_light);
            } else if (brandHeaderLogoOption == 2) {
                setHeaderBrand_logo(logo_dark);
            } else if (brandHeaderLogoOption == 3) {
                setHeaderBrand_logo(logo_full);
            }
        }
        else if (value == 2) {
            setHeaderBrand_logo(customHeaderLogoUrl);
        }

    }

    const showFooterOption = (value) => {
        setFooterOption(value);
        if (value == 1) {
            if (brandFooterLogoOption == 1) {
                setFooterBrand_logo(logo_light);
            } else if (brandFooterLogoOption == 2) {
                setFooterBrand_logo(logo_dark);
            } else if (brandFooterLogoOption == 3) {
                setFooterBrand_logo(logo_full);
            }
        }
        else if (value == 2) {
            setFooterBrand_logo(customFooterLogoUrl);
        }

    }

    const hnadleHeaderLogoChange = (value) => {
        if (value == 1) {

            setBrandHeaderLogoOption(1);
            setHeaderBrand_logo(logo_light);

        } else if (value == 2) {

            setBrandHeaderLogoOption(2);
            setHeaderBrand_logo(logo_dark);

        } else if (value == 3) {

            setBrandHeaderLogoOption(3);
            setHeaderBrand_logo(logo_full);

        }

    }

    const hnadleFooterLogoChange = (value) => {
        if (value == 1) {

            setBrandFooterLogoOption(1);
            setFooterBrand_logo(logo_light);

        } else if (value == 2) {

            setBrandFooterLogoOption(2);
            setFooterBrand_logo(logo_dark);

        } else if (value == 3) {

            setBrandFooterLogoOption(3);
            setFooterBrand_logo(logo_full);

        }

    }


    const handleChangeStart = (value) => {
        console.log('Change event started', value)
    };


    const handleChange = value => {
        setDuration(value)
    };

    const handleHeaderHeightChange = (value) => {
        setCustomHeaderLogoSize(value)
    }

    const handleFooterHeightChange = value => {
        setCustomFooterLogoSize(value)
    }

    const handleChangeComplete = (value) => {
        console.log('Change event completed', value)
    };


    const horizontalLabels = {
        0: '0',
        10: '10',
        20: '20',
        30: '30',
        40: '40',
        50: '50',
        60: '60',
        70: '70',
        80: '80',
        90: '90',
        100: '100'
    }

    const addClass = () => {
        setCollapse(true);
        setBackgroundType(2);
    }

    const removeClass = () => {
        setCollapse(false);
        setBackgroundType(1);
    }

    const handleSwitchChange = () => {
        setRedirectionStatus(!redirectionStatus);
        //setShowText1(!redirectionStatus);
    }


    useEffect(() => {
        if (redirectionType == 2) {
            setShowText(true);
        }
    }, [])



    return (
        <div>
            <Modal
                centered
                show={show}
                onHide={handleClose}
                className="modal-center-show"
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="show-preview-header">
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-desktop');
                                    }
                                }
                            ><MdOutlineDesktopMac /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-tablet');
                                    }
                                }
                            ><FaTabletAlt /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-mobile');
                                    }
                                }
                            ><FaMobileAlt /></button>
                        </div>
                    </div>
                    <div className="show-preview-body">
                        <div className="show-preview-content-2">
                            <div className={`preview-box-background ${deviceView}`}>
                                <div class="oEUMCt"></div>
                                <div
                                    className="preview-box"
                                    style={{
                                        ...(backgroundType === 1 ? { backgroundColor: BackgroundColor } : {}),
                                        ...(backgroundType !== 1
                                            ? {
                                                backgroundImage: `linear-gradient(${gradientDirectionMap[colorPosition]}, ${BackgroundColor}, ${backgroundColor2})`,
                                            }
                                            : {}),
                                    }}
                                >

                                    <div className={`preview-web-header ${headerPosition}`}>
                                        <div className="brand-logo">
                                            <img src={header_brand_logo} alt="" style={{ height: customHeaderLogoSize + 'px' }} />
                                        </div>
                                    </div>
                                    <div className="preview-body-redirection">
                                        <div className="preview">
                                            <div className="preview-design justify-content-center">
                                                {
                                                    contentOption == 1 ? (
                                                        <div className="image-uploder-preview">
                                                            {image && <img src={image} alt="Preview" style={{ objectFit: imagePosition }} />}
                                                        </div>
                                                    ) : contentOption == 2 ? (
                                                        <div className="video-uploder-preview">
                                                            {video && (
                                                                <video controls style={{ width: '100%', }}>
                                                                    <source src={video} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            )}
                                                        </div>

                                                    ) : contentOption == 3 ? (
                                                        <>
                                                            <div className="ratio ratio-16x9" dangerouslySetInnerHTML={{ __html: iframeContent }}>
                                                            </div>
                                                            {/* <iframe width="560" height="315" src={embedUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}

                                                        </>
                                                    ) : contentOption == 4 ? (
                                                        <>

                                                            <iframe width="560" height="315" src={embedUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                                        </>

                                                    ) : contentOption == 0 ? (

                                                        <>
                                                            {
                                                                redirectioData.content_type == 1 ? (
                                                                    <div className="image-uploder-preview">
                                                                        {redirectioData.image && <img src={API_URL + 'redirection/' + redirectioData.image} alt="Preview" style={{ width: '100%' }} />}
                                                                    </div>
                                                                ) : redirectioData.content_type == 2 ? (
                                                                    <div className="video-uploder-preview">
                                                                        {redirectioData.video && (
                                                                            <video controls style={{ width: '100%', }}>
                                                                                <source src={API_URL + 'redirection/' + redirectioData.video} type="video/mp4" />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        )}
                                                                    </div>
                                                                ) : redirectioData.content_type == 3 ? (
                                                                    <>
                                                                        <div dangerouslySetInnerHTML={{ __html: redirectioData.iframeContent }}>
                                                                        </div>
                                                                    </>
                                                                ) : redirectioData.content_type == 4 ? (

                                                                    <div className="youtube_video_box mb-3">
                                                                        <iframe width="560" height="315" src={redirectioData.youtube_embed_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                                    </div>
                                                                ) :

                                                                    <></>
                                                            }
                                                        </>
                                                    ) : <></>
                                                }
                                            </div>
                                            <div className={buttonPosition === 'Center' ? 'form-btn-center' : buttonPosition === 'Left' ? 'form-btn-left' : 'form-btn-right'}>
                                                <div className="flot-right">
                                                    {/* <div className="skip-timer">
                                                                    <div className="timer">0:30 Redirection</div>
                                                                </div> */}
                                                    <div className="skip-btn" style={{ display: redirectionType === 1 ? 'none' : 'block' }}>
                                                        <button type="button" className="btn btn-green" style={{ backgroundColor: ButtonBackgroundColor, color: TextColor, fontFamily: fontFamily }}>
                                                            {buttontext}
                                                            <BsSkipEndFill style={{ color: TextColor }} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`preview-footer ${footerPosition}`}>
                                        <div className="footer-brand-logo">
                                            <img src={footer_brand_logo} style={{ height: customFooterLogoSize + 'px' }} alt="" />
                                        </div>
                                        <div class="web-footer-text" style={{ color: footerTextColor }}>{footerText}</div>
                                    </div>
                                </div>
                                <div class="oEUMCterd"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="">
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Switch
                                id="custom-switch"
                                label="Redirection Enabled"
                                checked={redirectionStatus}
                                onClick={handleSwitchChange} />
                        </Form.Group>
                    </div>
                </div>
                {redirectionStatus &&
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="fill-screen-btn form-btn-right">
                                    <button type="button" className="btn full-screen-btn bg-green-dark" onClick={handleShow}><BsArrowsFullscreen /> Device Preview</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12  tab01 mt-4">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-12 col-md-12">
                                        {/* <Nav variant="pills" className="mb-4 flex-column">
                                            <Nav.Item className="mb-2">
                                                <Nav.Link eventKey="first">Header</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-2">
                                                <Nav.Link eventKey="second">Duration</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-2">
                                                <Nav.Link eventKey="third">Content</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-2">
                                                <Nav.Link eventKey="fourth">Background Color</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-2">
                                                <Nav.Link eventKey="fifth">Footer</Nav.Link>
                                            </Nav.Item>
                                        </Nav> */}
                                        <div className="accordion-list">
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0" className="mb-2">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><FaHeading /></div> <span className="tab-user-name" >Header</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="acc-content-body">
                                                            {/* <div className="select-brand">
                                                                    Select from Brand thumbnails
                                                                </div>
                                                                <div className="brand-image-preview mb-4">
                                                                    <img src={require('../../assets/images/logo/logo.png')} alt="" />
                                                            </div> */}
                                                            <div className="col-md-12">
                                                                <div className="content-body-form">
                                                                    <Form>
                                                                        {['radio'].map((type) => (
                                                                            <div key={`inline-${type}`} className="mb-3">


                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Brand Logo"
                                                                                    name="group1005"
                                                                                    type={type}
                                                                                    id={`inline-${type}-1005`}
                                                                                    checked={headerOption === 1}
                                                                                    onChange={() => showHeaderOption(1)}
                                                                                />
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Custom Logo"
                                                                                    name="group1005"
                                                                                    type={type}
                                                                                    id={`inline-${type}-1006`}
                                                                                    onChange={() => showHeaderOption(2)}
                                                                                    checked={headerOption === 2}
                                                                                />
                                                                                {/* <Form.Check
                                                                                    inline
                                                                                    label="Custom Text"
                                                                                    name="group1"
                                                                                    type={type}
                                                                                    id={`inline-${type}-3`}
                                                                                    onChange={() => showHeaderOption(3)}
                                                                                    defaultChecked={headerOption === 3}
                                                                                /> */}


                                                                            </div>
                                                                        ))}
                                                                    </Form>
                                                                    {
                                                                        headerOption == 1 ? (
                                                                            <>

                                                                                <Form>
                                                                                    {['radio'].map((type) => (
                                                                                        <div key={`inline-${type}`} className="mb-3">


                                                                                            <Form.Check
                                                                                                inline
                                                                                                label="Logo light"
                                                                                                name="group6789"
                                                                                                type={type}
                                                                                                id={`inline-${type}-6789`}
                                                                                                checked={brandHeaderLogoOption === 1}
                                                                                                onChange={() => hnadleHeaderLogoChange(1)}
                                                                                            />
                                                                                            <Form.Check
                                                                                                inline
                                                                                                label="Logo dark"
                                                                                                name="group6789"
                                                                                                type={type}
                                                                                                id={`inline-${type}-6788`}
                                                                                                onChange={() => hnadleHeaderLogoChange(2)}
                                                                                                checked={brandHeaderLogoOption === 2}
                                                                                            />
                                                                                            <Form.Check
                                                                                                inline
                                                                                                label="Logo full"
                                                                                                name="group6789"
                                                                                                type={type}
                                                                                                id={`inline-${type}-6787`}
                                                                                                onChange={() => hnadleHeaderLogoChange(3)}
                                                                                                checked={brandHeaderLogoOption === 3}
                                                                                            />

                                                                                        </div>
                                                                                    ))}
                                                                                </Form>
                                                                            </>
                                                                        ) : <></>
                                                                    }



                                                                    {
                                                                        headerOption == 2 ? (
                                                                            <div className="image-uploder-preview mb-3" >
                                                                                <input type="file"
                                                                                    accept="image/*"
                                                                                    onChange={handleSelectHeaderCustomLogo}
                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                        ) : <></>
                                                                    }

                                                                    {/* {
                                                                        headerOption == 3 ? (
                                                                            <div className="iFrame-uploder-preview">
                                                                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                                    <Form.Control className="did-floating-textarea" as="textarea" rows={3} value={iframeContent} onChange={(e) => { console.log(e.target.value); setIframeContent(e.target.value) }} />
                                                                                    <Form.Label className="did-floating-label">Enter iFrame URL</Form.Label>
                                                                                    <div className="mt-3">
                                                                                        <b>Want to show YouTube?</b>
                                                                                        <ol>
                                                                                            <li>Go back to your YouTube page,</li>
                                                                                            <li>Press Share option below the video,</li>
                                                                                            <li>Select the embed option,</li>
                                                                                            <li>Paste that code here.</li>
                                                                                        </ol>
                                                                                    </div>
                                                                                </Form.Group>
                                                                            </div>
                                                                        ) : <></>
                                                                    } */}
                                                                    <div className="col-md-12">
                                                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                            <Form.Select className="did-floating-select" value={headerPosition} onChange={handleHeaderPosition}>
                                                                                <option value="left">Left</option>
                                                                                <option value="center">Center</option>
                                                                                <option value="right">Right</option>
                                                                            </Form.Select>
                                                                            <Form.Label className="did-floating-label">Logo Position</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="content-body-form">
                                                                            <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1">
                                                                                <Form.Label>Logo Size</Form.Label>
                                                                                <Slider className="Duration-RangeSlider"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={customHeaderLogoSize}
                                                                                    // onChangeStart={handleChangeStart}
                                                                                    onChange={handleHeaderHeightChange}
                                                                                    // onChangeComplete={handleChangeComplete}
                                                                                    labels={horizontalLabels}
                                                                                />
                                                                            </Form.Group>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1" className="mb-2">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><GiDuration /></div> <span className="tab-user-name" >Duration</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="acc-content-body">
                                                            <div className="col-md-12">
                                                                <div className="content-body-form">
                                                                    <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1">
                                                                        <Form.Label>Duration</Form.Label>
                                                                        <Slider className="Duration-RangeSlider"
                                                                            min={0}
                                                                            max={100}
                                                                            value={duration}
                                                                            onChangeStart={handleChangeStart}
                                                                            onChange={handleChange}
                                                                            onChangeComplete={handleChangeComplete}
                                                                            labels={horizontalLabels}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <Form>
                                                                {['radio'].map((type) => (
                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                        <Form.Check
                                                                            inline
                                                                            label="Automatic Redirection"
                                                                            name="group9"
                                                                            defaultChecked={redirectionType == 1}
                                                                            type={type}
                                                                            id={`inline-${type}-8`}
                                                                            onClick={
                                                                                () => {
                                                                                    setShowText(false);
                                                                                    setRedirectionType(1);
                                                                                }

                                                                            }
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="Call to Action Button"
                                                                            name="group9"
                                                                            defaultChecked={redirectionType == 2}
                                                                            type={type}
                                                                            id={`inline-${type}-9`}
                                                                            onClick={() => {
                                                                                setShowText(true);
                                                                                setRedirectionType(2);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Form>

                                                            {showText && (
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="content-body-form">
                                                                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                                <Form.Control className="did-floating-input" type="text" placeholder={buttontext} value={buttontext} onChange={(e) => setButtontext(e.target.value)} />
                                                                                <Form.Label className="did-floating-label">Button Text</Form.Label>
                                                                            </Form.Group>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="content-body-form">
                                                                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                                <Form.Control className="did-floating-color" type="color" placeholder="" defaultValue={TextColor} onChange={(e) => setTextColor(e.target.value)} />
                                                                                <Form.Label className="did-floating-label">Text Color</Form.Label>
                                                                            </Form.Group>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="content-body-form">
                                                                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                                <Form.Control className="did-floating-color" type="color" placeholder="" defaultValue={ButtonBackgroundColor} onChange={(e) => setButtonBackgroundColor(e.target.value)} />
                                                                                <Form.Label className="did-floating-label">Background Color </Form.Label>
                                                                            </Form.Group>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="content-body-form">
                                                                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                                <Form.Select className="did-floating-select" value={buttonPosition} onChange={handlePositionChange}>
                                                                                    <option value="Left">Left</option>
                                                                                    <option value="Right">Right</option>
                                                                                    <option value="Center">Center</option>
                                                                                </Form.Select>
                                                                                <Form.Label className="did-floating-label">Button Position</Form.Label>
                                                                            </Form.Group>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="content-body-form">
                                                                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                                <Form.Control className="did-floating-select" as="select" value={fontFamily} onChange={(e) => setFontFamily(e.target.value)}>
                                                                                    <option value="Arial">Arial</option>
                                                                                    <option value="Arial Black">Arial Black</option>
                                                                                    <option value="Comic Sans MS">Comic Sans MS</option>
                                                                                    <option value="Courier New">Courier New</option>
                                                                                    <option value="Georgia">Georgia</option>
                                                                                    <option value="Impact">Impact</option>
                                                                                    <option value="Lucida Console">Lucida Console</option>
                                                                                    <option value="Lucida Sans Unicode">Lucida Sans Unicode</option>
                                                                                    <option value="Palatino Linotype">Palatino Linotype</option>
                                                                                    <option value="Tahoma">Tahoma</option>
                                                                                    <option value="Times New Roman">Times New Roman</option>
                                                                                    <option value="Trebuchet MS">Trebuchet MS</option>
                                                                                    <option value="Verdana">Verdana</option>
                                                                                </Form.Control>
                                                                                <Form.Label className="did-floating-label">Button Font Family</Form.Label>
                                                                            </Form.Group>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2" className="mb-2">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><BiBookContent /></div> <span className="tab-user-name" >Content</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="acc-content-body">
                                                            <Form>
                                                                {['radio'].map((type) => (
                                                                    <div key={`inline-${type}`} className="mb-3">


                                                                        <Form.Check
                                                                            inline
                                                                            label="Image"
                                                                            name="group15"
                                                                            type={type}
                                                                            id={`inline-${type}-15`}
                                                                            checked={contentOption == 1}
                                                                            onChange={() => showContentOption(1)}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="Video Upload"
                                                                            name="group15"
                                                                            type={type}
                                                                            id={`inline-${type}-16`}
                                                                            onChange={() => showContentOption(2)}
                                                                            checked={contentOption == 2}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="iFrame Embed"
                                                                            name="group15"
                                                                            type={type}
                                                                            id={`inline-${type}-17`}
                                                                            onChange={() => showContentOption(3)}
                                                                            checked={contentOption == 3}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="youtube"
                                                                            name="group15"
                                                                            type={type}
                                                                            id={`inline-${type}-18`}
                                                                            onChange={() => showContentOption(4)}
                                                                            checked={contentOption == 4}
                                                                        />

                                                                        {/* {
                                                                            redirectionId !== 0 && (
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="use old content"
                                                                                    name="group1"
                                                                                    type={type}
                                                                                    id={`inline-${type}-3`}
                                                                                    onChange={() => showContentOption(0)}
                                                                                    defaultChecked={contentOption === 0}

                                                                                />
                                                                            )

                                                                        } */}

                                                                    </div>
                                                                ))}
                                                            </Form>

                                                            {
                                                                contentOption == 1 ? (
                                                                    <>
                                                                        <div className="image-uploder-preview mb-3" >
                                                                            <input type="file"
                                                                                accept="image/*"
                                                                                onChange={handleImageChange}
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="content-body-form">
                                                                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                                    <Form.Select className="did-floating-select" value={imagePosition} onChange={handleImagePositionChange}>
                                                                                        <option value="contain">contain</option>
                                                                                        <option value="cover">cover</option>
                                                                                        <option value="fill">fill</option>
                                                                                    </Form.Select>
                                                                                    <Form.Label className="did-floating-label">Image Position</Form.Label>
                                                                                </Form.Group>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : <></>
                                                            }



                                                            {
                                                                contentOption == 2 ? (
                                                                    <div className="video-uploder-preview mb-3">
                                                                        <p><b>Note:</b> Want to use youtube? Use iframe option.<b /> </p>
                                                                        <input type="file"
                                                                            accept="video/*"
                                                                            onChange={handleVideoChange}
                                                                            className="form-control"
                                                                        />
                                                                        <Form>
                                                                            {['radio'].map((type) => (
                                                                                <div key={`inline-${type}`} className="mb-3">
                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="Auto Play"
                                                                                        name="group15"
                                                                                        type={type}
                                                                                        id={`inline-${type}-20`}
                                                                                        onClick={() => {
                                                                                            setShowText(true);
                                                                                            setAuto(true);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                        </Form>
                                                                    </div>
                                                                ) : <></>
                                                            }

                                                            {
                                                                contentOption == 3 ? (

                                                                    <div className="iFrame-uploder-preview">
                                                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                            <Form.Control className="did-floating-textarea" as="textarea" rows={3} value={iframeContent} onChange={(e) => { setIframeContent(e.target.value) }} />

                                                                            <Form.Label className="did-floating-label">Enter iframe </Form.Label>
                                                                            {/* <div className="mt-3">
                                                                                <b>Want to show YouTube?</b>
                                                                                <ol>
                                                                                    <li>Go back to your YouTube page,</li>
                                                                                    <li>Press Share option below the video,</li>
                                                                                    <li>Select the embed option,</li>
                                                                                    <li>Paste that code here.</li>
                                                                                </ol>
                                                                            </div> */}

                                                                        </Form.Group>
                                                                    </div>
                                                                ) : <></>
                                                            }
                                                            {
                                                                contentOption == 4 ? (
                                                                    <div className="iFrame-uploder-preview">
                                                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">


                                                                            {/* <Form.Control className="did-floating-textarea" as="textarea" rows={3} value={iframeContent} onChange={(e) => { console.log(e.target.value); setIframeContent(e.target.value) }} /> */}

                                                                            <input type="text" value={embedUrl} className="form-control did-floating-input" placeholder=""
                                                                                onChange={(e) => {

                                                                                    let youtubeLink = e.target.value;

                                                                                    const url = new URL(e.target.value);

                                                                                    if (url.searchParams.has("si")) {

                                                                                        const urlParts = youtubeLink.split('/');
                                                                                        const videoId = urlParts[urlParts.length - 1].split('?')[0];

                                                                                        youtubeLink = `https://www.youtube.com/watch?v=${videoId}`

                                                                                    }


                                                                                    setEmbedUrl(youtubeLink);
                                                                                }
                                                                                } />

                                                                            <Form.Label className="did-floating-label">Enter YouTube URL</Form.Label>
                                                                            {/* <div className="mt-3">
                                                                                <b>Want to show YouTube?</b>
                                                                                <ol>
                                                                                    <li>Go back to your YouTube page,</li>
                                                                                    <li>Press Share option below the video,</li>
                                                                                    <li>Select the embed option,</li>
                                                                                    <li>Paste that code here.</li>
                                                                                </ol>
                                                                            </div> */}

                                                                        </Form.Group>
                                                                    </div>
                                                                ) : <></>
                                                            }
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3" className="mb-2">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdColorLens /></div> <span className="tab-user-name" >Background Color</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="acc-content-body">
                                                            <div className="col-md-12">
                                                                <Form>
                                                                    {['radio'].map((type) => (
                                                                        <div key={`inline-${type}`} className="mb-3">
                                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                                <div className="d-flex align-items-center flex-wrap">
                                                                                    <div onClick={removeClass}>
                                                                                        <Form.Check
                                                                                            label="Single color"
                                                                                            name="group20"
                                                                                            type="radio"
                                                                                            id="reverseradio10"
                                                                                            className="me-4"
                                                                                            checked={backgroundType == 1}
                                                                                        />
                                                                                    </div>
                                                                                    <div onClick={addClass}>
                                                                                        <Form.Check
                                                                                            label="Color gradient"
                                                                                            name="group20"
                                                                                            type="radio"
                                                                                            id="reverseradio11"
                                                                                            checked={backgroundType == 2}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </Form.Group>

                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-color" type="color" placeholder="theme colors" defaultValue={BackgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} />
                                                                            <Form.Label className="did-floating-label">Page Color</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                    {collapse && (
                                                                        <>
                                                                            <div className="col-md-6">
                                                                                <Form.Group className="did-floating-label-content gradient" controlId="formBasicEmail">
                                                                                    <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={backgroundColor2} onChange={(e) => setBackgroundColor2(e.target.value)} />
                                                                                    <Form.Label className="did-floating-label">Gradient Color</Form.Label>
                                                                                </Form.Group>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                                    <Form.Control className="did-floating-select" as="select" value={colorPosition} onChange={handleValueChange}>
                                                                                        <option value="leftright">Left to Right</option>
                                                                                        <option value="rightleft">Right to Left</option>
                                                                                        <option value="topbottom">Top to Bottom</option>
                                                                                        <option value="bottomtop">Bottom to Top</option>
                                                                                    </Form.Control>
                                                                                    <Form.Label className="did-floating-label">Gradient Color Position</Form.Label>
                                                                                </Form.Group>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4" className="mb-2">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><RiFontSansSerif /></div> <span className="tab-user-name" >Footer</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="acc-content-body">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="content-body-form">
                                                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlText">
                                                                            <Form.Control className="did-floating-input" type="text" value={footerText} onChange={(e) => setFooterText(e.target.value)} placeholder="" />
                                                                            <Form.Label className="did-floating-label">Content Text</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="content-body-form">
                                                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                            <Form.Select className="did-floating-select" value={footerPosition} onChange={handleFooterPosition}>
                                                                                <option value="left">Left</option>
                                                                                <option value="center">Center</option>
                                                                                <option value="right">Right</option>
                                                                            </Form.Select>
                                                                            <Form.Label className="did-floating-label">Logo Position</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="content-body-form">
                                                                        <Form.Group className="did-floating-label-content gradient" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={footerTextColor} onChange={(e) => setFooterTextColor(e.target.value)} />
                                                                            <Form.Label className="did-floating-label">Text Color</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="content-body-form">
                                                                        <Form>
                                                                            {['radio'].map((type) => (
                                                                                <div key={`inline-${type}`} className="mb-3">


                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="Brand Logo"
                                                                                        name="group10020"
                                                                                        type={type}
                                                                                        id={`inline-${type}-10020`}
                                                                                        checked={footerOption === 1}
                                                                                        onChange={() => showFooterOption(1)}
                                                                                    />
                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="Custom Logo"
                                                                                        name="group10020"
                                                                                        type={type}
                                                                                        id={`inline-${type}-10021`}
                                                                                        onChange={() => showFooterOption(2)}
                                                                                        checked={footerOption === 2}
                                                                                    />
                                                                                    {/* <Form.Check
                                                                                    inline
                                                                                    label="Custom Text"
                                                                                    name="group1"
                                                                                    type={type}
                                                                                    id={`inline-${type}-3`}
                                                                                    onChange={() => showHeaderOption(3)}
                                                                                    defaultChecked={headerOption === 3}
                                                                                /> */}


                                                                                </div>
                                                                            ))}
                                                                        </Form>
                                                                        {
                                                                            footerOption == 1 ? (
                                                                                <>

                                                                                    <Form>
                                                                                        {['radio'].map((type) => (
                                                                                            <div key={`inline-${type}`} className="mb-3">


                                                                                                <Form.Check
                                                                                                    inline
                                                                                                    label="Logo light"
                                                                                                    name="group112"
                                                                                                    type={type}
                                                                                                    id={`inline-${type}-1132`}
                                                                                                    checked={brandFooterLogoOption === 1}
                                                                                                    onChange={() => hnadleFooterLogoChange(1)}
                                                                                                />
                                                                                                <Form.Check
                                                                                                    inline
                                                                                                    label="Logo dark"
                                                                                                    name="group112"
                                                                                                    type={type}
                                                                                                    id={`inline-${type}-1133`}
                                                                                                    onChange={() => hnadleFooterLogoChange(2)}
                                                                                                    checked={brandFooterLogoOption === 2}
                                                                                                />
                                                                                                <Form.Check
                                                                                                    inline
                                                                                                    label="Logo full"
                                                                                                    name="group112"
                                                                                                    type={type}
                                                                                                    id={`inline-${type}-1134`}
                                                                                                    onChange={() => hnadleFooterLogoChange(3)}
                                                                                                    checked={brandFooterLogoOption === 3}
                                                                                                />

                                                                                            </div>
                                                                                        ))}
                                                                                    </Form>
                                                                                </>
                                                                            ) : <></>
                                                                        }



                                                                        {
                                                                            footerOption == 2 ? (
                                                                                <div className="image-uploder-preview mb-3" >
                                                                                    <input type="file"
                                                                                        accept="image/*"
                                                                                        onChange={handleSelectFooterCustomLogo}
                                                                                        className="form-control"
                                                                                    />
                                                                                </div>
                                                                            ) : <></>
                                                                        }


                                                                        <div className="col-md-12">
                                                                            <div className="content-body-form">
                                                                                <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1">
                                                                                    <Form.Label>Logo Size</Form.Label>
                                                                                    <Slider className="Duration-RangeSlider"
                                                                                        min={0}
                                                                                        max={100}
                                                                                        value={customFooterLogoSize}
                                                                                        // onChangeStart={handleChangeStart}
                                                                                        onChange={handleFooterHeightChange}
                                                                                        // onChangeComplete={handleChangeComplete}
                                                                                        labels={horizontalLabels}
                                                                                    />
                                                                                </Form.Group>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 col-md-12">

                                        <div className="col-md-12">
                                            <div
                                                className="preview-box"
                                                style={{
                                                    ...(backgroundType === 1 ? { backgroundColor: BackgroundColor } : {}),
                                                    ...(backgroundType !== 1
                                                        ? {
                                                            backgroundImage: `linear-gradient(${gradientDirectionMap[colorPosition]}, ${BackgroundColor}, ${backgroundColor2})`,
                                                        }
                                                        : {})
                                                }}>
                                                <div className="preview-header">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="three-dot">
                                                            <span className="dot-red"><GrStatusGoodSmall /></span>
                                                            <span className="dot-yellow"><GrStatusGoodSmall /></span>
                                                            <span className="dot-green"><GrStatusGoodSmall /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`preview-web-header ${headerPosition}`}>
                                                    <div className="brand-logo">
                                                        <img src={header_brand_logo} alt="" style={{ height: customHeaderLogoSize + 'px' }} />
                                                    </div>
                                                </div>
                                                <div className="preview-body-redirection">
                                                    <div className="preview">
                                                        <div className="preview-design justify-content-center">
                                                            {
                                                                contentOption == 1 ? (
                                                                    <div className="image-uploder-preview">
                                                                        {image && <img src={image} alt="Preview" style={{ objectFit: imagePosition }} />}
                                                                    </div>
                                                                ) : contentOption == 2 ? (
                                                                    <div className="video-uploder-preview">
                                                                        {video && (
                                                                            <video controls style={{ width: '100%', }}>
                                                                                <source src={video} type="video/mp4" />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        )}
                                                                    </div>

                                                                ) : contentOption == 3 ? (
                                                                    <>
                                                                        <div className="ratio ratio-16x9" dangerouslySetInnerHTML={{ __html: iframeContent }}>
                                                                        </div>

                                                                    </>

                                                                ) : contentOption == 4 ? (<>
                                                                    <div className="youtube_video_box mb-3">
                                                                        <iframe width="560" height="315" src={embedUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                                    </div>
                                                                </>) : contentOption == 0 ? (

                                                                    <>
                                                                        {
                                                                            redirectioData.content_type == 1 ? (
                                                                                <div className="image-uploder-preview">
                                                                                    {redirectioData.image && <img src={API_URL + 'redirection/' + redirectioData.image} alt="Preview" style={{ width: '100%' }} />}
                                                                                </div>
                                                                            ) : redirectioData.content_type == 2 ? (
                                                                                <div className="video-uploder-preview">
                                                                                    {redirectioData.video && (
                                                                                        <video controls style={{ width: '100%', }}>
                                                                                            <source src={API_URL + 'redirection/' + redirectioData.video} type="video/mp4" />
                                                                                            Your browser does not support the video tag.
                                                                                        </video>
                                                                                    )}
                                                                                </div>

                                                                            ) : redirectioData.content_type == 3 ? (
                                                                                <>
                                                                                    <div className="ratio ratio-16x9" dangerouslySetInnerHTML={{ __html: redirectioData.iframeContent }}>
                                                                                    </div>

                                                                                </>

                                                                            ) : redirectioData.content_type == 4 ? (
                                                                                <>
                                                                                    <div className="youtube_video_box mb-3">
                                                                                        <iframe width="560" height="315" src={redirectioData.youtube_embed_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                                                    </div>
                                                                                </>
                                                                            ) : <></>
                                                                        }
                                                                    </>
                                                                ) : <></>
                                                            }
                                                        </div>
                                                        <div className={buttonPosition === 'Center' ? 'form-btn-center' : buttonPosition === 'Left' ? 'form-btn-left' : 'form-btn-right'}>
                                                            <div className="flot-right">
                                                                {/* <div className="skip-timer">
                                                                    <div className="timer">0:30 Redirection</div>
                                                                </div> */}
                                                                <div className="skip-btn" style={{ display: redirectionType === 1 ? 'none' : 'block' }}>
                                                                    <button type="button" className="btn btn-green" style={{ backgroundColor: ButtonBackgroundColor, color: TextColor, fontFamily: fontFamily }}>
                                                                        {buttontext}
                                                                        <BsSkipEndFill style={{ color: TextColor }} />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`preview-footer ${footerPosition}`}>
                                                    <div className="footer-brand-logo">
                                                        <img src={footer_brand_logo} style={{ height: customFooterLogoSize + 'px' }} alt="" />
                                                    </div>
                                                    <div class="web-footer-text" style={{ color: footerTextColor }}>{footerText}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                }
                <div className="col-md-12 mt-4">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            {
                                loading ? (
                                    <button type="button" className="btn bg-green-dark action-btn btn-green btn-spinner">
                                        <Spinner animation="border" role="status" />
                                    </button>
                                ) : (
                                    <button type="button" onClick={saveRedirection} className="btn bg-green-dark action-btn btn-green">
                                        Update
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Redirection;