import React, { useEffect } from 'react';


const BoardToken = 'b5cb378a-cfd5-0c9f-ed56-27ce521389ed';


const Bugs = () => {
 
    useEffect(() => {

        const user = JSON.parse(localStorage.getItem('user'))

        const name = user.fanme + " " + user.lname

        const email = user.email

        const profilePic = user.profile_image

        const theme = localStorage.getItem('theme')
       
        
            window.Canny('render', {
                boardToken: BoardToken,
                basePath: null, // See step 2
                ssoToken: null, // See step 3,
                theme: theme, // options: light [default], dark, auto
            });

            window.Canny('identify', {
                appID: '650afb424a24c6093c6d5139',
                user: {
                  // Replace these values with the current user's data
                  email: email,
                  name: name,
              
                  // These fields are optional, but recommended:
                  avatarURL: "https://xrl.ink/profile/" + profilePic,
                },
              });


       
    }, []);
    return (
        <>



         
                <div className="main-container">
                    <div className="main-page-body-content">
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">

                                <div data-canny />
                            </div>
                        </div>
                    </div>
                </div>

        </>

    );
};

export default Bugs;
