import { createContext, useState, useReducer, useMemo } from "react";

import { flexLinksReducers } from '../reducers/flexLinksReducers'


export const flexLinkContext = createContext();


export const FlexLinkProvider = ({ children }) => {

    const initialState = {
        isFetching: true,
        flexLinks: []
    };

    const [flexLink, flexLinkdispatch] = useReducer(flexLinksReducers, initialState);

    const flexLinkData = useMemo(() => flexLink, [flexLink]);

    return (
        <flexLinkContext.Provider value={{
            flexLinkData, flexLinkdispatch
        }}>
            {children}
        </flexLinkContext.Provider>
    );

};
