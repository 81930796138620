// src/components/List.js
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import ListItem from './ImageListItem';

const List = ({ list, items, updateimagegrid, removeImageGrid }) => {

    const handleDataChange = (id, data) => {
        updateimagegrid(id, data);
    };
    return (
        <Droppable droppableId={list.id} >
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index} >
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <ListItem item={item} index={index} handledatachange={handleDataChange} removeImageGrid={removeImageGrid}/>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default List;
