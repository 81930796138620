import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { SmartLinkContext } from "../../Components/CreateSmartlink/SmartLinkcontext";
import { savetrackdataAPI } from "../../BackendApi/Api/smartsLinkApi";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";

function TrackingCode() {

    const [loading, setLoading] = useState(false);

    const [smartlinkeditdata, setSmartlinkEditData, schedulesdata, setSchedulesData, linkcloakingdata, setLinkCloakingData, passwordprotection, setPasswordProtection, trackingdata, setTrackingData] = React.useContext(SmartLinkContext);

    const [googleanalytics, setGoogleAnalytics] = useState('');
    const [facebookpixel, setFacebookPixel] = useState('');
    const [adroll, setAdroll] = useState('');


    useEffect(() => {
        if (trackingdata && trackingdata.length > 0) {
            setGoogleAnalytics(trackingdata[0].google_tracking_code);
            setFacebookPixel(trackingdata[0].facebook_tracking_code);
            setAdroll(trackingdata[0].adroll_id);
        }
    }, [trackingdata])


    const savetrackdata = () => {
        const data = {
            "id": smartlinkeditdata._id,
            "google_tracking_code": googleanalytics,
            "facebook_tracking_code": facebookpixel,
            "adroll_id": adroll
        }

        setLoading(true);
        savetrackdataAPI(data).then((res) => {
            if (res.data.status == true) {
                PNotify.success({
                    title: 'Success',
                    text: 'Tracking Code Updated Successfully',
                });
                setTrackingData(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false);
        })
    }






    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                            <Form.Control className="did-floating-input" type="text" value={googleanalytics || ""} onChange={
                                (e) => {
                                    setGoogleAnalytics(e.target.value);
                                }
                            } placeholder=" " />
                            <Form.Label className="did-floating-label">Google Analytics</Form.Label>
                        </Form.Group>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                            <Form.Control className="did-floating-input" type="text" placeholder=" " />
                            <Form.Label className="did-floating-label">Google Tag Manager</Form.Label>
                        </Form.Group>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                            <Form.Control className="did-floating-input" type="text" value={facebookpixel} onChange={
                                (e) => {
                                    setFacebookPixel(e.target.value);
                                }
                            } placeholder=" " />
                            <Form.Label className="did-floating-label">Meta Pixel ID (Facebook)</Form.Label>
                        </Form.Group>
                    </div>
                </div>
                <div className="col-md-12">
                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                        <Form.Control className="did-floating-input" type="text" value={adroll} onChange={
                            (e) => {
                                setAdroll(e.target.value);
                            }
                        } placeholder=" " />
                        <Form.Label className="did-floating-label">AdRoll Pixel ID</Form.Label>
                    </Form.Group>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12 ">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            {
                                loading ? (
                                    <SpinnerLoader />
                                ) : (
                                    <button type="button" onClick={savetrackdata} className="btn bg-green-dark action-btn btn-green">
                                        Save
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackingCode;