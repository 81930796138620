import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import { Button } from "react-bootstrap";
import "./Qrtemplates.css";
import { TbTrash } from "react-icons/tb";
import { Modal } from "react-bootstrap";
import { MdOutlineQrCode } from "react-icons/md";
import Qr from "../assets/images/QR.webp";
import { TbEdit } from "react-icons/tb";
import { FaSearch } from "react-icons/fa"
import { Link } from "react-router-dom";
import EditQr from "../Components/EditQr/EditQr";
import Header from "../Components/Header/Header";
import { Redirect } from 'react-router-dom';
import { getTemplateApi } from "../BackendApi/Api/template";
import { deletetemplate } from "../BackendApi/Api/template";
import { API_URL } from "../BackendApi/Api/config";
import { toPng } from "html-to-image";
import { toJpeg } from "html-to-image";
import { downloadImage } from "../Helpers/imageDownload";
import CreatetemplateBrand from "../Components/Createtemplate/CreatetemplateBrand";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';



function Qrtemplates() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show03, setShow03] = useState(false);

    const handleClose03 = () => setShow03(false);
    const handleShow03 = () => setShow03(true);

    const [show04, setShow04] = useState(false);

    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);

    const [show05, setShow05] = useState(false);

    const handleClose05 = () => setShow05(false);
    const handleShow05 = () => setShow05(true);

    const [templateData, setTemplateData] = useState([]);
    const [templateImage, setTemplateImage] = useState("");

    const [filteredTemplateData, setFilteredTemplateData] = useState([]);
    const [templateSearch, setTemplateSearch] = useState("");

    const [templateStatus, setTemplateStatus] = useState(false);

    const divRef = useRef(null);

    const handleTemplateSearch = (e) => {
        const searchValue = e.target.value;
        setTemplateSearch(searchValue);
        const newFilter = templateData.filter((value) => {
            return value.template_name.toLowerCase().includes(templateSearch.toLowerCase());
        });

        if (searchValue === "" || searchValue.trim() === "") {
            setFilteredTemplateData(templateData);
        }
        else {
            setFilteredTemplateData(newFilter);
        }
    }


    const [editTemplateId, setEditTemplateId] = useState(null);

    const getTemplateData = () => {


        const url = window.location.href;
        const urlSplit = url.split('/');
        const urlbrandId = urlSplit[urlSplit.length - 1];

        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);

        // const created_by = user._id;
        // const owner_id = user.owner_id;
        // const role = user.role;

        const data = {
            // created_by: created_by,
            // owner_id: owner_id,
            // role: role
            brand_id: urlbrandId
        }

        getTemplateApi(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    setTemplateData(res.data.data);
                    setFilteredTemplateData(res.data.data);
                }
                else {
                    PNotify.error({
                        title: "Error",
                        text: "Template not created",
                        modules: {
                            Desktop: {
                                desktop: true
                            }
                        }
                    });
                }

            }
            else {
                PNotify.error({
                    title: "Error",
                    text: "Something went wrong",
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    }
                });
            }
        })
            .catch((err) => {
                console.log(err);
            });
    };


    const getusername = () => {
        //get user data from localstorage
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const username = user.fname + " " + user.lname;
        return username;
    }

    const [deleteId, setDeleteId] = useState("");

    const deleteTemplate = () => {

        const data = {
            id: deleteId
        }

        deletetemplate(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    PNotify.success({
                        title: "Success",
                        text: "Template deleted successfully",
                        modules: {
                            Desktop: {
                                desktop: true
                            }
                        }
                    });
                    getTemplateData();
                }
                else {
                    PNotify.error({
                        title: "Error",
                        text: "Template not deleted",
                        modules: {
                            Desktop: {
                                desktop: true
                            }
                        }
                    });
                }

            }
            else {
                PNotify.error({
                    title: "Error",
                    text: "Something went wrong",
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    }
                });
            }
        }).catch((err) => {
            console.log(err);
        });


    }


    useEffect(() => {
        getTemplateData();
    }, [templateStatus]);






    return (
        <>

            {/* QR Code modal start here */}
            <Modal show={show05} onHide={handleClose05} className="sidebar-edit-qr Sidebar-Modal-end lg-width"  >
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="main-card-title">QR Template</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <CreatetemplateBrand templateStatus={templateStatus} setTemplateStatus={setTemplateStatus} handleClose05={handleClose05} />
                </Modal.Body>
            </Modal>
            {/* modal end here */}

            {/* QR Code modal start here */}
            <Modal show={show} onHide={handleClose} centered className="qr-code-modal"  >

                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <img ref={divRef} src={API_URL + `qrcodes/` + templateImage} alt="" className="img-fluid popup-image" />
                        <div className="qr-btn-list">
                            <button className="btn qr-modal-btn me-2 " onClick={handleClose}>Share Link</button>
                            <button className="btn qr-modal-btn" onClick={() => {
                                handleClose();
                                downloadImage("png", divRef, templateImage);
                            }}>Download QR</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal end here */}

            {/* new domain modal start here */}
            <Modal show={show03} onHide={handleClose03} className="sidebar-edit-qr Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="main-card-title">Edit QR Template</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <EditQr templateId={editTemplateId} handleClose03={handleClose03} getTemplateData={getTemplateData} />
                </Modal.Body>
            </Modal>
            {/* new domain modal end here */}
            {/* Delete Modal Start From Here */}
            <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={
                        () => {
                            handleClose04();
                            deleteTemplate();
                        }
                    }>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Modal End Here */}
            {/* <Header /> */}

            <div className="container-fulid">
                <div className="row pd-26">
                    <div className="">
                        <div className="card-body table-card-body">
                            <div className="table-card-header add-btn d-flex">
                                <h5 className="main-card-title"><MdOutlineQrCode className="header-icon" />
                                    QR Templates
                                </h5>
                                <div className="card-content-header-right">
                                    <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow05} > <MdOutlineQrCode />New Template</Button>
                                </div>
                            </div>
                            <div className="wrap">
                                <form role="search" className="search-form form">
                                    <label>
                                        <span className="screen-reader-text">Search for...</span>
                                        <input type="search" className="search-field" placeholder="Type something..." value={templateSearch} onChange={handleTemplateSearch} />
                                    </label>
                                    <button class="search-submit button">
                                        <FaSearch />
                                    </button>
                                </form>
                            </div>
                            <div className="table-card-body-inner qr-mobile-hide">
                                <div className="Domain-table">
                                    <ul>
                                        {
                                            filteredTemplateData && filteredTemplateData.map((item) => {
                                                return (


                                                    <li className="qr-template-list-bg">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="Domain-table-view">
                                                                <div className="d-flex align-items-start">
                                                                    <div className="Qr-table-image">
                                                                        <button className="clr-theme text-decoration-none" onClick={
                                                                            () => {
                                                                                handleShow();
                                                                                setTemplateImage(item.thumbnail);
                                                                            }
                                                                        }  >
                                                                            <img src={API_URL + `qrcodes/` + item.thumbnail} alt="" className="img-fluid" />
                                                                        </button>
                                                                    </div>
                                                                    <div className="Domain-table-content">
                                                                        <h4>{item.template_name}</h4>
                                                                        <div className="d-flex flex-xl-row flex-lg-column temp-table-content">
                                                                            <h5>Last Updated:</h5>
                                                                            <h6>{(new Date(item.updated_on)).toLocaleDateString()}</h6>
                                                                            <h6>{(new Date(item.updated_on)).toLocaleTimeString("en-US", { minute: "2-digit", hour: "2-digit", })}</h6>
                                                                            <h6>by [
                                                                                {
                                                                                    getusername()
                                                                                }
                                                                                ]</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="Domain-table-action">
                                                                <div className="button-group">
                                                                    <button type="button" className="btn btn-edit me-3" onClick={
                                                                        () => {
                                                                            handleShow03();
                                                                            setEditTemplateId(item._id);
                                                                        }
                                                                    }>
                                                                        <TbEdit />
                                                                    </button>
                                                                    <button type="button" className="btn btn-delete" onClick={
                                                                        () => {
                                                                            handleShow04();
                                                                            setDeleteId(item._id);
                                                                        }
                                                                    } >
                                                                        <TbTrash />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>


                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="qr-template-mobile-show">

                        {
                            filteredTemplateData && filteredTemplateData.map((item) => {
                                return (
                                    <div className="qr-template-card">
                                        <div className="qr-template-card-body">
                                            <div className="qr-template-card-body-inner">
                                                <div className="qrtemplate-code-item">
                                                    <div className="mid-section-title"><h3 className="orb-typography h3">Template 1</h3></div>
                                                    <div className="SmartLinks-table-image">
                                                        <button className="clr-theme text-decoration-none" onClick={
                                                            () => {
                                                                handleShow();
                                                                setTemplateImage(item.thumbnail);
                                                            }
                                                        }  >
                                                            <img src={API_URL + `qrcodes/` + item.thumbnail} alt="" className="img-fluid" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="qr-code-details">
                                                    <div className="d-flex flex-xl-row flex-lg-column temp-table-content">
                                                        <h5>Last Updated:</h5>
                                                        <h6>{(new Date(item.updated_on)).toLocaleDateString("en-US", {
                                                            year: "numeric",
                                                            month: "2-digit",
                                                            day: "2-digit",
                                                        })}</h6>
                                                        <h6>{(new Date(item.updated_on)).toLocaleTimeString("en-US", {
                                                            minute: "numeric",
                                                            hour: "numeric",
                                                        })}</h6>
                                                        <h6>by [
                                                            {
                                                                getusername()
                                                            }
                                                            ]</h6>
                                                    </div>
                                                </div>
                                                <div className="qr-code-btn-list">
                                                    <div className="Domain-table-action">
                                                        <div className="button-group">
                                                            <button type="button" className="btn btn-edit me-3" onClick={
                                                                () => {
                                                                    handleShow03();
                                                                    setEditTemplateId(item._id);
                                                                }
                                                            }>
                                                                <TbEdit />
                                                            </button>
                                                            <button type="button" className="btn btn-delete" onClick={
                                                                () => {
                                                                    handleShow04();
                                                                    setDeleteId(item._id);
                                                                }
                                                            } >
                                                                <TbTrash />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Qrtemplates;