import { createContext, useState, useReducer, useMemo } from "react";

import {scheduledPostsReducer} from '../reducers/scheduledPostsReducer'


export const scheduledPostsContext = createContext();


export const ScheduledPostsProvider = ({ children }) => {

    const initialState = {
        isFetching: true,
        posts: []
    };
    
    const [scheduledPosts, scheduledPostsdispatch] = useReducer(scheduledPostsReducer, initialState);

    const scheduledPostsData = useMemo(() => scheduledPosts, [scheduledPosts]);

    return (
        <scheduledPostsContext.Provider value={{
            scheduledPostsData, scheduledPostsdispatch
        }}>
            {children}
        </scheduledPostsContext.Provider>
    );

};
       