import React from 'react'
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

const AddChannel = ({ CloseAddChannelModal, addnewchannel }) => {

    const [loading2, setLoading2] = useState(false);

    const [channelName, setChannelName] = useState('');
    const [channelicon, setChannelIcon] = useState(null);
    const [channelCategory, setChannelCategory] = useState('');
    const [url, setUrl] = useState('');

    const handleChannelCategoryChange = (e) => {
        setChannelCategory(e.target.value);
    };

    const handleChannelIcon = (e) => {
        setChannelIcon(e.target.files[0])
    }

    const addChannel = async (e) => {

        try {

            setLoading2(true)
            e.preventDefault();
            const data = localStorage.getItem("user");
            const user_id = JSON.parse(data)._id;
            const user = JSON.parse(data)
            const owner_id = JSON.parse(data).owner_id;

            const urlgg = window.location.href;
            const urlSplit = urlgg.split('/');
            const urlbrandId = urlSplit[urlSplit.length - 1];
          
            const formData = new FormData();
            formData.append('channel_icon', channelicon);
            formData.append('channel_name', channelName);
            formData.append('created_by', user_id);
            formData.append('owner_id', owner_id);
            formData.append('channel_category', channelCategory);
            formData.append('url', url);
            formData.append('brand_id', urlbrandId);
            formData.append('updator_name', user.fname + "" + user.lname);
            formData.append('updator_image', user.profile_image);
            if (channelName == '') {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: 'Please enter channel name',
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
                setLoading2(false)
            }
            else if (channelicon == null || channelicon == '') {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: 'Please select channel icon',
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
                setLoading2(false)
            } else if (channelCategory == '') {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: 'Please select channel category',
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
                setLoading2(false)
            }
            else if (url == '') {
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: 'Please enter url',
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
                setLoading2(false)
            }
            else {

                const result = await axios(`${API_URL}/branding/addnewsocialchannel`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData
                });

                console.log(result);

                if (result.data.status) {
                   
                    addnewchannel(result.data.data)
                    CloseAddChannelModal();
                    setLoading2(false)
                    PNotify.success({
                        title: 'Success',
                        delay: 2000,
                        text: 'Channel Added Successfully',
                        modules: {
                            Desktop: {
                                desktop: true
                            }
                        },
                    });
                    
                }
                else {
                    PNotify.error({
                        title: 'Error',
                        delay: 2000,
                        text: 'Something went wrong',
                        modules: {
                            Desktop: {
                                desktop: true
                            }
                        },
                    });
                    setLoading2(false)
                }

            }

        }

        catch (error) {
            setLoading2(false)
            PNotify.error({
                title: 'Error',
                delay: 2000,
                text: 'Something went wrong',
                modules: {
                    Desktop: {
                        desktop: true
                    }
                },
            });
        }

    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Create New Channel</Modal.Title>
            </Modal.Header>
            <Modal.Body className='custum-modal-body'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                            <Form.Control
                                className="did-floating-input" type="text"
                                placeholder="channel Name"
                                value={channelName}
                                onChange={(e) => setChannelName(e.target.value)} />
                            <Form.Label className="did-floating-label">Channel Name</Form.Label>
                        </Form.Group>
                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                            <Form.Control
                                className="did-floating-input" type="text"
                                placeholder="channel url"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)} />
                            <Form.Label className="did-floating-label">Url</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formBasicChannel" className="did-floating-label-content">
                            <Form.Control as="select" value={channelCategory} onChange={handleChannelCategoryChange} className="did-floating-select">
                                <option selected disabled>Select a Channel</option>
                                <option value="social">Social Media</option>
                                <option value="chat">Chat Platforms</option>
                                <option value="advertising">Advertising</option>
                                <option value="outreach">Direct Outreach</option>
                                <option value="online">Online</option>
                                <option value="out_home_media">Out of Home Media</option>
                                <option value="print_material">Print Material</option>
                                <option value="television">Television</option>
                            </Form.Control>
                            <Form.Label className="did-floating-label">Select Channel Category:</Form.Label>
                        </Form.Group>
                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                            <Form.Control className="did-floating-file" type="file" accept="image/*" onChange={handleChannelIcon} />
                            <Form.Label className="did-floating-label">Channel Icon</Form.Label>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {loading2 ? <button className="Active-bttn btn"><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading</button> :

                    <button className="Active-bttn btn" onClick={addChannel} disabled={loading2}> {loading2 ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : 'Add Channel'} </button>}
            </Modal.Footer>
        </>
    )
}

export default AddChannel