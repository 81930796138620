import React, { useState, useContext } from "react";
import "../../Qrtemplates/Qrtemplates.css";
import Accordion from 'react-bootstrap/Accordion';
import Fb from "../../assets/images/icons/facebook.png";
import Twitter from "../../assets/images/icons/twitter.png";
import Linkedin from "../../assets/images/icons/linkedin.png";
import Snapchat from "../../assets/images/icons/snapchat.png";
import apple from "../../assets/images/icons/apple.png";
import Insta from "../../assets/images/icons/instagram.png";
import google from "../../assets/images/icons/google.png";
import Tiktok from "../../assets/images/icons/tiktok.png";
import { TbInbox } from "react-icons/tb";
import { TemplateContext } from "../../TemplateContext";
import { API_URL } from "../../BackendApi/Api/config";
const Frontend_Url = process.env.REACT_APP_WEBSITE



function Addlogo() {

    const [pattern, setPattern, eye, setEye, logo, setLogo, frame, setFrame, patternColorType, setPatternColorType, patternDotsColor, setPatternDotsColor, patternGradientColor, setPatternGradientColor, eyeBallColor, setEyeBallColor, EyeCenterColor, setEyeCenterColor, backgroundcolortype, setBackgroundcolortype, backgroundcolorcode, setBackgroundcolorcode, height, setHeight, width, setWidth, eyeinside, setEyeinside] = React.useContext(TemplateContext);


    const [imageArray, setImageArray] = useState([
        {
            url: API_URL + "qrlogo/apple.png",
        },
        {
            url: API_URL + "qrlogo/facebook.png",
        },
        {
            url: API_URL + "qrlogo/google.png",
        },
        {
            url: API_URL + "qrlogo/instagram.png",
        },
        {
            url: API_URL + "qrlogo/linkedin.png",
        },
        {
            url: API_URL + "qrlogo/snapchat.png",
        },
        {
            url: API_URL + "qrlogo/tiktok.png",
        },
        {
            url: API_URL + "qrlogo/twitter.png",
        }

    ]);

    const handleLogo = (e) => {
        const newImage = e.target.files[0];


        // convert image file to base64 string
        const reader = new FileReader();
        reader.readAsDataURL(newImage);
        reader.onload = () => {
            setLogo(reader.result);
        }

        setLogo(newImage);
    }


    return (
        <>
            <Accordion.Header><TbInbox />Add Logo</Accordion.Header>
            <Accordion.Body>
                <div className="logo-content">
                    <div className="add-logo">
                        <div className="image-preview">
                            <div className="loading-screen" alt="Loading"><img src={logo}></img></div>
                        </div>
                        <div className="mt-2">
                            <div className="form-group block-upload">
                                <div className="upload-logo">
                                    <div className="custom-file"><input type="file" className="custom-file-input" onChange={
                                        handleLogo
                                    } id="customFile" alt="Choose" /><label className="custom-file-label" for="customFile">Choose</label></div>
                                </div>
                            </div>
                            <button className="btn btn-default btn-remove" onClick={
                                () => {
                                    setLogo(undefined);
                                }
                            } >Remove logo</button>
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-wrap ">

                        {imageArray && imageArray.map((item, index) => (
                            <div className="  me-4">
                                <button className="Qr-pattern"><img src={item.url} alt="Qr" onClick={
                                    () => {
                                        setLogo(item.url);
                                    }
                                } className="img-fluid" /></button>
                            </div>
                        ))}
                       
                    </div>
                </div>
            </Accordion.Body>
        </>
    )
}

export default Addlogo;