import React from "react";

function BuilderButton( props) {
    console.log("my props", props);
    return (
        <>
            <div className="button_content_box">
                <button className="btn link-btn-view bg_aqw" style={{ backgroundColor: props.buttonColor }}>{props.data.lable}</button>
            </div>
        </>
    );
}

export default BuilderButton;