import React, { useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import Loader2 from '../Loader/Loader2';

const ColumnChart = ({ deviceData }) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [loader, setLoader] = useState(false);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'," aqua"];

console.log("ggggggggggg",deviceData);
  return (
    <>
      {loader && <Loader2 />}
      <PieChart width={500} height={300}>
        <Pie
          data={Array.isArray(deviceData) && deviceData.map(entry => ({ name: entry._id, value: entry.value }))}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"

          labelLine={true}
          paddingAngle={5}
          dataKey="value"
          nameKey="name"
          label
        >
          {Array.isArray(deviceData) && deviceData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={selectedDevice === index ? COLORS[index % COLORS.length] : COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
      </PieChart>

      <div>
        {Array.isArray(deviceData) && deviceData.map((entry, index) => {
            console.log("entry", entry);
            const uniqueSet = new Set(entry.versions);
            const uniqueArray = [...uniqueSet];
            const versionInfo = entry.versions ? { version: uniqueArray.join(', ') } : null;
            const percentage = entry.percentage ? { percentage: entry.percentage } : null;
            let nullData = entry._id === null;

            if (entry.type === "device") {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      background: COLORS[index % COLORS.length],
                      marginRight: '8px',
                    }}
                  ></div>
                  <span>
                    {nullData ? "Others" : entry._id} : {entry.value}
                    {percentage && ` (${percentage.percentage})`}
                  </span>
                  <span style={{ marginLeft: '8px' }}>
                    {nullData ? "" : versionInfo && ` Version: ${versionInfo.version}`}
                  </span>
                </div>
              );
            }
            return null; // You might want to handle other types of entries here
          })}


      </div>
    </>
  );
};

export default ColumnChart;
