import React from "react";
import { API_URL } from "../../../BackendApi/Api/config";
import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";

function ImageGrid(props) {

    const saveClicks = (smartlink_id, unique_id) => {
        saveComponentsClicks({
            unique_id: unique_id,
            smartlink_id: smartlink_id,

        });
    }

    const items = Object.values(props.data.items);

    console.log("my image grid data", items)

    return (
        <>
            <div className="image-grid-custom mb-3">
                {items.map((item, index) => (
                    <div key={index} className="imagegrid-item">
                        <a href={item.link} onClick={() => { saveClicks(props.smartlink_id, item.unique_id) }} target="_blank">
                            <img
                                // src={API_URL + "flowpage/" + item.imagepreview}
                                src={(item.imagepreview.startsWith("http://") || item.imagepreview.startsWith("https://")) ? item.imagepreview : API_URL + "flowpage/" + item.imagepreview}

                                alt={`Image ${index}`} />
                        </a>
                    </div>
                ))}
            </div>
        </>
    );
}

export default ImageGrid;