import React from "react";
import { API_URL } from "../../../BackendApi/Api/config";

function ImageGrid(props) {

    console.log("my image grid props", props);



    const items = props.type == "auto" ? props.data : Object.values(props.data.items);

    console.log("my image grid data", items)





    return (
        <>
            <div className="image-grid-custom mb-3">
                {items.map((item, index) => (
                    <div key={index} className="imagegrid-item">
                        <a href={item.link}>
                            <img src={props.type == "auto" ? item.imagepreview: API_URL + "flowpage/" + item.imagepreview } alt={`Image ${index}`} />
                        </a>
                    </div>
                ))}
            </div>
        </>
    );
}

export default ImageGrid;