import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TbEdit, TbTrash } from 'react-icons/tb';
import Form from 'react-bootstrap/Form';
import { BiLinkAlt, BiLink } from 'react-icons/bi';
import { MdStorage } from 'react-icons/md';
import { TbArrowsShuffle2 } from 'react-icons/tb';
import { BsCursor } from 'react-icons/bs';
import { getApiApi } from '../../BackendApi/Api/api';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';



function TabData5({apiCreateStatus,setApiCreateStatus}) {
    const [show04, setShow04] = useState(false);

    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);

    const [show09, setShow09] = useState(false);

    const handleClose09 = () => setShow09(false);
    const handleShow09 = () => setShow09(true);

    const [apiData, setApiData] = useState([])
    

    const local_data = JSON.parse(localStorage.getItem("user"));

    const owner_id = local_data._id;

    console.log('owner_id', owner_id)

    const getApiData = () => {

        getApiApi({id:owner_id}).then((res) => {
            if (res.code == 200) {
                console.log('apidata', res)
                setApiData(res.data.data)
            }
            else {
                PNotify.error({
                    title: "Error",
                    text: res.message,
                });
            }
        }
        ).catch((error) => {
            PNotify.error({
                title: "Error",
                text: error,
            });
        })
    }

    useEffect(() => {
        getApiData();
    }, [apiCreateStatus])




    return (
        <div>
            {/* new Servicezone modal start here */}
            <Modal show={show09} onHide={handleClose09} className="Sidebar-Modal-end" >
                <Modal.Header closeButton>
                    <Modal.Title>Edit API</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>API Key Name</Form.Label>
                                <Form.Control type="text" placeholder="Key Name" />
                            </Form.Group>
                        </div>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Note</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder=" " />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={handleClose09}> Update </button>
                </Modal.Footer>
            </Modal>
            {/* modal end here */}

            {/* Delete Modal Start From Here */}
            <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title><TbTrash /> Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to Delete this ?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={handleClose04}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Modal End Here */}
            <div className="API-key">
                <div className="API-key-list">
                    <ul>
                        {apiData.map((item) => {

                            return (

                                <li >
                                    <div className="API-key-list-item d-flex">
                                        <div className="API-key-list-item-left">
                                            <h3>{item.api_name}</h3>
                                            <h4>Created Date - {item.created_on}</h4>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="d-flex align-items-center">
                                                <div className="button-group">
                                                    <button type="button" className="btn btn-edit me-2" onClick={handleShow09} >
                                                        <TbEdit />
                                                    </button>
                                                    <button type="button" className="btn btn-delete m-0" onClick={handleShow04} >
                                                        <TbTrash />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="API-key-details d-flex justify-mob01">
                                        <div className="API-key-details-left w-50">
                                            <h5> <BiLinkAlt />  Dynamic Links <span> </span></h5>
                                            <h5> <BiLink />  Simple Links<span> </span></h5>
                                            <h5> <TbArrowsShuffle2 /> Redirections<span> </span></h5>
                                            <h5> <BsCursor />  Visits<span> </span></h5>
                                            <h5> <MdStorage /> Storage Used<span> </span></h5>
                                        </div>
                                        <div className="API-key-details-right">
                                            <h5> {/* dynamic Link */} 05</h5>
                                            <h5> {/* Simple Links */} 2.5GB</h5>
                                            <h5> {/* Redirections */} 18</h5>
                                            <h5> {/* Visits */} 76</h5>
                                            <h5> {/* Storage Used */} 08</h5>
                                        </div>
                                    </div>
                                </li>


                            )
                        })
                        }


                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TabData5;