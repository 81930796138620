import React from "react";
import TabData6 from "../Components/SettingTabs/Tabbrand";
import Header from "../Components/Header/Header";
import Loader2 from "../Components/Loader/Loader2";

function CustomBrand() {

    const [loading, setLoading] = React.useState(true);

    const load = (data) => {
        setLoading(data);
    }

    return (
        <>
            {loading && <Loader2 />}
            <Header />

            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <TabData6 load={load} />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CustomBrand;