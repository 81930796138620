// import React, { useEffect, useState ,useRef } from 'react';
// import { FiCopy } from "react-icons/fi";

 
//  import Button from 'react-bootstrap/Button';
// import Overlay from 'react-bootstrap/Overlay';
// import Tooltip from 'react-bootstrap/Tooltip';

// function CopyCat({ text }) {

//     const tooltipref = useRef(null);
//     const [show, setShow] = useState(false);
//     const target = useRef(null);


//     const [copyToolTip, setCopyToolTip] = useState('Copy URL');
//     const copytext = () => {
//         navigator.clipboard.writeText(text)
//             .then(() => {
//                 console.log('URL copied to clipboard');
//                 setCopyToolTip('Copied');
//                 // Add any additional logic here
//             })
//             .catch((error) => {
//                 console.error('Failed to copy URL to clipboard', error);
//             });
//     }


//      useEffect(() => {
// console.log(show)
//      },[show])




//     return (
 

// <>
//       <button 
//       className="btn btn-sm btn-copy"
//       ref={target} 
//       onClick={() => {copytext();}}  
//       onMouseOut={() => {setCopyToolTip('Copy URL');setShow(false);}}
//       onMouseOver={() => {setShow(true);}}
//       onMouseEnter={() => {setShow(true);}}
//       >
//         <FiCopy />
//       </button>
//       <Overlay target={target.current} show={show} placement="top">
//         {(props) => (
//           <Tooltip  ref={tooltipref} {...props}>
//             {
//                 copyToolTip
//             }
//           </Tooltip>
//         )}
//       </Overlay>
//     </>



//      )


// }

// export default CopyCat;



import React, { useState, useRef } from 'react';
import { FiCopy } from "react-icons/fi";
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

function CopyCat({ text }) {
  const tooltipRef = useRef(null);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [copyToolTip, setCopyToolTip] = useState('Copy URL');

  const copyText = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          setCopyToolTip('Copied');
          console.log('Text copied to clipboard');
        })
        .catch((error) => {
          console.error('Failed to copy text to clipboard', error);
        });
    } else {
      // Fallback for environments without Clipboard API
      try {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setCopyToolTip('Copied');
      } catch (error) {
        console.error('Copying to clipboard is not supported in this environment.', error);
      }
    }
  }

  return (
    <>
      <button
        className="btn btn-sm btn-copy"
        ref={target}
        onClick={copyText}
        onMouseOut={() => {
          setCopyToolTip('Copy URL');
          setShow(false);
        }}
        onMouseOver={() => {
          setCopyToolTip('Copy URL');
          setShow(true);
        }}
      >
        <FiCopy />
      </button>
      <Overlay target={target.current} show={show} placement="top">
        {(props) => (
          <Tooltip ref={tooltipRef} {...props}>
            {copyToolTip}
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}

export default CopyCat;








