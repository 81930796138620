import React, { useEffect, useRef, useState } from "react";
import { SiInstagram } from "react-icons/si";
import { BsWhatsapp } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsTiktok } from "react-icons/bs";
import { BsSnapchat } from "react-icons/bs";
import { BsPaypal } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { BsPinterest } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { FaYoutube, FaTwitter } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { CiGlobe } from "react-icons/ci";
import { GrFormPreviousLink } from "react-icons/gr";
import { GrFormNextLink } from "react-icons/gr";
import { API_URL } from "../../BackendApi/Api/config";




export default function AutoGeneratedFlex({ myComponents, myref, }) {

    console.log("***myComponents", myComponents);

    // convert json object to array
    const myComponentsArr = Object.values(myComponents);


    let myComponents2 = [

        {
            type: 'HEADER', properties: {
                title: ["Mahindra Group"],
                description: 'Mahindra Group is an Indian multinational conglome…perations in over 100 countries across the globe.',
                headerFormat: 'H3', profileImage: 'https://th.bing.com/th/id/OIP.42kTZsTplLrC9VWofKvbDAHaHa?w=200&h=200&c=7&r=0&o=5&pid=1.7',
                altText: 'Mahindra Group Logo',
                backgroundImage: 'https://th.bing.com/th/id/OIP.PNSUZs2OiZ5TIn2lXczsBQHaFC?w=285&h=194&c=7&r=0&o=5&pid=1.7'
            }, showStatus: true
            , type: "HEADER", showStatus: true
        },

        {
            type: 'TITLE', properties:
                { headding: 'About Mahindra Group' }, showStatus: true
        },

        {
            type: 'IMAGETEXT', properties: {
                imageAltText: "Mahindra Manufacturing",
                imageCaption: "Mahindra's core automotive manufacturing",
                imageLink: "https://th.bing.com/th/id/OIG.QpVkkQ0_8u0iiKgzb6ZH?pid=ImgGn",
                link: "https://www.mahindra.com/",
                unique_id: "mahindra-img-01"
            }, showStatus: true
        },

        {
            type: 'IMAGEGRID', properties: [
                { unique_id: 'mahindra-grid-01', imageurl: 'https://th.bing.com/th/id/OIG.8CTLAcLrAFZXPVBu5NcH?pid=ImgGn', imagepreview: 'https://th.bing.com/th/id/OIG.8CTLAcLrAFZXPVBu5NcH?pid=ImgGn', alt: "Mahindra's electric vehicle", link: 'https://www.mahindra.com/' },
                { unique_id: 'mahindra-grid-02', imageurl: 'https://th.bing.com/th/id/OIP.UH7uo11ZUsAK9X_vVjRQswHaHa?w=210&h=210&c=7&r=0&o=5&pid=1.7', imagepreview: 'https://th.bing.com/th/id/OIP.UH7uo11ZUsAK9X_vVjRQswHaHa?w=210&h=210&c=7&r=0&o=5&pid=1.7', alt: "Mahindra's tractors and farm equipment", link: 'https://www.mahindra.com/' }
                , { unique_id: 'mahindra-grid-03', imageurl: 'https://images.unsplash.com/photo-1501707305551-9b2adda5e527', imagepreview: 'https://images.unsplash.com/photo-1501707305551-9b2adda5e527', alt: "Mahindra's aerospace technology", link: 'https://www.mahindra.com/' }
            ], showStatus: true
        },

        {
            type: 'BUTTON', properties:
                { lable: 'Learn More', link: 'https://www.mahindra.com/', buttonColor: 'green', unique_id: 'mahindra-btn-01' }, showStatus: true
        },

        {
            type: 'TEXT', properties:
                { html: '<p>Mahindra Group is known for its diverse portfol…trong focus on sustainability and innovation.</p>' }, showStatus: true
        },

        {
            type: 'ICONS', properties: {
                itemOrder: ['social-1', 'social-2', 'social-3', 'social-4'],
                items: {
                    "social- 1": { unique_id: '17050414093382fab0224-6b30-49fc-a12c-f0fd163b66fc', id: 'social-1', type: 'facebook', url: 'https://www.facebook.com/MahindraRise' },
                    "social - 2": { unique_id: '1705041411748a2b280d6-5881-4115-af08-c487bf5b91a1', id: 'social-2', type: 'twitter', url: 'https://twitter.com/MahindraRise' },
                    "social - 3": { unique_id: '1705041415507ceba7698-ce00-45e3-912d-b4a6c5d8c8e7', id: 'social-3', type: 'instagram', url: 'https://www.instagram.com/mahindra_rise/' },
                    "social - 4": { unique_id: '170504141802707477537-6b69-43ec-82ea-2c95c1c0c15f', id: 'social-4', type: 'linkedin', url: 'https://www.linkedin.com/company/mahindra-&-mahindra' }
                },
                listOrder: ["list-1"],
                lists: { "list-1": { id: 'list-1', title: 'Connect with Mahindra' } }
            }, showStatus: true
        },
        {
            type: "SPOTIFY",
            properties: {
                "realLink": "https://open.spotify.com/track/6LJG84ULDJA9Q1bwEKzkTT?si=49e2fac2992b44e2",// ADD ONLY SPOTIFY LINK HERE link like https://open.spotify.com/track/2ZRo7axmMPeSVUvDbGkJah  any spotify link 
                "link": "https://open.spotify.com/track/6LJG84ULDJA9Q1bwEKzkTT?si=49e2fac2992b44e2"/// ADD ONLY SPOTIFY LINK HERE link like spotify:track:2ZRo7axmMPeSVUvDbGkJah  any spotify link  
            },
            "showStatus": true
        },
        {
            type: "YOUTUBEIFRAME",
            properties: {
                Link: '<iframe width="560" height="315" src="https://www.youtube.com/embed/ORIHxlnajcI?si=B1Q1qOf6LQxjLcYk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
            },
            "showStatus": true
        },
        {
            type: "YOUTUBELINK",
            properties: {
                "realLink": "https://www.youtube.com/watch?v=l0LOA6bmZI0",// ADD ONLY YOUTUBE LINK HERE WITH NOT EMBED like " https://www.youtube.com/watch?v=l0LOA6bmZI0" choose brandname from youtube 
                "link": "https://www.youtube.com/embed/l0LOA6bmZI0"// ADD ONLY YOUTUBE LINK HERE WITH EMBED like "https://www.youtube.com/embed/l0LOA6bmZI0" choose brandname from youtube 
            },
            "showStatus": true
        },
    ]

    return (
        <>

            <div className="choose-template-list-inner-item-img-1">

                <div className="page_builder_design_body" style={{ height: "100%" }} id="capture"  >
                    <div className={`builder-box-design goWHMr `} style={{ height: "100%" }} ref={myref} >
                        <div className="builder_content" >

                            {myComponentsArr.map((component, index) => (
                                <>
                                    {
                                        component.type === "HEADER" && component.showStatus === true ? <BuilderHeader type="auto" data={component.properties} /> :
                                            component.type === "TITLE" && component.showStatus === true ? <BuilderTitle type="auto" data={component.properties} /> :
                                                component.type === "ICONS" && component.showStatus === true ? <BuilderIcon type="auto" data={component.properties} /> :
                                                    component.type === "BUTTON" && component.showStatus === true ? <BuilderButton type="auto" data={component.properties} /> :
                                                        component.type === "TEXT" && component.showStatus === true ? <BuilderText type="auto" data={component.properties} /> :
                                                            component.type === "IMAGETEXT" && component.showStatus === true ? <ImageText type="auto" data={component.properties} /> :
                                                                component.type === "YOUTUBELINK" && component.showStatus === true ? <YoutubeLink type="auto" data={component.properties} /> :
                                                                    //component.type === "SPOTIFY" && component.showStatus === true ? <Spotify type="auto" data={component.properties} /> :
                                                                    // component.type === "IMAGEGRID" && component.showStatus === true ? <ImageGrid type="auto" data={component.properties} /> :
                                                                    //component.type === "YOUTUBEIFRAME" && component.showStatus === true ? <Iframe type="auto" data={component.properties} /> :
                                                                    component.type === "CONTACTUS" && component?.showStatus === true ? <ContactUs data={component.properties} /> :
                                                                        component.type === "TESTIMONIAL" && component?.showStatus === true ? <Testimonials data={component.properties} /> :
                                                                            null

                                    }
                                </>
                            ))}
                            <div className="company_name">
                                <h5>Made by WithAstral</h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export const BuilderHeader = (props) => {

    const data = props.data


    return (
        <>
            {data.headerFormat == "H1" ?
                (
                    <div className="builder_content_header horizontal-header" style={{ display: 'block' }}>
                        <div className="d-flex gap-3 mb-3" >
                            <img src={data.profileImage} alt="" className="user-set-profile" />
                            <div>
                                <h1 className="builder_content_header_title tit_clor" color="#232323">{data.title}</h1>
                                <p className="builder_content_header_subtitle des_class" color="#232323">  {data.description}</p>
                            </div>
                        </div>
                    </div>
                ) : data.headerFormat == "H2" ?
                    (
                        <div className="builder_content_header vertical-header" style={{ display: 'block' }}>
                            <div className="builder_content_header_next mb-3">
                                <img src={data.profileImage} alt={data.altText} className="user-set-profile" />
                                <h1 className="builder_content_header_title tit_clor" color="#232323">{data.title}</h1>
                                <p className="builder_content_header_subtitle des_class" color="#232323">  {data.description}</p>
                            </div>
                        </div>
                    ) : data.headerFormat == "H3" ?
                        (
                            <div style={{ display: 'block' }}>
                                <div className="builder_content_header vertical-header addBgclass cGOoFD" style={{ backgroundImage: `url( ${data.backgroundImage})` }}>
                                    <div className="builder_content_header_next mb-3">
                                        <img src={data.profileImage} alt={data.altText} className="user-set-profile" />
                                        <h1 className="builder_content_header_title tit_clor" color="#232323">{data.title}</h1>
                                        <p className="builder_content_header_subtitle des_class" color="#232323">  {data.description}</p>
                                    </div>
                                </div>
                            </div>
                        ) : data.headerFormat == "H4" ?
                            (

                                <div className="builder_content_header" style={{ display: 'block' }}>
                                    <img src={data.backgroundImage} alt="" className="header-bg-img" />
                                    <div className="builder_content_header_next mb-3">
                                        <img src={data.profileImage} alt={data.altText} className="user-set-profile Qwedr" />
                                        <h1 className="builder_content_header_title tit_clor" color="#232323">{data.title}</h1>
                                        <p className="builder_content_header_subtitle des_class" color="#232323"> {data.description}</p>
                                    </div>
                                </div>
                            ) : (
                                <div className="builder_content_header" style={{ display: 'block' }}>
                                    <img src={data.backgroundImage} alt="" className="header-bg-img" />
                                    <div className="builder_content_header_next mb-3">
                                        <img src={data.profileImage} alt={data.altText} className="user-set-profile Qwedr" />
                                        <h1 className="builder_content_header_title tit_clor" color="#232323">{data.title}</h1>
                                        <p className="builder_content_header_subtitle des_class" color="#232323"> {data.description}</p>
                                    </div>
                                </div>
                            )}
        </>
    );
}


export const BuilderTitle = (props) => {
    return (
        <>
            {props.data.headding !== "" && <div className="text_content_box">
                <h2 className="m-0">{props.data.headding}</h2>
            </div>
            }
        </>
    );
}


export const BuilderIcon = (props) => {


    const socials = Object.values(props.data.items);
    return (
        <>
            {socials.length > 0 && <div className="social_item_list">
                {socials.map((item, index) => (
                    <div key={index}>
                        {
                            item.type == "FACEBOOK" ? <a > <BsFacebook /></a> :

                                item.type == "INSTAGRAM" ? <a ><SiInstagram /></a> :

                                    item.type == "WHATSAPP" ? <a ><BsWhatsapp /></a> :

                                        item.type == "TWITTER" ? <a ><FaTwitter /></a> :

                                            item.type == "LINKEDIN" ? <a ><BsLinkedin /></a> :

                                                item.type == "YOUTUBE" ? <a ><FaYoutube /></a> :

                                                    item.type == "TIKTOK" ? <a ><BsTiktok /></a> :

                                                        item.type == "SNAPCHAT" ? <a ><BsSnapchat /></a> :

                                                            item.type == "PAYPAL" ? <a ><BsPaypal /></a> :

                                                                item.type == "TELEGRAM" ? <a ><BsTelegram /></a> :

                                                                    item.type == "PINTEREST" ? <a ><BsPinterest /></a> : ""

                        }
                    </div>
                ))}

            </div>}
        </>
    );
}


export const BuilderButton = (props) => {
    return (
        <>
            <div className="button_content_box">
                <button className="btn link-btn-view bg_aqw" style={{ backgroundColor: props.data.buttonColor }}>{props.data.lable}</button>
            </div>
        </>
    );
}

export const BuilderText = (props) => {
    console.log("my text display props", props);
    return (
        <>
            <div className="text_content_box">

                <div dangerouslySetInnerHTML={{ __html: props.data.html }} />
            </div>
        </>
    );
}

export const ImageText = (props) => {
    return (
        <>
            <div className="image_with_text_content color_bg_text mb-3">
                <div className="brand_img_set">
                    <img src={props.data.imageLink} alt={props.data.imageAltText} />
                </div>
                <div className="image_text_content">
                    <p className="image_text_content_title m-0">{props.data.imageCaption}</p>
                </div>
            </div>
        </>
    );
}

export const YoutubeLink = (props) => {
    const items = props.data.realLink.replace("watch?v=", "embed/");
    return (
        <>
            {items !== "" && items.startsWith("https://www.youtube.com/embed") && <div className="youtube_video_box mb-3">
                <iframe width="560" height="315" src={items} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            }
        </>
    );
}

export const Spotify = (props) => {
    return (
        <>
            {props.data.realLink !== "" &&
                <div className="youtube_video_box mb-3">
                    <iframe width="560" height="315" src={props.data.link ? props.data.link : ""} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>}

        </>
    );
}

export const ImageGrid = (props) => {

    const items = props.type == "auto" ? props.data : Object.values(props.data.items);




    return (
        <>
            <div className="image-grid-custom mb-3">
                {items.map((item, index) => (
                    <div key={index} className="imagegrid-item">
                        <img src={item.imagepreview.startsWith("https://") ? item.imagepreview : item.imageurl} alt={`Image ${index}`} />
                    </div>
                ))}
            </div>
        </>
    );
}

export const Iframe = (props) => {
    return (
        <>
            {props.data.Link !== "" && props.data.Link.startsWith("<iframe") && <div className="youtube_video_box mb-3">
                <div dangerouslySetInnerHTML={{ __html: props.data.Link }}>
                </div>
            </div>}
        </>
    );
}

export const ContactUs = (props) => {
    return (
        <>
            <div className="contact_list">
                <div className="contact-card">
                    <div className="contact-card-inner">
                        <div className="contact-us-title">Contact Us</div>
                        <div className="cont-xys">
                            <div className="cont-icon">
                                <FaLocationDot />
                            </div>
                            <div className="contact-details-text">
                                {/* GANPATI PLAZA, No 109, Mirza Ismail Rd, Sindhi Camp, Jaipur, Rajasthan 302001 */}
                                {props.data.address}
                            </div>
                        </div>
                        <div className="cont-xys">
                            <div className="cont-icon">
                                <IoCall />
                            </div>
                            <div className="contact-details-text">
                                {/* 9874563210 / 7896541230 */}
                                {props.data.phone}
                            </div>
                        </div>
                        <div className="cont-xys">
                            <div className="cont-icon">
                                <IoMdMail />
                            </div>
                            <div className="contact-details-text">
                                {/* info@companyname.in */}
                                {props.data.email}
                            </div>
                        </div>
                        <div className="cont-xys">
                            <div className="cont-icon">
                                <CiGlobe />
                            </div>
                            <div className="contact-details-text">
                                {/* www.userwebsite.com */}
                                {props.data.website}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export const Testimonials = ({ data }) => {
    const [testimonials, setTestimonials] = useState([]);


    useEffect(() => {
        if (data.length > 0) {
            console.log("data janaman", data);
            setTestimonials(data);
        }
    }, [data]);

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === testimonials.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? testimonials.length - 1 : prevSlide - 1));
    };

    return (
        <>
            <div className="testimonial-slider">
                <div className="testimonial">

                    <button className="prevClick" onClick={prevSlide}><GrFormPreviousLink /></button>
                    <img src={
                        testimonials[currentSlide]?.imagePreview.startsWith('http') || testimonials[currentSlide]?.imagePreview.startsWith('https') ? testimonials[currentSlide]?.imagePreview : API_URL + "flowpage/" + testimonials[currentSlide]?.imagePreview
                    } alt={`Portrait of ${testimonials[currentSlide]?.name}`} className="testimonial-image" />
                    <button className="nextClick" onClick={nextSlide}><GrFormNextLink /></button>
                    <p className="comment">{testimonials[currentSlide]?.review}</p>
                    <p className="name">{testimonials[currentSlide]?.name}</p>
                </div>

            </div>

        </>
    );
}