
export function brandSelectionReducer(state, action) {
    switch (action.type) {
        case 'SET_BRANDS_DATA':

            console.log('SET_BRANDS_DATA', action.payload)
            return {
                ...state,
                brands: action.payload,
            }
        
        case 'SET_SELECTED_BRAND':
            return {
                ...state,
                selected: action.payload
            }

        default:

            return state

    }
}
