import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useBuilderContext } from "../../../builderContext";
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';
import SunEditor from "suneditor-react";

export function EditText() {
    const { editComponent } = useBuilderContext();
    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus;
    const [html, setHtml] = useState("");
    const [status, setStatus] = useState("");
    const editorInstance = useRef(null);

    useEffect(() => {
        if (mydata && mydata.html) {
            setHtml(mydata.html);
        }
        if (showStatus) {
            setStatus(showStatus);
        }
    }, [mydata, showStatus]);

    const handleSaveClick = () => {
        editComponent(position, "TEXT", { html }, status);
    };

    const getSunEditorInstance = (sunEditor) => {
        editorInstance.current = sunEditor;
        if (html && sunEditor.getContents() !== html) {
            sunEditor.setContents(html);
        }
    };

    const handleEditorChange = (content) => {
        if (content !== html) {
            setHtml(content);
        }
    };

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor="" color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Text</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="form-group mb-2">
                        <label htmlFor="exampleInputEmail1">Text</label>
                        <div>
                            <SunEditor
                                getSunEditorInstance={getSunEditorInstance}
                                setContents={html}
                                onChange={handleEditorChange}
                                setOptions={{
                                    height: "150px",
                                    plugins: plugins,
                                    buttonList: [
                                        ['undo', 'redo'],
                                        ['font', 'fontSize', 'formatBlock'],
                                        ['paragraphStyle', 'blockquote'],
                                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                        ['fontColor', 'hiliteColor'],
                                        ['removeFormat', 'link'],
                                        ['outdent', 'indent'],
                                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                                        ['fullScreen', 'showBlocks', 'codeView'],
                                        ['preview', 'print'],
                                    ],
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={handleSaveClick}>
                    Save
                </button>
            </div>
        </>
    );
}