import React, { useState, useEffect, useContext } from "react";
import { SmartLinkProvider } from "./SmartLinkcontext";
import CreateSmartlink from "./CreateSmartlink";


const SmartLinkeditMain = () => {

    return (
        <>
           
                <CreateSmartlink />
          
        </>

    );

}


export default SmartLinkeditMain;
