import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { useBuilderContext } from "../../../builderContext";
import { API_URL } from "../../../BackendApi/Api/config";
import { uploadSingleImageApi } from "../../../BackendApi/Api/flowpageApi";

export function EditImage() {

    const navigate = useHistory();

    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus;
    const [status, setStatus] = React.useState("")
    console.log("sasur ka nati data kaha hai be mydata", mydata);

    const { editComponent } = useBuilderContext();

    const [image, setImage] = React.useState(null);

    const [imageProperties, setImageProperties] = React.useState({
        unique_id: "",
        imageLink: "",
        imageCaption: "",
        imageAltText: "",
        link: "",
    });


    const updateImageData = (e) => {


        if (image === null) {

            editComponent(position, "IMAGETEXT", imageProperties,status)
        }

        if (image !== null) {
            const data = new FormData();
            data.append('image', image);
            uploadSingleImageApi(data).then((res) => {

                if (res.data.status == true) {

                    imageProperties.imageLink = res.data.data.filename;
                    editComponent(position, "IMAGETEXT", imageProperties,status)
                } else {
                    editComponent(position, "IMAGETEXT", imageProperties,status)
                }
            })

        }
    }

    useEffect(() => {
        if (mydata) {
            setImageProperties({
                imageLink: mydata?.imageLink,
                imageCaption: mydata?.imageCaption,
                imageAltText: mydata?.imageAltText,
                link: mydata?.link,
                unique_id: mydata?.unique_id
            })
        }

        if (showStatus) {
            setStatus(showStatus)
        }

    }, [])




    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Image</label>
                    </div>
                </div>
                <div className="form_block iuEvSC">
                    <input type="file" className="form-control" placeholder=""
                        onChange={(e) => {
                            setImage(e.target.files[0]);
                        }} />
                </div>
                <div className="form_box cvtPmc">
                    <div className="form-group did-floating-label-content">
                        <div className="form_block">
                            <textarea className="form-control did-floating-textarea" placeholder="" value={imageProperties.imageCaption}
                                onChange={
                                    (e) => {
                                        setImageProperties({ ...imageProperties, imageCaption: e.target.value })
                                    }
                                } />
                            <label htmlFor="exampleInputEmail1" className="gvofes did-floating-label">Image caption</label>
                        </div>
                    </div>
                    <div className="form-group did-floating-label-content">
                        <div className="form_block">
                            <input type="text" className="form-control did-floating-input" placeholder="" value={imageProperties.link}
                                onChange={
                                    (e) => {
                                        setImageProperties({ ...imageProperties, link: e.target.value })
                                    }
                                } />
                            <label htmlFor="exampleInputPassword1" className="gvofes did-floating-label">Link</label>
                        </div>
                    </div>
                    <div className="form-group did-floating-label-content">
                        <div className="form_block">
                            <input type="text" value={imageProperties.imageAltText}
                                className="form-control did-floating-input" placeholder=""
                                onChange={
                                    (e) => {
                                        setImageProperties({ ...imageProperties, imageAltText: e.target.value })
                                    }
                                } />
                            <label htmlFor="exampleInputPassword1" className="gvofes did-floating-label">Alt text</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={
                    () => {
                        updateImageData()
                    }
                }>
                    Update
                </button>
            </div>
        </>
    );
}