import React from "react";
import Bulkschedule from "./bulkschedule";

function Createpost() {
    return (
        <>

            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <Bulkschedule />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Createpost;