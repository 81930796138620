import React from "react";
const { API_URL } = require("../../../BackendApi/Api/config");

function ImageText(props) {
    return (
        <>
            <div className="image_with_text_content color_bg_text mb-3">
                <div className="brand_img_set">
                    <a href={props.data.link}>
                        <img src={props.type == "auto" ? props.data.imageLink : API_URL + "flowpage/" + props.data.imageLink} alt={props.data.imageAltText}  />
                    </a>
                </div>
                <div className="image_text_content">
                    <p className="image_text_content_title m-0">{ props.type == "auto" ? props.data.imageCaption : props.data.imageCaption}</p>
                </div>
            </div>
        </>
    );
}

export default ImageText;