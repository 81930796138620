import React, { useState, useContext } from "react";
import { TbEdit, TbTrash } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";
import { RiAddFill } from "react-icons/ri";
import "./Grouplist.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Multiselect from 'multiselect-react-dropdown';
import { getGroupDataApi, updateGroupApi, deleteGroupApi, addGroupApi } from "../../../BackendApi/Api/groupApi";
import { Spinner } from "react-bootstrap";
import { HiMiniUserGroup } from "react-icons/hi2";
import { useEffect } from "react";
import { UserContext } from "../../../UserContext";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import SpinnerLoader from "../../SpinnerLoader/SpinnerLoader";

function Grouplist() {
    const role = JSON.parse(localStorage.getItem("user")).role;
    const user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);
    const { groupdata, setGroupData, groupaddstatus, setGroupAddStatus } = useContext(UserContext);
    const [groupName, setGroupName] = useState('');
    const [colorCode, setGroupColor] = useState('');
    const [groupId, setGroupId] = useState('');

    const [show03, setShow03] = useState(false);

    const handleClose03 = () => setShow03(false);
    const handleShow03 = () => setShow03(true);

    const [show04, setShow04] = useState(false);

    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);

    const [show08, setShow08] = useState(false);

    const handleClose08 = () => setShow08(false);
    const handleShow08 = () => setShow08(true);

    const [group_name, setGrpName] = useState("");
    const [color_code, setColorCode] = useState("#000000");

    const [brandData, setBrandData] = useState([]);

    const [brandId, setBrandId] = useState('');

    const [accessLevel, setAccessLevel] = useState("no-access"); // Default to "No Access"





    // const [groupdata, setGroupData] = useState([]);
    // const [groupaddstatus, setGroupAddStatus] = useState(false);

    const getGroupData = () => {
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const owner_id = user._id;
        getGroupDataApi({ owner_id: owner_id }).then((response) => {
            if (response.code === 200) {
                setGroupData(response.data.data);
            }
            else {
                PNotify.error({
                    title: "Error",
                    text: response.message,
                });
            }
        }).catch((error) => {
            alert(error);
        })
    }

    const handleDeleteGroup = (e) => {
        e.preventDefault();
        const formData = {
            id: groupId
        }
        deleteGroupApi(formData).then((response) => {
            if (response.code === 200) {
                if (response.data.status === true) {
                    setGroupAddStatus(!groupaddstatus)
                    getGroupData();
                    PNotify.success({
                        title: 'Success',
                        text: "Group data deleted Successfully",
                    });
                }
                else {
                    PNotify.error({
                        title: "Error",
                        text: response.data.message,
                    });
                }
            }
            else {
                PNotify.error({
                    title: "Error",
                    text: response.data.message,
                });
            }
        }).catch((error) => {
            alert(error);
        })

        handleClose04();


    }


    const [loading2, setLoading2] = useState(false)

    const handleUpdateGroup = (e) => {
        e.preventDefault();
        setLoading2(true)
        if (groupName === "") {
            PNotify.error({
                title: 'Oh No!',
                delay: 2000,
                text: 'Please enter group name',
            });
            setLoading(false);
            setLoading2(false)
            return false;
        }
        if (colorCode === "") {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter group name',
            });
            setLoading(false);
            setLoading2(false)
            return false;
        }
        const formData = {
            id: groupId,
            group_name: groupName,
            color_code: colorCode,
            social_access: accessLevel


        }
        updateGroupApi(formData).then((response) => {
            if (response.code === 200) {
                setGroupAddStatus(!groupaddstatus)
                handleClose03();
                PNotify.success({
                    title: 'Success',
                    delay: 2000,
                    text: response.data.message,
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
            }
            else {
                PNotify.error({
                    title: "Error",
                    text: response.message,
                });
            }
        }).catch((error) => {
            alert(error);
        }).finally((err) => {
            setLoading2(false)
        })
    }


    useEffect(() => {
        getGroupData();
    }, [groupaddstatus])

    const handleAddGroup = (e) => {
        setLoading(true);
        e.preventDefault();
        if (group_name === "") {
            PNotify.error({
                title: 'Oh No!',
                delay: 1500,
                text: 'Please enter group name',
            });
            setLoading(false);
            return false;
        }
        const groupData = {
            group_name: group_name,
            color_code: color_code,
            owner_id: user._id,
            created_by: user._id,
            social_access: accessLevel
        }

        addGroupApi(groupData).then((response) => {
            console.log("Response", response);
            if (response.code === 204) {
                setGroupAddStatus(!groupaddstatus)
                setShow08(false)
                PNotify.error({
                    title: 'Error',
                    delay: 2000,
                    text: "Group already exists",
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
            }
            if (response.code === 200) {
                setGroupAddStatus(!groupaddstatus)
                setShow08(false)
                PNotify.success({
                    title: 'Success',
                    delay: 2000,
                    text: response.data.message,
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    },
                });
            }
            else {
                PNotify.error({
                    title: 'Error',
                    delay: 1000,
                    text: response.data.message,
                });
            }
            setLoading(false);
        }).catch((error) => {
            PNotify.error({
                title: 'Error',
                text: error,
            });
            setLoading(false);
        })
    }

    return (

        <div>
            <Modal show={show03} onHide={handleClose03} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="main-card-title">Edit Groups</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group did-floating-label-content">
                                <input type="text" className="form-control did-floating-input" id="exampleFormControlInput1" value={groupName} onChange={(e) => setGroupName(e.target.value)} defaultValue={groupName} />
                                <label for="exampleFormControlInput1" className="form-label did-floating-label">Group Name</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control className="did-floating-color" type="color" placeholder="theme colors" defaultValue={colorCode} value={colorCode} onChange={(e) => setGroupColor(e.target.value)} />
                                <Form.Label className="did-floating-label">Select Color</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Form.Group controlId="accessLevel">
                            <Form.Label>Social Posts Access Level</Form.Label>
                            <Form.Control
                                as="select"
                                value={accessLevel}
                                onChange={(e) => setAccessLevel(e.target.value)}
                            >
                                <option value="no-access">No Access</option>
                                <option value="approval-required">Approval Required</option>
                                <option value="full-posting">Full Posting Access</option>
                                <option value="view-only">View Only</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {loading2 ? <button className="Active-bttn btn"><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading</button> :
                        <button className="Active-bttn btn" onClick={(e) => {
                            handleUpdateGroup(e);
                        }
                        }> Confirm </button>}
                </Modal.Footer>
            </Modal>

            {/* Delete Modal Start From Here */}
            <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash /> Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={handleDeleteGroup}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Modal End Here */}

            {/* Add group modal start */}

            <Modal show={show08} onHide={handleClose08} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Group</Modal.Title>
                </Modal.Header>
                <Modal.Body className="custum-modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control
                                    className="did-floating-input"
                                    type="text"
                                    placeholder=""
                                    value={group_name}
                                    onChange={(e) => setGrpName(e.target.value)}
                                />
                                <Form.Label className="did-floating-label">Group Name</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-md-12">
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control
                                    className="did-floating-color"
                                    type="color"
                                    placeholder="theme colors"
                                    value={color_code}
                                    onChange={(e) => setColorCode(e.target.value)}
                                    defaultValue="#7367f0"
                                />
                                <Form.Label className="did-floating-label">Select Color</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="col-md-12">
                            <Form.Group controlId="accessLevel">
                                <Form.Label>Social Posts Access Level</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={accessLevel}
                                    onChange={(e) => setAccessLevel(e.target.value)}
                                >
                                    <option value="no-access">No Access</option>
                                    <option value="approval-required">Approval Required</option>
                                    <option value="full-posting">Full Posting Access</option>
                                    <option value="view-only">View Only</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {loading ? (
                        <button className="Active-bttn btn">
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading
                        </button>
                    ) : (
                        <button className="Active-bttn btn" onClick={(e) => handleAddGroup(e)}>
                            Create Group
                        </button>
                    )}
                </Modal.Footer>
            </Modal>




            <div className="tab-content-header add-btn d-flex ms-0">
                <h5 className="tab-content-title" ><div className="tab-content-icon"><HiMiniUserGroup /></div>Group List</h5>
                <div className="tab-content-header-right">
                    <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow08} > <RiAddFill />Add Group</Button>
                </div>
            </div>

            <div className="Member-list">
                <ul className="list-group">
                    {groupdata.map((grp) => {
                        return (
                            <li className="list-group-item d-flex align-items-center justify-content-between">
                                <div className="member-content">
                                    <h3>{grp.group_name}</h3>
                                    {/* <h4>Created on :    {(grp.created_on).substring(0, 10)}</h4> */}
                                </div>
                                <div className="member-action">
                                    <div className="d-flex align-items-center">
                                        <div className="button-group">
                                            <button type="button" className="btn btn-edit me-3" onClick={
                                                (e) => {
                                                    handleShow03();
                                                    setGroupId(grp._id);
                                                    setGroupName(grp.group_name);
                                                    setGroupColor(grp.color_code);
                                                    setAccessLevel(grp.social_access);

                                                }}>
                                                <TbEdit />
                                            </button>
                                            <button type="button" className="btn btn-delete" onClick={
                                                (e) => {
                                                    handleShow04();
                                                    setGroupId(grp._id);
                                                }
                                            } >
                                                <TbTrash />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>)
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Grouplist;