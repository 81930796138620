import React, { useEffect, useState, useContext } from "react";
import "./PageBuilder.css";
import "../Components/Flowinner/Flowedit.css";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { BiColorFill, BiLayout } from "react-icons/bi";
import { RiAddFill } from "react-icons/ri";
import { Tab, Nav } from "react-bootstrap";
import { FiCode } from "react-icons/fi";
import Tab3 from "./Components/BuilderTab/Tab3";
import { HashRouter, Route, Switch, useHistory } from 'react-router-dom';
import { Tab2 } from "./Components/BuilderTab/Tab2";
import { HeaddingAdd } from "./Components/AddBlockList/HeadingList";
import { SocialLinkAdd } from "./Components/AddBlockList/SocialLinkList";
import { ButtonLinkAdd } from "./Components/AddBlockList/ButtonLinkList";
import { TextAdd } from "./Components/AddBlockList/TextList";
import { ImageAdd } from "./Components/AddBlockList/ImageList";
import { YoutubeAdd } from "./Components/AddBlockList/YoutubeList";
import { SpotifyAdd } from "./Components/AddBlockList/SpotifyList";
import { ImageGridAdd } from "./Components/AddBlockList/ImageGridList";
import { EditHeader } from "./Components/EditBlockList/EditHeader";
import { EditSocialLink } from "./Components/EditBlockList/EditSocialLink";
import { EditButtonLink } from "./Components/EditBlockList/EditButtonLink";
import { EditYoutube } from "./Components/EditBlockList/EditYoutube";
import { EditImage } from "./Components/EditBlockList/EditImage";
import { EditText } from "./Components/EditBlockList/EditText";
import { EditImageGrid } from "./Components/EditBlockList/EditImageGrid";
import { EditSubHeading } from "./Components/EditBlockList/EditSubHeading";
import { EditSpotify } from "./Components/EditBlockList/EditSpotify";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import Qrtemplates from "../Components/SmartLinkTabs/Qrtemplates";
import { MdQrCode } from 'react-icons/md'
import Icon02 from "../assets/images/indent-decrease.svg";
import Icon01 from "../assets/images/indent-increase.svg";
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { TbWebhook, TbSocial } from "react-icons/tb";
import { Form } from "react-bootstrap";
import { addWebhooks, getwebhooks } from "../BackendApi/Api/webhooks";
import { useParams } from "react-router-dom";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { updateSocialPreview, getsocialpreview } from "../BackendApi/Api/flowpageApi";
import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader";
import { API_URL } from "../BackendApi/Api/config";
import { SmartLinkContext } from "../Components/CreateSmartlink/SmartLinkcontext";
import { useBuilderContext } from "../builderContext";
import { YoutubeAddIframe, YoutubeIframe } from "./Components/AddBlockList/YoutubeIframe";
import { EdityoutubeIframe } from "./Components/EditBlockList/EdityoutubeIframe";
import { TestimonialsAdd } from "./Components/AddBlockList/TestimonialsList";
import { ContactAdd } from "./Components/AddBlockList/ContactUs";
import { EventGridAdd } from "./Components/AddBlockList/EventGridList";
function BuilderRight({ changeColor, changeButtonColor, changeFontFamily, changeBackgroundColor2, changeBackgroundColor, changeColorPosition, changeBackgroundType }) {

    const { backgroundType, setBackgroundType, BackgroundColor, setBackgroundColor, backgroundColor2, setBackgroundColor2, colorPosition, setColorPosition, title, setTitle, setDescription, description } = useBuilderContext();

    const [smartlinkeditdata, setSmartlinkEditData,] = useContext(SmartLinkContext);
    const [addClass, setAddClass] = React.useState(false);
    const [webhook_url, setWebhook_url] = useState('');
    const [webhooksdata, setWebhooksData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [collapse, setCollapse] = useState(false);
    // const [backgroundType, setBackgroundType] = useState(1);
    // const [BackgroundColor, setBackgroundColor] = useState('#3713ed');
    // const [backgroundColor2, setBackgroundColor2] = useState('#f01a45');
    // const [colorPosition, setColorPosition] = useState('leftright');
    const [headerPosition, setHeaderPosition] = useState('left');

    const [favicon, setFavicon] = useState(null);
    const [openGraphImage, setOpenGraphImage] = useState(null);
    const [imageUrls, setImageUrls] = useState({ favicon: '', openGraphImage: '' });
    const [socialPreviewData, setSocialPreviewData] = useState({})
    const params = useParams();
    const classToggle = () => {
        document.querySelector('.tab_content').classList.toggle('xEfrZ');

        setAddClass((prev) => !prev);
    }

    const navigate = useHistory();


    useEffect(() => {
        if (params.id !== undefined) {
            getwebhookurl();
        }
    }, [params.id])

    const handleSelectFavicon = (e) => {
        setFavicon(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    favicon: reader.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle file selection for Open Graph Image
    const handleSelectOG = (e) => {
        setOpenGraphImage(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    openGraphImage: reader.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    };


    const handleAddWebhooks = (e) => {
        e.preventDefault();
        setLoading(true);
        addWebhooks({ webhook_url: webhook_url, smartlink_id: params.id }).then((res) => {
            if (res.code === 200) {
                setLoading(false);
                PNotify?.success({
                    title: 'Success',
                    text: res.data.message,
                });
                getwebhookurl();
            }
        }).catch((err) => {
            setLoading(false);
            PNotify?.error({
                title: 'Error',
                text: err,
            })
        }).finally(() => {
            setLoading(false);
        });
    }

    const getwebhookurl = () => {
        setLoading(true);
        getwebhooks({ smartlink_id: params.id }).then((res) => {
            if (res.code === 200) {
                setLoading(false);
                setWebhooksData(res.data.data);
                setWebhook_url(res.data.data.webhook_url)
            }
        }).catch((err) => {
            setLoading(false);
            PNotify?.error({
                title: 'Error',
                text: err,
            })
        }).finally(() => {
            setLoading(false);
        });
    }

    let webhooks = webhooksdata?.webhook_url

    const addClassCheck = () => {
        setCollapse(true);
        setBackgroundType(2);
    }

    const removeClass = () => {
        setCollapse(false);
        setBackgroundType(1);
    }


    const handleValueChange = (e) => {
        const selectedValue = e.target.value;
        setColorPosition(selectedValue);
    };

    const handleUpdateSocialPreview = (e) => {
        e.preventDefault();
        setLoading(true);

        const formdata = new FormData();
        formdata.append("smartlinkId", params.id);
        formdata.append("description", description);
        formdata.append("title", title);
        formdata.append("favicon", favicon);
        formdata.append("og_image", openGraphImage);

        updateSocialPreview(formdata)
            .then((res) => {
                setLoading(false);

                if (res.code === 200) {
                    PNotify.success({
                        title: 'Success',
                        text: "Social Preview Updated Successfully",
                    });
                } else {
                    PNotify?.error({
                        title: 'Error',
                        text: "Something Went Wrong",
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                PNotify?.error({
                    title: 'Error',
                    text: err,
                });
            });
    };


    useEffect(() => {
        getsocialpreviewData();
    }, [params.id])

    const getsocialpreviewData = () => {
        const data = {
            "smartlink_id": params.id
        }
        getsocialpreview(data).then((res) => {
            if (res.code === 200) {
                let previewData = res.data.data
                setSocialPreviewData(previewData);
                setTitle(previewData?.title);
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    openGraphImage: previewData?.favicon,
                }));
                setDescription(previewData?.description);
                setImageUrls({
                    openGraphImage: API_URL + 'ogimages/' + previewData?.og_image,
                    favicon: previewData?.favicon,
                });
            } else {
                PNotify?.error({
                    title: 'Error',
                    text: "Something went wrong",
                });
            }
        }).catch((err) => {
            PNotify?.error({
                title: 'Error',
                text: err,
            });
        })
    }

    return (
        <>

            <div className="mobile-hide">
                <div className="d-flex" style={{ height: "100vh" }}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                        <Nav variant="pills" className="flex-column">
                            <div class="RightSide_fixed_bar">
                                <ul class="RightSide_fixed_bar_list">
                                    <Nav.Item className="RightSide_fixed_bar_list_item">
                                        <Nav.Link eventKey="first" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <BiColorFill />
                                            Appearance
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item">
                                        <Nav.Link eventKey="second" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <BiLayout />
                                            Layout
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item">
                                        <Nav.Link eventKey="third" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <RiAddFill />
                                            Add Block
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item">
                                        <Nav.Link eventKey="forth" className="RightSide_fixed_bar_list_item_link oALEn qr-template">
                                            <MdQrCode />
                                            QR Code
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item">
                                        <Nav.Link eventKey="fifth" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <TbWebhook />
                                            Webhook
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item">
                                        <Nav.Link eventKey="sixth" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <FiCode />
                                            Tracking
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="RightSide_fixed_bar_list_item">
                                        <Nav.Link eventKey="seventh" className="RightSide_fixed_bar_list_item_link oALEn">
                                            <TbSocial />
                                            Social Preview
                                        </Nav.Link>
                                    </Nav.Item>
                                </ul>
                            </div>
                        </Nav>

                        <Tab.Content className="cFOcQM tab_content">
                            <div className="d-flex align-items-center"><button className="Back-btn-icon" onClick={() => navigate.push("/flexpage")}><IoArrowBackCircleOutline /> </button><div className="Back-btn-text"><h5>Back to FlexPage</h5></div></div>
                            <Tab.Pane eventKey="first">
                                <div class="styles__TabContent themes_sec">
                                    <div class="styles__TabContent__Header themes_sec_header">
                                        <h3>Themes</h3>
                                    </div>
                                    <div className="templates_list">
                                        <div className="theme-gallery">
                                            <div role="button" className="templates_list_item" onClick={
                                                () => {
                                                    changeColor('color_white');
                                                }
                                            }  >
                                                <div className="ThumbnailContainer">
                                                    <div className="rounded-3 Thumbnail"></div>
                                                    <div className="button-box"></div>
                                                    <div className="video-box change_color"></div>
                                                </div>
                                            </div>
                                            <div role="button" className="templates_list_item" onClick={
                                                () => {
                                                    changeColor('color_pink');
                                                }
                                            }>
                                                <div className="ThumbnailContainer dohZLT">
                                                    <div className="rounded-3 Thumbnail"></div>
                                                    <div className="button-box JqLIB"></div>
                                                    <div className="video-box"></div>
                                                </div>
                                            </div>
                                            <div role="button" className="templates_list_item" onClick={
                                                () => {
                                                    changeColor('color_green');
                                                }
                                            }>
                                                <div className="ThumbnailContainer htmJFC">
                                                    <div className="rounded-3 Thumbnail"></div>
                                                    <div className="button-box eHEwqA"></div>
                                                    <div className="video-box"></div>
                                                </div>
                                            </div>
                                            <div role="button" className="templates_list_item" onClick={
                                                () => {
                                                    changeColor('goWHMr');
                                                }
                                            }>
                                                <div className="ThumbnailContainer gqRGFY">
                                                    <div className="rounded-3 Thumbnail"></div>
                                                    <div className="button-box kQamYq"></div>
                                                    <div className="video-box"></div>
                                                </div>
                                            </div>
                                            <div role="button" className="templates_list_item" onClick={
                                                () => {
                                                    changeColor('color_beige');
                                                }
                                            }>
                                                <div className="ThumbnailContainer bnqqfO">
                                                    <div className="rounded-3 Thumbnail"></div>
                                                    <div className="button-box eXJCbl"></div>
                                                    <div className="video-box"></div>
                                                </div>
                                            </div>
                                            <div role="button" className="templates_list_item" onClick={
                                                () => {
                                                    changeColor('color_yellow');
                                                }
                                            }>
                                                <div className="ThumbnailContainer bWgPiP">
                                                    <div className="rounded-3 Thumbnail"></div>
                                                    <div className="button-box fqVEao"></div>
                                                    <div className="video-box"></div>
                                                </div>
                                            </div>
                                            <div role="button" className="templates_list_item" onClick={
                                                () => {
                                                    changeColor('color_black');
                                                }
                                            }>
                                                <div className="ThumbnailContainer gKQzFr">
                                                    <div className="rounded-3 Thumbnail"></div>
                                                    <div className="button-box iCFlgu"></div>
                                                    <div className="video-box change_color"></div>
                                                </div>
                                            </div>
                                            <div role="button" className="templates_list_item" onClick={
                                                () => {
                                                    changeColor('color_blue');
                                                }
                                            }>
                                                <div className="ThumbnailContainer kQbHFv">
                                                    <div className="rounded-3 Thumbnail"></div>
                                                    <div className="button-box eqpOAJ"></div>
                                                    <div className="video-box"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="styles__TabContent__Header themes_sec_header">
                                        <h3>Background Color</h3>
                                    </div>
                                    <div className="templates_list">

                                        <div className="col-md-12">
                                            <Form>
                                                {['radio'].map((type) => (
                                                    <div key={`inline-${type}`} className="mb-3">
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <div className="d-flex align-items-center flex-wrap">
                                                                <div onClick={removeClass}>
                                                                    <Form.Check
                                                                        label="Solid"
                                                                        name="group20"
                                                                        type="radio"
                                                                        id="reverseradio935"
                                                                        className="me-4"
                                                                        checked={backgroundType == 1}
                                                                    />
                                                                </div>
                                                                <div onClick={addClassCheck}>
                                                                    <Form.Check
                                                                        label="Gradient"
                                                                        name="group20"
                                                                        type="radio"
                                                                        id="reverseradio936"
                                                                        checked={backgroundType == 2}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form.Group>

                                                    </div>
                                                ))}
                                            </Form>
                                            {backgroundType == 1 && (
                                                <div className="set_btn_colored">
                                                    <div className="set_btn_colored_inner">
                                                        <div className="default-color-picker-list">
                                                            <div className="default-color-picker-list-item">
                                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#7367f6" }}
                                                                    onClick={
                                                                        () => {
                                                                            setBackgroundColor('#7367f6');
                                                                        }
                                                                    }
                                                                ></button>
                                                            </div>
                                                            <div className="default-color-picker-list-item">
                                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#28c76f" }}
                                                                    onClick={
                                                                        () => {
                                                                            setBackgroundColor('#28c76f');
                                                                        }
                                                                    }
                                                                ></button>
                                                            </div>
                                                            <div className="default-color-picker-list-item">
                                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#ea5455" }}
                                                                    onClick={
                                                                        () => {
                                                                            setBackgroundColor('#ea5455');
                                                                        }
                                                                    }
                                                                ></button>
                                                            </div>
                                                            <div className="default-color-picker-list-item">
                                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#ff9f43" }}
                                                                    onClick={
                                                                        () => {
                                                                            setBackgroundColor('#ff9f43');
                                                                        }
                                                                    }
                                                                ></button>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {backgroundType == 2 && (
                                                <>
                                                    <div className="set_btn_colored">
                                                        <div className="set_btn_colored_inner">
                                                            <div className="default-color-picker-list">
                                                                <div className="default-color-picker-list-item">
                                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#7367f6" }}
                                                                        onClick={
                                                                            () => {
                                                                                setBackgroundColor('#7367f6');
                                                                            }
                                                                        }
                                                                    ></button>
                                                                </div>
                                                                <div className="default-color-picker-list-item">
                                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#28c76f" }}
                                                                        onClick={
                                                                            () => {
                                                                                setBackgroundColor('#28c76f');
                                                                            }
                                                                        }
                                                                    ></button>
                                                                </div>
                                                                <div className="default-color-picker-list-item">
                                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#ea5455" }}
                                                                        onClick={
                                                                            () => {
                                                                                setBackgroundColor('#ea5455');
                                                                            }
                                                                        }
                                                                    ></button>
                                                                </div>
                                                                <div className="default-color-picker-list-item">
                                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#ff9f43" }}
                                                                        onClick={
                                                                            () => {
                                                                                setBackgroundColor('#ff9f43');
                                                                            }
                                                                        }
                                                                    ></button>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="set_btn_colored">
                                                        <div className="set_btn_colored_inner">
                                                            <div className="default-color-picker-list">

                                                                <div className="default-color-picker-list-item">
                                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#11cdef" }}
                                                                        onClick={
                                                                            () => {
                                                                                setBackgroundColor2('#11cdef');
                                                                            }
                                                                        }
                                                                    ></button>
                                                                </div>
                                                                <div className="default-color-picker-list-item">
                                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#f73164" }}
                                                                        onClick={
                                                                            () => {
                                                                                setBackgroundColor2('#f73164');
                                                                            }
                                                                        }
                                                                    ></button>
                                                                </div>

                                                                <div className="default-color-picker-list-item">
                                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#5f27cd" }}
                                                                        onClick={
                                                                            () => {
                                                                                setBackgroundColor2('#5f27cd');
                                                                            }
                                                                        }
                                                                    ></button>
                                                                </div>
                                                                <div className="default-color-picker-list-item">
                                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#feca57" }}
                                                                        onClick={
                                                                            () => {
                                                                                setBackgroundColor2('#feca57');
                                                                            }
                                                                        }
                                                                    ></button>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                        <Form.Control className="did-floating-color" type="color" placeholder="theme colors" defaultValue={BackgroundColor} value={BackgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} />
                                                        <Form.Label className="did-floating-label">Page Color</Form.Label>
                                                    </Form.Group>
                                                </div>
                                                {collapse && (
                                                    <>
                                                        <div className="col-md-12">
                                                            <Form.Group className="did-floating-label-content gradient" controlId="formBasicEmail">
                                                                <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={backgroundColor2} defaultValue={backgroundColor2} onChange={(e) => setBackgroundColor2(e.target.value)} />
                                                                <Form.Label className="did-floating-label">Gradient Color</Form.Label>
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                <Form.Control className="did-floating-select" as="select" value={colorPosition} onChange={handleValueChange}>
                                                                    <option value="leftright">Left to Right</option>
                                                                    <option value="rightleft">Right to Left</option>
                                                                    <option value="topbottom">Top to Bottom</option>
                                                                    <option value="bottomtop">Bottom to Top</option>
                                                                </Form.Control>
                                                                <Form.Label className="did-floating-label">Gradient Color Position</Form.Label>
                                                            </Form.Group>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="ZWEFTR"></div> */}
                                    <div class="styles__TabContent__Header themes_sec_header">
                                        <h3>Button Color</h3>
                                    </div>
                                    <div className="set_btn_colored">
                                        <div className="set_btn_colored_inner">
                                            <div className="default-color-picker-list">
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#7367f6" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#7367f6');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#28c76f" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#28c76f');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#ea5455" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#ea5455');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#ff9f43" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#ff9f43');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#1e1e1e" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#1e1e1e');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#E6379a" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#E6379a');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#11cdef" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#11cdef');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#f73164" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#f73164');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#2dce89" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#2dce89');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#5f27cd" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#5f27cd');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="default-color-picker-list-item">
                                                    <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#feca57" }}
                                                        onClick={
                                                            () => {
                                                                changeButtonColor('#feca57');
                                                            }
                                                        }
                                                    ></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ZWEFTR"></div>
                                    <div className="font_family_content">
                                        <div class="section-inner-title">
                                            <h3>Font Packs</h3>
                                        </div>
                                        <div className="select-font">
                                            <div className="select-font-list">
                                                <div className="select-font-list-item">
                                                    <button className="select-font-list-item-inner" style={{ fontFamily: 'Roboto, sans-serif' }}
                                                        onClick={
                                                            () => {
                                                                changeFontFamily('Roboto, sans-serif');
                                                            }
                                                        }
                                                    >
                                                        <div className="select-font-list-item-inner-text">
                                                            <p className="select-font-list-item-inner-text-title">Roboto</p>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="select-font-list-item">
                                                    <button className="select-font-list-item-inner" style={{ fontFamily: 'Playfair Display, serif' }}
                                                        onClick={
                                                            () => {
                                                                changeFontFamily('Playfair Display, serif');
                                                            }
                                                        }
                                                    >
                                                        <div className="select-font-list-item-inner-text">
                                                            <p className="select-font-list-item-inner-text-title">Playfair Display</p>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="select-font-list-item">
                                                    <button className="select-font-list-item-inner" style={{ fontFamily: 'Oswald, sans-serif' }}
                                                        onClick={
                                                            () => {
                                                                changeFontFamily('Oswald, sans-serif');
                                                            }
                                                        }
                                                    >
                                                        <div className="select-font-list-item-inner-text">
                                                            <p className="select-font-list-item-inner-text-title">Oswald</p>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="select-font-list-item">
                                                    <button className="select-font-list-item-inner" style={{ fontFamily: 'Libre Baskerville, serif' }}
                                                        onClick={
                                                            () => {
                                                                changeFontFamily('Libre Baskerville, serif');
                                                            }
                                                        }
                                                    >
                                                        <div className="select-font-list-item-inner-text">
                                                            <p className="select-font-list-item-inner-text-title">Libre Baskerville</p>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="select-font-list-item">
                                                    <button className="select-font-list-item-inner" style={{ fontFamily: 'Lugrasimo, cursive' }}
                                                        onClick={
                                                            () => {
                                                                changeFontFamily('Lugrasimo, cursive');
                                                            }
                                                        }
                                                    >
                                                        <div className="select-font-list-item-inner-text">
                                                            <p className="select-font-list-item-inner-text-title">Lugrasimo</p>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className="styles__TabContent themes_sec">
                                    <HashRouter>
                                        <Switch>
                                            <Route path="/" component={Tab2} exact></Route>
                                            <Route path="/header" component={EditHeader} exact></Route>
                                            <Route path="/editsocial" component={SocialLinkAdd} exact></Route>
                                            {/* <Route path="/editsocial" component={EditSocialLink} exact></Route> */}
                                            <Route path="/editbutton" component={EditButtonLink} exact></Route>
                                            <Route path="/edityoutube" component={EditYoutube} exact></Route>
                                            <Route path="/editimage" component={EditImage} exact></Route>
                                            <Route path="/edittext" component={EditText} exact></Route>
                                            <Route path="/editimagegrid" component={ImageGridAdd} exact></Route>
                                            <Route path="/editsubheading" component={EditSubHeading} exact></Route>
                                            <Route path="/editspotify" component={EditSpotify} exact></Route>
                                            <Route path="/edityoutubeiframe" component={EdityoutubeIframe} exact></Route>
                                            <Route path="/editevent" component={EventGridAdd} exact></Route>
                                            <Route path="/edittestimonials" component={TestimonialsAdd} exact></Route>
                                            <Route path="/editcontact" component={ContactAdd} exact></Route>

                                        </Switch>
                                    </HashRouter>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div className="styles__TabContent themes_sec">
                                    <HashRouter>
                                        <Switch>
                                            <Route path="/" component={Tab3} exact></Route>
                                            <Route path="/headding" component={HeaddingAdd} exact></Route>
                                            <Route path="/sociallink" component={SocialLinkAdd} exact></Route>
                                            <Route path="/buttonlink" component={ButtonLinkAdd} exact></Route>
                                            <Route path="/text" component={TextAdd} exact></Route>
                                            <Route path="/image" component={ImageAdd} exact></Route>
                                            <Route path="/youtube" component={YoutubeAdd} exact></Route>
                                            <Route path="/spotify" component={SpotifyAdd} exact></Route>
                                            <Route path="/imagegrid" component={ImageGridAdd} exact></Route>
                                            <Route path="/youtubeaddiframe" component={YoutubeAddIframe} exact></Route>
                                            <Route path="/testimoials" component={TestimonialsAdd} exact></Route>
                                            <Route path="/contact" component={ContactAdd} exact></Route>
                                            <Route path="/event" component={EventGridAdd} exact></Route>
                                        </Switch>
                                    </HashRouter>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="forth">
                                <div className="styles__TabContent themes_sec" style={{ margin: '20px 0px' }}>
                                    <Qrtemplates pageType="flex" />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                                <div className="styles__TabContent themes_sec" style={{ margin: '0px 20px' }}>
                                    {/* <div className="content-body-form">
                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                            <Form.Control className="did-floating-input" type="text" value="" placeholder=" " />
                                            <Form.Label className="did-floating-label">URL </Form.Label>
                                        </Form.Group>
                                    </div> */}
                                    <div className="col-md-12">
                                        <div className="content-body-form">
                                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                <Form.Control className="did-floating-input" type="text" value={webhook_url} onChange={(e) => setWebhook_url(e.target.value)} placeholder="" />
                                                <Form.Label className="did-floating-label" >URL </Form.Label>
                                                <div className="col-md-12 mb-3 mt-4">
                                                    <div className="form-btn">
                                                        <div className="form-btn-right">


                                                            {
                                                                loading ? (
                                                                    <SpinnerLoader />
                                                                ) : (

                                                                    (webhooks ? (<button
                                                                        type="button"
                                                                        onClick={handleAddWebhooks}
                                                                        className="btn bg-green-dark action-btn btn-green"
                                                                    >
                                                                        Update URL
                                                                    </button>) : (<button
                                                                        type="button"
                                                                        onClick={handleAddWebhooks}
                                                                        className="btn bg-green-dark action-btn btn-green"
                                                                    >
                                                                        Add URL
                                                                    </button>))
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixth">
                                <div className="styles__TabContent themes_sec" style={{ margin: '0px 20px' }}>
                                    <div className="content-body-form">
                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlInput1">
                                            <Form.Control className="did-floating-input" type="text" value="" placeholder=" " />
                                            <Form.Label className="did-floating-label">Google Analytics</Form.Label>
                                        </Form.Group>
                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlInput2">
                                            <Form.Control className="did-floating-input" type="text" value="" placeholder=" " />
                                            <Form.Label className="did-floating-label">Google Tag Manager</Form.Label>
                                        </Form.Group>
                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTInput3">
                                            <Form.Control className="did-floating-input" type="text" value="" placeholder=" " />
                                            <Form.Label className="did-floating-label">Meta Pixel ID (Facebook)</Form.Label>
                                        </Form.Group>
                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlInput4">
                                            <Form.Control className="did-floating-input" type="text" value="" placeholder=" " />
                                            <Form.Label className="did-floating-label">AdRoll Pixel ID</Form.Label>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="seventh">
                                <div className="styles__TabContent themes_sec" style={{ margin: '0px 20px' }}>
                                    <div className="content-body-form">
                                        <div className="col-md-12">
                                            <div className="content-body-form">
                                                <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                    <div className="d-flex">
                                                        <Form.Control className="did-floating-input" type="text" value={title} onChange={(e) => { setTitle(e.target.value) }} placeholder="" />
                                                        <Form.Label className="did-floating-label">Title</Form.Label>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea2">
                                                    <Form.Control className="did-floating-textarea" as="textarea" rows={3} value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder="" />
                                                    <Form.Label className="did-floating-label">Description</Form.Label>
                                                </Form.Group>
                                            </div>

                                            <div className="row">
                                                <div className='col-md-12'>
                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                        <Form.Control
                                                            className="did-floating-file"
                                                            type="file"
                                                            placeholder="Company Logo"
                                                            onChange={handleSelectFavicon}
                                                        />
                                                        <Form.Label className="did-floating-label">Favicon</Form.Label>
                                                        <img src={imageUrls.favicon} alt="" className="preview-logo-image" style={{ height: '100px', width: '100px' }} />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-md-12'>
                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                        <Form.Control className="did-floating-file" type="file" placeholder="Company Logo" onChange={handleSelectOG} />
                                                        <Form.Label className="did-floating-label">Social Network Image (Open Graph)</Form.Label>
                                                        {<img src={imageUrls.openGraphImage} className="preview-logo-image" style={{ height: '100px', width: '100px' }} />}
                                                    </Form.Group>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-12 ">
                                                <div className='form-btn'>
                                                    <div className='form-btn-right'>
                                                        {
                                                            loading ? (
                                                                <SpinnerLoader />
                                                            ) : (
                                                                <button type="button" onClick={handleUpdateSocialPreview} className="btn bg-green-dark action-btn btn-green">
                                                                    Update Social Preview
                                                                </button>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                        <div class="styles__CloseTab-sc-isevmn-1 jKiTgm">
                            <button type="button" onClick={classToggle}>
                                {addClass ? <img src={Icon01} alt="icn" /> : <img src={Icon02} alt="icn" />}
                            </button>
                        </div>
                    </Tab.Container>
                </div>
            </div>


            <div className="mobile-show">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="builder_mobile_navigation">
                        <div class="RightSide_fixed_bar">
                            <ul class="RightSide_fixed_bar_list">
                                <Nav.Item className="RightSide_fixed_bar_list_item">
                                    <Nav.Link eventKey="first" className="RightSide_fixed_bar_list_item_link oALEn">
                                        <BiColorFill />
                                        Appearance
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="RightSide_fixed_bar_list_item">
                                    <Nav.Link eventKey="second" className="RightSide_fixed_bar_list_item_link oALEn">
                                        <BiLayout />
                                        Layout
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="RightSide_fixed_bar_list_item">
                                    <Nav.Link eventKey="third" className="RightSide_fixed_bar_list_item_link oALEn">
                                        <RiAddFill />
                                        Add Block
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="RightSide_fixed_bar_list_item">
                                    <Nav.Link eventKey="forth" className="RightSide_fixed_bar_list_item_link oALEn qr-template">
                                        <RiAddFill />
                                        Qr Code
                                    </Nav.Link>
                                </Nav.Item>
                            </ul>
                        </div>
                    </Nav>

                    <Tab.Content className="cFOcQM tab_content">
                        <div className="d-flex align-items-center"><button className="Back-btn-icon" onClick={() => navigate.push('/flexpage')}><IoArrowBackCircleOutline /> </button><div className="Back-btn-text"><h5>Back to FlexPage</h5></div></div>
                        <Tab.Pane eventKey="first">
                            <div class="styles__TabContent themes_sec">
                                <div class="styles__TabContent__Header themes_sec_header">
                                    <h3>Themes</h3>
                                </div>
                                <div className="templates_list">
                                    <div className="theme-gallery">
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_white');
                                            }
                                        }  >
                                            <div className="ThumbnailContainer">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box"></div>
                                                <div className="video-box change_color"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_pink');
                                            }
                                        }>
                                            <div className="ThumbnailContainer dohZLT">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box JqLIB"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_green');
                                            }
                                        }>
                                            <div className="ThumbnailContainer htmJFC">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box eHEwqA"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('goWHMr');
                                            }
                                        }>
                                            <div className="ThumbnailContainer gqRGFY">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box kQamYq"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_beige');
                                            }
                                        }>
                                            <div className="ThumbnailContainer bnqqfO">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box eXJCbl"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_yellow');
                                            }
                                        }>
                                            <div className="ThumbnailContainer bWgPiP">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box fqVEao"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_black');
                                            }
                                        }>
                                            <div className="ThumbnailContainer gKQzFr">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box iCFlgu"></div>
                                                <div className="video-box change_color"></div>
                                            </div>
                                        </div>
                                        <div role="button" className="templates_list_item" onClick={
                                            () => {
                                                changeColor('color_blue');
                                            }
                                        }>
                                            <div className="ThumbnailContainer kQbHFv">
                                                <div className="rounded-3 Thumbnail"></div>
                                                <div className="button-box eqpOAJ"></div>
                                                <div className="video-box"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ZWEFTR"></div>
                                <div className="set_btn_colored">
                                    <div className="set_btn_colored_inner">
                                        <label for="foo" color="gray" class="style__Label-bufferapp-ui__sc-153wil8-5 dPYltt">Button Color</label>
                                        <div className="default-color-picker-list">
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#7367f6" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#7367f6');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#28c76f" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#28c76f');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#ea5455" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#ea5455');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#ff9f43" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#ff9f43');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#1e1e1e" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#1e1e1e');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#E6379a" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#E6379a');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#11cdef" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#11cdef');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#f73164" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#f73164');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#2dce89" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#2dce89');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#5f27cd" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#5f27cd');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                            <div className="default-color-picker-list-item">
                                                <button className="default-color-picker-list-item-inner" style={{ backgroundColor: "#feca57" }}
                                                    onClick={
                                                        () => {
                                                            changeButtonColor('#feca57');
                                                        }
                                                    }
                                                ></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ZWEFTR"></div>
                                <div className="font_family_content">
                                    <div class="section-inner-title">
                                        <h3>Font Packs</h3>
                                    </div>
                                    <div className="select-font">
                                        <div className="select-font-list">
                                            <div className="select-font-list-item">
                                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Roboto, sans-serif' }}
                                                    onClick={
                                                        () => {
                                                            changeFontFamily('Roboto, sans-serif');
                                                        }
                                                    }
                                                >
                                                    <div className="select-font-list-item-inner-text">
                                                        <p className="select-font-list-item-inner-text-title">Roboto</p>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="select-font-list-item">
                                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Playfair Display, serif' }}
                                                    onClick={
                                                        () => {
                                                            changeFontFamily('Playfair Display, serif');
                                                        }
                                                    }
                                                >
                                                    <div className="select-font-list-item-inner-text">
                                                        <p className="select-font-list-item-inner-text-title">Playfair Display</p>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="select-font-list-item">
                                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Oswald, sans-serif' }}
                                                    onClick={
                                                        () => {
                                                            changeFontFamily('Oswald, sans-serif');
                                                        }
                                                    }
                                                >
                                                    <div className="select-font-list-item-inner-text">
                                                        <p className="select-font-list-item-inner-text-title">Oswald</p>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="select-font-list-item">
                                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Libre Baskerville, serif' }}
                                                    onClick={
                                                        () => {
                                                            changeFontFamily('Libre Baskerville, serif');
                                                        }
                                                    }
                                                >
                                                    <div className="select-font-list-item-inner-text">
                                                        <p className="select-font-list-item-inner-text-title">Libre Baskerville</p>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className="select-font-list-item">
                                                <button className="select-font-list-item-inner" style={{ fontFamily: 'Lugrasimo, cursive' }}
                                                    onClick={
                                                        () => {
                                                            changeFontFamily('Lugrasimo, cursive');
                                                        }
                                                    }
                                                >
                                                    <div className="select-font-list-item-inner-text">
                                                        <p className="select-font-list-item-inner-text-title">Lugrasimo</p>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="second">
                            <div className="styles__TabContent themes_sec">
                                <HashRouter>
                                    <Switch>
                                        <Route path="/" component={Tab2} exact></Route>
                                        <Route path="/header" component={EditHeader} exact></Route>
                                        <Route path="/editsocial" component={SocialLinkAdd} exact></Route>
                                        <Route path="/editsocial" component={EditSocialLink} exact></Route>
                                        <Route path="/editbutton" component={EditButtonLink} exact></Route>
                                        <Route path="/edityoutube" component={EditYoutube} exact></Route>
                                        <Route path="/editimage" component={EditImage} exact></Route>
                                        <Route path="/edittext" component={EditText} exact></Route>
                                        <Route path="/editimagegrid" component={ImageGridAdd} exact></Route>
                                        <Route path="/editsubheading" component={EditSubHeading} exact></Route>
                                        <Route path="/editspotify" component={EditSpotify} exact></Route>
                                        <Route path="/testimoials" component={TestimonialsAdd} exact></Route>

                                    </Switch>
                                </HashRouter>
                            </div>
                        </Tab.Pane> */}
                        <Tab.Pane eventKey="third">
                            <div className="styles__TabContent themes_sec">
                                <HashRouter>
                                    <Switch>
                                        <Route path="/" component={Tab3} exact></Route>
                                        <Route path="/headding" component={HeaddingAdd} exact></Route>
                                        <Route path="/sociallink" component={SocialLinkAdd} exact></Route>
                                        <Route path="/buttonlink" component={ButtonLinkAdd} exact></Route>
                                        <Route path="/text" component={TextAdd} exact></Route>
                                        <Route path="/image" component={ImageAdd} exact></Route>
                                        <Route path="/youtube" component={YoutubeAdd} exact></Route>
                                        <Route path="/spotify" component={SpotifyAdd} exact></Route>
                                        <Route path="/imagegrid" component={ImageGridAdd} exact></Route>
                                        <Route path="/youtubeaddiframe" component={YoutubeAddIframe} exact></Route>
                                        <Route path="/testimoials" component={TestimonialsAdd} exact></Route>
                                    </Switch>
                                </HashRouter>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="forth">
                            <div className="styles__TabContent themes_sec" style={{ margin: '20px 0px' }}>
                                <Qrtemplates pageType="flex" />
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    );
}

export default BuilderRight;