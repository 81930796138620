import { useState,createContext } from "react";
import { getVanityApi, addVanityApi } from './BackendApi/Api/vanityApi'

const VanityContext = createContext();


const VanityProvider = ({ children }) => {
    const [vanitySavedStatus, setVanitySavedStatus] = useState(false);
    const [vanityName, setVanityName] = useState('');
    const [vanityLogo, setVanityLogo] = useState('');
    const [vantyCode,setVanityCode]=useState('');
    const [vanityData, setVanityData] = useState([])

    const getVanityData = () => {
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        let owner_id;
    
        if (user.role == 0) {
            owner_id = user._id;
        } else {
            owner_id = user.created_by;
        }
    
        getVanityApi({owner_id: owner_id})
            .then((res) => {
                if (res.code == 200) {
                    setVanityData(res.data.data);
                } else {
                    alert(res.message);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };
    


    return (
        <VanityContext.Provider value={{vanityName,setVanityName,vanityLogo,setVanityLogo,vantyCode,setVanityCode,vanitySavedStatus,setVanitySavedStatus,vanityData,setVanityData,getVanityData}}>
        {children}
        </VanityContext.Provider>
    );
    }

    export { VanityContext, VanityProvider };
