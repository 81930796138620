export const socialAccountReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SOCIAL_ACCOUNTS':
      console.log("the coming payload", action.payload);
      return {
        ...state,
        accounts: [...action.payload],
      };

    case 'FETCHING_SOCIAL_ACCOUNTS':

      return {
        ...state,
        isFetching: action.payload,
      }

    default:

  }
};


  