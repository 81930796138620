import React, { useEffect, useState } from "react";
import List from "./List";
import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin, BsYoutube, BsTiktok, BsPaypal, BsGithub, BsPinterest, BsTwitterX, BsSnapchat } from "react-icons/bs";
import { RiWhatsappFill } from "react-icons/ri";
import { FaTelegramPlane, FaLongArrowAltLeft } from "react-icons/fa";
import { DragDropContext } from '@hello-pangea/dnd';
import { Link, useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { TbSocial } from "react-icons/tb";
import { useBuilderContext } from "../../../builderContext";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

export function SocialLinkList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <TbSocial />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Social Links</h2>
                            <p className="add-block-btn-text-desc">Add a heading to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}


export function SocialLinkAdd(props) {






    const location = useLocation();
    const frameType = location.state != undefined ? "update" : "add";
    const position = location.state != undefined ? location.state.position : null;
    const showStatus = location.state?.showStatus

    const [status, setStatus] = useState("")
    const navigate = useHistory();
    const { addComponent, editComponent } = useBuilderContext();
    //on click show dropdown items

    const showwDropdown = () => {
        const dropdown = document.querySelector('.dropdown-menu');
        dropdown.classList.toggle('show');
    }

    //on click hide dropdown items

    useEffect(() => {
        const dropdown = document.querySelector('.dropdown-menu');
        const dropdownItems = document.querySelectorAll('.drop_social_item');
        dropdownItems.forEach((item) => {
            item.addEventListener('click', () => {
                dropdown.classList.remove('show');
            })
        })
        if (showStatus) {
            setStatus(showStatus)
        }
    }, [])


    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        console.log("list==", result);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            socialLinks.itemOrder,
            result.source.index,
            result.destination.index
        );


        const newSocialLinks = {
            ...socialLinks,
            itemOrder: reorderedItems
        }

        setSocialLinks(newSocialLinks);

    };

    const [socialLinks, setSocialLinks] = useState(
        frameType == "update" ? location.state?.data : {
            lists: {
                'list-1': {
                    id: 'list-1',
                    title: 'List 1',
                },
            },
            items: {

            },
            listOrder: ['list-1'],
            itemOrder: [],
        }
    );

  

    const { lists, items, listOrder, itemOrder } = socialLinks;


    const updateSocialUrl = (id, url) => {
        const newItems = {
            ...items,
            [id]: {
                ...items[id],
                url: url
            }
        }
        const newSocialLinks = {
            ...socialLinks,
            items: newItems
        }
        setSocialLinks(newSocialLinks);
    }


    const addNewSocial = (type, url) => {

        const count = socialLinks.itemOrder.length;
        const newId = `social-${count + 1}`;
        // add item to items and itemOrder 

        const uniqueId = Date.now() + uuidv4();
        const newItems = {
            ...items,
            [newId]: {
                unique_id: uniqueId,
                id: newId,
                type: type,
                url: url,
            }
        }
        const newItemOrder = [...itemOrder, newId];

        // add newItems and newItemOrder to socialLinks

        const newSocialLinks = {
            ...socialLinks,
            items: newItems,
            itemOrder: newItemOrder
        }

        setSocialLinks(newSocialLinks);




        console.log(newSocialLinks);

    }

    const removeSocial = (id) => {
        const newItems = {
            ...items
        }
        delete newItems[id];
        const newItemOrder = itemOrder.filter((item) => item !== id);

        const newSocialLinks = {
            ...socialLinks,
            items: newItems,
            itemOrder: newItemOrder
        }

        setSocialLinks(newSocialLinks);

    }


    return (
        <>
            <div className="add_block">



                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">

                            {
                                frameType == "update" ? "update social link" : "Add Social Link"
                            }
                        </label>
                    </div>
                </div>
                <div className="title_drop_header iuEvSC">
                    <label color="grayDarker" class="style__Label-bufferapp-ui__sc-153wil8-5 gvofes">Social Links</label>
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                            onClick={showwDropdown}
                        >
                            Select Social Link
                        </button>
                        <ul className="dropdown-menu social_dropdown_menu" aria-labelledby="dropdownMenuButton1">
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('FACEBOOK', 'https://facebook.com/')}>
                                    <div className="social_item_icon">
                                        <BsFacebook />
                                    </div>
                                    <div className="social_item_text">
                                        <div className="social_item_text_title" >Facebook</div>
                                    </div>
                                </div>
                            </li>
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('INSTAGRAM', 'https://instagram.com/')}>
                                    <div className="social_item_icon">
                                        <BsInstagram />
                                    </div>
                                    <div className="social_item_text">
                                        <div className="social_item_text_title">Instagram</div>
                                    </div>
                                </div>
                            </li>
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('WHATSAPP', 'https://whatsapp.com/')}>
                                    <div className="social_item_icon">
                                        <RiWhatsappFill />
                                    </div>
                                    <div className="social_item_text">
                                        <div className="social_item_text_title">Whatsapp</div>
                                    </div>
                                </div>
                            </li>
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('TWITTER', 'https://twitter.com/')}>
                                    <div className="social_item_icon">
                                        <BsTwitterX />
                                    </div>
                                    <div className="social_item_text">
                                        <div className="social_item_text_title">X (formerly Twitter)</div>
                                    </div>
                                </div>
                            </li>
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('LINKEDIN', 'https://linkedin.com/')}>
                                    <div className="social_item_icon">
                                        <BsLinkedin />
                                    </div>
                                    <div className="social_item_text">
                                        <div className="social_item_text_title">Linkedin</div>
                                    </div>
                                </div>
                            </li>
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('YOUTUBE', 'https://youtube.com/')}>
                                    <div className="social_item_icon">
                                        <BsYoutube />
                                    </div>
                                    <div className="social_item_text">
                                        <div className="social_item_text_title">Youtube</div>
                                    </div>
                                </div>
                            </li>
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('TIKTOK', 'https://tiktok.com/')}>
                                    <div className="social_item_icon">
                                        <BsTiktok />
                                    </div>
                                    <div className="social_item_text" >
                                        <div className="social_item_text_title">Tiktok</div>
                                    </div>
                                </div>
                            </li>
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('PAYPAL', 'https://paypal.com/')}>
                                    <div className="social_item_icon">
                                        <BsPaypal />
                                    </div>
                                    <div className="social_item_text">
                                        <div className="social_item_text_title">Paypal</div>
                                    </div>
                                </div>
                            </li>
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('SNAPCHAT', 'https://snapchat.com/')}>
                                    <div className="social_item_icon">
                                        <BsSnapchat />
                                    </div>
                                    <div className="social_item_text">
                                        <div className="social_item_text_title">Snapchat</div>
                                    </div>
                                </div>
                            </li>
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('TELEGRAM', 'https://telegram.com/')}>
                                    <div className="social_item_icon">
                                        <FaTelegramPlane />
                                    </div>
                                    <div className="social_item_text">
                                        <div className="social_item_text_title">Telegram</div>
                                    </div>
                                </div>
                            </li>
                            <li className="drop_social_item">
                                <div className="social_item_inner" onClick={() => addNewSocial('PINTEREST', 'https://pinterest.com/')}>
                                    <div className="social_item_icon">
                                        <BsPinterest />
                                    </div>
                                    <div className="social_item_text">
                                        <div className="social_item_text_title">Pinterest</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="icons_list">
                    <DragDropContext onDragEnd={onDragEnd}>
                        {listOrder.map((listId) => {
                            const list = lists[listId];
                            const listItems = itemOrder.map((itemId) => items[itemId]);
                            return <List key={list.id} list={list} items={listItems} updateSocialUrl={updateSocialUrl} removeSocial={removeSocial} />;
                        })}
                    </DragDropContext>
                </div>
                <div className="add-block-btn">
                    {
                        frameType == "update" ?
                            <button className="btn publish-btn" onClick={
                                () => {
                                    editComponent(position, "ICONS", socialLinks,status);
                                    navigate.push("/");
                                }
                            }>
                                Update
                            </button>
                            :
                            <button className="btn publish-btn" onClick={
                                () => {
                                    addComponent("ICONS", socialLinks);
                                    navigate.push("/");
                                }
                            }>
                                Add
                            </button>
                    }

                </div>
            </div>
        </>
    );
}