import {apiCall} from './auth.js';
import {API_URL} from './config.js';



//createApiApi
export const createApiApi = (formData) => apiCall(`${API_URL}api/addapi`, "POST", formData);

//getApiApi
export const getApiApi = (data) => apiCall(`${API_URL}api/getapi`, "POST", data);

//updateApiApi
export const updateApiApi = (formData) => apiCall(`${API_URL}api/updateapi`, "POST", formData);

//deleteApiApi
export const deleteApiApi = (formData) => apiCall(`${API_URL}api/deleteapi`, "DELETE", formData);

//getApiByIdApi
export const getApiByIdApi = (formData) => apiCall(`${API_URL}api/getapibyid`, "POST", formData);

