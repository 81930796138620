// ParentProvider.js
import React from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { UserProvider } from './UserContext';
import { SocialAccountProvider } from "./contexts/context/socialAccountContext";
import { FlexLinkProvider } from './contexts/context/flexLinkContext';
import { SocialPostProvider } from './socialpostcontext';
import { VanityProvider } from './VanityContext';
import { ScheduledPostsProvider } from "./contexts/context/scheduledPostsContext";
import { CompletedPostsProvider } from "./contexts/context/completedPostsContext";
import { DraftedPostsProvider } from "./contexts/context/draftedPostsContext";
import { ArchivedPostsProvider } from "./contexts/context/archivedPostsContext";
import { BrandSelectionProvider } from "./contexts/context/brandSelectionContext";
import { BrandProvider } from './brandContext';
import { TemplateProvider } from './TemplateContext';
import { SessionProvider } from './sessionContext';
import { CampaignProvider } from './campaignContext';
import { BuilderContextProvider } from './builderContext';
import { SmartLinkProvider } from './Components/CreateSmartlink/SmartLinkcontext';
import { AuthProvider } from './AuthContext';

const ParentProvider = ({ children }) => {
    return (
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID}>
            <AuthProvider>
            <UserProvider>
                <AuthProvider>
                <SocialAccountProvider>
                    <FlexLinkProvider>
                        <SocialPostProvider>
                            <VanityProvider>
                                <ScheduledPostsProvider>
                                    <CompletedPostsProvider>
                                        <DraftedPostsProvider>
                                            <ArchivedPostsProvider>
                                                <BrandSelectionProvider>
                                                    <BrandProvider>
                                                        <TemplateProvider>
                                                            <SessionProvider>
                                                                <CampaignProvider>
                                                                    <BuilderContextProvider>
                                                                        <SmartLinkProvider>
                                                                            {children}
                                                                        </SmartLinkProvider>
                                                                    </BuilderContextProvider>
                                                                </CampaignProvider>
                                                            </SessionProvider>
                                                        </TemplateProvider>
                                                    </BrandProvider>
                                                </BrandSelectionProvider>
                                            </ArchivedPostsProvider>
                                        </DraftedPostsProvider>
                                    </CompletedPostsProvider>
                                </ScheduledPostsProvider>
                            </VanityProvider>
                        </SocialPostProvider>
                    </FlexLinkProvider>
                </SocialAccountProvider>
                </AuthProvider>
            </UserProvider>
            </AuthProvider>
        </IntercomProvider>
    );
};

export default ParentProvider;