import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import Picker from 'emoji-picker-react';
import { FaTwitter, FaLinkedin, FaYoutube, FaInstagram } from "react-icons/fa";
import { ImMagicWand } from "react-icons/im";
import { GoFileMedia } from "react-icons/go";
import { v4 as uuid } from "uuid";
import { FaFacebook } from "react-icons/fa";
import { socialAccountContext } from "../contexts/context/socialAccountContext";

import { DateTime } from 'luxon';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import useScheduleDataActions from "../contexts/actions/useScheduleDataActions";
import useDraftDataActions from "../contexts/actions/useDraftDataActions";
import AiPosts from "./AiPosts"
import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader";
import MediaPost from "./MediaPost";
import { TbTrash } from "react-icons/tb";
import { SiCanva } from "react-icons/si";
import { getCanvaAuthorization, getUser, revoke } from "../canva/auth";

import { useLocation } from "react-router-dom";

import Canva from "./canva";
import AuthContext from "../AuthContext";

let BASE_URL = process.env.REACT_APP_API_URL

const API_URL = process.env.REACT_APP_API_URL;

const BulkSchedule = ({ closemodal, method, accountdata, scheduleBinData, type, postType }) => {

    const location = useLocation();
    const { setIsAuthenticated, setIsCanvaConnected, setAuthData, isCanvaConnected, isAuthenticated } = useContext(AuthContext)
    const user = JSON.parse(localStorage.getItem("user"));

    const { connectedAccount } = useContext(socialAccountContext);

    const { getscheduleddata } = useScheduleDataActions();

    const { getdrafteddata } = useDraftDataActions();

    const [customization, setCustomization] = useState(false)

    const [masterPosts, setMasterPosts] = useState([]);

    const textareaRef = useRef(null);

    const [showPicker, setShowPicker] = useState(false);

    const [selectedchannel, setSelectedChannel] = useState([]);

    const [isAiView, setIsAiView] = useState(false);

    const [indexforai, setIndexforai] = useState(null);

    const [newloading, setNewLoading] = useState(false)
    const [newloading2, setNewLoading2] = useState(false)
    const [show03, setShow03] = useState(false);

    const handleClose03 = () => setShow03(false);
    const handleShow03 = (ID, data) => {
        setShow03(true);
        //   closemodl(); // Close the parent modal
    };

    const switchToModal04 = () => {
        setShow03(true);
        closemodal();
    }
    const closemodl = () => {

        closemodal();
    }
    useEffect(() => {

        if (method == "channel") {

            if (accountdata) {

                const seldata = [accountdata._id]


                setSelectedChannel(seldata)


            }

        }


    }, [accountdata, method]);


    const adjustHeight = () => {
        const element = textareaRef.current;
        if (element) {
            // Reset the height to a minimum value to ensure it can shrink
            element.style.height = 'auto';
            // Set the height to the scroll height to fit the content
            element.style.height = `${element.scrollHeight}px`;
        }
    };


    const handleSave2 = async () => {
        setNewLoading(true)

        console.log("save2masterposts", masterPosts);
        if (customization === false) {

            if (selectedchannel.length == 0) {
                PNotify.error({
                    title: 'Error',
                    text: 'Please select at least one channel.',
                });
                setNewLoading(false)
                return;
            }

            if (masterPosts[0].caption == "") {
                PNotify.error({
                    title: 'Error',
                    text: 'Please enter caption for post',
                });
                setNewLoading(false)
                return;
            }

            if (masterPosts[0].mediaType == null || (masterPosts[0].mediaType == "image" && masterPosts[0].images.length == 0) || (masterPosts[0].mediaType == "video" && masterPosts[0].videos.length == 0)) {
                PNotify.error({
                    title: 'Error',
                    text: 'Please select atleast one image or video',
                });
                setNewLoading(false)
                return;
            }

            if (masterPosts[0].mediaType != null) {

                let upload = false

                if (masterPosts[0].mediaType == "image") {

                    for (let i = 0; i < masterPosts[0].images.length; i++) {
                        if (masterPosts[0].images[i].isUploading == true) {
                            upload = true
                            break;
                        }
                    }


                }

                if (masterPosts[0].mediaType == "video") {

                    for (let i = 0; i < masterPosts[0].videos.length; i++) {
                        if (masterPosts[0].videos[i].isUploading == true) {
                            upload = true
                            break;
                        }
                    }
                }

                if (upload == true) {
                    PNotify.error({
                        title: 'Error',
                        text: 'Please wait for image or video to upload',
                    });
                    setNewLoading(false)
                    return;
                }

            }

            const data = [];

            console.log("selectedchannel", selectedchannel);

            //run a loop theough selectedchannel and add it to data
            for (let i = 0; i < selectedchannel.length; i++) {

                const refinedMasterPosts = masterPosts.map((post) => {

                    if (post.mediaType == "image") {
                        const refinedmedia = post.images && post.images.map((media) => {
                            return {
                                id: media.id, imgUrl: media.imgUrl, isUploading: false,
                            }
                        })

                        return {
                            caption: post.caption, images: refinedmedia, videos: [], mediaType: post.mediaType, dateTime: post.dateTime
                        }
                    }

                    if (post.mediaType == "video") {
                        const refinedmedia = post.videos && post.videos.map((media) => {
                            return {
                                id: media.id, imgUrl: media.imgUrl, isUploading: false,
                            }
                        })

                        return {
                            caption: post.caption, videos: refinedmedia, images: [], mediaType: post.mediaType, media: refinedmedia, dateTime: post.dateTime
                        }
                    }


                })

                data.push({ id: selectedchannel[i], owner_id: user.owner_id, created_by: user._id, posts: refinedMasterPosts });
            }
            try {

                const response = await fetch(`${API_URL}/socialposts/bulkscheduleposts`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        data: data,
                        created_by: user._id,
                        owner_id: user.owner_id,
                        type: "SCHEDULED"
                    })
                })

                const result = await response.json();

                if (result.status == true) {
                    getscheduleddata();
                    PNotify.success({
                        title: 'Success',
                        text: 'Post scheduled successfully',
                    })
                    closemodl();

                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong, Please try again',
                    });
                }
            } catch (error) {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong, Please try again',
                });
            } finally {
                setNewLoading(false)
            }

        }

    }

    const SaveDraft = async () => {
        setNewLoading2(true)
        if (customization === false) {

            if (selectedchannel.length == 0) {
                PNotify.error({
                    title: 'Error',
                    text: 'Please select at least one channel.',
                });
                setNewLoading2(false)
                return;
            }

            if (masterPosts[0].title == "") {
                PNotify.error({
                    title: 'Error',
                    text: 'Please enter title',
                })
                // alert("Please enter title");
                setNewLoading2(false)
                return;
            }

            if (masterPosts[0].caption == "") {
                PNotify.error({
                    title: 'Error',
                    text: 'Please enter caption',
                })
                // alert("Please enter caption");
                setNewLoading2(false)
                return;
            }

            if (masterPosts[0].mediaType == null || (masterPosts[0].mediaType == "image" && masterPosts[0].images.length == 0) || (masterPosts[0].mediaType == "video" && masterPosts[0].videos.length == 0)) {

                PNotify.error({
                    title: 'Error',
                    text: 'Please select atleast one image or video',
                })
                setNewLoading2(false)
                return;
            }
            const data = [];

            console.log("selectedchannel", selectedchannel);

            //run a loop theough selectedchannel and add it to data
            for (let i = 0; i < selectedchannel.length; i++) {

                const refinedMasterPosts = masterPosts.map((post) => {

                    if (post.mediaType == "image") {
                        const refinedmedia = post.images && post.images.map((media) => {
                            if (media.isUploading == false) {
                                return {
                                    id: media.id, imgUrl: media.imgUrl, isUploading: false,
                                }
                            }
                        })

                        return {
                            caption: post.caption, images: refinedmedia, videos: [], mediaType: post.mediaType, dateTime: post.dateTime
                        }
                    }

                    if (post.mediaType == "video") {
                        const refinedmedia = post.videos && post.videos.map((media) => {
                            if (media.isUploading == false) {
                                return {
                                    id: media.id, imgUrl: media.imgUrl, isUploading: false,
                                }
                            }
                        })

                        return {
                            caption: post.caption, videos: refinedmedia, images: [], mediaType: post.mediaType, media: refinedmedia, dateTime: post.dateTime
                        }
                    }


                })



                data.push({ id: selectedchannel[i], owner_id: user.owner_id, created_by: user._id, posts: refinedMasterPosts });
            }
            try {
                const response = await fetch(`${API_URL}/socialposts/bulkscheduleposts`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        data: data,
                        created_by: user._id,
                        owner_id: user.owner_id,
                        type: "DRAFTED"
                    })
                })

                const result = await response.json();

                if (result.status == true) {
                    getdrafteddata();
                    PNotify.success({
                        title: 'Success',
                        text: 'draft saved successfully',
                    })
                    closemodl();

                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong, Please try again',
                    });

                }

            } catch (error) {
                PNotify.error({
                    title: 'Error',
                    text: 'Something went wrong, Please try again',
                });
            } finally {
                setNewLoading2(false)
            }

        }

    }

    // useEffect(() => {
    //     const newPost = {
    //         caption: scheduleBinData?.caption || "",
    //         dateTime: "",
    //         mediaType: scheduleBinData?.mediaType || null,
    //         images: scheduleBinData?.images || [],
    //         videos: scheduleBinData?.videos || [],
    //     };

    //     const ddate = new Date();
    //     ddate.setMinutes(ddate.getMinutes() + 5);
    //     const formattedDateTime = ddate.toISOString().slice(0, 19);
    //     const kolkata = convertToKolkataTimezone(formattedDateTime);
    //     newPost.dateTime = kolkata;

    //     setMasterPosts([newPost]);
    // }, [scheduleBinData]);


    const handleselectedaccount = (id) => {


        if (selectedchannel.includes(id)) {
            setSelectedChannel(selectedchannel.filter((item) => item !== id));
        } else {
            setSelectedChannel(prev => [...prev, id]);
        }

    }

    const handleuploadfile = async (file, index) => {

        try {

            var uniqueId = uuid();

            // var thumbnail;

            if (file.type.startsWith('video/')) {
                // thumbnail = await generateVideoThumbnails(file, 1);

                const updatedPosts = [...masterPosts];
                updatedPosts[index].videos = [...updatedPosts[index].videos, { id: uniqueId, imgUrl: "", isUploading: true }];
                setMasterPosts(updatedPosts);
            }

            if (file.type.startsWith("image/")) {

                // thumbnail = URL.createObjectURL(file);
                const updatedPosts = [...masterPosts];
                updatedPosts[index].images = [...updatedPosts[index].images, { id: uniqueId, imgUrl: "", isUploading: true }];
                setMasterPosts(updatedPosts);
            }

            console.log("masterposts : ", masterPosts);

            const formData = new FormData();
            formData.append('image', file);

            const response = await fetch(`${API_URL}/socialposts/upload`, {
                method: 'POST',
                body: formData
            });

            const result = await response.json();

            if (result.status) {
                console.log("file".file)
                if (file.type.startsWith('video/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].videos.findIndex(item => item.id === uniqueId);
                        updatedPosts[index].videos[mediaIndex].imgUrl = result.filename;
                        updatedPosts[index].videos[mediaIndex].isUploading = false;
                        return updatedPosts;
                    });
                }
                if (file.type.startsWith('image/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].images.findIndex(item => item.id === uniqueId);
                        updatedPosts[index].images[mediaIndex].imgUrl = result.filename;
                        updatedPosts[index].images[mediaIndex].isUploading = false;

                        return updatedPosts;
                    });
                }

                console.log("masterposts after : ", masterPosts);
            } else {
                PNotify.error({
                    title: 'Error',
                    text: 'Error uploading file',
                });
                if (file.type.startsWith('video/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].videos.findIndex(item => item.id === uniqueId);
                        //delete
                        updatedPosts[index].videos.splice(mediaIndex, 1);
                        return updatedPosts;
                    });
                }

                if (file.type.startsWith('image/')) {
                    setMasterPosts(prevPosts => {
                        const updatedPosts = [...prevPosts];
                        const mediaIndex = updatedPosts[index].images.findIndex(item => item.id === uniqueId);
                        //delete
                        updatedPosts[index].images.splice(mediaIndex, 1);
                        return updatedPosts;
                    });
                }

            }

        } catch (error) {
            PNotify.error({
                title: 'Error',
                text: 'Error uploading file',
            });
            if (file && file.type.startsWith('video/')) {
                setMasterPosts(prevPosts => {
                    const updatedPosts = [...prevPosts];
                    const mediaIndex = updatedPosts[index].videos.findIndex(item => item.id === uniqueId);
                    //delete
                    updatedPosts[index].videos.splice(mediaIndex, 1);
                    return updatedPosts;
                });
            }

            if (file && file.type.startsWith('image/')) {
                setMasterPosts(prevPosts => {
                    const updatedPosts = [...prevPosts];
                    const mediaIndex = updatedPosts[index].images.findIndex(item => item.id === uniqueId);
                    //delete
                    updatedPosts[index].images.splice(mediaIndex, 1);
                    return updatedPosts;
                });
            }

            // Handle error if necessary
        }
    }

    function convertToLocalTimezone(dateString) {
        console.log("incoming date string : ", dateString);
        const kolkataDate = DateTime.fromISO(dateString, { zone: 'Asia/Kolkata' });

        if (!kolkataDate.isValid) {
            console.error("Invalid date string");
            return null;
        }

        const localDate = kolkataDate.setZone(DateTime.local().zoneName);
        const localDateString = localDate.toISO({ includeOffset: false, suppressMilliseconds: true }).slice(0, 16);

        console.log("local date string : ", localDateString);
        return localDateString;
    }

    function convertToKolkataTimezone(dateString) {
        const localDate = DateTime.fromISO(dateString, { zone: DateTime.local().zoneName });

        if (!localDate.isValid) {
            console.error("Invalid date string");
            return null;
        }

        const kolkataDate = localDate.setZone('Asia/Kolkata');
        const kolkataDateString = kolkataDate.toISO({ includeOffset: false, suppressMilliseconds: true }).slice(0, 16);

        return kolkataDateString;
    }

    const getIconAndImage = (platform, accountdata) => {
        switch (platform) {
            case 'FACEBOOK':
                return {
                    icon: <FaFacebook />,
                    image: accountdata.facebook_pageProfilePicture,
                    localImage: API_URL + "/socialProfile/" + accountdata?.localProfilePath
                };
            case 'TWITTER':
                return {
                    icon: <FaTwitter />,
                    image: accountdata.Twitter_user_profilePicture,
                    localImage: API_URL + "/socialProfile/" + accountdata?.localProfilePath
                };
            case 'LINKEDIN':
                return {
                    icon: <FaLinkedin />,
                    image: accountdata.LinkedIn_picture,
                    localImage: API_URL + "/socialProfile/" + accountdata?.localProfilePath
                };
            case 'YOUTUBE':
                return {
                    icon: <FaYoutube />,
                    image: accountdata.youtube_picture,
                    localImage: API_URL + "/socialProfile/" + accountdata?.localProfilePath
                };
            case 'INSTAGRAM':
                return {
                    icon: <FaInstagram />,
                    image: accountdata.instagram_business_account_profile_picture_url,
                    localImage: API_URL + "/socialProfile/" + accountdata?.localProfilePath
                };
            case 'NO_CHANNELS':
                return "Please Connect AtList One Channels "
            default:
                return {
                    icon: null,
                    image: 'https://via.placeholder.com/50' // Default placeholder image
                };
        }
    };

    const insertaipost = (daata) => {
        const updatedPosts = [...masterPosts];
        updatedPosts[indexforai].caption = updatedPosts[indexforai].caption + daata;
        setMasterPosts(updatedPosts);
    }

    const insertaipostVideo = (data) => {
        const newData = data.map((item, index) => {
            let uniqueId = uuid();
            return { id: uniqueId, imgUrl: item, isUploading: false }
        })
        setMasterPosts((pre) => {
            const updatedPosts = [...pre];
            updatedPosts[0] = { ...updatedPosts[0], mediaType: "video", videos: [...updatedPosts[0].videos, ...newData] }
            return updatedPosts
        })
        console.log("videos", data)
    };
    const insertaipostImage = (data) => {
        const newData = data.map((item, index) => {
            let uniqueId = uuid();
            return { id: uniqueId, imgUrl: item, isUploading: false }
        })
        setMasterPosts((pre) => {
            const updatedPosts = [...pre];
            updatedPosts[0] = { ...updatedPosts[0], mediaType: "image", images: [...updatedPosts[0].images, ...newData] }
            return updatedPosts
        })
        console.log("images", data)
    };

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const setShowAiModal = () => {
        setIsOpen(prev => {
            if (!prev && isOpen2) {
                setIsOpen2(false);
            }
            return !prev;
        });
    }

    const setShowMediaModal = () => {
        setIsOpen2(prev => {
            if (!prev && isOpen) {
                setIsOpen(false);
            }
            return !prev;
        });
    }


    const onCanvaConnectClick = async () => {
        try {

            const result = await getCanvaAuthorization();

            if (result) {
                console.log(result);

            } else {

            }
        } catch (error) {
            console.log(error);

        } finally {

        }
    };

    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const [isCanvaConnected, setIsCanvaConnected] = useState(false);
    // const [authData, setAuthData] = useState(null);

    const state = user?.owner_id;

    const insertAsset = async (design_id, uniqueId) => {
        const user = JSON.parse(localStorage.getItem("user"));

        try {
            const response = await fetch(`${API_URL}/socialposts/exportCanvaDesign`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    design_id: design_id,
                    user_id: user?.owner_id
                })
            });

            const data = await response.json();
            if (data.success) {
                setMasterPosts((pre) => {
                    const updatedPosts = [...pre];
                    updatedPosts[0] = {
                        ...updatedPosts[0],
                        images: updatedPosts[0].images.map(image =>
                            image.id === uniqueId
                                ? { ...image, imgUrl: data.url, isUploading: false }
                                : image
                        )
                    };
                    return updatedPosts;
                });
                localStorage.removeItem('design_data');
            } else {
                // Remove image with the uniqueId in case of failure
                setMasterPosts((pre) => {
                    const updatedPosts = [...pre];
                    updatedPosts[0] = {
                        ...updatedPosts[0],
                        images: updatedPosts[0].images.filter(image => image.id !== uniqueId)
                    };
                    return updatedPosts;
                });
                console.error('Failed to upload asset:', data.message);
            }
        } catch (error) {
            // Remove image with the uniqueId in case of error
            setMasterPosts((pre) => {
                const updatedPosts = [...pre];
                updatedPosts[0] = {
                    ...updatedPosts[0],
                    images: updatedPosts[0].images.filter(image => image.id !== uniqueId)
                };
                return updatedPosts;
            });
            console.error('Error creating Canva design:', error);
        }
    };


    const handleCanvaAuth = async () => {
        const authorizationUrl = `${API_URL}/socials/canvaOAuth`;

        const width = 700;
        const height = 700;
        const left = (window.screen.width / 5) - (width / 5);
        const top = (window.screen.height / 3) - (height / 3);

        let authWindow = window.open(
            `${authorizationUrl}?owner_id=${state}`,
            'CANVA_AUTH',
            `width=${width},height=${height},left=${left},top=${top}`
        );

        const windowCheckInterval = setInterval(() => {
            if (authWindow.closed) {
                clearInterval(windowCheckInterval); // Stop checking when window is closed
                // setIsOpen3(prev => !prev); // Update the state when the window is closed
                // alert('Window closed');
                setIsCanvaConnected(true);
                setIsAuthenticated(true);
            }
        }, 500)

    };

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return;
            const { type, message } = event.data;
            if (type === 'CANVA_AUTH') {
                setAuthData(message);
                setIsCanvaConnected(true);
                setIsAuthenticated(true);
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {

        const checkCanvaRedirect = () => {

            const urlParams = new URLSearchParams(location.search);
            const source = urlParams.get('source');

            if (source === 'canva_redirect') {
                const localData = JSON.parse(localStorage.getItem("design_data"));

                if (localData) {
                    setMasterPosts(localData?.masterPosts);

                    const design_id = localData?.data?.id;

                    const uniqueId = uuid();


                    const updatedPosts = [...localData?.masterPosts];
                    updatedPosts[0].images = [...updatedPosts[0].images, { id: uniqueId, imgUrl: "", isUploading: true }];
                    setMasterPosts(updatedPosts);

                    insertAsset(design_id, uniqueId);

                }
                else {

                    const newPost = {
                        caption: scheduleBinData?.caption || "",
                        dateTime: "",
                        mediaType: scheduleBinData?.mediaType || null,
                        images: scheduleBinData?.images || [],
                        videos: scheduleBinData?.videos || [],
                    };

                    const ddate = new Date();
                    ddate.setMinutes(ddate.getMinutes() + 5);
                    const formattedDateTime = ddate.toISOString().slice(0, 19);
                    const kolkata = convertToKolkataTimezone(formattedDateTime);
                    newPost.dateTime = kolkata;

                    setMasterPosts([newPost]);
                }

            }
            else {

                const newPost = {
                    caption: scheduleBinData?.caption || "",
                    dateTime: "",
                    mediaType: scheduleBinData?.mediaType || null,
                    images: scheduleBinData?.images || [],
                    videos: scheduleBinData?.videos || [],
                };

                const ddate = new Date();
                ddate.setMinutes(ddate.getMinutes() + 5);
                const formattedDateTime = ddate.toISOString().slice(0, 19);
                const kolkata = convertToKolkataTimezone(formattedDateTime);
                newPost.dateTime = kolkata;

                setMasterPosts([newPost]);
            }

        }

        checkCanvaRedirect()



    }, [scheduleBinData]);

    useEffect(() => {},
     [masterPosts]);



    const uploadAssetAndCreateDesign = async () => {

        const user = JSON.parse(localStorage.getItem("user"));


        const formData = new FormData();
        formData.append('user_id', user?.owner_id);

        try {
            const response = await fetch(`${API_URL}/socialposts/uploadAssetAndCreateDesign`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.success) {

                localStorage.setItem("design_data", JSON.stringify({
                    location: window.location.href,
                    data: data.data,
                    masterPosts: masterPosts
                }));

                const editUrl = data.data.urls.edit_url;
                window.location.assign(editUrl);

            } else {
                console.error('Failed to upload asset:', data.message);
            }
        } catch (error) {
            console.error('Error creating Canva design:', error);
        }
    };


    // const [editlink, setEditLink] = useState(null);

    // const uploadAssetAndCreateDesign = async () => {

    //     const user = JSON.parse(localStorage.getItem("user"));


    //     const formData = new FormData();
    //     formData.append('user_id', user?.owner_id);

    //     try {
    //         const response = await fetch(`${API_URL}/socialposts/uploadAssetAndCreateDesign`, {
    //             method: 'POST',
    //             body: formData,
    //         });

    //         const data = await response.json();
    //         if (data.success) {

    //             localStorage.setItem("design_data", JSON.stringify({
    //                 location: window.location.href,
    //                 data: data.data,
    //                 masterPosts: masterPosts
    //             }));

    //             const editUrl = data.data.urls.edit_url;

    //             setEditLink(editUrl)
    //             // window.location.assign(editUrl);

    //             setIsOpen3(true)

    //         } else {
    //             console.error('Failed to upload asset:', data.message);
    //         }
    //     } catch (error) {
    //         console.error('Error creating Canva design:', error);
    //     }
    // };





    return (
        <>
            <Modal show={show03} onHide={handleClose03} style={{ zIndex: 99999999999999999 }} backdrop="static" keyboard={false} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Confirm Dublicate</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to dublicate this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose03}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={() => { postType == "Drafted" ? SaveDraft() : handleSave2() }} >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal.Body className="post-schedule-body">

                <Container>

                    {isOpen && (
                        <div className="ai-modal-new">
                            <div className="ai-modal-inner">
                                <AiPosts insertpost={insertaipost} />
                            </div>
                        </div>
                    )}

                    {isOpen2 && (
                        <div className="media-modal-new">
                            <div className="ai-modal-inner">
                                <MediaPost insertaipostVideo={insertaipostVideo} insertaipostImage={insertaipostImage} />
                            </div>
                        </div>
                    )}

                    {isOpen3 && (
                        <div className="media-modal-new">
                            <div className="ai-modal-inner">
                                {/* <iframe src={editlink} width="100%" height="600px" style={{ border: 'none' }}></iframe> */}
                                <Canva insertaipostImage={insertaipostImage} masterPosts={masterPosts} insertaipostVideo={insertaipostVideo} />
                            </div>
                        </div>
                    )}



                    <Row>

                        <Col md={isAiView ? 8 : 12} >
                            <div className="channel-list- mb-3 mt-3">
                                {method == 'all' &&
                                    <>

                                        {connectedAccount.accounts &&
                                            connectedAccount.accounts.map((item, index) => {

                                                return (
                                                    <>
                                                        <div
                                                            key={index}
                                                            className={`channel-list-box ${selectedchannel.includes(item._id) ? 'active' : ''
                                                                }`}
                                                            onClick={() => handleselectedaccount(item._id)}
                                                        >
                                                            <div className="channel-list-box-icon facebook-profile">
                                                                <img
                                                                    src={getIconAndImage(item.platform, item).localImage}
                                                                    alt="profile"
                                                                />
                                                                <div className="bdyGBn social-icon-view">
                                                                    {getIconAndImage(item.platform, item).icon}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                )
                                            })}

                                    </>
                                }

                                {
                                    (method === 'channel' && accountdata) && (
                                        <>
                                            <div className={`channel-list-box active`}>
                                                <div className="channel-list-box-icon facebook-profile">
                                                    <img
                                                        src={getIconAndImage(accountdata.platform, accountdata).localImage}
                                                        alt='profile'
                                                    />
                                                    <div className="bdyGBn social-icon-view">
                                                        {getIconAndImage(accountdata.platform, accountdata).icon}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {connectedAccount.accounts?.length == 0 && <p>{getIconAndImage("NO_CHANNELS", "")}</p>}
                            </div>

                            <div className="post-container-view" style={{ display: customization ? 'none' : 'block' }}>
                                <div className="row">
                                    {masterPosts.length > 0 ?
                                        masterPosts.map((post, index) => (
                                            <div className="col-md-12 mb-3" key={index}>

                                                <div className="post-box mt-2">
                                                    <div className="text-area-box">
                                                        <div className="mb-3">
                                                            <textarea
                                                                ref={textareaRef}
                                                                className="form-control custom-text-area"
                                                                rows="3"
                                                                placeholder="What's on your mind?"
                                                                onInput={adjustHeight}
                                                                style={{ overflowY: 'hidden', resize: 'none' }}
                                                                value={post.caption}
                                                                onChange={(e) => {

                                                                    const updatedPosts = [...masterPosts];
                                                                    updatedPosts[index].caption = e.target.value;
                                                                    setMasterPosts(updatedPosts);

                                                                }
                                                                }
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="image-upload-container">

                                                        <div className="upload-sec">
                                                            <div className="upload-images-list-2">
                                                                <div style={{ display: post.mediaType === 'image' ? 'block' : 'none' }}>
                                                                    <div className="upload-images-list">
                                                                        {post.images.map((image, imgindex) => (
                                                                            <div key={imgindex}>
                                                                                <div className="image-container-view mb-2">
                                                                                    {!image.isUploading ?

                                                                                        <img src={image.imgUrl?.startsWith("https://") ? image.imgUrl : `${API_URL}/posts/${image.imgUrl}`} alt="preview" />

                                                                                        : <div className="d-flex justify-content-center align-items-center h-100">
                                                                                            <div className="text-center">
                                                                                                <div
                                                                                                    className="spinner-border text-primary mb-3"
                                                                                                    role="status"
                                                                                                    style={{
                                                                                                        width: '3rem',
                                                                                                        height: '3rem',
                                                                                                        animation: 'rotate 2s linear infinite',
                                                                                                    }}
                                                                                                >
                                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                                </div>
                                                                                                <p className="mb-0">Uploading...</p>
                                                                                            </div>
                                                                                            <style>{`
                                                                                                @keyframes rotate {
                                                                                                from {
                                                                                                    transform: rotate(0deg);
                                                                                                }
                                                                                                to {
                                                                                                    transform: rotate(360deg);
                                                                                                }
                                                                                                }
                                                                                            `}</style>
                                                                                        </div>}
                                                                                    <button className="remove-image sc-iBbrVh YsbxX _closeButton_1c4j9_57 "
                                                                                        onClick={(e) => {

                                                                                            if (post.images.length === 1) {
                                                                                                setMasterPosts(prevPosts => {
                                                                                                    const updatedPosts = [...prevPosts];
                                                                                                    updatedPosts[index].mediaType = null;
                                                                                                    updatedPosts[index].images = [];
                                                                                                    return updatedPosts;
                                                                                                })
                                                                                            }
                                                                                            else {

                                                                                                const updatedPosts = [...masterPosts];
                                                                                                updatedPosts[index].images = updatedPosts[index].images.filter(item => item.id !== image.id);


                                                                                                setMasterPosts(updatedPosts)
                                                                                            }

                                                                                        }}>
                                                                                        <IoClose />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                    {/* <div className="file-input-container">
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => {

                                                                    handleuploadfile(e.target.files[0], index);
                                                                }
                                                                }

                                                                multiple
                                                                className="file-input"
                                                            />
                                                            <label htmlFor="fileInput">
                                                                <i className="input-icon">📁</i>
                                                                <span className="file-input-text">select a file</span>
                                                            </label>
                                                        </div> */}

                                                                </div>

                                                                <div style={{ display: post.mediaType === 'video' ? 'block' : 'none' }}>
                                                                    <div className="upload-images-list">
                                                                        {post.videos.map((video, videoindex) => (
                                                                            <div key={videoindex}>
                                                                                <div className="image-container-view mb-2">
                                                                                    <div classsName="upload-images-list">
                                                                                        {!video.isUploading ?
                                                                                            <video src={video.imgUrl?.startsWith("https://") ? video.imgUrl : `${API_URL}/posts/${video.imgUrl}`} controls />
                                                                                            : <div className="d-flex justify-content-center align-items-center h-100">
                                                                                                <div className="text-center">
                                                                                                    <div
                                                                                                        className="spinner-border text-primary mb-3"
                                                                                                        role="status"
                                                                                                        style={{
                                                                                                            width: '3rem',
                                                                                                            height: '3rem',
                                                                                                            animation: 'rotate 2s linear infinite',
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                                    </div>
                                                                                                    <p className="mb-0">Uploading...</p>
                                                                                                </div>
                                                                                                <style>{`@keyframes rotate {from {transform: rotate(0deg);}to {transform: rotate(360deg);}}`}
                                                                                                </style>
                                                                                            </div>}
                                                                                        <button className="remove-image sc-iBbrVh YsbxX _closeButton_1c4j9_57 "
                                                                                            onClick={(e) => {

                                                                                                if (post.videos.length == 1) {

                                                                                                    setMasterPosts(prevPosts => {
                                                                                                        const updatedPosts = [...prevPosts];
                                                                                                        updatedPosts[index].mediaType = null;
                                                                                                        updatedPosts[index].videos = [];
                                                                                                        return updatedPosts;
                                                                                                    })

                                                                                                }
                                                                                                else {

                                                                                                    const updatedPosts = [...masterPosts];
                                                                                                    updatedPosts[index].videos = updatedPosts[index].videos.filter(item => item.id !== video.id);

                                                                                                    setMasterPosts(updatedPosts)

                                                                                                }

                                                                                            }}>
                                                                                            <IoClose />
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>


                                                                </div>

                                                                <div className="file-input-container">
                                                                    <input
                                                                        type="file"
                                                                        accept={post.mediaType === null ? "image/*,video/*" : post.mediaType === "image" ? "image/*" : "video/*"}
                                                                        onChange={(e) => {

                                                                            if (e.target.files[0] && e.target.files[0].type.startsWith('video/') && post.videos.length === 0) {

                                                                                setMasterPosts(prevPosts => {
                                                                                    const updatedPosts = [...prevPosts];
                                                                                    updatedPosts[index].mediaType = "video";
                                                                                    return updatedPosts;
                                                                                })

                                                                            }

                                                                            if (e.target.files[0] && e.target.files[0].type.startsWith('image/') && post.images.length === 0) {

                                                                                setMasterPosts(prevPosts => {
                                                                                    const updatedPosts = [...prevPosts];
                                                                                    updatedPosts[index].mediaType = "image";
                                                                                    return updatedPosts;
                                                                                })

                                                                            }

                                                                            handleuploadfile(e.target.files[0], index);

                                                                        }
                                                                        }

                                                                        multiple
                                                                        className="file-input"
                                                                    />
                                                                    <label htmlFor="fileInput">
                                                                        <i className="input-icon">📁</i>
                                                                        <span className="file-input-text">select a file</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="post-extra-sec">
                                                    <div className="post-extra-sec-content justify-content-between">
                                                        <div className="left-side d-flex align-items-center gap-3">
                                                            <div className="emoji-box">
                                                                <img
                                                                    className="emoji-icon"
                                                                    src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                                                                    onClick={() => setShowPicker(val => !val)} />
                                                                {showPicker && <Picker
                                                                    pickerStyle={{ width: '100%' }}
                                                                    onEmojiClick={(emojiObject) => {
                                                                        setMasterPosts(prevPosts => {
                                                                            const updatedPosts = [...prevPosts];
                                                                            updatedPosts[index].caption += emojiObject.emoji;
                                                                            return updatedPosts;
                                                                        })

                                                                        setShowPicker(false);
                                                                    }} />}
                                                            </div>
                                                            <div className="post-date-time">
                                                                <div className="form-group">
                                                                    <input
                                                                        type="datetime-local"
                                                                        value={post.dateTime ? convertToLocalTimezone(post.dateTime) : ''}
                                                                        className="form-control"
                                                                        onChange={(e) => {
                                                                            const kolkataDateString = convertToKolkataTimezone(e.target.value);
                                                                            console.log("kolkata timestring", kolkataDateString);
                                                                            setMasterPosts(prevPosts => {
                                                                                const updatedPosts = [...prevPosts];
                                                                                updatedPosts[index].dateTime = kolkataDateString;
                                                                                return updatedPosts;
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div>

                                                                <button className="btn post-btn ai-view-btn"
                                                                    onClick={() => {
                                                                        setIndexforai(index);
                                                                        setShowAiModal(true);
                                                                    }}
                                                                ><ImMagicWand /></button>
                                                            </div>

                                                            <div>

                                                                <button className="btn post-btn ai-view-btn"
                                                                    onClick={() => {
                                                                        setShowMediaModal(true);
                                                                    }}
                                                                ><GoFileMedia /></button>
                                                            </div>

                                                            <div>



                                                                {!isAuthenticated ? (
                                                                    <button className="btn post-btn ai-view-btn" onClick={handleCanvaAuth}><SiCanva />Canva</button>
                                                                ) : !isCanvaConnected ? (
                                                                    <button className="btn post-btn ai-view-btn" onClick={handleCanvaAuth}><SiCanva />Canva</button>
                                                                ) : (
                                                                    <button className="btn post-btn ai-view-btn"
                                                                        onClick={() => {
                                                                            uploadAssetAndCreateDesign();
                                                                        }}
                                                                    ><SiCanva /> canva </button>
                                                                )}
                                                            </div>
                                                            {/* {!isAuthenticated ? (
                                                                    <button className="btn post-btn ai-view-btn" onClick={handleCanvaAuth}><SiCanva />Canva</button>
                                                                ) : !isCanvaConnected ? (
                                                                    <button className="btn post-btn ai-view-btn" onClick={handleCanvaAuth}><SiCanva />Canva</button>
                                                                ) : ( */}
                                                            {/* <button className="btn post-btn ai-view-btn"
                                                                onClick={() => {
                                                                    // onCanvaConnectClick();
                                                                    if (isCanvaConnected) {
                                                                        uploadAssetAndCreateDesign();
                                                                    } else {
                                                                        handleCanvaAuth();
                                                                    }
                                                                    // uploadAssetAndCreateDesign(!isOpen3);
                                                                }}
                                                            ><SiCanva /> canva </button> */}
                                                            {/* )} */}
                                                            {/* </div> */}


                                                            {/* <div>

                                                                <button className="btn post-btn ai-view-btn"
                                                                    onClick={() => {
                                                                        onCanvaConnectClick();
                                                                        // setIsOpen3(!isOpen3);
                                                                    }}
                                                                ><SiCanva />connect</button>
                                                            </div> */}

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : <div>Please add a post to schedule You can also use Ai to generate posts</div>
                                    }
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12">
                                    <div className="post-submit-btn mt-2" style={{ textAlign: 'right' }}>
                                        <button className="btn add-more-post-btn me-0" onClick={addnewpost}><FiPlus />New Post</button>
                                    </div>
                                </div>
                            </div> */}

                        </Col>



                    </Row>

                </Container>
                <div className="post-submit-btn mt-4" style={{ textAlign: 'right' }} >
                    {type == "Duplicate" ? <>{
                        newloading ? <SpinnerLoader /> :
                            <button
                                type="submit"
                                className="btn bg-green-dark action-btn btn-green "
                                onClick={() => { handleShow03() }}
                            >Duplicate</button>}
                    </> :
                        <>
                            {newloading2 ? <SpinnerLoader /> :
                                <button
                                    type="submit"
                                    className="btn bg-green-dark action-btn move-to-draft-btn"
                                    onClick={SaveDraft}
                                >Move to Drafts</button>
                            }

                            {newloading ? <SpinnerLoader /> :
                                <button
                                    type="submit"
                                    className="btn bg-green-dark action-btn save-post-btn "
                                    onClick={handleSave2}
                                >Save</button>}
                        </>
                    }
                </div>
            </Modal.Body >
            <Modal.Footer>

            </Modal.Footer>


            {/* <div>
                <h1>Video Thumbnail Generator</h1>
                <input type="file" accept="video/*" onChange={handleThumbnailGeneration} />
                {thumbnails.map((thumbnail, index) => (
                    <img key={index} src={thumbnail} alt={`Thumbnail ${index}`} />
                ))}
            </div> */}
        </>
    )
}

export default BulkSchedule;
