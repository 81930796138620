import React, { useEffect, useRef, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { MdReviews } from "react-icons/md";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useBuilderContext } from "../../../builderContext";
import { useHistory, useLocation } from "react-router-dom";
import { API_URL } from "../../../BackendApi/Api/config";
import { uploadSingleImageApi } from "../../../BackendApi/Api/flowpageApi";

export function TestimonialsList() {

    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdReviews />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Testimonials</h2>
                            <p className="add-block-btn-text-desc">Add a Testimonials List to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}


export function TestimonialsAdd() {

    const location = useLocation();

    const frameType = location.state != undefined ? "update" : "add";
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus
    const profileImageRef = useRef(null);
    const navigate = useHistory();
    const { addComponent, editComponent } = useBuilderContext();

    const [testimonials, setTestimonials] = useState([
        {
            name: "",
            image: "",
            review: "",
            imagePreview: ""
        }

    ]);

    useEffect(() => {

        if (frameType === "update") {
            setTestimonials(mydata);
        }
    }, [frameType]);


    const add = async () => {

        const testamo = [...testimonials];

        if (testamo.length > 0) {

            testamo.map(async (testimonial, index) => {

                if (testimonial.image instanceof File) {

                    const formData = new FormData();

                    formData.append(`image`, testimonial.image);

                    const result = await uploadSingleImageApi(formData);

                    const response = await result.data;

                    if (response.status === true) {

                        testamo[index].imagePreview = response.data.filename;
                        testamo[index].image = response.data.filename;

                    }

                }
                console.log("testamotestamotestamotestamo", testamo)
            })
            addComponent("TESTIMONIAL", testamo);
            navigate.push("/");

        }

    }

    const update = () => {

        const testamo = [...testimonials];

        if (testamo.length > 0) {

            testamo.map(async (testimonial, index) => {

                if (testimonial.image instanceof File) {

                    const formData = new FormData();

                    formData.append(`image`, testimonial.image);

                    const result = await uploadSingleImageApi(formData);

                    const response = await result.data;

                    if (response.status === true) {

                        testamo[index].imagePreview = response.data.filename;
                        testamo[index].image = response.data.filename;

                    }

                }
                console.log("testamotestamotestamotestamo", testamo)
            })
            editComponent(position, "TESTIMONIAL", testamo, showStatus);
            navigate.push("/");

        }
    }

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Testimonials</label>
                    </div>
                </div>
                <Button
                    className="add-block-button btn publish-btn m-3"
                    onClick={() => {
                        setTestimonials([
                            ...testimonials,
                            {
                                name: "",
                                image: "",
                                review: "",
                                imagePreview: ""
                            }
                        ]);
                    }}

                >Add Another</Button>

            </div>

            {testimonials && testimonials.map((item, index) => {
                return (
                    <div>
                        <div className="icons_list_item cvtPmc">
                            <div className="position-relative">
                                <div className="image-input-container mb-4">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={
                                            (e) => {
                                                const file = e.target.files[0];

                                                if (file) {
                                                    const reader = new FileReader();

                                                    reader.onloadend = () => {
                                                        console.log("jhingalal jhingallala jhingallala", reader.result);
                                                        setTestimonials((prevTestimonials) => {
                                                            const updatedTestimonials = [...prevTestimonials];
                                                            updatedTestimonials[index] = {
                                                                ...updatedTestimonials[index],
                                                                imagePreview: reader.result,
                                                                image: file,

                                                            };
                                                            return updatedTestimonials;
                                                        });
                                                    };
                                                    reader.readAsDataURL(file);

                                                };

                                                // Read the image file as a data URL

                                            }
                                        }
                                        ref={profileImageRef}
                                        style={{ display: 'none' }}
                                    />
                                    <div className="preview_image_box">
                                        {testimonials[index].imagePreview ? (
                                            <img src={API_URL+"flowpage/"+ testimonials[index].imagePreview} alt="Selected" className="preview-image" />
                                        ) : (
                                            <img
                                                src={require('../../../assets/images/image_default.png')} // Replace this with the URL or path to your default image
                                                alt="Default"
                                                className="default-image"
                                            />
                                        )}
                                    </div>
                                    <div className="image-input" onClick={() => profileImageRef.current.click()}>
                                        <span className="icon">📷</span>
                                        <span className="text">
                                            Upload Image (PNG, JPG or GIF)
                                        </span>
                                    </div>
                                </div>

                                <div className="did-floating-label-content form-group">
                                    <input type="text"
                                        value={testimonials[index].name}
                                        onChange={(e) => {

                                            setTestimonials((prevTestimonials) => {
                                                const updatedTestimonials = [...prevTestimonials];
                                                updatedTestimonials[index] = {
                                                    ...updatedTestimonials[index],
                                                    name: e.target.value
                                                };
                                                return updatedTestimonials;
                                            })

                                        }}

                                        className="did-floating-input form-control" placeholder="" />
                                    <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">User Name</label>
                                </div>

                                <div className="did-floating-label-content form-group">
                                    <textarea
                                        value={testimonials[index].review}
                                        onChange={(e) => {

                                            setTestimonials((prevTestimonials) => {
                                                const updatedTestimonials = [...prevTestimonials];
                                                updatedTestimonials[index] = {
                                                    ...updatedTestimonials[index],
                                                    review: e.target.value
                                                };
                                                return updatedTestimonials;
                                            })
                                        }
                                        }

                                        className="form-control did-floating-textarea" id="headding" rows="3" placeholder=""

                                    ></textarea>
                                    <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">Testimonials Text</label>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            })}

            <div className="add-block-btn">

                {
                    frameType === "update" ?
                        <button className="btn publish-btn" onClick={
                            () => {
                                update()
                            }
                        }>
                            Update
                        </button>
                        :
                        <button className="btn publish-btn" onClick={
                            () => {
                                add()
                            }
                        }>
                            Add
                        </button>
                }

            </div>
        </>
    );
}