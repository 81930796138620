// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isCanvaConnected, setIsCanvaConnected] = useState(false);
    const [authData, setAuthData] = useState(null);
    const [user, setUser] = useState(null); // Assuming you might need user state

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, isCanvaConnected, setIsCanvaConnected, authData, setAuthData, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
