import React, { useContext, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { TbLicense } from "react-icons/tb";
import { TemplateContext } from "../../TemplateContext";

function SizeSettings() {

    const [pattern, setPattern, eye, setEye, logo, setLogo, frame, setFrame, patternColorType, setPatternColorType, patternDotsColor, setPatternDotsColor, patternGradientColor, setPatternGradientColor, eyeBallColor, setEyeBallColor, EyeCenterColor, setEyeCenterColor, backgroundcolortype, setBackgroundcolortype, backgroundcolorcode, setBackgroundcolorcode, height, setHeight, width, setWidth, eyeinside, setEyeinside] = React.useContext(TemplateContext);

    return (
        <>
            <Accordion.Header><TbLicense />Size</Accordion.Header>
            <Accordion.Body>
                <Form.Group className="did-floating-label-content mb-0" controlId="formBasicEmail"> 
                    <select className="did-floating-select" aria-label="Default select example" onClick={
                        (e) => {
                            let value = e.target.value;
                            let arr = value.split("X");
                            setHeight(arr[0]);
                            setWidth(arr[1]);
                        }
                    } >
                        <option value="512X512" selected>512*512</option>
                        <option value="1024X1024">1024*1024</option>
                        <option value="2048X2048">2048*2048</option>
                        <option value="4096X4096">4096*4096</option>


                    </select>
                    <Form.Label className="did-floating-label">Height && Width</Form.Label>
                </Form.Group>

                {/* <Form.Control type="text" value={height || ""} onChange={
                        (e) => {
                            setHeight(e.target.value)
                        }
                    } placeholder="500" /> */}
                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="text" value={width || ""} onChange={
                        (e) => {
                            setWidth(e.target.value)
                        }
                    } placeholder="500" />
                </Form.Group> */}
            </Accordion.Body>
        </>
    )
}

export default SizeSettings;