import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";


export const UserUpdateApi = (formData) => apiCall(`${API_URL}user/update`, "PATCH", formData);
export const UserRegistereApi = (formData) => apiCall(`${API_URL}user/register`, "POST", formData);
export const memberRegistereApi = (formData) => apiCall(`${API_URL}user/registermember`, "POST", formData);
export const getuserApi = (formData) => apiCall(`${API_URL}user/getalluser`, "GET", formData);
export const getMemberApi = (data) => apiCall(`${API_URL}user/getmember`, "POST", data);
export const sendMailApi = (data) => apiCall(`${API_URL}user/sendmail`, "POST", data);
export const resetPasswordApi = (data) => apiCall(`${API_URL}user/resetpassword`, "POST", data);
export const updateUserPasswordApi = (data) => apiCall(`${API_URL}user/updateuserpassword`, "POST", data);
export const verifyTokenApi = (data) => apiCall(`${API_URL}user/verifytoken`, "POST", data);
export const deleteMemberApi = (data) => apiCall(`${API_URL}user/deletemember`, "POST", data);
export const deleteTeamMember = (data) => apiCall(`${API_URL}user/deleteTeamMember`, "POST", data);
export const forgotPasswordApi = (data) => apiCall(`${API_URL}user/forgotpassword`, "POST", data);
export const updatePasswordByTokenApi = (data) => apiCall(`${API_URL}user/tokenupdateuserpassword`, "POST", data);
export const resendMemberEmail = (data) => apiCall(`${API_URL}user/resendmemberemail`, "POST", data);
export const resendMemberRequest = (data) => apiCall(`${API_URL}user/resendMemberRequest`, "POST", data);
export const sendOtpByEmail = (data) => apiCall(`${API_URL}user/sendOtpByEmail`, "POST", data);
export const verifyOtpByEmail = (data) => apiCall(`${API_URL}user/verifyOtpByEmail`, "POST", data);
export const getUserApi = (data) => apiCall(`${API_URL}user/getUser`, "POST", data);
export const getOwner = (data) => apiCall(`${API_URL}user/getownerdata`, "POST", data)
  



//deleteMemberApi

