import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import QRCodeStyling from 'qr-code-styling';
import Sidebar from "../Sidebar/Sidebar";
import "../../Qrtemplates/Qrtemplates.css";
import { MdOutlineQrCode } from "react-icons/md";
import { Accordion } from "react-bootstrap";
import DomainType from "./DomainType";
import SessionName from "./SessionName";
import Choosepattern from "./Choosepatterns";
import Chooseeye from "./Chooseeye";
import Addlogo from "./AddLogo";
import Setcolor from "./SetColor";
import Chooseframe from "./Chooseframe";
import Header from "../Header/Header";
import SizeSettings from "./SizeSettings";
import { Redirect } from 'react-router-dom';
import { TemplateContext } from "../../TemplateContext";
import { addTemplate } from '../../BackendApi/Api/template';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { toPng } from 'html-to-image';
import { toJpeg } from 'html-to-image';
import { toSvg } from 'html-to-image';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';




function CreatetemplateBrand({ templateStatus, setTemplateStatus, handleClose05 }) {
    const [showText, setShowText] = React.useState(true);

    const [pattern, setPattern, eye, setEye, logo, setLogo, frame, setFrame, patternColorType, setPatternColorType, patternDotsColor, setPatternDotsColor, patternGradientColor, setPatternGradientColor, eyeBallColor, setEyeBallColor, EyeCenterColor, setEyeCenterColor, backgroundcolortype, setBackgroundcolortype, backgroundcolorcode, setBackgroundcolorcode, height, setHeight, width, setWidth, eyeinside, setEyeinside, caption, setCaption, resetTemplateData] = React.useContext(TemplateContext);


    // console.log("pattern", pattern);
    // console.log("eye", eye);
    // console.log("eyeinside", eyeinside);
    // console.log("logo", logo);
    // console.log("patternColorType", patternColorType);
    // console.log("patternDotsColor", patternDotsColor);
    // console.log("eyeBallColor", eyeBallColor);
    // console.log("eyeinside", EyeCenterColor);
    // console.log("frame", frame);


    const qrCodeRef = useRef(null);



    useEffect(() => {


        if (patternColorType === "color") {
            var patternColorTypeValue = {
                "type": pattern,
                "color": patternDotsColor
            };
        } else {
            var patternColorTypeValue = {
                "type": "linear",
                "gradient": {
                    "type": "linear",
                    "rotation": 0,
                    "colorStops": [
                        {
                            "offset": 0,
                            "color": patternDotsColor
                        },
                        {
                            "offset": 1,
                            "color": patternGradientColor
                        }
                    ]
                }
            };
        }

        if (backgroundcolortype === "solid") {
            var backgroundcolortypeValue = {
                "color": backgroundcolorcode
            };
        } else {
            var backgroundcolortypeValue = {
                "color": "#00000000"
            };
        }


        const qrCode = new QRCodeStyling({
            width: width,
            height: height,
            data: 'https://optisoft.in',
            margin: 0,
            qrOptions: {
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'Q',
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0,
            },
            dotsOptions: patternColorTypeValue,
            backgroundOptions: backgroundcolortypeValue,
            image: logo,
            dotsOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#6a1a4c', color2: '#6a1a4c', rotation: '0' },
            },
            cornersSquareOptions: {
                type: eye,
                color: eyeBallColor,
                gradient: null,
            },
            cornersSquareOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            cornersDotOptions: {
                type: eyeinside,
                color: EyeCenterColor,
                gradient: null,
            },
            cornersDotOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            backgroundOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#ffffff', color2: '#ffffff', rotation: '0' },
            },
        });

        qrCode.append(qrCodeRef.current);
        qrCode.update();
        //   return () => {
        //     qrCode.destroy();
        //   };
    }, [pattern, eye, eyeinside, logo, patternColorType, patternDotsColor, patternGradientColor, eyeBallColor, EyeCenterColor, backgroundcolortype, backgroundcolorcode, height, width, frame]);

    const divRef = useRef(null);

    const downloadImage = useCallback((format) => {
        console.clear();
        console.log("format======================>", format);
        if (divRef.current === null) {
            return
        }

        // create switch case for different formats

        switch (format) {

            case 'png':

                toPng(divRef.current, { cacheBust: true, })
                    .then((dataUrl) => {
                        const link = document.createElement('a')
                        link.download = 'my-image-name.png'
                        link.href = dataUrl
                        link.click()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                break;

            case 'jpeg':
                toJpeg(divRef.current, { quality: 0.95 })
                    .then((dataUrl) => {
                        const link = document.createElement('a')
                        link.download = 'my-image-name.jpeg'
                        link.href = dataUrl
                        link.click()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                break;

            case 'svg':
                toSvg(divRef.current, { quality: 0.95 })
                    .then((dataUrl) => {
                        const link = document.createElement('a')
                        link.download = 'my-image-name.svg'
                        link.href = dataUrl
                        link.click()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                break;

        }


    }, [divRef])

    const [name, setName] = useState("");

    const [redirectToQR, setRedirectToQR] = useState(false);
    const [loading,setLoading]=useState(false)

    if (redirectToQR) {
        return <Redirect to="/qrtemplate" />;
    }

    const createtemplate = () => {
        setLoading(true)

        if (patternColorType === "color") {
            var patternColorTypeValue = {
                "type": pattern,
                "color": patternDotsColor
            };
        } else {
            var patternColorTypeValue = {
                "type": "linear",
                "gradient": {
                    "type": "linear",
                    "rotation": 0,
                    "colorStops": [
                        {
                            "offset": 0,
                            "color": patternDotsColor
                        },
                        {
                            "offset": 1,
                            "color": patternGradientColor
                        }
                    ]
                }
            };
        }

        if (backgroundcolortype === "solid") {
            var backgroundcolortypeValue = {
                "color": backgroundcolorcode
            };
        } else {
            var backgroundcolortypeValue = {
                "color": "#00000000"
            };
        }

        const qrCode = {
            width: width,
            height: height,
            data: 'https://optisoft.in',
            margin: 0,
            qrOptions: {
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'Q',
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0,
            },
            dotsOptions: patternColorTypeValue,
            backgroundOptions: backgroundcolortypeValue,
            image: logo,
            dotsOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#6a1a4c', color2: '#6a1a4c', rotation: '0' },
            },
            cornersSquareOptions: {
                type: eye,
                color: eyeBallColor,
                gradient: null,
            },
            cornersSquareOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            cornersDotOptions: {
                type: eyeinside,
                color: EyeCenterColor,
                gradient: null,
            },
            cornersDotOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            backgroundOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#ffffff', color2: '#ffffff', rotation: '0' },
            },
            frameOptions: {
                type: frame,
            },
        };

        toJpeg(divRef.current, { quality: 0.95 })
            .then(dataUrl => {
                const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, '');

                const url = window.location.href;
                const urlArr = url.split("/");
                const brand_id = urlArr[urlArr.length - 1];

                console.log("brand_id  template=======>", brand_id);

                // // Convert base64 string to Blob object
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'image/jpeg' });

                const userdata = localStorage.getItem("user");
                const user = JSON.parse(userdata);
                const userid = user._id;
                const owner_id = user.owner_id;

                const formdata = new FormData();
                formdata.append("created_by", userid);
                formdata.append("owner_id", owner_id);
                formdata.append("template_name", name);
                formdata.append("styling", JSON.stringify(qrCode));
                formdata.append("image", blob);
                formdata.append("brand_id", brand_id);
                formdata.append("caption", caption);

                if (name === "") {
                    PNotify.error({
                        title: 'Error!',
                        text: 'Please enter template name',
                    });
                    setLoading(false)
                    
                } else {

                    addTemplate(formdata).then((res) => {
                        if (res.code === 200) {
                            if (res.data.status === true) {
                                PNotify.success({
                                    title: 'Success!',
                                    text: 'Template created successfully',
                                });
                                setTemplateStatus(!templateStatus)
                                resetTemplateData();
                            }
                            else {
                                PNotify.error({
                                    title: 'Error!',
                                    text: 'Template not created',
                                });
                            }
                        }
                        else {
                            PNotify.error({
                                title: 'Error!',
                                text: 'Something went wrong',
                            });
                        }
                    }).catch((err) => {
                        console.log("err", err);
                    }).finally((err)=>{
                        setLoading(false)
                    });
                    handleClose05();

                }
            })

        // setShow05(false);
    }


    return (
        <>
            <div className="row pd-26">
                <div className=" ">
                    <div className="card-body table-card-body">
                        <div className="table-card-header add-btn d-flex">
                            <h5 className="main-card-title"><MdOutlineQrCode className="header-icon" />CREATE TEMPLATES</h5>
                        </div>
                        <div className="table-card-body-inner">
                            <div className="Create-Template">
                                <div className="row">
                                    <div className="col-md-8 ps-0">
                                        <div className="">
                                            <div className="col-md-12 ps-0">
                                                <Accordion defaultActiveKey="" className="Create-Template-Accordion">
                                                    {/* <Accordion.Item eventKey="1">
                                                                                <DomainType />
                                                                            </Accordion.Item>
                                                                            <Accordion.Item eventKey="2">
                                                                                <SessionName />
                                                                            </Accordion.Item> */}
                                                    <Accordion.Item eventKey="3">
                                                        <Choosepattern />
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="4">
                                                        <Chooseeye />
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="5">
                                                        <Addlogo />
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="6">
                                                        <Setcolor />
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="7">
                                                        <Chooseframe />
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="8">
                                                        <SizeSettings />
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                            <div className="col-md-12 p-0">
                                                {showText && <div className="form-group mb-3">
                                                    <input type="text" className="did-floating-input form-control" value={name} onChange={
                                                        (e) => {
                                                            setName(e.target.value)
                                                        }
                                                    } placeholder="Enter a name for your new template" />
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div ref={divRef} className={
                                            frame === "qr-bg-col" ? "qr-box qr-bg-col" : frame === "qr-bg-col2" ? "qr-box qr-bg-col2" : frame === "qr-bg-col3" ? "qr-box qr-bg-col3" : frame === "qr-bg-col4" ? "qr-box qr-bg-col4" : "qr-box"
                                        } >
                                            <div className="canvas-title2"><h2>{caption}</h2></div>
                                            <div className="Qr-image">
                                                <div ref={qrCodeRef} />
                                            </div>
                                            <div className="canvas-title "><h2>{caption}</h2></div>
                                            <div className="canvas-title3 "><h2>{caption}</h2></div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className='form-btn mt-3'>
                                                <div className='form-btn-right justify-content-center gap-3'>
                                                    <button className="btn bg-green-dark action-btn btn-green" onClick={() => downloadImage('png')}>PNG</button>
                                                    <button className="btn bg-green-dark action-btn btn-green" onClick={() => downloadImage('jpeg')}>JPEG</button>
                                                    <button className="btn bg-green-dark action-btn btn-green" onClick={() => downloadImage('svg')}>SVG</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-btn mt-3'>
                                        <div className=''>
                                            {loading?<SpinnerLoader/>:
                                            <button type="button" className="btn bg-green-dark action-btn btn-green" onClick={createtemplate}>
                                                Create Template
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreatetemplateBrand;