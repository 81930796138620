import React from "react";
import { Chart } from "react-google-charts";
import { getsmartlinkcountery } from "../../BackendApi/Api/smartsLinkApi";

export const options = {
  world: {
    region: "world",
    displayMode: "regions",
    resolution: "countries",
    colorAxis: { colors: [ "#e7711c", "#4374e0",  ] },//blue red yellow green
  },
  europe: {
    region: "150",
    displayMode: "regions",
    resolution: "countries",
    colorAxis: { colors: ["#e7711c", "#4374e0", ] },
  },

};



function ChartData({ smartlinkcountery }) {
  const data = [
    ["Country", "visits", "Percentage"],
  ]

 
  return (
    <Chart
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data[selection[0].row + 1];
            console.log("Selected : " + region);
          },
        },
      ]}
      chartType="GeoChart"
      width="100%"
      height="400px"
      data={smartlinkcountery}
      options={options.world}
      divices={smartlinkcountery}
    />
  );
}

export default ChartData;