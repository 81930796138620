import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { verifyaccountApi } from "../../BackendApi/Api/auth";

function Verify() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const user = params.get("user");

    useEffect(() => {
        if (user) {
            verifyAccount(user);
        }
    }, [user]);

    const verifyAccount = (user) => {
        verifyaccountApi({ user: user }).then((res) => {
            console.log("res", res);
                if (res.code === 200) {
                    alert(res.data.message);
                    window.location.href = "/login";
                } else {
                    alert(res.data.message);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };
    return (
        <div className="Login">
            <h1>Verfying your account.........</h1>
        </div>
    );
}

export default Verify;
