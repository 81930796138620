import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';

function Slider() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1300: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    }
                }}
                modules={[Autoplay]}
            >
                <SwiperSlide>
                    <div className="slider-box">
                        <div className="slider-box-inner">
                            <div className="slider-icon">
                                <img src={require('../../landingassets/Images/protection.png')} alt="slider" />
                            </div>
                            <div className="slider-title">
                                <h6>Updatable</h6>
                            </div>
                            <div className="slider-description">
                                All links, pages, QR codes use Astral's dynamic link technology. So you can make changes after the fact, on the fly, without worrying you forgot something
                            </div>
                            {/* <div className="slider-btn">
                                <a href="#" className="btn btn-color">View Detail <img src={require('../../landingassets/Images/next.png')} alt="next" /></a>
                            </div> */}
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-box">
                        <div className="slider-box-inner">
                            <div className="slider-icon">
                                <img src={require('../../landingassets/Images/protection.png')} alt="slider" />
                            </div>
                            <div className="slider-title">
                                <h6>Customizable</h6>
                            </div>
                            <div className="slider-description">
                                Every shade of color to match your brand, text, videos and all the content
                            </div>
                            {/* <div className="slider-btn">
                                <a href="#" className="btn btn-color">View Detail <img src={require('../../landingassets/Images/next.png')} alt="next" /></a>
                            </div> */}
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-box">
                        <div className="slider-box-inner">
                            <div className="slider-icon">
                                <img src={require('../../landingassets/Images/protection.png')} alt="slider" />
                            </div>
                            <div className="slider-title">
                                <h6>Boostable</h6>
                            </div>
                            <div className="slider-description">
                                We make creating campaigns across multiple social and advertising channels easy so you can maximize your efforts.
                            </div>
                            {/* <div className="slider-btn">
                                <a href="#" className="btn btn-color">View Detail <img src={require('../../landingassets/Images/next.png')} alt="next" /></a>
                            </div> */}
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-box">
                        <div className="slider-box-inner">
                            <div className="slider-icon">
                                <img src={require('../../landingassets/Images/protection.png')} alt="slider" />
                            </div>
                            <div className="slider-title">
                                <h6>Trackable</h6>
                            </div>
                            <div className="slider-description">
                                Astral makes tracking & managing metrics & deriving analytics easy! Astral helps you with the who, what, when, where, and how of your audience.
                            </div>
                            {/* <div className="slider-btn">
                                <a href="#" className="btn btn-color">View Detail <img src={require('../../landingassets/Images/next.png')} alt="next" /></a>
                            </div> */}
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default Slider;