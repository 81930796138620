import React from "react";
import { Link } from "react-router-dom";
import { MdImage } from "react-icons/md";
import { FaChevronRight, FaLongArrowAltLeft } from "react-icons/fa";
import axios from "axios";
import { API_URL } from "../../../BackendApi/Api/config";
import { uploadSingleImageApi } from "../../../BackendApi/Api/flowpageApi";
import { useBuilderContext } from "../../../builderContext";
import { useHistory } from "react-router-dom";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { v4 as uuidv4 } from "uuid";



export function ImageList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdImage />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Image</h2>
                            <p className="add-block-btn-text-desc">Add a Image to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}






export function ImageAdd() {

    const navigate = useHistory();

    const { addComponent } = useBuilderContext();

    const [image, setImage] = React.useState(null);

    const [imageProperties, setImageProperties] = React.useState({
        imageLink: "",
        imageCaption: "",
        imageAltText: "",
        link: ""
    });


    const addImageData = async (e) => {
        const data = new FormData();
        data.append('image', image);
        // data.append('upload_preset', 'buffer');

        uploadSingleImageApi(data).then((res) => {
            console.log(res.data);
            if (res.code == 200) {

                const uniqueId = Date.now() + uuidv4();

                const imageproerty = {
                    imageLink: "",
                    imageCaption: imageProperties.imageCaption,
                    imageAltText: imageProperties.imageAltText,
                    link: imageProperties.link
                }
                if (res.data.status == true) {
                    imageproerty.imageLink = res.data.data.filename;
                }

                imageproerty.unique_id = uniqueId;

                addComponent("IMAGETEXT", imageproerty);

                navigate.push("/")

            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: 'try again',
                });
            }

        }).catch((err) => {
            PNotify.error({
                title: 'Error',
                text: 'try again',
            });
        })
    }



    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Image</label>
                    </div>
                </div>
                <div className="form_block iuEvSC">
                    <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => {
                            setImage(e.target.files[0]);
                        }}
                    />
                </div>

                <div className="form_box cvtPmc">
                    <div className="form-group mb-2">

                        <div className="form_block">
                            <textarea className="form-control did-floating-textarea" placeholder=""
                                onChange={
                                    (e) => {
                                        setImageProperties({ ...imageProperties, imageCaption: e.target.value })
                                    }
                                } />
                            <label htmlFor="exampleInputEmail1" className="gvofes did-floating-label">Image caption</label>
                        </div>
                    </div>
                    <div className="form-group">

                        <div className="form_block">
                            <input type="text" className="form-control did-floating-input" placeholder=""
                                onChange={
                                    (e) => {
                                        setImageProperties({ ...imageProperties, link: e.target.value })
                                    }
                                } />
                            <label htmlFor="exampleInputPassword1" className="gvofes did-floating-label">Link</label>
                        </div>
                    </div>
                    <div className="form-group">

                        <div className="form_block">
                            <input type="text" className="form-control did-floating-input" placeholder=""
                                onChange={
                                    (e) => {
                                        setImageProperties({ ...imageProperties, imageAltText: e.target.value })
                                    }
                                } />
                            <label htmlFor="exampleInputPassword1" className="gvofes did-floating-label">Alt text</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={
                    () => {
                        addImageData()
                    }
                }>
                    Add
                </button>
            </div>
        </>
    );
}