import React, { useEffect, useState } from 'react';
import './Comments.css';
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
const API_URL = process.env.REACT_APP_API_URL;

const Comments = ({ post_id }) => {
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [expandedComments, setExpandedComments] = useState({});
  const toggleReadMore = (id) => {
    // Toggle the expansion state for the specific comment by ID
    setExpandedComments(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));

  };

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  const fetchComments = async () => {
    try {
      const response = await fetch(`${API_URL}/socialposts/getIdeasCommentsByPostId/${post_id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        setComments(data.getComments);
      } else {
        PNotify.error({
          title: 'Error',
          text: data.message || 'Failed to fetch comments',
        });
      }
    } catch (err) {
      PNotify.error({
        title: 'Error',
        text: err.message,
      });
    }
  };

  useEffect(() => {
    fetchComments();
  }, [post_id]);

  const handleCommentSubmit = async (e) => {

    e.preventDefault();
    if (newComment == "" || newComment == undefined || newComment == null) {
      PNotify.error({
        title: "Error",
        text: "Enter any comment",
      });
      return
    }
    try {
      const response = await fetch(`${API_URL}/socialposts/addCommentsIdeasPost`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: user?._id,
          post_Id: post_id,
          content: newComment
        })
      });

      const result = await response.json();

      if (result.success) {
        fetchComments();
        setNewComment(''); // Clear the input field after successful submission
      } else {
        PNotify.error({
          title: 'Error',
          text: result.data.message,
        });
      }
    } catch (err) {
      PNotify.error({
        title: 'Error',
        text: err.message,
      });
    }
  };
  const timeSince = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  // Check if the date is in the future
  if (seconds < 0) {
    return "in the future";
  }
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      return interval === 1 ? "1 year ago" : `${interval} years ago`;
    }
  
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval === 1 ? "1 month ago" : `${interval} months ago`;
    }
  
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval === 1 ? "1 day ago" : `${interval} days ago`;
    }
  
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval === 1 ? "1 hour ago" : `${interval} hours ago`;
    }
  
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval === 1 ? "1 minute ago" : `${interval} minutes ago`;
    }
  
    return "just now";
  };
  
  return (
    <>
      <div className="comments-container">
        <h2>Comments</h2>
        <ul>
          {comments.map((comment, index) => (
            <li key={comment._id} className="comment-item">
              <div className="comment-header">
                <div className="user-info">
                  <img
                    src={comment.userDetail?.profile_image
                      ? `${API_URL}/profile/${comment.userDetail.profile_image}`
                      : "default-profile-image-url"} // Use a default image URL if profile_image is not available
                    alt={comment.userDetail?.username ? `${comment.userDetail.username}'s profile` : 'Default profile'}
                    className="profile-image"
                  />
                  <div className="user-details">
                    <strong className="username">{comment.userDetail?.username || 'Unknown User'}</strong>
                    <span className="email">{comment.userDetail?.email || 'Unknown Email'}</span>
                  </div>
                </div>
                <span className="date">{timeSince(comment.createdAt)}</span>
                {/* <span className="date">{new Date(comment.createdAt).toLocaleString()}</span> */}

              </div>


              <p className="comment-text" style={{ maxHeight: expandedComments[comment._id] ? 'none' : '100px' }}>
                {comment.content?.length > 200
                  ? `${expandedComments[comment._id] ? comment.content : comment.content.substring(0, 120) + '...'}`
                  : comment.content
                }
              </p>
              {comment.content?.length > 200 && (
                <span className="read-more" onClick={() => toggleReadMore(comment._id)}>
                  {expandedComments[comment._id] ? 'Read less' : 'Read more'}
                </span>
              )}
            </li>
          ))}
        </ul>

      </div>
      <div style={{ padding: 10 }} >
        <form className="comment-form" onSubmit={handleCommentSubmit}>
          <textarea
            value={newComment}
            onChange={handleInputChange}
            placeholder="Write a comment..."
            rows="4"
          />
          <button type="submit" disabled={newComment !== "" ? false : true}>Comment</button>
        </form>
      </div>
    </>
  );
};

export default Comments;
