import { API_URL } from "./config.js";
import {apiCall} from "./auth";


export const addDomainApi = (formData) => apiCall(`${API_URL}domain/adddomain`, "POST", formData);

export const getDomainDataApi = (data) => apiCall(`${API_URL}domain/getdomain`, "POST", data);

export const deleteDomainApi = (formData) => apiCall(`${API_URL}domain/deletedomain`, "DELETE", formData);

export const verifyDomainApi = (formData) => apiCall(`${API_URL}domain/verifydomain`, "POST", formData);