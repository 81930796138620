import { DateTime } from 'luxon';

export function postTimeLocale(dateString) {
    console.log("incoming date string : ", dateString);
    const kolkataDate = DateTime.fromISO(dateString, { zone: 'Asia/Kolkata' });

    if (!kolkataDate.isValid) {
        console.error("Invalid date string");
        return null;
    }

    const localDate = kolkataDate.setZone(DateTime.local().zoneName);
    const localDateString = localDate.toISO({ includeOffset: true, suppressMilliseconds: true });
    const timeZoneAbbreviation = localDate.toFormat('ZZZZ');

    console.log("local date string : ", localDateString);
    console.log("time zone abbreviation : ", timeZoneAbbreviation);

    return { localDateString, timeZoneAbbreviation };
}




