import React from "react";
import Google from "../../assets/images/icons/google.webp";
import Fb from "../../assets/images/icons/fb.webp";
import Twitter from "../../assets/images/icons/twitter.webp";
import Apple from "../../assets/images/icons/apple.webp";
import LinkedIn from "../../assets/images/icons/linkedin.png";
import Microsoft from "../../assets/images/icons/microsoft.webp";

function Authentications() {
    return (
        <div>
            <div className="Authentications-list mt-4">
                <ul>
                    <li>
                        <div className="Authentications d-flex align-items-center">
                            <div className="Authentications-lmage">
                                <img src={Google} alt="google" className="img-fluid" />
                            </div>
                            <div className="Authentications-item-left">
                                <h3>Connect with Google</h3>
                                <h4>You can set alternative login with google</h4>
                            </div>
                            <div className="Authentications-item-right ms-auto">
                                <button className="Authentications-item-right-connect-btn">Connect</button>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="Authentications d-flex align-items-center">
                            <div className="Authentications-lmage">
                                <img src={Fb} alt="google" className="img-fluid" />
                            </div>
                            <div className="Authentications-item-left">
                                <h3>Connect with Facebook</h3>
                                <h4>Connected from -   28/12/2022</h4>
                            </div>
                            <div className="Authentications-item-right ms-auto">
                                <button className="Authentications-item-right-btn">Remove</button>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="Authentications d-flex align-items-center">
                            <div className="Authentications-lmage">
                                <img src={Twitter} alt="google" className="img-fluid" />
                            </div>
                            <div className="Authentications-item-left">
                                <h3>Connect with Twitter</h3>
                                <h4>Connected from -   28/12/2022</h4>
                            </div>
                            <div className="Authentications-item-right ms-auto">
                                <button className="Authentications-item-right-btn">Remove</button>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="Authentications d-flex align-items-center">
                            <div className="Authentications-lmage">
                                <img src={Apple} alt="google" className="img-fluid" />
                            </div>
                            <div className="Authentications-item-left">
                                <h3>Connect with Apple</h3>
                                <h4>Connected from -   28/12/2022</h4>
                            </div>
                            <div className="Authentications-item-right ms-auto">
                                <button className="Authentications-item-right-btn">Remove</button>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="Authentications d-flex align-items-center">
                            <div className="Authentications-lmage">
                                <img src={LinkedIn} alt="google" className="img-fluid" />
                            </div>
                            <div className="Authentications-item-left">
                                <h3>Connect with LinkedIn</h3>
                                <h4>Connected from -   28/12/2022</h4>
                            </div>
                            <div className="Authentications-item-right ms-auto">
                                <button className="Authentications-item-right-btn">Remove</button>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="Authentications d-flex align-items-center">
                            <div className="Authentications-lmage">
                                <img src={Microsoft} alt="google" className="img-fluid" />
                            </div>
                            <div className="Authentications-item-left">
                                <h3>Connect with Microsoft</h3>
                                <h4>Connected from -   28/12/2022</h4>
                            </div>
                            <div className="Authentications-item-right ms-auto">
                                <button className="Authentications-item-right-btn">Remove</button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    )
}

export default Authentications;