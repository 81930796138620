import React, { useState, useRef, useEffect, useContext } from "react";

import { FaCircleCheck } from "react-icons/fa6";
import { FaAngleDown, FaAngleUp, FaFacebook, FaTwitter } from "react-icons/fa6";
import { Button } from "react-bootstrap";
// import "firebase/auth";
// import { app } from "./../firebase.config";
// import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { socialAccountContext } from "../../contexts/context/socialAccountContext";

import axios from "axios";
import SpinnerLoader from "../../Components/SpinnerLoader/SpinnerLoader";
import useSocialChannels from "../../contexts/actions/useSocialChannels";

const API_URL = process.env.REACT_APP_API_URL;

const Channels = () => {
    const { connectedAccount, connectedAccountdispatch } = useContext(socialAccountContext);
    const { getConnectedChannels } = useSocialChannels();
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (connectedAccount.accounts) {
            const facebookChannel = connectedAccount.accounts.filter(
                (channel) => channel.platform === "TWITTER"
            );
            setChannels(facebookChannel);
        }
    }, [connectedAccount]);

    const user = JSON.parse(localStorage.getItem('user'));

    const [isOpen, setIsOpen] = useState(false);

    const decoratedOnClick = () => {
        setIsOpen(!isOpen);
    };


    // const API_URL = process.env.REACT_APP_API_URL; // Assuming you have an API URL in your environment variables
    
    const handlePostMessage = async (event) => {
      if (event.data.type === "twitter_auth") {
        const { Twitter_oauthAccessToken } = event.data.message;
  
        if (Twitter_oauthAccessToken) {
          const Twitter_user = event.data.message; // Assuming this contains the complete user data
  
          try {
            const results = await fetch(`${API_URL}/socialposts/saveTwitteraccounts`, {
              method: "POST",
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ data: Twitter_user, owner_id: user.owner_id }),
            });
  
            const json = await results.json();
            console.log("Saved user data:", json);
            if (json) {
                getConnectedChannels()
              setLoading(false);
            }
            return json;
          } catch (error) {
            console.log("Error:", error.message);
          } finally {
            setLoading(false);
          }
        }
      }
    };
  
    const handleTwitterLogin = () => {
      setLoading(true);
      
    //   const oauthUrl = `https://localhost:5000/twitter/auth`; // Your backend route for Twitter authentication
      const oauthUrl = `${API_URL}/twitter/auth`
  
      const width = 600;
      const height = 600;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;
  
      const popupWindow = window.open(
        oauthUrl,
        "Twitter Login",
        `width=${width},height=${height},left=${left},top=${top}`
      );
  
      window.addEventListener("message", handlePostMessage);
  
      // Close the popup window when the main window is closed
      window.onbeforeunload = () => {
        popupWindow.close();
        setLoading(false);
        window.removeEventListener("message", handlePostMessage);
      };
  
      const checkPopupClosed = setInterval(() => {
        if (popupWindow.closed) {
          clearInterval(checkPopupClosed);
          setLoading(false);
          window.removeEventListener("message", handlePostMessage);
        }
      }, 1000);
    };


    return (

        <>

            <div className="channel-list">
                <div className="channel-box-wrapper">
                    <div className={`fQzPXW accordion-header ${isOpen ? 'active' : ''}`}>
                        <div className="channel-list-item">
                            <div className="channel-left-side">
                                <div className="social-icon">
                                    <img src={`${API_URL}/channels/1690434796818-twitter.png`} alt="facebook" />
                                </div>
                                <div className="social-details">
                                    <div className="social-name d-flex">
                                        <h4 className="m-0">X (Formerly Twitter)</h4>
                                    </div>
                                    {/* <div className="social-description">
                                        Page or Group
                                    </div> */}
                                </div>
                            </div>
                            <div className="channel-right-side">
                                <div className="channel-right-side-btn">
                                        <Button onClick={handleTwitterLogin} className="btn btn-sm float-right connect-user-btn">Connect</Button>
                                    </div>
                                <div className="channel-right-side-btn bPqQjn">
                                    <Button onClick={decoratedOnClick} className="btn transparent-btn">
                                        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isOpen && (
                        <div className="accordion-collapse">
                            <div className="accordion-body-content">
                                <div className="connected-channels">
                                    <h3 className="accordion-title">Connected channels</h3>
                                    {channels ? channels.map((item, index) => {



                                        return (




                                            <div className="connected-channel-box" key={index}>
                                                <div className="connected-left-side">
                                                    <div className="channel-list-box-icon facebook-profile">
                                                        <img src={item.Twitter_user_profilePicture} alt="facebook" />
                                                        <div className="bdyGBn social-icon-view">
                                                            <FaTwitter />
                                                        </div>
                                                    </div>
                                                    <div className="channel-list-box-content">
                                                        <p className="user-name-id">{item.Twitter_screenName}</p>
                                                        <p className="platform-name">{item.platform}</p>
                                                    </div>
                                                </div>
                                                <div className="connected-right-side">
                                                    <div className="connected-sign">
                                                        <FaCircleCheck />
                                                    </div>
                                                </div>
                                            </div>
                                        )




                                    }

                                    ) :
                                        <>
                                            <p>You dont have any connected channels</p>
                                        </>

                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>



            </div>



        </>
    )
}

export default Channels