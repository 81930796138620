import React from 'react'
import { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/Css/custom.css'

import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { Spinner } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL + "/";


const AcceptInvitation = () => {

    const url = window.location.href;
    const splitarray = url.split("/");
    const id = splitarray[splitarray.length - 1];

    const brand_categories = [
        "Technology Company",
        "Healthcare Company",
        "Financial Services Company",
        "Retail Company",
        "Manufacturing Company",
        "Energy Company",
        "Telecommunications Company",
        "Transportation and Logistics Company",
        "Media and Entertainment Company",
        "Hospitality and Leisure Company",
        "Real Estate Company",
        "Agriculture Company",
        "Construction Company",
        "Education and Training Company",
        "Non-Profit Organization",
        "Government Entity",
        "Consulting Firm",
        "Legal Services Firm",
        "Marketing and Advertising Agency",
        "Food and Beverage Company",
        "Pharmaceutical Company",
        "Biotechnology Company",
        "Automotive Company",
        "Aerospace and Defense Company",
        "Environmental Services Company",
        "Fashion and Apparel Company",
        "Consumer Goods Company",
        "Publishing Company",
        "Sports and Recreation Company",
        "Travel and Tourism Company",
        "Art and Design Company",
        "Event Planning Company",
        "Research and Development Company",
        "Software Development Company",
        "Hardware Manufacturing Company",
        "E-commerce Company",
        "Start-up",
        "Family-Owned Business",
        "Multinational Corporation",
        "Small and Medium-sized Enterprise (SME)",
        "Franchise",
        "Cooperative",
        "Sole Proprietorship",
        "Partnership",
    ];

    const [loading, setLoading] = useState(true);

    const [member_account_type, setMember_account_type] = useState('notfound');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [brand_name, setBrand_name] = useState('');
    const [brand_description, setBrand_description] = useState('');

    const [selected_categories, setSelected_categories] = useState([]);

    const [password, setPassword] = useState('');
    const [confirm_password, setConfirm_password] = useState('');

    const [is_accepting , setIs_accepting] = useState(false);

    const getinvitationdetails = async () => {

        setLoading(true);

        try {



            const response = await fetch(`${API_URL}user/getinvitationdetails`, {
                method: "POST",

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    invitation_id: id
                })
            });

            const res = await response.json();

            if (res.status) {

                const drumta = res.data;

                if (drumta.is_request_accepted === true) {

                    setMember_account_type('already_accepted');
                    setLoading(false);


                }
                else {

                    if (drumta.is_reciever_already_exist === true) {

                        setMember_account_type('existing');

                        setLoading(false);
                    }
                    else {


                        setMember_account_type('new');

                        setEmail(drumta.recievers_email);

                        setLoading(false);
                    }
                }

            }
            else {
                setMember_account_type('notfound')

                setLoading(false);
            }

        }
        catch (error) {
            setMember_account_type('error')

            setLoading(false);
        }

    }





    useEffect(() => {
        getinvitationdetails();
    }, []);

    const handleBrandCategory = (selected) => {
        setSelected_categories(selected);
    }

    const validateform = () => {

        if (fname == '') {

            PNotify.error({
                title: 'Error',
                text: 'Please enter first name',
            });
            return false;
        }

        if (lname == '') {

            PNotify.error({
                title: 'Error',
                text: 'Please enter last name',
            });
            return false;
        }

        if (email == '') {

            PNotify.error({
                title: 'Error',
                text: 'Please enter email',
            });
            return false;
        }

        if (password == '') {

            PNotify.error({
                title: 'Error',
                text: 'Please enter password',
            });
            return false;
        }

        if (confirm_password == '') {

            PNotify.error({
                title: 'Error',
                text: 'Please enter confirm password',
            });
            return false;
        }

        if (password != confirm_password) {

            PNotify.error({
                title: 'Error',
                text: 'Password and confirm password does not match',
            });
            return false;
        }

        return true;
    }


    const handleacceptInvitation = async (e) => {

        try {

            setIs_accepting(true);


            e.preventDefault();

            let data = {};

            if (member_account_type == "new") {


                const validate = await validateform();
                if (!validate) {

                    return;
                }
                else {

                    data.invitation_id = id
                    data.fname = fname
                    data.lname = lname
                    data.email = email
                    data.password = password

                }

            }

            if (member_account_type == "existing") {
                data.invitation_id = id

            }

            const response = await fetch(`${API_URL}user/acceptinvitation`, {
                method: "POST",

                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const res = await response.json();


            if (res.status) {

                setMember_account_type('accepted')

                setIs_accepting(false);

            }
            else {
                setMember_account_type('error')

                setIs_accepting(false);

            }

        }

        catch (error) {
            setMember_account_type('error')

            setIs_accepting(false);

        }




    }
    return (
        <>{

            loading ?
                <div className="loader_box">
                    <div className="loader">Loading.....</div>
                </div>
                :
                <>
                    {
                        member_account_type == "notfound" &&

                        <div>
                            <div>No invitation found</div>
                        </div>


                    }

                    {
                        member_account_type == "error" &&

                        <div>
                            <div>Something went wrong</div>
                        </div>

                    }

                    {
                        member_account_type == "already_accepted" &&

                        <div>
                            <div>Invitation already accepted</div>
                        </div>

                    }
                    {
                        member_account_type == "accepted" &&

                        <div>
                                <div>Invitation accepted successfully</div>

                        </div>
                    }

                    {
                        member_account_type == "existing" &&

                        <div>
                                <div>
                                    {
                                        is_accepting ? (
                                            <button className="btn accept_btn"><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading</button>

                                        ) : (
                                            <button className='btn accept_btn' onClick={handleacceptInvitation}>Accept Invitation</button>
                                        )
                                    }


                            </div>
                        </div>
                    }

                    {
                        member_account_type == "new" &&

                        <div className='accept_form_box'>
                            <div className='accept_form_box_inner'>
                                <h1 className='form_title'>Accept Invitation</h1>
                                <form>
                                    <div className='form-group did-floating-label-content'>
                                        <input type="text" class="form-control did-floating-input" name='brand_name' id='brand_name' value={fname} onChange={(e) => setFname(e.target.value)} />
                                        <label htmlFor="brand_name" class="form-label did-floating-label">Frist Name</label>
                                    </div>
                                    <div className='form-group did-floating-label-content'>
                                        <input type="text" class="form-control did-floating-input" name='brand_name' id='brand_name' value={lname} onChange={(e) => setLname(e.target.value)} />
                                        <label htmlFor="brand_name" class="form-label did-floating-label">Last Name</label>
                                    </div>
                                    <div className='form-group did-floating-label-content'>
                                        <input type="email" class="form-control did-floating-input" name='email' id='email' value={email} disabled />
                                        <label htmlFor="email" class="form-label did-floating-label">Email</label>
                                    </div>
                                    {/* <div className='form-group did-floating-label-content'>
                            <input type="text" class="form-control did-floating-input" name='brand_name' id='brand_name' value={brand_name} onChange={(e) => setBrand_name(e.target.value)} />
                            <label htmlFor="brand_name" class="form-label did-floating-label">Brand Name</label>
                        </div>
                        <div className='form-group did-floating-label-content'>
                            <input type="text" class="form-control did-floating-input" name='brand_description' id='brand_description' value={brand_description} onChange={(e) => setBrand_description(e.target.value)} />
                            <label htmlFor="brand_description" class="form-label did-floating-label">Brand Description</label>
                        </div>
                        <div className='form-group did-floating-label-content'>
                            <select
                                id="brand_categories"
                                labelKey="category"
                                onChange={handleBrandCategory}
                                options={brand_categories}
                                placeholder="Select brand categories"
                                selected={selected_categories}
                                class="custom-select-box did-floating-select form-select"
                            />
                            <label htmlFor="brand_categories" class="did-floating-label form-label">Brand Categories</label>
                        </div> */}
                                    <div className='form-group did-floating-label-content'>
                                        <input type="password" class="form-control did-floating-input" name='password' id='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                        <label htmlFor="password" class="form-label did-floating-label">Password</label>
                                    </div>
                                    <div className='form-group did-floating-label-content'>
                                        <input type="password" class="form-control did-floating-input" name='confirm_password' id='confirm_password' value={confirm_password} onChange={(e) => setConfirm_password(e.target.value)} />
                                        <label htmlFor="confirm_password" class="form-label did-floating-label">Confirm Password</label>
                                    </div>
                                        <div className='form-group'>
                                            
                                            {
                                                is_accepting ? (
                                                    <button className="btn accept_btn"><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Loading</button>

                                                ) : (
                                                        <button className='btn accept_btn' onClick={handleacceptInvitation}>Create Account & Accept Invitation</button>
                                                )
                                            }
                                    </div>
                                </form>
                            </div>
                        </div>

                    }

                </>
        }

        </>
    )
}

export default AcceptInvitation