import React, { useState, useRef, useEffect, useContext } from "react";

import { RiWechatChannelsLine } from "react-icons/ri";
import Facebook from "./Channels/Facebook"
import Twitter from "./Channels/Twitter"
import LinkedIn from "./Channels/LinkedIn"
import Youtube from "./Channels/Youtube"
import Instagram from "./Channels/Instagram"

const Channels = () => {

    useEffect(() => {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '1150846412593429',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v19.0'
            });


        };
    }, []);


    return (

        <>
            <div className="setting-tab-content-body-1">
                <div className="tab-content-header add-btn d-flex m-0">
                    <h5 className="tab-content-title"><div className="tab-content-icon"><RiWechatChannelsLine /></div>Connect your channels</h5>
                </div>
                <Facebook />
                <Instagram />
                <Twitter />
                <LinkedIn />
                <Youtube />
            </div>

        </>
    )
}

export default Channels