import { createContext, useState, useReducer, useMemo } from "react";

import { draftedPostsReducer } from '../reducers/draftedPostsReducer'


export const draftedPostsContext = createContext();

export const DraftedPostsProvider = ({ children }) => {

    const initialState = {
        isFetching: true,
        posts: []
    };

    const [draftedPosts, draftedPostsdispatch] = useReducer(draftedPostsReducer, initialState);

    const draftedPostsData = useMemo(() => draftedPosts, [draftedPosts]);

    return (
        <draftedPostsContext.Provider value={{
            draftedPostsData, draftedPostsdispatch
        }}>
            {children}
        </draftedPostsContext.Provider>
    );
};

