import React from "react";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { TbTrash } from "react-icons/tb";
import { getDomainDataApi, deleteDomainApi, verifyDomainApi } from "../../BackendApi/Api/domainApi";
import { data } from "../Chart/ColumnChart";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";



function MyDomain({ domainDatastatus, setDomainDataStatus }) {
    const [show04, setShow04] = useState(false);
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);

    const [domainData, setDomainData] = useState([]);
    const [domainId, setDomainId] = useState('');
    const [loadingStates, setLoadingStates] = useState({});;


    const getDomainData = () => {

        const url = window.location.href;
        const urlSplit = url.split('/');
        const urlbrandId = urlSplit[urlSplit.length - 1];

        //get brand id from url



        const user = JSON.parse(localStorage.getItem('user'));
        const data = {
            // owner_id: user.owner_id,
            // created_by: user._id,
            // role: user.role
            brand_id: urlbrandId
        }
        getDomainDataApi(data).then((res) => {
            setDomainData(res.data.data);
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    //delete domain
    const deleteDomain = (e) => {
        const data = {
            id: domainId
        }
        deleteDomainApi(data).then((res) => {
            setDomainDataStatus(!domainDatastatus);
            getDomainData()
        }).catch((err) => {
            console.log(err);
        })
    }

    const getnamefromlocalstore = () => {
        const data = JSON.parse(localStorage.getItem('user'));
        return { name: data.fname + " " + data.lname, image: data.profile_image };
    }

    const handleVerifyDomain = (item) => {

        setLoadingStates(prevState => ({
            ...prevState,
            [item._id]: true
        }))
        const Userdata = localStorage.getItem("user");
        const user = JSON.parse(Userdata);
        const url = window.location.href;
    const urlSplit = url.split('/');
    const urlbrandId = urlSplit[urlSplit.length - 1];
        const data = {
            id: item._id,
            domain_name: item.domain_name,
            brand_id: urlbrandId,
            updator_name: user.fname + "" + user.lname,
            updator_image: user.profile_image
        }
        verifyDomainApi(data).then((res) => {
            setDomainDataStatus(!domainDatastatus);
            console.log(res);
        }).catch((err) => {
            console.log(err);
        }).finally((err) => {
            setLoadingStates(prevState => ({
                ...prevState,
                [item._id]: false
            }));
        })
    }


    useEffect(() => {
        getDomainData();
    }, [domainDatastatus])


    return (
        <div>
            {/* Delete Modal Start From Here */}
            <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={(e) => {
                        deleteDomain(e);
                        handleClose04();
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Modal End Here */}

            <div className="Domain-table">
                <ul>
                    {domainData.map((item) => {
                        return (
                            <li key={item._id}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="Domain-table-content">
                                        <h4>{item.domain_name}</h4>
                                        <div className="dflex003">
                                            <h5>Last Updated:</h5>
                                            <h6>{(new Date(item.lastupdated)).getFullYear()}/{(new Date(item.lastupdated)).getMonth() + 1}/{(new Date(item.lastupdated)).getDate()}</h6>
                                            <h6>{(new Date(item.lastupdated)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h6>
                                            <h6>by [{getnamefromlocalstore().name}]</h6>
                                        </div>
                                    </div>
                                    <div className={item.status == 0 ? "Domain-table-status" : "Domain-table-status2"}>
                                        <h5>{item.status}</h5>
                                    </div>
                                    <div className="Domain-table-action">
                                        <div className="button-group">
                                            {loadingStates[item._id] ? <SpinnerLoader /> :
                                                <button className="add-usar-btn me-2 margin-imp btn btn-primary" onClick={() => {
                                                    handleVerifyDomain(item);
                                                    // setDomainName(item.domain_name);
                                                    // setDomainId(item._id);
                                                }}>reverify</button>}
                                            <button type="button" className="btn btn-delete" onClick={() => {
                                                setDomainId(item._id);
                                                handleShow04();
                                            }
                                            } >
                                                <TbTrash />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })}

                </ul>
            </div>
        </div>
    )
}

export default MyDomain;