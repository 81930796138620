// MainRouter.js
import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

// Import components
import Dashboard from "../dashboard/Dashboard";
import Login from "../Components/Login/Login";
import SmartLinks from "../SmartLinks/SmartLinks";

import Setting from "../Setting/Setting";
import Metrics from "../Metrics/Metrics";
import Forgotpassword from "../Components/Login/Forgotpassword";
import CreateAccount from "../Components/Login/Createaccount";
import CustomBrand from "../CustomBrand/CustomBrand";
import Campaigns from "../Campaigns/Campaigns";
import ViewCampaign from "../Campaigns/ViewCampaign";
import Home from "../pages/Home/Home";
import Contact from "../pages/Contact/Contact";
import Portifolio from "../pages/PublicFlexpages/flexpages";
import ResetPassword from "../Components/Login/reset";
import Page404 from "../pages/Page404/Page404";
import FlexpageTemplate from "../FlexpageTemplate/PageBuilder";
import CreateBrand from "../CustomBrand/CreateBrand";
import SocialPage from "../SocialDesign/SocialPage";
import Createpost from "../SocialContent/Createpost";
import AcceptInvitation from "../pages/Login/AcceptInvitation";
import Verify from "../Components/Login/Verify";
import EditBrand from "../CustomBrand/EditBrand";
import Flowpages from "../Flowpages/Flowpages";
import PageBuilder from "../PageBuilder/PageBuilder";
import Bugs from "../Components/feedback/Bugs";
import Feedback from "../Components/feedback/feedback";
import SmarteditMain from "../Components/CreateSmartlink/SmartLinkeditMain";
import PrivateRouteNoSidebar from "./PrivateRouteNoSidebar";
import SocialPage2 from "../SocialDesign/SocialPage2";

import Canva from "../canva/canva";

import CanBan from "../SocialContent/postideas/KanbanBoard"
import CanvaOAuthPage from "../SocialContent/Twitter";


const MainRouter = () => {
  return (
    <Switch>
      {/* Public Routes */}
      <PublicRoute exact path="/" component={Home} />
      <PublicRoute exact path="/home" component={Home} />
      <PublicRoute exact path="/contact" component={Contact} />
      <PublicRoute exact path="/login" component={Login} restricted={true} />
      <PublicRoute exact path="/createaccount" component={CreateAccount} restricted={true} />
      <PublicRoute exact path="/forgotpassword" component={Forgotpassword} />
      <PublicRoute exact path="/reset" component={ResetPassword} />
      <PublicRoute exact path="/acceptinvitation/:id" component={AcceptInvitation} />
      <PublicRoute exact path="/verifyaccount" component={Verify} />
      <PublicRoute exact path="/canva" component={Canva} />
      {/* <PublicRoute exact path="/canban" component={CanBan} /> */}


      <PrivateRouteNoSidebar exact path="/createbrand" component={CreateBrand} />


      {/* Private Routes */}
      <PrivateRoute exact path="/dashboard" component={Dashboard} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/flexlinks" component={SmartLinks} accessallowedroles={["0", "2"]} />

      <PrivateRoute exact path="/editflexlink/:id" component={SmarteditMain} accessallowedroles={["0", "2"]} />
      
      <PrivateRoute path="/setting" component={Setting} accessallowedroles={["0", "2"]} />
      <PrivateRoute path="/:type/metrics/:id" component={Metrics} accessallowedroles={["0", "2"]} />
     
      <PrivateRoute exact path="/custombrand" component={CustomBrand} accessallowedroles={["0"]} />
      <PrivateRoute exact path="/campaigns" component={Campaigns} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/viewcampaign/:id" component={ViewCampaign} accessallowedroles={["0", "2"]} />
     
      <PrivateRoute exact path="/flexpagetemplate" component={FlexpageTemplate} accessallowedroles={["0"]} />
     
      <PrivateRoute path="/social_design" component={SocialPage2} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/createpost" component={Createpost} accessallowedroles={["0", "2"]} />
      <PrivateRoute exact path="/brandedit/:id" component={EditBrand} accessallowedroles={["0"]} />
      <PrivateRoute exact path="/flexpage" component={Flowpages} accessallowedroles={["0", "2"]} />
      <PrivateRoute path="/editflexpage/:id" component={PageBuilder} accessallowedroles={["0", "2"]} />

      <PrivateRoute exact path="/feature-request" component={Feedback} accessallowedroles={["0", "2"]} />

      <PrivateRoute exact path="/bugs-report" component={Bugs} accessallowedroles={["0", "2"]} />


      {/* Catch-all route for Portifolio or 404 */}
      <Route path="/:username?">
        {({ match }) => {
          if (match && match.params.username) {
            return <Portifolio />;
          }
          return <Page404 />;
        }}
      </Route>
    </Switch>
  );
};

export default MainRouter;