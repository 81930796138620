import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";
import {multipartApiCall} from "./auth";


//create template
export const addTemplate = (formData) => multipartApiCall(`${API_URL}template/addtemplate`, "POST", formData);

//getTemplateApi 
// export const getTemplateApi = (data) => apiCall(`${API_URL}template/getalltemplate`, "POST",data);

export const getTemplateApi = (data) => apiCall(`${API_URL}template/getalltemplate`, "POST",data);

//delete template
export const deletetemplate = (data) => apiCall(`${API_URL}template/deletetemplate`, "delete",data);

//getTemplatebyIdApi  
export const getTemplatebyIdApi = (data) => apiCall(`${API_URL}template/gettemplatebyid`, "POST",data);

//updateTemplate
export const updateTemplate = (formData) => multipartApiCall(`${API_URL}template/updatetemplate`, "POST", formData);
