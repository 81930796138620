import React, { useContext, useState, useRef, useEffect } from "react";
import QRCodeStyling from 'qr-code-styling';
import Qr from "../../assets/images/Qr01.webp";
import { Accordion } from "react-bootstrap";
import DomainType from "../Createtemplate/DomainType";
import SessionName from "../Createtemplate/SessionName";
import Choosepattern from "../Createtemplate/Choosepatterns";
import Chooseeye from "../Createtemplate/Chooseeye";
import Addlogo from "../Createtemplate/AddLogo";
import Setcolor from "../Createtemplate/SetColor";
import Chooseframe from "../Createtemplate/Chooseframe";
import { getTemplatebyIdApi } from "../../BackendApi/Api/template";
import { TemplateContext } from "../../TemplateContext";
import { toJpeg } from 'html-to-image';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';

import { updateTemplate } from "../../BackendApi/Api/template";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";


function EditQr({ templateId, handleClose03, getTemplateData }) {




    const [pattern, setPattern, eye, setEye, logo, setLogo, frame, setFrame, patternColorType, setPatternColorType, patternDotsColor, setPatternDotsColor, patternGradientColor, setPatternGradientColor, eyeBallColor, setEyeBallColor, EyeCenterColor, setEyeCenterColor, backgroundcolortype, setBackgroundcolortype, backgroundcolorcode, setBackgroundcolorcode, height, setHeight, width, setWidth, eyeinside, setEyeinside, caption, setCaption, resetTemplateData] = useContext(TemplateContext);

    const qrCodeRef = useRef(null);
    const divRef = useRef(null);

    const [templateData, setTemplateData] = useState(null)
    const [name, setName] = useState("")

    const [tempId, setTempId] = useState("");

    // console.log("pattern", pattern);


    useEffect(() => {

        if (patternColorType == "color") {
            var patternColorTypeValue = {
                "type": pattern,
                "color": patternDotsColor
            };
        } else {
            var patternColorTypeValue = {
                "type": pattern,
                "gradient": {
                    "type": "linear",
                    "rotation": 0,
                    "colorStops": [
                        {
                            "offset": 0,
                            "color": patternDotsColor
                        },
                        {
                            "offset": 1,
                            "color": patternGradientColor
                        }
                    ]
                }
            };
        }

        if (backgroundcolortype === "solid") {
            var backgroundcolortypeValue = {
                "color": backgroundcolorcode
            };
        } else {
            var backgroundcolortypeValue = {
                "color": "#00000000"
            };
        }


        const qrCode = new QRCodeStyling({
            width: width,
            height: height,
            data: 'https://optisoft.in',
            margin: 0,
            qrOptions: {
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'Q',
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0,
            },
            dotsOptions: patternColorTypeValue,
            backgroundOptions: backgroundcolortypeValue,
            image: logo,
            dotsOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#6a1a4c', color2: '#6a1a4c', rotation: '0' },
            },
            cornersSquareOptions: {
                type: eye,
                color: eyeBallColor,
                gradient: null,
            },
            cornersSquareOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            cornersDotOptions: {
                type: eyeinside,
                color: EyeCenterColor,
                gradient: null,
            },
            cornersDotOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            backgroundOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#ffffff', color2: '#ffffff', rotation: '0' },
            },
        });

        qrCode.append(qrCodeRef.current);
        qrCode.update();
        //   return () => {
        //     qrCode.destroy();
        //   };
    }, [pattern, eye, eyeinside, logo, patternColorType, patternDotsColor, patternGradientColor, eyeBallColor, EyeCenterColor, backgroundcolortype, backgroundcolorcode, height, width, frame]);



    const getTemplateDataById = () => {
        if (templateId !== null || templateId !== undefined || templateId !== "") {
            const data = { "id": templateId }
            getTemplatebyIdApi(data).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === true) {
                        const apiResponse = res.data.data;
                        console.log("apiResponse 6", apiResponse);
                        setTemplateData(apiResponse);
                        setTempId(apiResponse._id);
                        setName(apiResponse.template_name);
                        setCaption(apiResponse.caption);
                        const styling = JSON.parse(apiResponse.styling);
                        console.log("styling", styling);
                        setPattern(styling.dotsOptions.type);
                        setEye(styling.cornersSquareOptions.type);
                        setEyeinside(styling.cornersDotOptions.type);
                        setLogo(styling.image);
                        if (styling.frameOptions.type !== null) {
                            setFrame(styling.frameOptions.type);
                        }
                        else {
                            setFrame("qr-bg-col");
                        }
                        //check key exist or not in object
                        // setPatternColorType(styling.dotsOptions.colorType ? "color" : "gradient");
                        if (styling.dotsOptions.hasOwnProperty("gradient")) {

                            setPatternColorType("gradient");
                            setPatternDotsColor(styling.dotsOptions.gradient.colorStops[0].color);
                            setPatternGradientColor(styling.dotsOptions.gradient.colorStops[1].color);
                        } else {
                            setPatternColorType("color");
                            setPatternDotsColor(styling.dotsOptions.color);
                        }

                        //set background color property
                        if (styling.backgroundOptions.hasOwnProperty("color")) {
                            // setBackgroundcolortype("solid");
                            setBackgroundcolorcode(styling.backgroundOptions.color);
                        } else {
                            // setBackgroundcolortype("transparent");
                            setBackgroundcolorcode("#00000000");
                        }


                        setEyeBallColor(styling.cornersSquareOptions.color);
                        setEyeCenterColor(styling.cornersDotOptions.color);
                        // setBackgroundcolorcode(styling.backgroundOptions.color);

                    }
                    else {
                        PNotify.error({
                            title: 'Error',
                            text: 'Template data not found',
                        });
                    }

                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                    });
                }
            }).catch((err) => {
                console.log(err);
            })
        }

    }

    useEffect(() => {
        getTemplateDataById();
    }, [])


    const [loading, setloading] = useState(false)

    const updateTemplateData = () => {
        setloading(true)
        if (patternColorType == "color") {
            var patternColorTypeValue = {
                "type": pattern,
                "color": patternDotsColor
            };
        } else {
            var patternColorTypeValue = {
                "type": "linear",
                "gradient": {
                    "type": "linear",
                    "rotation": 0,
                    "colorStops": [
                        {
                            "offset": 0,
                            "color": patternDotsColor
                        },
                        {
                            "offset": 1,
                            "color": patternGradientColor
                        }
                    ]
                }
            };
        }


        if (backgroundcolortype === "solid") {
            var backgroundcolortypeValue = {
                "color": backgroundcolorcode
            };
        } else {
            var backgroundcolortypeValue = {
                "color": "#00000000"
            };
        }

        const qrCode = {
            width: width,
            height: height,
            data: 'https://optisoft.in',
            margin: 0,
            qrOptions: {
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'Q',
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0,
            },
            dotsOptions: patternColorTypeValue,
            backgroundOptions: backgroundcolortypeValue,
            image: logo,
            dotsOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#6a1a4c', color2: '#6a1a4c', rotation: '0' },
            },
            cornersSquareOptions: {
                type: eye,
                color: eyeBallColor,
                gradient: null,
            },
            cornersSquareOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            cornersDotOptions: {
                type: eyeinside,
                color: EyeCenterColor,
                gradient: null,
            },
            cornersDotOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            backgroundOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#ffffff', color2: '#ffffff', rotation: '0' },
            },
            frameOptions: {
                type: frame,
            },
        };

        toJpeg(divRef.current, { quality: 0.95 })
            .then(dataUrl => {
                const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, '');

                // // Convert base64 string to Blob object
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'image/jpeg' });

                const userdata = localStorage.getItem("user");
                const user = JSON.parse(userdata);
                const userid = user._id;
                const url = window.location.href;
                const urlSplit = url.split('/');
                const urlbrandId = urlSplit[urlSplit.length - 1];
                const formdata = new FormData();
                formdata.append("id", tempId);
                formdata.append("created_by", userid);
                formdata.append("template_name", name);
                formdata.append("styling", JSON.stringify(qrCode));
                formdata.append("image", blob);
                formdata.append("caption", caption);
                formdata.append("updator_name", user.fname + "" + user.lname);
                formdata.append("updator_image", user.profile_image);
                formdata.append("brand_id", urlbrandId);

                updateTemplate(formdata).then((res) => {
                    if (res.code === 200) {
                        if (res.data.status === true) {
                            PNotify.success({
                                title: 'Success',
                                text: 'Template Updated successfully',
                            });
                            resetTemplateData();
                            handleClose03();
                            getTemplateData();
                            // window.location.reload();
                        }
                        else {
                            PNotify.error({
                                title: 'Error',
                                text: 'Template not created',
                            });
                        }

                    }
                    else {
                        PNotify.error({
                            title: 'Error',
                            text: 'Something went wrong',
                        });

                    }
                }).catch((err) => {
                    console.log("err", err);
                }).finally((err) => {
                    setloading(false)
                });
                // setShow03(false);

            });


    }









    const [showText, setShowText] = React.useState(false);



    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <Accordion defaultActiveKey="" className="Create-Template-Accordion">
                                {/* <Accordion.Item eventKey="1">
                                    <DomainType />
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <SessionName />
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="3">
                                    <Choosepattern />
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Chooseeye />
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Addlogo />
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Setcolor />
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Chooseframe />
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-md-12 p-0">
                            {/* <div className="form-group mb-3">
                                <input type="checkbox" className="form-check-input me-2" onClick={() => setShowText(!showText)} id="exampleCheck1" />
                                <label className="form-check-label" for="exampleCheck1">Save as a template</label>
                            </div> */}
                            <div className="form-group mb-3">
                                <input type="text" className="form-control" value={name} onChange={
                                    (e) => {
                                        setName(e.target.value);
                                    }
                                } placeholder="Enter a name for your new template" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    {/* <div className="Qr-image"> */}
                    <div ref={divRef} className={
                        frame === "qr-bg-col" ? "qr-box qr-bg-col" : frame === "qr-bg-col2" ? "qr-box qr-bg-col2" : frame === "qr-bg-col3" ? "qr-box qr-bg-col3" : frame === "qr-bg-col4" ? "qr-box qr-bg-col4" : "qr-box"
                    } >
                        <div className="canvas-title2"><h2>{caption}</h2></div>
                        <div className="Qr-image">
                            <div ref={qrCodeRef} />
                        </div>
                        <div className="canvas-title "><h2>{caption}</h2></div>
                        <div className="canvas-title3 "><h2>{caption}</h2></div>
                    </div>

                    {/* </div> */}
                </div>
                <div className="col-md-12">
                    <div className="d-flex justify-content-end pt-3">
                        {loading ? <SpinnerLoader /> :
                            <button className="btn Active-bttn" onClick={updateTemplateData} >Save</button>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditQr;