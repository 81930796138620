import { createContext, useState, useReducer, useMemo } from "react";

import { archivedPostsReducer } from '../reducers/archivedPostsReducer'

export const archivedPostsContext = createContext();


export const ArchivedPostsProvider = ({ children }) => {

    const initialState = {
        isFetching: true,
        posts: []
    };

    const [archivedPosts, archivedPostsdispatch] = useReducer(archivedPostsReducer, initialState);

    const archivedPostsData = useMemo(() => archivedPosts, [archivedPosts]);

    return (
        <archivedPostsContext.Provider value={{
            archivedPostsData, archivedPostsdispatch
        }}>
            {children}
        </archivedPostsContext.Provider>
    );
}