export const SubscribsationBackhalfValid =(customTag,subscription)=> {


    const tagLength = customTag.length;
    let subscriptionDetails = subscription
   
    const product_id = subscriptionDetails.plan.product;

    const free_plan_product_id = "prod_PAAduHufx7V6u2";
    const business_plan_product_id = "prod_PAAcpUnG3NVViV";
    const standard_plan_product_id = "prod_PAAVI1PTyrd3gp";
    const growth_plan_product_id = "prod_PAAYfJ2pb18PH8";
    const enterprise_plan_product_id = "prod_PHhHX8X2q53c9m";

    if (
        product_id === standard_plan_product_id ||
        product_id === growth_plan_product_id || business_plan_product_id) {
        // Paid users: 3 or more characters
        if (tagLength >= 3) {
            return { isValid: true, message: "Valid tag length." };
        } else {
            return { isValid: false, message: "Your plan only allows custom tags with 3 or more characters. Please enter a tag with at least 3 characters." };
        }
    } else if (product_id === free_plan_product_id) {
        // Free/Trial users: 5 or more characters
        if (tagLength >= 5) {
            return { isValid: true, message: "Valid tag length." };
        } else {
            return { isValid: false, message: "Your plan only allows custom tags with 5 or more characters. Please enter a tag with at least 5 characters." };
        }
    } else if (product_id === enterprise_plan_product_id) {
        // Enterprise users: 2 or more characters
        if (tagLength >= 2) {
            return { isValid: true, message: "Valid tag length." };
        } else {
            return { isValid: false, message: "Only enterprise users can register tags with 2 characters or less. Please upgrade to an enterprise plan." };
        }
    } else {
        return { isValid: false, message: "Invalid subscription plan." };
    }
}

