// import { useEffect, useContext } from 'react';
// import axios from 'axios'
// import qs from 'qs';
// import './PriceTable.css'
// import { useLocation } from 'react-router-dom';
// import { UserContext } from "../UserContext";

// const apiKey = process.env.REACT_APP_STRIPE_KEY;
// const priceTableId = process.env.REACT_APP_PRICETABLE_ID;
// const stripePubKey = process.env.REACT_APP_PUB_KEY;
// const WEBSITE = process.env.REACT_APP_WEBSITE;


// function PriceTable() {

//   const { stripe_customer_id,owner_email} = useContext(UserContext);

//   const location = useLocation();

//   const userid = JSON.parse(localStorage.getItem("user"))._id;
//   const email = owner_email;






//   useEffect(() => {
//     const segments = location.pathname.split('/').filter(segment => segment !== '');

//     if (((segments.length === 2 && segments[0] === 'setting' && segments[1] === 'price') || (segments.length === 1 && segments[0] === 'price-plan')) && (stripe_customer_id !== undefined && stripe_customer_id !== "" && stripe_customer_id !== null && stripe_customer_id !== null)) {
//       const data = qs.stringify({
//         customer: stripe_customer_id,
//         return_url: WEBSITE,
//       });

//       const config = {
//         method: 'post',
//         maxBodyLength: Infinity,
//         url: 'https://api.stripe.com/v1/billing_portal/sessions',
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//           'Authorization': `Bearer ${apiKey}`,
//         },
//         data: data,
//       };
//       axios
//         .request(config)
//         .then((response) => {

//           window.location.href = response.data.url
//           console.log("response data", response.data)
//         })
//         .catch((error) => {
//           alert(error);
//         });
//     }


//   }, [location.pathname]);




//   // Paste the stripe-pricing-table snippet in your React component
//   return (


//       <>
//         {
//           stripe_customer_id === undefined ? (
//             <stripe-pricing-table
//               client-reference-id={userid}
//               pricing-table-id={priceTableId}
//               publishable-key={stripePubKey}
//               customer-email={email}
//             >


//             </stripe-pricing-table>
//           ) : "HOLD ON REDIRECTING YOU TO YOUR SUBSCRIOTIN PORTAL"
//         }
//       </>



//   );
// }

// export default PriceTable;



import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { UserContext } from "../UserContext";
import { format } from 'date-fns';
import styled from 'styled-components';
import './PriceTable.css';

const apiKey = process.env.REACT_APP_STRIPE_KEY;
const priceTableId = process.env.REACT_APP_PRICETABLE_ID;
const stripePubKey = process.env.REACT_APP_PUB_KEY;
const WEBSITE = process.env.REACT_APP_WEBSITE;

const SubscriptionCard = styled.div`
  ${'' /* background: white;
  border-radius: 8px;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
  max-width: 400px;
  margin: 20px ;
  font-family: Arial, sans-serif; */}

  h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 16px;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 8px;
  }

  p {
    color: #666;
    font-size: 16px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    span:first-child {
      font-weight: bold;
    }
  }

  button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    width: 100%;

    &:hover {
      background-color: #45a049;
    }
  }
`;

function PriceTable() {
  const { stripe_customer_id, owner_email } = useContext(UserContext);
  const location = useLocation();
  const userid = JSON.parse(localStorage.getItem("user"))._id;
  const email = owner_email;
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    const segments = location.pathname.split('/').filter(segment => segment !== '');

    if (((segments.length === 2 && segments[0] === 'setting' && segments[1] === 'price') || (segments.length === 1 && segments[0] === 'price-plan')) && stripe_customer_id) {
      fetchSubscriptionData();
    }
  }, [location.pathname, stripe_customer_id]);

  const fetchSubscriptionData = async () => {
    try {
      const response = await axios.get(`https://api.stripe.com/v1/customers/${stripe_customer_id}/subscriptions`, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
        },
      });
      if (response.data.data.length > 0) {
        setSubscriptionData(response.data.data[0]);
      }
    } catch (error) {
      console.error('Error fetching subscription data:', error);
    }
  };

  const handleUpdateClick = async () => {
    const data = qs.stringify({
      customer: stripe_customer_id,
      return_url: WEBSITE,
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.stripe.com/v1/billing_portal/sessions',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${apiKey}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      window.location.href = response.data.url;
    } catch (error) {
      alert('Error redirecting to subscription portal: ' + error.message);
    }
  };
  console.log("subscriptionData", subscriptionData)
  const SubscriptionTile = ({ subscriptionData }) => {
    const product_id = subscriptionData.plan.product;
    const productPlans = {
      prod_PAAduHufx7V6u2: "Free Plan",
      prod_PAAcpUnG3NVViV: "Business Plan",
      prod_PAAVI1PTyrd3gp: "Standard Plan",
      prod_PAAYfJ2pb18PH8: "Growth Plan",
      prod_PHhHX8X2q53c9m: "Enterprise Plan",
    };

    const planName = productPlans[product_id] || "Current Plan";
    const interval = subscriptionData.plan.interval;
    const price = subscriptionData.plan.amount / 100;
    const renewalDate = new Date(subscriptionData.current_period_end * 1000);

    return (
      <SubscriptionCard className='subscription-card'>
        <h2>{planName}</h2>
        <p>
          <span>Billing:</span>
          <span>{interval === 'month' ? 'Monthly' : 'Annually'}</span>
        </p>
        <p>
          <span>Price:</span>
          <span>${price.toFixed(2)} per {interval}</span>
        </p>
        <p>
          <span>Renewal Date:</span>
          <span>{format(renewalDate, 'MMMM d, yyyy')}</span>
        </p>
        <button onClick={handleUpdateClick}>Update Subscription</button>

      </SubscriptionCard>
    );
  };

  return (
    <>
      {!stripe_customer_id ? (
        <stripe-pricing-table
          client-reference-id={userid}
          pricing-table-id={priceTableId}
          publishable-key={stripePubKey}
          customer-email={email}
        />
      ) : subscriptionData ? (
        <SubscriptionTile subscriptionData={subscriptionData} />
      ) : (
        "Loading subscription information..."
      )}
    </>
  );
}

export default PriceTable;



