import React, { useState, useEffect } from 'react';
import { IoIosMore } from "react-icons/io";

import { useLocation, useHistory } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const CanvaDashboard = ({ insertaipostVideo, insertaipostImage, masterPosts }) => {
  const location = useLocation();
  const [designs, setDesigns] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [asset, setAsset] = useState(null);

  const [singleDesign, setSingleDesign] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    console.log(" user?.owner_id", user?.owner_id)
    const fetchDesigns = async () => {
      try {
        const response = await fetch(`${API_URL}/socialposts/getCanvaDesigns`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json' // Ensure proper content type
          },
          body: JSON.stringify({ user_id: user?.owner_id })
        });
        const data = await response.json();
        let designs = data.result.items;
        if (data.success) {
          setDesigns(designs);

          const urlParams = new URLSearchParams(location.search);
          const source = urlParams.get('source');

          console.log("this is the source", source);
          if (source === 'canva_redirect') {
            const localData = JSON.parse(localStorage.getItem("design_data"));

            if (localData) {
              const design_id = localData?.data?.id;

              console.log("design_id after", design_id);
              console.log("designs after", designs);

              const singleDesign = designs.find(design => design.id === design_id);

              console.log("singleDesign.bhaukal", singleDesign);
              setSingleDesign(singleDesign);
            }
          }

        }
      } catch (error) {
        console.error('Error fetching Canva designs:', error);
      }
    };

    fetchDesigns();
  }, []);


  const uploadAssetAndCreateDesign = async () => {
    if (!asset) {
      console.error('No file selected');
      return;
    }
    const user = JSON.parse(localStorage.getItem("user"));


    const formData = new FormData();
    formData.append('asset', asset);
    formData.append('user_id', user?.owner_id);

    try {
      const response = await fetch(`${API_URL}/socialposts/uploadAssetAndCreateDesign`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        // setSingleDesign(data.data);
        console.log('Asset uploaded and design created:', data);

        localStorage.setItem("design_data", JSON.stringify({
          location: window.location.href,
          data: data.data,
          masterPosts: masterPosts
        }));


        // Open a full-width window with the edit URL
        const editUrl = data.data.urls.edit_url;
        window.location.assign(editUrl);
      } else {
        console.error('Failed to upload asset:', data.message);
      }
    } catch (error) {
      console.error('Error creating Canva design:', error);
    }
  };





  const insertAsset = async (design_id) => {

    const user = JSON.parse(localStorage.getItem("user"))


    try {


      const response = await fetch(`${API_URL}/socialposts/exportCanvaDesign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          design_id: design_id,
          user_id: user?.owner_id
        })

      });

      const data = await response.json();
      if (data.success) {
        const urls = [data.url]
        insertaipostImage(urls)
        setSingleDesign(null);
        console.log('Asset uploaded and design created:', data);
      } else {
        console.error('Failed to upload asset:', data.message);
      }
    }
    catch (error) {
      console.error('Error creating Canva design:', error);
    }



  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setAsset(file);
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setMediaPreview(fileURL);
    }
  };

  return (
    <div style={containerStyle}>

      <div>

        {
          !singleDesign &&
          <>

            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <label style={labelStyle}>
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*"
                  style={inputStyle}
                />
                <div style={uploadContentStyle}>
                  <img
                    src="https://via.placeholder.com/40"
                    alt="Upload icon"
                    style={iconStyle}
                  />
                  <span>Upload Image or Video</span>
                </div>
              </label>
              {mediaPreview && (
                <img
                  src={mediaPreview}
                  alt="Preview"
                  style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '4px' }}
                />
              )}
            </div>

            <button
              onClick={uploadAssetAndCreateDesign}

            >edit in canva</button>

          </>

        }





        {
          singleDesign &&



          <>

            <img src={singleDesign?.thumbnail?.url} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '4px' }} />

            {/* <button
            // onClick={uploadAssetAndCreateDesign}
 
            >sync changes</button> */}

            <button
              // onClick={uploadAssetAndCreateDesign}

              onClick={() => insertAsset(singleDesign.id)}

            >Insert</button>

          </>

        }


      </div>
      {/* <h1 style={titleStyle}>Your Canva Designs</h1> */}
      <div style={gridStyle}>
        {designs.map((design) => (
          <div
            style={cardStyle}
            key={design.id}
            onMouseEnter={() => setHoveredId(design.id)}
            onMouseLeave={() => setHoveredId(null)}
          >
            <div style={imageContainerStyle}>
              <img src={design?.thumbnail?.url} alt={design.title} style={imageStyle} />
              {hoveredId === design.id && (
                <div style={iconContainerStyle}>
                  <IoIosMore
                    className="text-gray-800 hover:text-blue-500 hover:bg-blue"
                    size={24}
                  />

                </div>
              )}
            </div>
            <div style={detailsStyle}>
              <p style={titleTextStyle}>
                {design.title && design.title.length > 20
                  ? design.title.slice(0, 18) + "..."
                  : design.title || "No title available"}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};





const labelStyle = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  border: '1px solid #ccc',
  borderRadius: '4px',
  padding: '8px 12px',
  backgroundColor: '#f9f9f9',
};

const inputStyle = {
  display: 'none',
};

const uploadContentStyle = {
  display: 'flex',
  alignItems: 'center',
};

const iconStyle = {
  width: '24px',
  height: '24px',
  marginRight: '8px',
};


const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#f4f4f4',
  overflowY: 'auto',
  overflowX: 'hidden',
  height: '100vh',
  width: '100%',
  maxWidth: '100vw',
  boxSizing: 'border-box',
};

const titleStyle = {
  textAlign: 'center',
  color: '#333',
  marginTop: '20px',
  fontSize: '2.5rem',
};

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: '20px',
  width: '100%',
  maxWidth: '100%',
  overflowX: 'hidden',
};

const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease-in-out',
  maxWidth: '100%',
  position: 'relative',
};

const imageContainerStyle = {
  position: 'relative',
  width: '100%',
  height: '150px',
};

const imageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
};

const iconContainerStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  borderRadius: '20%',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'opacity 0.3s ease-in-out',
  cursor: 'pointer',
};

const detailsStyle = {
  textAlign: 'start',
  margin: '10px 0 0 0',
};

const titleTextStyle = {
  fontSize: '1rem',
  color: '#333',
  margin: '0',
  padding: '5px 0',
};


export default CanvaDashboard;