import { createContext, useState, useReducer, useMemo } from "react";

import { brandSelectionReducer } from '../reducers/brandSelectionReducer'

export const brandSelectionContext = createContext();

export const BrandSelectionProvider = ({ children }) => {

    const initialState = {
        selected: 'all',
        brands: []
    };

    const [sidebarbrandswa, sidebarBrandsDispatch] = useReducer(brandSelectionReducer, initialState);

    const sidebarBrands = useMemo(() => sidebarbrandswa, [sidebarbrandswa]);

    return (
        <brandSelectionContext.Provider value={{
            sidebarBrands, sidebarBrandsDispatch
        }}>
            {children}
        </brandSelectionContext.Provider>
    );
}