import React from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useState } from "react";
import  Editor  from 'react-simple-wysiwyg';
import { useBuilderContext } from "../../../builderContext";
import { useEffect,useRef } from "react";
import { useLocation } from "react-router-dom";
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';
import SunEditor from "suneditor-react";
export function EditText() {
    const { editComponent } = useBuilderContext();



    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus=location.state?.showStatus

    const [status,setStatus]=useState("")
    

    const [html, setHtml] = useState({
        html: "",
    });

    useEffect(() => {
        if (mydata) {
            setHtml({
                html: mydata.html,
            });
        }
        if (showStatus) {
            setStatus(showStatus)
        }
        console.log("useEffect");
    }, []);

    

    const handleSaveClick = () => {
        editComponent(position, "TEXT", html,status);
    };
    const editor = useRef();

    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    useEffect(() => {
        // Update the SunEditor content when the html state changes
        if (editor.current) {
            editor.current.setContents(html.html);
        }
    }, [html])

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Text</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="form-group mb-2">
                        <label htmlFor="exampleInputEmail1">Text</label>
                        {/* <Editor value={html.html}  onChange={
                            (e) => {
                                const value = e.target.value;
                                setHtml(() => ({
                                    html: value,
                                }));
                            }
                        } /> */}

                        <div>
                            <SunEditor setAllPlugins={true}
                                getSunEditorInstance={getSunEditorInstance}
                                onChange={(content) => {
                                    setHtml({
                                        html: content,
                                    });
                                }}

                                setOptions={{
                                    height: "150px",
                                    plugins: plugins, // Add your plugin configuration here

                                    buttonList: [
                                        ['undo', 'redo'],
                                        ['font', 'fontSize', 'formatBlock'],
                                        ['paragraphStyle', 'blockquote'],
                                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                        ['fontColor', 'hiliteColor',],
                                        ['removeFormat', 'link'],
                                        ['outdent', 'indent'],
                                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                                        ['fullScreen', 'showBlocks', 'codeView'],
                                        ['preview', 'print'],
                                    ],
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={handleSaveClick} >
                    Save
                </button>
            </div>
        </>
    );
}