import React from "react";
import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";
const { API_URL } = require("../../../BackendApi/Api/config");


function ImageText(props) {

    const saveClicks = (smartlink_id, unique_id) => {
        saveComponentsClicks({
            unique_id: unique_id,
            smartlink_id: smartlink_id,

        });
    }

    console.log("image props", props);

    return (
        <>
            <div className="image_with_text_content color_bg_text mb-3">
                <div className="brand_img_set">
                    <a href={props.data.link} onClick={() => { saveClicks(props.smartlink_id, props.data.unique_id) }} target="_blank">
                    <img 
                    // src={API_URL + "flowpage/" + props.data.imageLink} 
                    src={(props.data.imageLink.startsWith("http://") || props.data.imageLink.startsWith("https://")) ? props.data.imageLink : API_URL + "flowpage/" + props.data.imageLink}                   
                    alt={props.data.imageAltText}  />
                    </a>
                </div>
                <div className="image_text_content">
                    <p className="image_text_content_title m-0">{props.data.imageCaption}</p>
                </div>
            </div>
        </>
    );
}

export default ImageText;