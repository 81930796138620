import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useBuilderContext } from "../../../builderContext";

export function EditButtonLink() {

    const { editComponent } = useBuilderContext();



    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus;
   
    const [status, setStatus] = useState("")
    const [currentstate, setCurrentState] = useState(mydata);

    const [btnpropties, setBtnpropties] = React.useState({
        unique_id: '',
        lable: '',
        link: '',
    });

    useEffect(() => {
        if (mydata) {
            setBtnpropties({
                lable: mydata?.lable,
                link: mydata?.link,
                unique_id: mydata?.unique_id,
            });
        }

        if (showStatus) {
            setStatus(showStatus)
        }
        console.log("useEffect");
    }, []);

    // Handle changes in input fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBtnpropties((prevProps) => ({
            ...prevProps,
            [name]: value,
        }));
    };

    const handleSaveClick = () => {
        editComponent(position, "BUTTON", btnpropties,status);
    };



    return (
        console.log("screen"),
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Button Link</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="form-group mb-4">
                        <div className="form_block">
                            <input type="text" value={btnpropties.lable} className="form-control did-floating-input" placeholder=""
                                onChange={
                                    (e) => {
                                        const value = e.target.value;
                                        setBtnpropties((prevProps) => ({
                                            ...prevProps,
                                            lable: value,
                                        }));
                                    }
                                } />
                            <label htmlFor="exampleInputEmail1" className="gvofes did-floating-label">Label</label>
                        </div>
                    </div>
                    <div className="form-group did-floating-label-content">
                        <div className="form_block">
                            <input type="text" value={btnpropties.link} className="form-control did-floating-input" placeholder=""
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setBtnpropties((prevProps) => ({

                                        ...prevProps,
                                        link: value,
                                    }));
                                }

                                } />
                            <label htmlFor="exampleInputPassword1" className="gvofes did-floating-label">Link</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={handleSaveClick} >
                    Save
                </button>
            </div>
        </>
    );
}