import React, { useState } from 'react';
import './Contact.css';
import Header from '../../LandingComponent/Header/Header';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Footer from '../../LandingComponent/Footer/Footer';


function Contact() {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <div className="Contact">
            {/* Header goes here */}
            <Header />
            {/* Header ends here */}

            {/* Contact us section goes here */}
            <div className="hero mt-120">
                <div className="container">
                    <div className="text-center">
                        <div className="hero-content">
                            <h1 className="mb-4">
                                Contact Us
                            </h1>
                            <p>
                                Send us an email vulputate bibendum justo sed, tincidunt quisque
                                dictum eget dolor vel maximus.
                            </p>
                        </div>
                        <div className="contact-form text-start">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3"  controlId="validationCustom01">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Name" required />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3"  controlId="validationCustom02">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control type="phone" placeholder="Enter Phone" required />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" controlId="validationCustom03">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" required />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <Form.Group className="mb-3" controlId="validationCustom04">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" rows={3} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="hero-btn mt-4 d-flex justify-content-center pb-100">
                                    <Button className='btn-start' type='submit'>Send Email</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact us section ends here */}
            {/* Footer goes here */}
            <Footer />
            {/* Footer ends here */}
        </div>
    );
}

export default Contact;