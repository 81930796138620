import React from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft, FaExternalLinkAlt } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { useBuilderContext } from "../../../builderContext";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import *  as PNotify from '@pnotify/core';
import { Form } from "react-bootstrap";


export function EdityoutubeIframe() {

    const navigate = useHistory();
    const { editComponent } = useBuilderContext();



    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus
    console.log("location", location)
    const setvaluedit = location.state?.selectedValue

    const [selectedValue, setSelectedValue, ] = useState(setvaluedit);

    const handleRadioChange = (value ) => {
        setSelectedValue(value);
    };

    
    const [link, setLink] = React.useState({
        link: "",
    });
    const [status, setStatus] = useState('')
    useEffect(() => {
        if (mydata) {
            if (mydata.Link) {
                setLink({
                    link: mydata.Link,
                });
            }
        }
        if (showStatus) {
            setStatus(showStatus)
        }
    }, []);



    const handleSaveClick = () => {

        const data = {
            Link: link.link
        }
        console.log("data", data);
        editComponent(position, "YOUTUBEIFRAME", data, status, selectedValue);
        navigate.push("/");
    };

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Iframe</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="position-relative">
                        <div className="form_block">
                            <textarea value={link.link} className="form-control did-floating-textarea" placeholder=""
                                onChange={(e) => {

                                    let youtubeLink = e.target.value;

                                    // const url = new URL(e.target.value);

                                    // if (url.searchParams.has("si")) {

                                    // const urlParts = youtubeLink.split('/');
                                    // const videoId = urlParts[urlParts.length - 1].split('?')[0];

                                    //     youtubeLink = `https://www.youtube.com/watch?v=${videoId}`

                                    // }


                                    setLink({ link: youtubeLink });
                                }
                                } />
                            <label className="did-floating-label">iframe</label>
                        </div>
                        <div className="link_target">
                            <button type="button" className="btn btn-sm">
                                <FaExternalLinkAlt />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>


                <Form>
                    {['radio'].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                inline
                                label="Add Subscribe Button"
                                name="group9"
                                checked={selectedValue === true}
                                type={type}
                                id={`inline-${type}-8`}
                                onChange={() => handleRadioChange(true)}
                            />
                            <Form.Check
                                inline
                                label="Remove Subscribe Button"
                                name="group9"
                                checked={selectedValue === false}
                                type={type}
                                id={`inline-${type}-9`}
                                onChange={() => handleRadioChange(false)}
                            />

                        </div>
                    ))}
                </Form>


            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={handleSaveClick} >
                    Save
                </button>
            </div>
        </>
    );
}