import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
// import Fb from '../../assets/images/icons/fb.webp';
import BrandLogo from '../../assets/images/icons/Brandlogo.webp';
import { MdLocalPhone, MdMail } from "react-icons/md";
import P404 from '../../assets/images/icons/P404.webp';
import Instagram from '../../assets/images/icons/instagram.webp';
import Twitter from '../../assets/images/icons/twitter.webp';
import { SmartLinkContext } from "../CreateSmartlink/SmartLinkcontext";
import { updateScheduleData, updatesmartlinkApi } from "../../BackendApi/Api/smartsLinkApi";
import { getBrandBySmartlinkIdApi } from "../../BackendApi/Api/brandApi";
import { getscheduleapi } from "../../BackendApi/Api/smartsLinkApi";
import axios from "axios";
import { MdOutlineEditCalendar, MdColorLens } from "react-icons/md";
import { Accordion } from 'react-bootstrap';
import { TbWorld } from "react-icons/tb";
import { FaHeading, FaTabletAlt, FaMobileAlt } from "react-icons/fa";
import { BsImage, BsArrowsFullscreen } from "react-icons/bs";
import { RiFontSansSerif } from "react-icons/ri";
import { GrStatusGoodSmall } from "react-icons/gr";
import fb from './socialImages/facebook.png'
import insta from './socialImages/instagram.png'
import twitt from './socialImages/twitter.png'
import internet from './socialImages/internet.png'
import pin from './socialImages/pinterest.png'
import tiktok from './socialImages/tiktok.png'
import { API_URL } from '../../BackendApi/Api/config';
import Slider from 'react-rangeslider';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import Modal from 'react-bootstrap/Modal';
import { MdOutlineDesktopMac } from "react-icons/md";


function Schedule() {

    const [loading, setLoading] = useState(false);

    const [smartlinkeditdata, setSmartlinkEditData,IosTargeting, setIosTargeting,latest, setLatest,] = React.useContext(SmartLinkContext);
    const [showText, setShowText] = useState(false);

    const [brandData, setBrandData] = useState({});

    const [start_time, setStart_time] = useState('');
    const [end_time, setEnd_time] = useState('');
    const [endDateStatus, setEndDateStatus] = useState(false);

    const [headerOption, setHeaderOption] = useState(1);
    const [footerOption, setFooterOption] = useState(1);
    const [brandHeaderLogoOption, setBrandHeaderLogoOption] = useState(2);
    const [brandFooterLogoOption, setBrandFooterLogoOption] = useState(2);
    const [headerPosition, setHeaderPosition] = useState('left');
    const [footerPosition, setFooterPosition] = useState('left');

    const [header_brand_logo, setHeaderBrand_logo] = useState('');
    const [footer_brand_logo, setFooterBrand_logo] = useState('');

    const [customHeaderLogoUrl, setCustomHeaderLogoUrl] = useState('');
    const [customFooterLogoUrl, setCustomFooterLogoUrl] = useState('');

    const [customHeaderLogo, setCustomHeaderLogo] = useState('')
    const [customFooterLogo, setCustomFooterLogo] = useState('')

    const [previousCustomHeaderLogo, setPreviousCustomHeaderLogo] = useState('')
    const [previousCustomFooterLogo, setPreviousCustomFooterLogo] = useState('')

    const [footerText, setFooterText] = useState('All Rights Reserved');
    const [footerTextColor, setFooterTextColor] = useState('#7367f0');

    const [logo_light, setLogo_light] = useState('');
    const [logo_dark, setLogo_dark] = useState('');
    const [logo_full, setLogo_full] = useState('');

    const [customHeaderLogoSize, setCustomHeaderLogoSize] = useState(32);
    const [customFooterLogoSize, setCustomFooterLogoSize] = useState(32);

    const [backgroundType, setBackgroundType] = useState(1);
    const [collapse, setCollapse] = useState(false);

    const [BackgroundColor, setBackgroundColor] = useState('#3713ed');
    const [backgroundColor2, setBackgroundColor2] = useState('#f01a45');
    const [colorPosition, setColorPosition] = useState('leftright');

    const [previousLostImage, setPreviousLostImage] = useState('lost.gif');

    const [lostImage, setLostImage] = useState(null);

    const [lostUrl, setLostUrl] = useState(null);

    const [status, setStatus] = useState(false);
    const [switchStatus, setSwitchStatus] = useState(false);

    const [deviceView, setDeviceView] = useState('view-desktop');


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const hnadleHeaderLogoChange = (value) => {
        if (value == 1) {

            setBrandHeaderLogoOption(1);
            setHeaderBrand_logo(logo_light);

        } else if (value == 2) {

            setBrandHeaderLogoOption(2);
            setHeaderBrand_logo(logo_dark);

        } else if (value == 3) {

            setBrandHeaderLogoOption(3);
            setHeaderBrand_logo(logo_full);

        }

    }

    const showHeaderOption = (value) => {
        setHeaderOption(value);
        if (value == 1) {
            if (brandHeaderLogoOption == 1) {
                setHeaderBrand_logo(logo_light);
            } else if (brandHeaderLogoOption == 2) {
                setHeaderBrand_logo(logo_dark);
            } else if (brandHeaderLogoOption == 3) {
                setHeaderBrand_logo(logo_full);
            }
        }
        else if (value == 2) {
            setHeaderBrand_logo(customHeaderLogoUrl);
        }

    }

    const handleSelectHeaderCustomLogo = (e) => {
        setCustomHeaderLogo(e.target.files[0]);
        const file = e.target.files[0];

        // Check if a file was selected
        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setCustomHeaderLogoUrl(reader.result);
                setHeaderBrand_logo(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelectLostImage = (e) => {
        setLostImage(e.target.files[0]);
        const file = e.target.files[0];

        // Check if a file was selected
        if (file) {
            // Code to execute when a file is selected
            const reader = new FileReader();

            reader.onload = () => {
                setLostUrl(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }



    const handleHeaderHeightChange = (value) => {
        setCustomHeaderLogoSize(value)
    }

    const handleFooterHeightChange = value => {
        setCustomFooterLogoSize(value)
    }

    const handleSelectFooterCustomLogo = (e) => {
        setCustomFooterLogo(e.target.files[0]);
        const file = e.target.files[0];

        // Check if a file was selected
        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setCustomFooterLogoUrl(reader.result);
                setFooterBrand_logo(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleHeaderPosition = (e) => {
        const selectedPosition = e.target.value;
        setHeaderPosition(selectedPosition);
    }

    const getscheduledata = () => {
        const id = window.location.href.split('/')[window.location.href.split('/').length - 1]
        const data = {
            "id": id
        }
        getscheduleapi(data).then((res) => {


            if (res.data.status == true) {

                const scheduleData = res.data.data[0];
                setSwitchStatus(scheduleData.schedule_status);


                if (scheduleData.logo_light.startsWith('http') || scheduleData.logo_light.startsWith('https')) {
                    setLogo_light(scheduleData.logo_light);
                } else {
                    setLogo_light(API_URL + 'brands/' + scheduleData.logo_light);
                }
                if (scheduleData.logo_dark.startsWith('http') || scheduleData.logo_dark.startsWith('https')) {
                    setLogo_dark(scheduleData.logo_dark);
                } else {
                    setLogo_dark(API_URL + 'brands/' + scheduleData.logo_dark);
                }
                if (scheduleData.logo_full.startsWith('http') || scheduleData.logo_full.startsWith('https')) {
                    setLogo_full(scheduleData.logo_full);
                } else {
                    setLogo_full(API_URL + 'brands/' + scheduleData.logo_full);
                }
                // setLogo_light(API_URL + 'brands/' + scheduleData.logo_light);
                // setLogo_dark(API_URL + 'brands/' + scheduleData.logo_dark);
                // setLogo_full(API_URL + 'brands/' + scheduleData.logo_full);
                setEndDateStatus(scheduleData.schedule_end_date_status);

                if (scheduleData.schedule_status == true) {
                    setStatus(true);
                }
                console.log("this is schedule datasss", scheduleData)

                // (campaign.end_date).split("T")[0]

                if (scheduleData.schedule_end_date_status == true) {
                    setStart_time((scheduleData.schedule_start_date).split("T")[0]);
                    setEnd_time((scheduleData.schedule_end_date).split("T")[0]);
                }




                console.log("this is sched", scheduleData.logo_dark);

                if (scheduleData.schedule_properties) {

                    const scheduleProperties = scheduleData.schedule_properties
                    if (scheduleData.logo_light.startsWith('http') || scheduleData.logo_light.startsWith('https')) {
                        setLogo_light(scheduleData.logo_light);
                    } else {
                        setLogo_light(API_URL + 'brands/' + scheduleData.logo_light);
                    }
                    if (scheduleData.logo_dark.startsWith('http') || scheduleData.logo_dark.startsWith('https')) {
                        setLogo_dark(scheduleData.logo_dark);
                    } else {
                        setLogo_dark(API_URL + 'brands/' + scheduleData.logo_dark);
                    }
                    if (scheduleData.logo_full.startsWith('http') || scheduleData.logo_full.startsWith('https')) {
                        setLogo_full(scheduleData.logo_full);
                    } else {
                        setLogo_full(API_URL + 'brands/' + scheduleData.logo_full);
                    }
                    // setLogo_light(API_URL + 'brands/' + scheduleData.logo_light);
                    // setLogo_dark(API_URL + 'brands/' + scheduleData.logo_dark);
                    // setLogo_full(API_URL + 'brands/' + scheduleData.logo_full);

                    setBackgroundType(scheduleProperties.background_type);
                    if (scheduleProperties.background_type == 2) {
                        setCollapse(true);
                    }

                    setBackgroundColor(scheduleProperties.bgcolor1);
                    setBackgroundColor2(scheduleProperties.bgcolor2);

                    setFooterPosition(scheduleProperties.footer_logo_position);
                    setFooterText(scheduleProperties.footer_text);
                    setFooterTextColor(scheduleProperties.footer_text_color);
                    setHeaderPosition(scheduleProperties.header_logo_position);

                    setHeaderOption(scheduleProperties.header_logo_option);

                    setFooterOption(scheduleProperties.footer_logo_option);

                    setBrandHeaderLogoOption(scheduleProperties.header_brand_logo);
                    setBrandFooterLogoOption(scheduleProperties.footer_brand_logo);

                    setCustomHeaderLogoUrl(API_URL + 'redirection/' + scheduleProperties.header_custom_logo)
                    setCustomFooterLogoUrl(API_URL + 'redirection/' + scheduleProperties.footer_custom_logo);

                    setCustomHeaderLogoSize(scheduleProperties.header_logo_size);
                    setCustomFooterLogoSize(scheduleProperties.footer_logo_size);

                    setPreviousCustomHeaderLogo(scheduleProperties.header_custom_logo);
                    setPreviousCustomFooterLogo(scheduleProperties.footer_custom_logo);

                    if (scheduleProperties.header_logo_option == 1) {


                        if (scheduleProperties.header_brand_logo == 1) {

                            if (scheduleData.logo_light.startsWith('http') || scheduleData.logo_light.startsWith('https')) {

                                setHeaderBrand_logo(scheduleData.logo_light);
                            }
                            else {

                                setHeaderBrand_logo(API_URL + 'brands/' + scheduleData.logo_light);
                            }
                        }
                        else if (scheduleProperties.header_brand_logo == 2) {

                            if (scheduleData.logo_dark.startsWith('http') || scheduleData.logo_dark.startsWith('https')) {

                                setHeaderBrand_logo(scheduleData.logo_dark);
                            } else {

                                setHeaderBrand_logo(API_URL + 'brands/' + scheduleData.logo_dark);
                            }
                        }
                        else if (scheduleProperties.header_brand_logo == 3) {


                            if (scheduleData.logo_full.startsWith('https') || scheduleData.logo_full.startsWith('https')) {

                                setHeaderBrand_logo(scheduleData.logo_full);
                            } else {

                                setHeaderBrand_logo(API_URL + 'brands/' + scheduleData.logo_full);
                            }
                        }
                    }

                    if (scheduleProperties.footer_logo_option == 1) {
                        if (scheduleProperties.footer_brand_logo == 1) {


                            if (scheduleData.logo_light.startsWith('http') || scheduleData.logo_light.startsWith('https')) {
                                setFooterBrand_logo(scheduleData.logo_light);
                            } else {
                                setFooterBrand_logo(API_URL + 'brands/' + scheduleData.logo_light);

                            }
                        }

                        else if (scheduleProperties.footer_brand_logo == 2) {


                            if (scheduleData.logo_dark.startsWith('http') || scheduleData.logo_dark.startsWith('https')) {
                                setFooterBrand_logo(scheduleData.logo_dark);
                            } else {
                                setFooterBrand_logo(API_URL + 'brands/' + scheduleData.logo_dark);

                            }
                        }
                        else if (scheduleProperties.footer_brand_logo == 3) {


                            if (scheduleData.logo_full.startsWith('http') || scheduleData.logo_full.startsWith('https')) {
                                setFooterBrand_logo(scheduleData.logo_full);
                            } else {
                                setFooterBrand_logo(API_URL + 'brands/' + scheduleData.logo_full);

                            }

                        }
                    }

                    if (scheduleProperties.header_logo_option == 2) {

                        if(scheduleProperties.header_custom_logo.startsWith('http') || scheduleProperties.header_custom_logo.startsWith('https')) {

                            setHeaderBrand_logo(scheduleProperties.header_custom_logo);
                        }

                        else {
                            setHeaderBrand_logo(API_URL + 'redirection/' + scheduleProperties.header_custom_logo);
                        }
                    }

                    if (scheduleProperties.footer_logo_option == 2) {

                        if(scheduleProperties.footer_custom_logo.startsWith('http') || scheduleProperties.footer_custom_logo.startsWith('https')) {
                            setFooterBrand_logo(scheduleProperties.footer_custom_logo);
                        }

                        else {
                            setFooterBrand_logo(API_URL + 'redirection/' + scheduleProperties.footer_custom_logo);
                        }
                    }

                    setPreviousLostImage(scheduleProperties.lost_image);

                    setLostUrl(API_URL + 'redirection/' + scheduleProperties.lost_image);

                } else {

                    if (scheduleData.logo_light.startsWith('http') || scheduleData.logo_light.startsWith('https')) {

                        setLogo_light(scheduleData.logo_light);
                    }
                    else {
                        setLogo_light(API_URL + 'brands/' + scheduleData.logo_light);

                    }

                    if (scheduleData.logo_dark.startsWith('http') || scheduleData.logo_dark.startsWith('https')) {

                        setLogo_dark(scheduleData.logo_dark);
                        setHeaderBrand_logo(scheduleData.logo_dark);
                        setFooterBrand_logo(scheduleData.logo_dark);
                    }
                    else {
                        setLogo_dark(API_URL + 'brands/' + scheduleData.logo_dark);
                        setHeaderBrand_logo(API_URL + 'brands/' + scheduleData.logo_dark);
                        setFooterBrand_logo(API_URL + 'brands/' + scheduleData.logo_dark);

                    }

                    if (scheduleData.logo_full.startsWith('http') || scheduleData.logo_full.startsWith('https')) {

                        setLogo_full(scheduleData.logo_full);
                    }
                    else {
                        setLogo_full(API_URL + 'brands/' + scheduleData.logo_full);

                    }

                    setHeaderOption(1);
                    setFooterOption(1);

                    setBrandFooterLogoOption(2);
                    setBrandHeaderLogoOption(2);

                }


            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleFooterPosition = (e) => {
        const selectedPosition = e.target.value;
        setFooterPosition(selectedPosition);
    }

    const showFooterOption = (value) => {
        setFooterOption(value);
        if (value == 1) {
            if (brandFooterLogoOption == 1) {
                setFooterBrand_logo(logo_light);
            } else if (brandFooterLogoOption == 2) {
                setFooterBrand_logo(logo_dark);
            } else if (brandFooterLogoOption == 3) {
                setFooterBrand_logo(logo_full);
            }
        }
        else if (value == 2) {
            setFooterBrand_logo(customFooterLogoUrl);
        }

    }

    const hnadleFooterLogoChange = (value) => {
        if (value == 1) {

            setBrandFooterLogoOption(1);
            setFooterBrand_logo(logo_light);

        } else if (value == 2) {

            setBrandFooterLogoOption(2);
            setFooterBrand_logo(logo_dark);

        } else if (value == 3) {

            setBrandFooterLogoOption(3);
            setFooterBrand_logo(logo_full);

        }

    }

    const removeClass = () => {
        setCollapse(false);
        setBackgroundType(1);
    }

    const addClass = () => {
        setCollapse(true);
        setBackgroundType(2);
    }

    const handleValueChange = (e) => {
        const selectedValue = e.target.value;
        setColorPosition(selectedValue);
    };

    const gradientDirectionMap = {
        leftright: 'to right',
        rightleft: 'to left',
        topbottom: 'to bottom',
        bottomtop: 'to top',
    };


    useEffect(() => {
        getscheduledata();
    }, [])



    const updateSchedule = () => {
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        setLoading(true);

        const formData = new FormData();


        if (switchStatus == false) {
            formData.append("schedule_status", switchStatus);
            formData.append("id", smartlinkeditdata._id);
        }


        if (switchStatus == true) {


            if (endDateStatus == true && (end_time == '' || end_time == null || end_time == undefined)) {

                setLoading(false);

                PNotify.error({
                    title: 'Error',
                    text: 'Please Select End Date',
                });
                return false;

            } else if (start_time == '' || start_time == null || start_time == undefined) {

                setLoading(false);

                PNotify.error({
                    title: 'Error',
                    text: 'Please Select Start Date',
                });
                return false;
            }
            else {

                formData.append("schedule_status", switchStatus);

                formData.append("id", smartlinkeditdata._id);
                formData.append("schedule_start_date", start_time);

                formData.append("schedule_end_date", end_time);
                formData.append("schedule_end_date_status", endDateStatus);

                formData.append('background_type', backgroundType);
                formData.append('bgcolor1', BackgroundColor);
                formData.append('bgcolor2', backgroundColor2);
                formData.append('gradient_colorPosition', colorPosition);
                formData.append('gradient_gradientDirectionMap', gradientDirectionMap[colorPosition]);

                formData.append('previous_custom_footer_logo', previousCustomFooterLogo);
                formData.append('previous_custom_header_logo', previousCustomHeaderLogo);
                formData.append("previous_lost_image", previousLostImage);
                formData.append("updator_image", user.profile_image);
                formData.append("updator_name", user.fname + " " + user.lname);

                if (lostImage != null) {
                    formData.append('lost_image', lostImage);
                } else {
                    formData.append('lost_image', previousLostImage);
                }


                if (headerOption == 2 && (customHeaderLogo != null || customHeaderLogo != '' || customHeaderLogo != undefined)) {
                    formData.append('header_custom_logo', customHeaderLogo);
                } else {
                    formData.append('header_custom_logo', previousCustomHeaderLogo);

                }

                if (footerOption == 2 && (customFooterLogo != null || customFooterLogo != '' || customFooterLogo != undefined)) {
                    formData.append('footer_custom_logo', customFooterLogo);
                } else {
                    formData.append('footer_custom_logo', previousCustomFooterLogo);

                }

                formData.append('header_logo_position', headerPosition);
                formData.append('footer_logo_position', footerPosition);
                formData.append('footer_text', footerText);
                formData.append('footer_text_color', footerTextColor);

                formData.append('header_logo_option', headerOption);
                formData.append('footer_logo_option', footerOption);

                formData.append('header_brand_logo', brandHeaderLogoOption);
                formData.append('footer_brand_logo', brandFooterLogoOption);

                formData.append('header_logo_size', customHeaderLogoSize);
                formData.append('footer_logo_size', customFooterLogoSize);

            }
        }





        updateScheduleData(formData).then((res) => {
            if (res.data.status == true) {
                setLoading(false);
                PNotify.success({
                    title: 'Success',
                    text: 'Schedule Updated Successfully',
                });
                setLatest(!latest);
                getscheduledata();
            }
        }).catch((err) => {
            setLoading(false);
            console.log(err)
        }).finally(() => {
            setLoading(false);
        })

    }

    const getBrandData = () => {

        //get smartlinkid from url
        const smartlinkid = window.location.href.split('/')[window.location.href.split('/').length - 1]

        const data = {
            "smartlink_id": smartlinkid
        }



        getBrandBySmartlinkIdApi(data).then((res) => {
            if (res.data.status == true) {
                setBrandData(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getBrandData()
    }, [])

    const handleSwitchChange = () => {
        // setShowText(!showText);
        setEndDateStatus(!endDateStatus);
    };

    const handleSchedulChange = (status) => {

        setSwitchStatus(status);


    }

    const horizontalLabels = {
        0: '0',
        10: '10',
        20: '20',
        30: '30',
        40: '40',
        50: '50',
        60: '60',
        70: '70',
        80: '80',
        90: '90',
        100: '100'
    }

    console.log("brandlogs", logo_light)
    return (
        <>


            <Modal
                centered
                show={show}
                onHide={handleClose}
                className="modal-center-show"
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="show-preview-header">
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-desktop');
                                    }
                                }
                            ><MdOutlineDesktopMac /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-tablet');
                                    }
                                }
                            ><FaTabletAlt /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-mobile');
                                    }
                                }
                            ><FaMobileAlt /></button>
                        </div>
                    </div>
                    <div className="show-preview-body">
                        <div className="show-preview-content-2">
                            <div className={`preview-box-background ${deviceView}`}>
                                <div class="oEUMCt"></div>
                                <div className="preview-box-password"
                                    style={{
                                        ...(backgroundType === 1 ? { backgroundColor: BackgroundColor } : {}),
                                        ...(backgroundType !== 1
                                            ? {
                                                backgroundImage: `linear-gradient(${gradientDirectionMap[colorPosition]}, ${BackgroundColor}, ${backgroundColor2})`,
                                            }
                                            : {}),
                                    }}
                                >
                                    <div className={`preview-web-header ${headerPosition}`}>
                                        <div className="brand-logo">
                                            <img src={header_brand_logo} alt="" style={{ height: customHeaderLogoSize + 'px' }} />
                                        </div>
                                    </div>
                                    <div className='page404-body preview-body-password'>
                                        <div className='page404-body-img page-img-404'>
                                            {lostUrl ? <img src={lostUrl} alt="404" className="img-fluid" /> : <img src={API_URL + 'redirection/lost.gif'} alt="404" className="img-fluid" />}
                                            {/* <img src={lostUrl} alt="404" className="img-fluid" /> */}
                                        </div>
                                    </div>
                                    <div className={`preview-footer ${footerPosition}`}>
                                        <div className="footer-brand-logo">
                                            <img src={footer_brand_logo} style={{ height: customFooterLogoSize + 'px' }} alt="" />
                                        </div>
                                        <div class="web-footer-text" style={{ color: footerTextColor }}>{footerText}</div>
                                    </div>
                                </div>
                                <div class="oEUMCterd"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>





            <div className="row">
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            {/* <Form.Label>if users visit the smart link out side of the schedule they will redirected to the 404 page</Form.Label> */}
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label={switchStatus ? "Schedule Enabled" : "Schedule Disabled"}
                                checked={switchStatus}
                                onChange={() => handleSchedulChange(!switchStatus)}
                            />

                        </Form.Group>
                    </div>
                </div>

                {switchStatus &&
                    <>
                        <div className="col-md-12">
                            <div className="fill-screen-btn form-btn-right mb-4">
                                <button type="button" className="btn full-screen-btn bg-green-dark" onClick={handleShow}><BsArrowsFullscreen /> Device Preview</button>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className="mb-2">
                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdOutlineEditCalendar /></div> <span className="tab-user-name" >Date</span></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="acc-content-body">
                                            <div className="content-body-form">
                                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control className="did-floating-input" type="date" value={start_time} onChange={
                                                        (e) => {
                                                            setStart_time(e.target.value)
                                                        }
                                                    } placeholder="" />
                                                    <Form.Label className="did-floating-label">Start From</Form.Label>
                                                </Form.Group>
                                            </div>
                                            <Form>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="End Date"
                                                    checked={endDateStatus}
                                                    onChange={handleSwitchChange}
                                                />
                                            </Form>
                                            {endDateStatus && <div className="col-md-12">
                                                <div className="content-body-form">
                                                    <Form.Group className="did-floating-label-content mt-3" controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control className="did-floating-input" type="date" value={end_time} onChange={
                                                            (e) => {
                                                                setEnd_time(e.target.value)
                                                            }
                                                        } placeholder="Start From" />
                                                        <Form.Label className="did-floating-label">End on</Form.Label>
                                                    </Form.Group>
                                                </div>
                                            </div>}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className="mb-2">
                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><FaHeading /></div> <span className="tab-user-name" >Header</span></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="acc-content-body">
                                            <div className="col-md-12">
                                                <div className="content-body-form">
                                                    <Form>
                                                        {['radio'].map((type) => (
                                                            <div key={`inline-${type}`} className="mb-3">
                                                                <Form.Check
                                                                    inline
                                                                    label="Brand Logo"
                                                                    name="group1"
                                                                    type={type}
                                                                    id={`inline-${type}-1`}
                                                                    checked={headerOption == 1}
                                                                    onChange={() => showHeaderOption(1)}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Custom Logo"
                                                                    name="group1"
                                                                    type={type}
                                                                    id={`inline-${type}-2`}
                                                                    onChange={() => showHeaderOption(2)}
                                                                    checked={headerOption == 2}
                                                                />

                                                            </div>
                                                        ))}
                                                    </Form>
                                                    {
                                                        headerOption == 1 ? (
                                                            <>

                                                                <Form>
                                                                    {['radio'].map((type) => (
                                                                        <div key={`inline-${type}`} className="mb-3">


                                                                            <Form.Check
                                                                                inline
                                                                                label="Logo light"
                                                                                name="group5"
                                                                                type={type}
                                                                                id={`inline-${type}-3`}
                                                                                checked={brandHeaderLogoOption == 1}
                                                                                onChange={() => hnadleHeaderLogoChange(1)}
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Logo dark"
                                                                                name="group5"
                                                                                type={type}
                                                                                id={`inline-${type}-4`}
                                                                                onChange={() => hnadleHeaderLogoChange(2)}
                                                                                checked={brandHeaderLogoOption == 2}
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Logo full"
                                                                                name="group5"
                                                                                type={type}
                                                                                id={`inline-${type}-5`}
                                                                                onChange={() => hnadleHeaderLogoChange(3)}
                                                                                checked={brandHeaderLogoOption == 3}
                                                                            />

                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                            </>
                                                        ) : <></>
                                                    }

                                                    {
                                                        headerOption == 2 ? (
                                                            <div className="image-uploder-preview mb-3" >
                                                                <input type="file"
                                                                    accept="image/*"
                                                                    onChange={handleSelectHeaderCustomLogo}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        ) : <></>
                                                    }


                                                    <div className="col-md-12">
                                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Select className="did-floating-select" value={headerPosition} onChange={handleHeaderPosition}>
                                                                <option value="left">Left</option>
                                                                <option value="center">Center</option>
                                                                <option value="right">Right</option>
                                                            </Form.Select>
                                                            <Form.Label className="did-floating-label">Logo Position</Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="content-body-form">
                                                            <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1">
                                                                <Form.Label>Logo Size</Form.Label>
                                                                <Slider className="Duration-RangeSlider"
                                                                    min={0}
                                                                    max={100}
                                                                    value={customHeaderLogoSize}
                                                                    // onChangeStart={handleChangeStart}
                                                                    onChange={handleHeaderHeightChange}
                                                                    // onChangeComplete={handleChangeComplete}
                                                                    labels={horizontalLabels}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6" className="mb-2">
                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><BsImage /></div> <span className="tab-user-name" >Content</span></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="acc-content-body">
                                            <div className="col-md-12">
                                                <div className="content-body-form">
                                                    <Form>
                                                        <div className="image-uploder-preview mb-3" >
                                                            <input type="file"
                                                                accept="image/*"
                                                                onChange={handleSelectLostImage}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </Form>




                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2" className="mb-2">
                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdColorLens /></div> <span className="tab-user-name" >Background Color</span></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="acc-content-body">
                                            <div className="col-md-12">
                                                <Form>
                                                    {['radio'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <div className="d-flex align-items-center flex-wrap">
                                                                    <div onClick={removeClass}>
                                                                        <Form.Check
                                                                            label="Single color"
                                                                            name="group20"
                                                                            type="radio"
                                                                            id="reverseradio935"
                                                                            className="me-4"
                                                                            checked={backgroundType == 1}
                                                                        />
                                                                    </div>
                                                                    <div onClick={addClass}>
                                                                        <Form.Check
                                                                            label="Color gradient"
                                                                            name="group20"
                                                                            type="radio"
                                                                            id="reverseradio936"
                                                                            checked={backgroundType == 2}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Form.Group>

                                                        </div>
                                                    ))}
                                                </Form>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                            <Form.Control className="did-floating-color" type="color" placeholder="theme colors" defaultValue={BackgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} />
                                                            <Form.Label className="did-floating-label">Page Color</Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                    {collapse && (
                                                        <>
                                                            <div className="col-md-6">
                                                                <Form.Group className="did-floating-label-content gradient" controlId="formBasicEmail">
                                                                    <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={backgroundColor2} onChange={(e) => setBackgroundColor2(e.target.value)} />
                                                                    <Form.Label className="did-floating-label">Gradient Color</Form.Label>
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                    <Form.Control className="did-floating-select" as="select" value={colorPosition} onChange={handleValueChange}>
                                                                        <option value="leftright">Left to Right</option>
                                                                        <option value="rightleft">Right to Left</option>
                                                                        <option value="topbottom">Top to Bottom</option>
                                                                        <option value="bottomtop">Bottom to Top</option>
                                                                    </Form.Control>
                                                                    <Form.Label className="did-floating-label">Gradient Color Position</Form.Label>
                                                                </Form.Group>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" className="">
                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><RiFontSansSerif /></div> <span className="tab-user-name" >Footer</span></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="acc-content-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="content-body-form">
                                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlText">
                                                            <Form.Control className="did-floating-input" type="text" value={footerText} onChange={(e) => setFooterText(e.target.value)} placeholder="" />
                                                            <Form.Label className="did-floating-label">Content Text</Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="content-body-form">
                                                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Select className="did-floating-select" value={footerPosition} onChange={handleFooterPosition}>
                                                                <option value="left">Left</option>
                                                                <option value="center">Center</option>
                                                                <option value="right">Right</option>
                                                            </Form.Select>
                                                            <Form.Label className="did-floating-label">Logo Position</Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="content-body-form">
                                                        <Form.Group className="did-floating-label-content gradient" controlId="formBasicEmail">
                                                            <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={footerTextColor} onChange={(e) => setFooterTextColor(e.target.value)} />
                                                            <Form.Label className="did-floating-label">Text Color</Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="content-body-form">
                                                        <Form>
                                                            {['radio'].map((type) => (
                                                                <div key={`inline-${type}`} className="mb-3">


                                                                    <Form.Check
                                                                        inline
                                                                        label="Brand Logo"
                                                                        name="group96"
                                                                        type={type}
                                                                        id={`inline-${type}-96`}
                                                                        checked={footerOption == 1}
                                                                        onChange={() => showFooterOption(1)}
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Custom Logo"
                                                                        name="group96"
                                                                        type={type}
                                                                        id={`inline-${type}-97`}
                                                                        onChange={() => showFooterOption(2)}
                                                                        checked={footerOption == 2}
                                                                    />
                                                                    {/* <Form.Check
                                                                                    inline
                                                                                    label="Custom Text"
                                                                                    name="group1"
                                                                                    type={type}
                                                                                    id={`inline-${type}-3`}
                                                                                    onChange={() => showHeaderOption(3)}
                                                                                    defaultChecked={headerOption === 3}
                                                                                /> */}


                                                                </div>
                                                            ))}
                                                        </Form>
                                                        {
                                                            footerOption == 1 ? (
                                                                <>

                                                                    <Form>
                                                                        {['radio'].map((type) => (
                                                                            <div key={`inline-${type}`} className="mb-3">


                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Logo light"
                                                                                    name="group112"
                                                                                    type={type}
                                                                                    id={`inline-${type}-112`}
                                                                                    checked={brandFooterLogoOption == 1}
                                                                                    onChange={() => hnadleFooterLogoChange(1)}
                                                                                />
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Logo dark"
                                                                                    name="group112"
                                                                                    type={type}
                                                                                    id={`inline-${type}-113`}
                                                                                    onChange={() => hnadleFooterLogoChange(2)}
                                                                                    checked={brandFooterLogoOption == 2}
                                                                                />
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Logo full"
                                                                                    name="group112"
                                                                                    type={type}
                                                                                    id={`inline-${type}-114`}
                                                                                    onChange={() => hnadleFooterLogoChange(3)}
                                                                                    checked={brandFooterLogoOption == 3}
                                                                                />

                                                                            </div>
                                                                        ))}
                                                                    </Form>
                                                                </>
                                                            ) : <></>
                                                        }



                                                        {
                                                            footerOption == 2 ? (
                                                                <div className="image-uploder-preview mb-3" >
                                                                    <input type="file"
                                                                        accept="image/*"
                                                                        onChange={handleSelectFooterCustomLogo}
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            ) : <></>
                                                        }
                                                        <div className="col-md-12">
                                                            <div className="content-body-form">
                                                                <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1">
                                                                    <Form.Label>Logo Size</Form.Label>
                                                                    <Slider className="Duration-RangeSlider"
                                                                        min={0}
                                                                        max={100}
                                                                        value={customFooterLogoSize}
                                                                        // onChangeStart={handleChangeStart}
                                                                        onChange={handleFooterHeightChange}
                                                                        // onChangeComplete={handleChangeComplete}
                                                                        labels={horizontalLabels}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className='page404-inner'>
                                <div className="preview-box-password"
                                    style={{
                                        ...(backgroundType === 1 ? { backgroundColor: BackgroundColor } : {}),
                                        ...(backgroundType !== 1
                                            ? {
                                                backgroundImage: `linear-gradient(${gradientDirectionMap[colorPosition]}, ${BackgroundColor}, ${backgroundColor2})`,
                                            }
                                            : {}),
                                    }}
                                >
                                    <div className="preview-header">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="three-dot">
                                                <span className="dot-red"><GrStatusGoodSmall /></span>
                                                <span className="dot-yellow"><GrStatusGoodSmall /></span>
                                                <span className="dot-green"><GrStatusGoodSmall /></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`preview-web-header ${headerPosition}`}>
                                        <div className="brand-logo">
                                            <img src={header_brand_logo} alt="" style={{ height: customHeaderLogoSize + 'px' }} />
                                        </div>
                                    </div>
                                    <div className='page404-body'>
                                        <div className='page404-body-img page-img-404'>
                                            {lostUrl ? <img src={lostUrl} alt="404" className="img-fluid" /> : <img src={API_URL + 'redirection/lost.gif'} alt="404" className="img-fluid" />}
                                            {/* <img src={lostUrl} alt="404" className="img-fluid" /> */}
                                        </div>
                                    </div>
                                    <div className={`preview-footer ${footerPosition}`}>
                                        <div className="footer-brand-logo">
                                            <img src={footer_brand_logo} style={{ height: customFooterLogoSize + 'px' }} alt="" />
                                        </div>
                                        <div class="web-footer-text" style={{ color: footerTextColor }}>{footerText}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                }
            </div>
            <div className="row mt-4">
                <div className="col-md-12 ">
                    <div className='form-btn'>
                        <div className='form-btn-right'>

                            {
                                loading ? (
                                    <SpinnerLoader />
                                ) : (
                                    <button type="button" onClick={updateSchedule} className="btn bg-green-dark action-btn btn-green">
                                        Update
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Schedule;