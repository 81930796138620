import React from "react";
import Spinner from 'react-bootstrap/Spinner';

function SpinnerLoader() {
    return (
        <button type="button" className="btn bg-green-dark action-btn btn-green btn-spinner">
            <Spinner animation="border" role="status" />
        </button>
    );
}

export default SpinnerLoader;