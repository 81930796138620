import { createContext, useState } from "react";

const SocialPostContext = createContext();

// create a context for the user provider


const SocialPostProvider = ({ children }) => {

    const [connectedChannel, setConnectedChannel] = useState({
        facebook: {
            status: false,
            accessToken: '',
            name: '',
            email: '',
            image: require('./assets/images/icons/fb.webp'),
            userId: '',
        },
        twitter: {
            status: false,
            image: require('./assets/images/icons/fb.webp'),
            url: 'https://www.facebook.com/',
        },
        instagram: {
            status: false,
            image: require('./assets/images/icons/fb.webp'),
            url: 'https://www.facebook.com/',
        },
        reddit: {
            status: false,
            image: require('./assets/images/icons/fb.webp'),
            url: 'https://www.facebook.com/',
        },
        pinterest: {
            status: false,
            image: require('./assets/images/icons/fb.webp'),
            url: 'https://www.facebook.com/',
        },
        tiktok: {
            status: false,
            image: require('./assets/images/icons/fb.webp'),
            url: 'https://www.facebook.com/',
        },
        linkedin: {
            status: false,
            image: require('./assets/images/icons/fb.webp'),
            url: 'https://www.facebook.com/',
        },
        youtube: {
            status: false,
            image: require('./assets/images/icons/fb.webp'),
            url: 'https://www.facebook.com/',
        }
    });

 

    return (
        <SocialPostContext.Provider value={{
            connectedChannel, setConnectedChannel
        }}>
            {children}
        </SocialPostContext.Provider>
    );
};

export { SocialPostContext, SocialPostProvider };