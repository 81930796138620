import React from "react";
import { useContext } from "react";
import { useBuilderContext } from "../../../builderContext";
import { API_URL } from "../../../BackendApi/Api/config";

function BuilderHeader(props) {

    const { myComponents } = useBuilderContext();

    console.log("myComponents foe header view", myComponents);

    const data = props.type == "auto" ? props.data : myComponents[0].properties;

    console.log("data for header view", data);


    console.log("kaha hai be background image", data.backgroundImage);



    return (
        <>
            { data.headerFormat == "H1" ?
                (
                    <div className="builder_content_header horizontal-header" style={{ display: 'block' }}>
                        <div className="d-flex gap-3 mb-3" >
                            <img src={props.type == "auto" ? data.profileImage :API_URL + "flowpage/" + data.profileImage} alt="" className="user-set-profile" />
                            <div>
                                <h1 className="builder_content_header_title tit_clor" color="#232323">{ data.title}</h1>
                                <p className="builder_content_header_subtitle des_class" color="#232323">  { data.description}</p>
                            </div>
                        </div>
                    </div>
                ) :  data.headerFormat == "H2" ?
                    (
                        <div className="builder_content_header vertical-header" style={{ display: 'block' }}>
                            <div className="builder_content_header_next mb-3">
                                <img src={props.type == "auto" ? data.profileImage : API_URL + "flowpage/" + data.profileImage} alt={data.altText} className="user-set-profile" />
                                <h1 className="builder_content_header_title tit_clor" color="#232323">{  data.title}</h1>
                                <p className="builder_content_header_subtitle des_class" color="#232323">  {  data.description}</p>
                            </div>
                        </div>
                    ) : data.headerFormat == "H3" ?
                        (
                            <div style={{ display: 'block' }}>
                                <div className="builder_content_header vertical-header addBgclass cGOoFD" style={{ backgroundImage: `url( ${props.type == "auto" ? data.backgroundImage : API_URL + "flowpage/" + data.backgroundImage})` }}>
                                    <div className="builder_content_header_next mb-3">
                                        <img src={props.type == "auto" ? data.profileImage : API_URL + "flowpage/" + data.profileImage} alt={data.altText} className="user-set-profile" />
                                        <h1 className="builder_content_header_title tit_clor" color="#232323">{ data.title}</h1>
                                        <p className="builder_content_header_subtitle des_class" color="#232323">  { data.description}</p>
                                    </div>
                                </div>
                            </div>
                        ) : data.headerFormat == "H4" ?
                            (

                                <div className="builder_content_header" style={{ display: 'block' }}>
                                    <img src={props.type == "auto" ? data.backgroundImage :  API_URL + "flowpage/" + data.backgroundImage} alt="" className="header-bg-img" />
                                    <div className="builder_content_header_next mb-3">
                                        <img src={props.type == "auto" ? data.profileImage : API_URL + "flowpage/" + data.profileImage} alt={data.altText} className="user-set-profile Qwedr" />
                                        <h1 className="builder_content_header_title tit_clor" color="#232323">{ data.title}</h1>
                                        <p className="builder_content_header_subtitle des_class" color="#232323"> { data.description}</p>
                                    </div>
                                </div>
                            ) : (
                                <div className="builder_content_header" style={{ display: 'block' }}>
                                    <img src={props.type == "auto" ? data.backgroundImage : API_URL + "flowpage/" + data.backgroundImage} alt="" className="header-bg-img" />
                                    <div className="builder_content_header_next mb-3">
                                        <img src={props.type == "auto" ? data.profileImage : API_URL + "flowpage/" + data.profileImage} alt={data.altText} className="user-set-profile Qwedr" />
                                        <h1 className="builder_content_header_title tit_clor" color="#232323">{ data.title}</h1>
                                        <p className="builder_content_header_subtitle des_class" color="#232323"> { data.description}</p>
                                    </div>
                                </div>
                            )}
        </>
    );
}

export default BuilderHeader;