import React from "react";
import { Chart } from "react-google-charts";

export const options = {
  isStacked: true,
  height: 300,
  legend: { position: "top", maxLines: 3 },
  vAxis: { minValue: 0 },
};

export function AreaChart({ smartlinkcountery }) {
  // Define the column definitions
  if (!Array.isArray(smartlinkcountery)) {
    // Handle the case where smartlinkcountery is not iterable
    console.error("smartlinkcountery is not iterable. Check the data source.");
    return null; // Or any other suitable action based on your application's logic
  }

  // Define the column definitions
  const columns = [
    { type: "string", label: "Days", totalVisites: smartlinkcountery.map((x) => x.totalVisits) },
    { type: "number", label: "Visits" },
    { type: "number", label: "Scans" },
    { type: "number", label: "Clicks" },
  ];

  // Combine the columns and data into a DataTable
  const dataTable = [columns, ...smartlinkcountery];
console.log("smartlinkcountery",smartlinkcountery)
  return (
    <Chart
      chartType="AreaChart"
      width="100%"
      height="400px"
      data={dataTable} // Use the DataTable with columns and data
      options={options}
    />
  );
}
