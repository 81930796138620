import React, { useEffect, useContext, useState } from "react";
import Form from 'react-bootstrap/Form';
import { SmartLinkContext } from "../../Components/CreateSmartlink/SmartLinkcontext";
import 'react-notifications/lib/notifications.css';
import { updatesmartlinkApi } from "../../BackendApi/Api/smartsLinkApi";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { sessionContext } from "../../sessionContext";
import { getMetaApi } from "../../BackendApi/Api/metaApi";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { API_URL } from "../../BackendApi/Api/config";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { Spinner } from "react-bootstrap";
import { SubscribsationBackhalfValid } from "../../Helpers/SubscribsationBackhalfValid";
import { UserContext } from "../../UserContext";


function SmartLinkEditing() {

    const { sessionName, setSessionName, sessionDescription, setSessionDescription, SessionData, setSessionData } = useContext(sessionContext);


    const [smartlinkeditdata, setSmartlinkEditData, IosTargeting, setIosTargeting,latest, setLatest, AndroidTargeting, setAndroidTargeting, updatedApi, setUpdatedApi,] = useContext(SmartLinkContext);

    const { subscriptionStatus,subscriptionDetails } = useContext(UserContext);

    console.log("smartlinkeditdata-------------", smartlinkeditdata);

    const [longUrl, setLongUrl] = React.useState('')
    const [shorturl, setShortUrl] = React.useState('')
    const [title, setTitle] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [favicon, setFavicon] = useState('');
    const [urlType, setUrlType] = React.useState('');
    const [campName, setCampName] = React.useState('');
    const [campDescription, setCampDescription] = React.useState('');
    const [FullShortUrl, setFullShortUrl] = React.useState('');
    const [backhalf, setBackhalf] = useState('');
    const [old_backhalf, setOldBackhalf] = useState('');

    const [loading, setLoading] = useState(false);
    const [openGraphImage, setOpenGraphImage] = useState('');

    const [styling, setStyling] = useState('');

    const [socialNetworkImagePreview, setSocialNetworkImagePreview] = useState(null);

    const [imageUrls, setImageUrls] = useState({
        openGraphImage: '',
        favicon: '',

    });






    const handleSelectFavicon = (e) => {
        setFavicon(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    favicon: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    }




    const handleSelectOG = (e) => {
        setOpenGraphImage(e.target.files[0]);
        const file = e.target.files[0];

        // Check if a file was selected
        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    openGraphImage: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };



    const validateUrl = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    };

    const addClass = () => {
        document.querySelector(".url-type-deisgn1").classList.add("active");
        document.querySelector(".url-type-deisgn").classList.remove("active");
        setUrlType("dynamic");
    };

    const removeClass = () => {
        document.querySelector(".url-type-deisgn1").classList.remove("active");
        document.querySelector(".url-type-deisgn").classList.add("active");
        setUrlType("simple");
    };

    console.log("urlType", urlType);

    useEffect(() => {
        setLongUrl(smartlinkeditdata?.long_url);
        // setShortUrl(smartlinkeditdata?.backhalf);
        setTitle(smartlinkeditdata?.title);
        setDescription(smartlinkeditdata?.description);
        setUrlType(smartlinkeditdata?.url_type);
        // setFavicon(smartlinkeditdata?.favicon);

        setImageUrls((prevImageUrls) => ({
            ...prevImageUrls,
            openGraphImage: smartlinkeditdata?.favicon
        }))
        setCampName(smartlinkeditdata?.campaign_name);
        setCampDescription(smartlinkeditdata?.campaign_description);
        setFullShortUrl(smartlinkeditdata?.full_short_url);
        setBackhalf(smartlinkeditdata?.backhalf);
        setOldBackhalf(smartlinkeditdata?.backhalf);
        setStyling(smartlinkeditdata?.styling);
        // setResizedImage(smartlinkeditdata?.favicon)

        // if (smartlinkeditdata?.favicon) {
        //     // Define a regular expression pattern to check if it's a valid URL
        //     const urlPattern = /^(http(s)?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

        //     // Check if the favicon is a valid URL using the pattern
        //     if (urlPattern.test(smartlinkeditdata.favicon)) {
        //         setImageUrls({
        //             openGraphImage: API_URL + 'ogimages/' + smartlinkeditdata?.og_image,
        //             favicon: smartlinkeditdata.favicon
        //         });
        //     } else {
        //         // Handle the case where the favicon is not a valid URL
        //         console.error('Invalid favicon URL:', smartlinkeditdata.favicon);
        //     }
        // }


        setImageUrls({
            openGraphImage: API_URL + 'ogimages/' + smartlinkeditdata?.og_image,
            favicon: smartlinkeditdata?.favicon
        })


    }, [smartlinkeditdata]);


    function base64ToBinary(base64String) {
        const binaryString = atob(base64String);
        const byteArray = new Uint8Array(binaryString.length);

        for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
        }

        return byteArray;
    }


    const resizeImage = (file) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const image = new Image();

            image.onload = () => {
                canvas.width = 32;
                canvas.height = 32;
                ctx.drawImage(image, 0, 0, 32, 32);
                canvas.toBlob((blob) => {
                    resolve(new File([blob], 'image.png'));
                }, 'image/png');
            };

            image.src = URL.createObjectURL(file);
        });
    };

    const updatesmartlinkdata = async () => {
        setLoading(true);
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const style = JSON.parse(styling);

        style.data = FullShortUrl + "?method=scan"

        try {

        if (favicon) {
            var resizedImage = await resizeImage(favicon);
        }
        const validationResult = SubscribsationBackhalfValid(backhalf,subscriptionDetails);

        if (!validationResult.isValid) {
            PNotify.error({
                title: 'Error',
                text: validationResult.message,
            });
            setLoading(false);
            return false;
        } 
        
        const formdata = new FormData();
        formdata.append("id", smartlinkeditdata._id);
        formdata.append("long_url", longUrl);
        formdata.append("backhalf", backhalf);
        formdata.append("old_backhalf", old_backhalf);
        formdata.append("title", title);
        formdata.append("url_type", urlType);
        formdata.append("description", description); 
        formdata.append("updator_image", user.profile_image);
        formdata.append("updator_name", user.fname + " " + user.lname);


        // if(resizedImage){
        //     const base64StringWithoutPrefix = resizedImage.substring('data:image/png;base64,'.length);
        // const decodedString = atob(base64StringWithoutPrefix);
        // const blob = new Blob([decodedString], { type: 'image/png' });

        // formdata.append("favicon", blob);

        // }

        formdata.append("favicon", resizedImage);

        formdata.append("previous_favicon", imageUrls.favicon);

        formdata.append("full_short_url", FullShortUrl);
        formdata.append("styling", JSON.stringify(style));
        formdata.append("updated_on", new Date());

        if (openGraphImage) {
            formdata.append("og_image", openGraphImage);
        }

        formdata.append("campaignData", JSON.stringify({
            campaign_name: campName,
            campaign_description: campDescription,
            campaign_id: smartlinkeditdata.campaign_id
        }))

            
            setLoading(true);
            updatesmartlinkApi(formdata).then((res) => {
                setLoading(true)
                if (res.data.status === true) {
                    setLoading(true)

                    PNotify.success({
                        title: "success",
                        text: "FlexLink Updated Successfully",
                    });
                    const smartlinkdata = res.data.data;
                    setSmartlinkEditData(smartlinkdata);
                    setLatest(!latest);
                    setLoading(false)
                }else{
                    PNotify.error({
                        title: 'Error',
                        text: res.data.message,
                    })
                }
            })
        }catch (err) {
            alert("err",);
        }finally {
            setLoading(false)
        }
        
    }
    const [loadingMeta, setLodingMeta] = useState(false)
    const getMetaData = () => {
        const data = {
            url: longUrl
        }

        if (!validateUrl(longUrl)) {
            // URL is invalid, handle error
            PNotify.error({
                title: 'Error',
                text: 'Please enter a valid URL',
            });
            setLodingMeta(false);
        }
        else {
            setLodingMeta(true);
            getMetaApi(data).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === true) {
                        const title = res.data.data.title;
                        const description = res.data.data.description;
                        const icon = res.data.data.icon;

                        setTitle(title);
                        setDescription(description);
                        setImageUrls((prevImageUrls) => ({
                            ...prevImageUrls,
                            favicon: icon
                        }))
                        setLodingMeta(false);
                    }
                    else {
                        PNotify.error({
                            title: 'Error',
                            text: 'Data not found',
                        });
                        setLodingMeta(false);
                    }

                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                    });
                    setLodingMeta(false);
                }
            }).catch((err) => {
                console.log("an error occouired", err.message);
                setLodingMeta(false);
            });
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="did-floating-label-content" controlId="formBasicText">
                            <div className="d-flex">
                                <Form.Control className="did-floating-input" type="text" value={longUrl} onChange={
                                    (e) => {
                                        setLongUrl(e.target.value);
                                    }
                                } placeholder="" />
                                <Form.Label className="did-floating-label">Destination Url</Form.Label>

                            </div>
                        </Form.Group>
                    </div>
                </div>
                <Form.Group className="did-floating-label-content" controlId="formBasicText">
                    <Form.Control
                        className="did-floating-input"
                        style={{ color: 'blue' }}
                        type="text"
                        value={FullShortUrl}
                        onChange={(e) => {
                            setFullShortUrl(e.target.value);

                            const segments = e.target.value.split('/');

                            const backhalf = segments[segments.length - 1];

                            setBackhalf(backhalf);
                        }}
                    />
                    <Form.Label className="did-floating-label">Update Vanity Tag</Form.Label>
                </Form.Group>


                {/* <hr></hr> */}

                <div className="label-title mb-3">
                    <h5>Social Preview</h5>
                </div>

                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                            <div className="d-flex">
                                <Form.Control className="did-floating-input" type="text" value={title} onChange={(e) => { setSmartlinkEditData({ ...smartlinkeditdata, title: e.target.value }); }} placeholder="" />
                                <Form.Label className="did-floating-label">Title</Form.Label>
                                <button className="btn bg-green-dark form-inline-btn2" onClick={getMetaData} >{loadingMeta ? (<Spinner  />) : ("Refresh Meta")}</button>
                            </div>
                        </Form.Group>
                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea2">
                            <Form.Control className="did-floating-textarea" as="textarea" rows={3} value={description} onChange={(e) => { setSmartlinkEditData({ ...smartlinkeditdata, description: e.target.value }); }} placeholder="" />
                            <Form.Label className="did-floating-label">Description</Form.Label>
                        </Form.Group>
                        {/* <Form.Group className="mb-4 image-preview-edit2" controlId="formBasicEmail">
                            <Form.Label>Favicon</Form.Label>
                            <img src={favicon} alt="" className="img-fluid" />
                        </Form.Group> */}

                    </div>

                    <div className="row">
                        <div className='col-md-4'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control
                                    className="did-floating-file"
                                    type="file"
                                    placeholder="Company Logo"
                                    onChange={handleSelectFavicon}
                                />
                                <Form.Label className="did-floating-label">Favicon</Form.Label>
                                <img src={imageUrls.favicon} alt="" className="img-fluid" style={{ height: '100px', width: '100px' }} />
                            </Form.Group>
                        </div>
                        <div className='col-md-4'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control className="did-floating-file" type="file" placeholder="Company Logo" onChange={handleSelectOG} />
                                <Form.Label className="did-floating-label">Social Network Image (Open Graph)</Form.Label>
                                {<img src={imageUrls.openGraphImage} className="preview-logo-image" />}
                            </Form.Group>
                        </div>
                    </div>

                </div>

            </div>
            <div className="row mt-4">
                <div className="col-md-12 ">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            {
                                // loading ? (
                                //     <SpinnerLoader />
                                // ) : (
                                    <button 
                                    type="button" 
                                    onClick={updatesmartlinkdata} 
                                    className="btn bg-green-dark action-btn btn-green"
                                    disabled={loading} // Disable the button while loading
                                >
                                    {loading ? <Spinner /> : "Update FlexLink"}
                                </button>
                                
                                // )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    )
}

export default SmartLinkEditing;