import React from "react";

function Iframe(props) {
    console.log("PROP",props.data)
    return (
        <>
            <div className="youtube_video_box mb-3">
                <div dangerouslySetInnerHTML={{ __html: props.data.Link }}>
                </div>            </div>
        </>
    );
}


export default Iframe;