import React, { useState, useEffect, useContext } from 'react';
import Profile from "../../assets/images/icons/coca.png";
import { TbEdit, TbTrash } from "react-icons/tb";
import { RiAddFill } from "react-icons/ri";
import { BsPalette } from "react-icons/bs";
import { Button } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import { Modal } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs';
import BrandAssets from "../Createbrand/BrandAssets";
import Socials from "../Createbrand/Socials";
import VanityTags from "../Createbrand/VanityTags";
import EditBrandAssets from "../Editbrand/BrandAssets";
import EditSocials from "../Editbrand/Socials";
import EditVanityTags from "../Editbrand/VanityTags";
import { getBrandApi, createBrandApi, updateBrandApi, cohereBrandingDeatails } from '../../BackendApi/Api/brandApi'
import { getVanityApi, addVanityApi } from '../../BackendApi/Api/vanityApi'
import { BrandContext } from '../../brandContext';
import { VanityContext } from '../../VanityContext';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../Loader/Loader';
import qrOptiions from "../Login/defaultQr.json";
import { API_URL } from "../../BackendApi/Api/config";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import Loader2 from '../Loader/Loader2';
import { UserContext } from "../../UserContext";
import { useHistory } from "react-router-dom";
import { brandSelectionContext } from '../../contexts/context/brandSelectionContext';

function TabData6({ load }) {

    const history = useHistory();

    const userdata = localStorage.getItem("user");
    const user = JSON.parse(userdata);

    const role = user.role;


    const { brandDataSaved, setBrandDataSaved, company_name, setCompanyName, address, description, setDescription, brandEmail, setBrandEmail, setBrand_type, brand_Type,
        brandPhone, setBrandPhone, logo_light, setLogoLight, logo_dark, setLogoDark,
        logo_full, setLogoFull, primary_color, setPrimaryColor, secondary_color, setSecondaryColor, brand_id, setBrandId,
        facebookUrl, setFacebookUrl, twitterUrl, setTwitterUrl, instagramUrl, setInstagramUrl, websiteUrl, setWebsiteUrl,
        pinterestUrl, setPinterestUrl, tiktokUrl, setTiktokUrl, otherUrl, setOtherUrl, brandEditStatus, setBrandEditStatus,
        logo_symbolic, setLogoSymbolic, additional_color_1, setAdditionalColor1, additional_color_2, setAdditionalColor2 } = useContext(BrandContext);

    const { vanityName, setVanityName, vanityLogo, setVanityLogo, vantyCode, setVanityCode, vanitySavedStatus, setVanitySavedStatus, vanityData, setVanityData, getVanityData } = useContext(VanityContext);
    console.log(brandPhone)


    const { subscriptionStatus } = useContext(UserContext);

    const [show03, setShow03] = useState(false);

    const handleClose03 = () => setShow03(false);
    const handleShow03 = () => setShow03(true);

    const [show04, setShow04] = useState(false);

    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);

    const [show05, setShow05] = useState(false);

    const handleClose05 = () => setShow05(false);
    const handleShow05 = () => setShow05(true);

    const [show06, setShow06] = useState(false);

    const handleClose06 = () => setShow06(false);
    const handleShow06 = () => setShow06(true);

    const [show07, setShow07] = useState(false);

    const handleClose07 = () => setShow07(false);
    const handleShow07 = () => setShow07(true);

    const [brandData, setBrandData] = useState([]);

    const [show08, setShow08] = useState(false);

    const handleClose08 = () => setShow08(false);
    const handleShow08 = () => setShow08(true);
    const { sidebarBrands } = useContext(brandSelectionContext);
    const [brandIdForVanity, setBrandIdForVanity] = useState('');
    const [loading, setLoading] = useState(false);
    const addVanityLogo = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setVanityLogo(reader.result);
        };
    };

    const validateUrl = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    };

    const validateEmail = (email) => {
        const pattern = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');
        return !!pattern.test(email);
    };

    const loaderStatus = (status) => {
        load(status);
    }


    const createBrand = async (e) => {
        e.preventDefault();

        if ((subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
            || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && brandData.length >= 1) {
            PNotify.error({
                title: 'Error',
                text: 'Please upgrade your plan to continue',
            });
            return false;
        }
        else if (company_name === '') {
            PNotify.error({
                title: "Error",
                text: "Please Enter Company Name",
            });
            return false;
        }
        else if (description === '') {
            PNotify.error({
                title: "Error",
                text: "Please Enter Description",
            });
            return false;
        }
        else if (brandEmail === '' || !validateEmail(brandEmail)) {
            PNotify.error({
                title: "Error",
                text: "Please Enter valid Email",
            });
            return false;
        }
        else if (brandPhone === '') {
            PNotify.error({
                title: "Error",
                text: "Please Enter Phone Number",
            });
            return false;
        }
        else if (logo_light === '') {
            PNotify.error({
                title: "Error",
                text: "Please Upload Logo Light",
            });
            return false;
        }
        else if (logo_dark === '') {
            PNotify.error({
                title: "Error",
                text: "Please Upload Logo Dark",
            });
            return false;
        }
        else if (logo_full === '') {
            PNotify.error({
                title: "Error",
                text: "Please Upload Logo Full",
            });
            return false;
        }
        else if (logo_symbolic === '') {
            PNotify.error({
                title: "Error",
                text: "Please Upload Logo Symbolic",
            });
            return false;
        }
        else if (primary_color === '') {
            PNotify.error({
                title: "Error",
                text: "Please Enter Primary Color",
            });
            return false;
        }
        else if (secondary_color === '') {
            PNotify.error({
                title: "Error",
                text: "Please Enter Secondary Color",
            });
            return false;
        }
        else if (additional_color_1 === '') {
            PNotify.error({
                title: "Error",
                text: "Please Enter Additional Color 1",
            });
            return false;
        }
        else if (additional_color_2 === '') {
            PNotify.error({
                title: "Error",
                text: "Please Enter Additional Color 2",
            });
            return false;
        }
        else {

            const userdata = localStorage.getItem("user");
            const user = JSON.parse(userdata);
            const owner_id = user.owner_id;
            const created_by = user._id;

            const formData = new FormData();
            formData.append('company_name', company_name);
            formData.append('description', description);
            formData.append('company_name', brand_Type);
            formData.append('email', brandEmail);
            formData.append('phone', brandPhone);
            formData.append('logo_light', logo_light);
            formData.append('logo_dark', logo_dark);
            formData.append('logo_full', logo_full);
            formData.append('logo_symbolic', logo_symbolic);
            formData.append('primary_color', primary_color);
            formData.append('secondary_color', secondary_color);
            formData.append('additional_color_1', additional_color_1);
            formData.append('additional_color_2', additional_color_2);
            formData.append('facebook_url', facebookUrl);
            formData.append('twitter_url', twitterUrl);
            formData.append('instagram_url', instagramUrl);
            formData.append('website_url', websiteUrl);
            formData.append('pinterest_url', pinterestUrl);
            formData.append('tiktok_url', tiktokUrl);
            formData.append('other_url', otherUrl);
            formData.append('owner_id', owner_id);
            formData.append('created_by', created_by);
            formData.append('qr_options', JSON.stringify(qrOptiions));

            loaderStatus(true);

            createBrandApi(formData).then(response => {
                if (response.code === 200) {
                    setBrandDataSaved(!brandDataSaved);
                    loaderStatus(false);
                    PNotify.success({
                        title: "Success",
                        text: "Brand data Added Successfully",
                    });
                }
                else {
                    loaderStatus(false);
                    PNotify.error({
                        title: "Error",
                        text: response.message,
                    });
                }
            }
            )
            handleClose03();
        }
    }

    //add vanity    
    const addVanity = async (e) => {
        e.preventDefault();

        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const owner_id = user.owner_id;
        const created_by = user._id;


        const formData = {
            vanity_name: vanityName,
            logo: vanityLogo,
            vanity_code: vantyCode,
            brand_id: brand_id,
            owner_id: owner_id,
            created_by: created_by
        }

        loaderStatus(true);

        addVanityApi(formData).then(response => {
            if (response.code === 200) {
                setVanitySavedStatus(!vanitySavedStatus);
                loaderStatus(false);
                PNotify.success({
                    title: "Success",
                    text: response.data.message,
                });
            }
            else {
                loaderStatus(false);
                PNotify.error({
                    title: "Error",
                    text: response.data.message,
                });
            }
        }
        )

    }



    //get brand data


    const getBrandData = () => {
        loaderStatus(true);
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const owner_id = user.owner_id;
        const role = user.role;
        const brands = user.brands;

        const data = {
            owner_id: owner_id,
            role: role,
            brands: brands
        }

        getBrandApi(data).then((res) => {

            if (res.data.status === true) {
                if (sidebarBrands.selected === "all") {
                    setBrandData(res.data.data);
                    loaderStatus(false);
                } else {
                    let daa = res.data.data.filter((brand) => brand._id === sidebarBrands.selected);
                    setBrandData(daa);
                    loaderStatus(false);
                }

            }
            else {
                loaderStatus(false);
                history.push('/createbrand');
            }

        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            loaderStatus(false);
        }
        )
    }

    const updateBrand = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('company_name', company_name);
        formData.append('description', description);
        formData.append('address', address);
        formData.append('email', brandEmail);
        formData.append('phone', brandPhone);
        formData.append('logo_light', logo_light);
        formData.append('logo_dark', logo_dark);
        formData.append('logo_full', logo_full);
        formData.append('primary_color', primary_color);
        formData.append('secondary_color', secondary_color);
        formData.append('facebook_url', facebookUrl);
        formData.append('twitter_url', twitterUrl);
        formData.append('instagram_url', instagramUrl);
        formData.append('website_url', websiteUrl);
        formData.append('pinterest_url', pinterestUrl);
        formData.append('tiktok_url', tiktokUrl);
        formData.append('other_url', otherUrl);
        formData.append('id', brand_id);
        formData.append('updator_image', user.profile_image);
        formData.append('updator_name', user.fname + "" + user.lname);


        loaderStatus(true);

        updateBrandApi(formData).then((response) => {
            if (response.code === 200) {
                handleClose07();
                setBrandEditStatus(!brandEditStatus);
                loaderStatus(false);
                PNotify.success({
                    title: "Success",
                    text: "Brand Updated Successfully",
                });
            }
            else {
                handleClose07();
                loaderStatus(false);
                PNotify.error({
                    title: "Error",
                    text: response.data.message,
                });
            }
        }).catch((error) => {
            loaderStatus(false);
            PNotify.notice({
                title: "Notice",
                text: error,
            });
            PNotify.notice({
                title: "Notice",
                text: error,
            });
        })
    }

    useEffect(() => {
        getBrandData();
        getVanityData();
    }, [brandDataSaved, brandEditStatus, vanitySavedStatus])

    const getCompanyDetails = () => {
        setLoading(true);
        if (company_name == "") {
            PNotify.error({
                title: "Error",
                text: "Please enter company name",
            })
            setLoading(false);
        }
        const data = {
            message: company_name
        };

        cohereBrandingDeatails(data).then((response) => {
            console.log(" cohereBrandingDeatails", response.data.data);
            if (response.data.status == true) {
                let result = response.data.data
                const socials = result?.social_media_handles
                setDescription(result?.description);
                setBrand_type(result?.company_type);
                setBrandEmail(result?.email);
                setBrandPhone(result?.contact_number);
                setFacebookUrl(socials?.Facebook);
                setTwitterUrl(socials?.Twitter);
                setInstagramUrl(socials?.Instagram);
                setWebsiteUrl(result?.url);
                setOtherUrl(socials?.LinkedIn);
                setTiktokUrl(socials?.TikTok);

                console.log("comapany nameeeeeedf", result?.company_name);

                setCompanyName(result?.company_name);
                setPinterestUrl(socials?.Pinterest);
                setLoading(false);

            } else {
                PNotify.error({
                    title: "Error",
                    text: response.data.message,
                });
                setLoading(false);
            }

        }).catch((error) => {
            PNotify.error({
                title: "Error",
                text: error,
            });
            setLoading(false);
        })
    }

    return (
        <div>
            {/* {loading ? <Loader2 /> : null} */}
            <>
                {/* add vanity modal start here */}
                <Modal show={show06} onHide={handleClose06} className="Sidebar-Modal-end createbrand-width">
                    <Modal.Header closeButton>
                        <Modal.Title>Add Vanity</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <div className="row">
                            {/* <div className="col-md-12">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>LOGO/ICON</Form.Label>
                                <Form.Control type="file" placeholder="Company Logo" onChange={addVanityLogo} />
                            </Form.Group>
                        </div> */}
                            <div className="col-md-12">
                                <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                    <Form.Control className="did-floating-input" type="text" placeholder="Name" value={vanityName} onChange={(e) => setVanityName(e.target.value)} />
                                    <Form.Label className="did-floating-label">NAME</Form.Label>
                                </Form.Group>
                            </div>
                            {/* <div className="col-md-12">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Select Secondary Color</Form.Label>
                                <Form.Control type="color" placeholder="theme colors" defaultValue="#B9B3F8" value={vantyCode} onChange={(e) => { setVanityCode(e.target.value) }} />
                            </Form.Group>
                        </div> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="Active-bttn btn" type='submit' onClick={addVanity}> Save </button>
                        <button className="Cancel-bttn btn" onClick={handleClose06}> Cancel </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={show08} onHide={handleClose08} centered className='Confirm-Delete'>
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><TbTrash />Subscription not active !</Modal.Title>
                    </Modal.Header>
                    {role == "0" ? (<>
                        <Modal.Body>Please Upgrade to  an active plan to continue using this feature</Modal.Body>
                        <Modal.Footer>
                            <Button className='delete-Cancel-bttn' onClick={() => { history.push("/setting/price") }}>
                                Upgrade
                            </Button>
                        </Modal.Footer>
                    </>) : (
                        <Modal.Body>Please contact ypur admin and ask them to upgrade to  an active plan.</Modal.Body>
                    )}
                </Modal>

                {/* create brand modal start here */}
                <Modal show={show03} onHide={handleClose03} className="Sidebar-Modal-end createbrand-width auto_brand_modal">


                    {/* <Modal.Header closeButton>
                        <Modal.Title>Create Brand</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0" className='mb-3'>
                                <Accordion.Header>Details</Accordion.Header>
                                <Accordion.Body>
                                    <BrandAssets getCompanyDetails={getCompanyDetails} loading={loading} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className='mb-3'>
                                <Accordion.Header>Socials</Accordion.Header>
                                <Accordion.Body>
                                    <Socials />
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <button disabled={(subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                            || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && brandData.length >= 1 ? true : false} className="Active-bttn btn" type='submit' onClick={createBrand}> Save </button>
                        <button className="Cancel-bttn btn" onClick={handleClose03}> Cancel </button>
                    </Modal.Footer> */}
                </Modal>
                {/* modal End here */}

                {/* Edit Brand modal start here */}
                <Modal show={show07} onHide={handleClose07} className="Sidebar-Modal-end createbrand-width">
                    <Modal.Header closeButton>
                        <Modal.Title><TbEdit />Edit Brand</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>

                        <Accordion defaultActiveKey="4">
                            <Accordion.Item eventKey="4" className='mb-3'>
                                <Accordion.Header>BrandAssets</Accordion.Header>
                                <Accordion.Body>
                                    {/* <BrandAssets /> */}
                                    <EditBrandAssets />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5" className='mb-3'>
                                <Accordion.Header>Socials</Accordion.Header>
                                <Accordion.Body>
                                    <EditSocials />
                                    {/* <Socials /> */}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>


                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="Active-bttn btn" onClick={updateBrand}> Save </button>
                        <button className="Cancel-bttn btn" onClick={handleClose07}> Cancel </button>
                    </Modal.Footer>
                </Modal>
                {/* modal End here */}

                {/* Delete Modal Start From Here */}
                <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><TbTrash /> Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                    <Modal.Footer>
                        <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                            Cancel
                        </Button>
                        <Button className='confirm-Delete-bttn' onClick={handleClose04}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>



                <div className="table-card-header add-btn d-flex">
                    <h5 className="main-card-title"><BsPalette className="header-icon" /> CUSTOM BRANDING</h5>
                    <div className="card-content-header-right">
                        <Button className="btn btn-sm float-right add-user-btn"
                            onClick={
                                () => {
                                    if ((subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                                        || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") && brandData.length >= 1) {
                                        // handleShow08();
                                        history.push("/createbrand");
                                    }
                                    else {
                                        // handleShow03();
                                        history.push("/createbrand");
                                    }
                                }

                            } > <BsPalette /> Add Brand</Button>
                    </div>
                </div>
                <div className="setting-tab-content-body ps-0 mobile-view-hide">
                    <div className="brand-list">
                        <ul>
                            {brandData.map((data, index) => {

                                const updatedOnTime = new Date(data.updated_on).toLocaleTimeString("en-US", {
                                    minute: "2-digit",
                                    hour: "2-digit",
                                });
                                const createdOnTime = new Date(data.created_on).toLocaleTimeString("en-US", {
                                    minute: "2-digit",
                                    hour: "2-digit",
                                });
                                console.log("usss", data.user);
                                return (
                                    <li>
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <div className="Domain-table-view brand_table_view">
                                                <div className="d-flex align-items-center">
                                                    <div className="brand-table-image">
                                                        <img src={data.logo_light?.startsWith('http') || data.logo_light?.startsWith('https') ? data.logo_light : API_URL + `brands/` + data.logo_light} alt="" className="img-fluid" />
                                                        {/* API_URL + `brands/` + data.logo_light */}
                                                    </div>
                                                    <div className="Domain-table-content">
                                                        <h4>{data.company_name}</h4>
                                                        <p>{data.description}</p>




                                                        <div className="brand-Vanity-Tag d-flex align-items-center">
                                                            <h3>Vanity Tags:</h3>
                                                            <div className="vanity-tag d-flex">
                                                                {vanityData
                                                                    .filter(vdata => vdata.brand_id === data._id)
                                                                    .map(vdata => <h4>{vdata.vanity_name}</h4>)}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Domain-table-action">


                                                <div key={index}>
                                                    <div className="details-box-smartlink">
                                                        <div className="d-flex align-items-center">
                                                            <div className="profile-user-image">
                                                                {(data?.creator_name || data?.user) && (
                                                                    <img
                                                                        src={
                                                                            API_URL + `profile/` + (data?.creator_image || data?.user?.profile_image)
                                                                        }
                                                                        alt="Creator Image"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="user-details">
                                                                <h6>{data?.creator_name || `${data?.user?.fname} ${data?.user?.lname}`}</h6>
                                                                <div className="d-flex align-items-center">
                                                                    <span>
                                                                        {(data?.creator_name || data?.user)
                                                                            ? `Created: ${new Date(data?.created_on).toLocaleDateString("en-US", {
                                                                                year: "numeric",
                                                                                month: "numeric",
                                                                                day: "2-digit",
                                                                            })} ${createdOnTime}`
                                                                            : ""}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="details-box-smartlink">
                                                        <div className="d-flex align-items-center">
                                                            <div className="profile-user-image">
                                                                {data?.updator_name && (
                                                                    <img
                                                                        src={API_URL + `profile/` + data?.updator_image}
                                                                        alt="Updator Image"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="user-details">
                                                                <h6>{data?.updator_name}</h6>
                                                                <div className="d-flex align-items-center">
                                                                    <span>
                                                                        {data?.updator_name
                                                                            ? `Updated: ${new Date(data?.updated_on).toLocaleDateString("en-US", {
                                                                                year: "numeric",
                                                                                month: "numeric",
                                                                                day: "2-digit",
                                                                            })} ${updatedOnTime}`
                                                                            : ""}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>









                                                <div className="button-group d-flex align-items-center justify-content-end">



                                                    <Link to={`/brandedit/` + data._id}>
                                                        <button type="button" className="btn btn-edit me-3">
                                                            <TbEdit />
                                                        </button>
                                                    </Link>
                                                    <button type="button" className="btn btn-delete" onClick={handleShow04} >
                                                        <TbTrash />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="smartlink-mobile-show mt-3">
                    <div className="brand-list">
                        <ul>
                            {brandData.map((data) => {
                                return (
                                    <li>
                                        <div className="Domain-table-view">
                                            <div className="brand-table-image mb-4">
                                                <img src={data.logo_light?.startsWith("http") || data.logo_light?.startsWith("https") ? data.logo_light : API_URL + `brands/` + data.logo_light} alt="" className="img-fluid" />
                                            </div>
                                            <div className="Domain-table-content">
                                                <h4>{data.company_name}
                                                </h4>
                                                <p>{data.description}</p>
                                                <div className="d-flex ">
                                                    <h5>last Updated </h5>
                                                    <h6 className='ms-4'>{(new Date(data.created_on)).toLocaleDateString({
                                                        timeZone: "UTC",
                                                    })}  {(new Date(data.created_on)).toLocaleTimeString({
                                                        timeZone: "UTC",
                                                    })}  by [{JSON.parse(localStorage.getItem("user")).fname + " " + JSON.parse(localStorage.getItem("user")).lname}]</h6>
                                                </div>

                                                <div className="brand-Vanity-Tag d-flex align-items-center">
                                                    <h3>Vanity Tags:</h3>
                                                    <div className="vanity-tag d-flex">
                                                        {vanityData
                                                            .filter(vdata => vdata.brand_id === data._id)
                                                            .map(vdata => <h4>{vdata.vanity_name}</h4>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Domain-table-action">
                                            <div className="button-group d-flex">

                                                <Link to={`/brandedit/` + data._id}>
                                                    <button type="button" className="btn btn-edit me-3">
                                                        <TbEdit />
                                                    </button>
                                                </Link>
                                                <button type="button" className="btn btn-delete" onClick={handleShow04} >
                                                    <TbTrash />
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </>
        </div>

    )
}

export default TabData6;