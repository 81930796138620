import React from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft, FaExternalLinkAlt } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { useBuilderContext } from "../../../builderContext";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import *  as PNotify from '@pnotify/core';


export function EditIframe() {

    const navigate = useHistory();
    const { editComponent } = useBuilderContext();



    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus

    const [status, setStatus] = useState("")


    const [link, setLink] = React.useState({
        link: "",
    });
    useEffect(() => {
        if (mydata) {
            if (mydata.Link) {
                setLink({
                    link: mydata.Link,
                });
            }
        }

        if (showStatus) {
            setStatus(showStatus)
        }
        console.log("useEffect", mydata);
    }, []);




    const handleSaveClick = () => {


        const data = {
            Link: link.link
        }
        editComponent(position, "IFRAME", data, status);
        navigate.push("/");


    };
    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Ifarme</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="position-relative">
                        <div className="form_block">
                            <textarea type="text" value={link.link} className="form-control did-floating-textarea" placeholder=""
                                onChange={(e) => {
                                    let youtubeLink = e.target.value;
                                    setLink({ link: youtubeLink });
                                }
                                } />
                            <label className="did-floating-label">Iframe URL</label>
                        </div>
                        <div className="link_target">
                            <button type="button" className="btn btn-sm">
                                <FaExternalLinkAlt />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={handleSaveClick} >
                    Save
                </button>
            </div>
        </>
    );
}