import React, { useState, useContext, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { SmartLinkContext } from "../../Components/CreateSmartlink/SmartLinkcontext";
import { updatecampaigntypeAPI } from "../../BackendApi/Api/smartsLinkApi";
import { updatecampaigndataAPI } from "../../BackendApi/Api/smartsLinkApi";
import { saveutmdataApi, getutmdataApi, updateutmdataApi, updatesmartlinkApi, getutmstatusApi } from "../../BackendApi/Api/smartsLinkApi";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { Spinner } from "react-bootstrap";



function UTMsParameters() {

    const [loading, setLoading] = useState(false);
    const [smartlinkeditdata, setSmartlinkEditData, IosTargeting, setIosTargeting,latest, setLatest] = useContext(SmartLinkContext);

    const [switchStatus, setSwitchStatus] = useState(false);

    const smartlinkid = window.location.href.split('/')[window.location.href.split('/').length - 1]

    const [updateData, setUpdateData] = useState([])

    const [utmData, setUtmData] = useState([])

    const [label, setLabel] = useState("");
    const [value, setValue] = useState("");

    // My Code Start Here


    const updateUtmStatus = () => {

        setSwitchStatus(!switchStatus);
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const data = {
            "utm_status": !switchStatus,
            "id": smartlinkid,
            "updator_image": user.profile_image,
            "updator_name": user.fname + " " + user.lname,
        }

        updatesmartlinkApi(data).then((res) => {
            if (res.data.status == true) {
                setSwitchStatus(!switchStatus);
                if (res.data.data.utm_status == true) {
                  
                    PNotify.success({
                        title: 'Success',
                        text: 'UTM Enabled',
                    });
                 
                }
                if (res.data.data.utm_status == false) {
                    PNotify.success({
                        title: 'Success',
                        text: 'UTM Disabled',
                    });
                }
            }
        }
        ).catch((error) => {
            console.log(error);
        });
    }





    const handleInputChange = (index, newValue) => {
        // Create a shallow copy of the modifiedData state to update the specific item
        const updatedData = [...updateData];
        updatedData[index].value = newValue;
        setUpdateData(updatedData);
    };


    const updateUtm = () => {

        setLoading(true);
        updateutmdataApi(updateData).then((res) => {
            if (res.data.status == true) {
                PNotify.success({
                    title: 'Success',
                    text: 'UTM Updated Successfully',
                });
                setLatest(!latest);
                // setUtmData(res.data.data);
            }
        }
        ).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })
    }





const [saveutnLoding,setSaveUtmLoding]=useState(false)
    const saveutmdata = () => {
        setSaveUtmLoding(true)
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
   
        const campaigntypedata = [{
            "smartlink_id": smartlinkid,
            "label": label,
            "value": value,
            "type": 1,

            "updator_image": user.profile_image,
            "updator_name": user.fname + " " + user.lname
        }]

        if (label == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please Enter Label',
            });
            setSaveUtmLoding(false);
            return false;
        }
        else if (value == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please Enter Value',
            });
            setSaveUtmLoding(false);
            return false;
        }
        else {

            saveutmdataApi(campaigntypedata).then((res) => {
                if (res.data.status == true) {
                    setUtmData([...utmData, res.data.data[0]]);
                    setUpdateData([...updateData, res.data.data[0]]);
                    setValue('');
                    setLabel('')
                    PNotify.success({
                        title: 'success',
                        text: "UTM add successfully",
                    });
                    // H
                }
            }
            ).catch((err) => {
               
                // Handle network errors or other unexpected issues
                console.error("An error occurred while saving UTM data:", err);
            })
            .finally((err) => {
                // H
                // Whether success or failure, always set loading state to false
                setSaveUtmLoding(false);
            });
        }
    }


    // const getutmdata = () => {
    //     const formData = {
    //         "smartlink_id": smartlinkid,
    //     }
    //     getutmdataApi(formData).then((res) => {
    //         if (res.data.status == true) {

    //             let utmdata = res.data.data;

    //             utmData.sort(function (a, b) {
    //                 return a.position - b.position
    //             })


    //             setUtmData(res.data.data);
    //             setUpdateData(res.data.data);

    //         }
    //     }
    //     ).catch((error) => {
    //         console.log(error);
    //     });
    // }

    const getutmdata = () => {
        const formData = {
            "smartlink_id": smartlinkid,
        };

        getutmdataApi(formData)
            .then((res) => {
                if (res.data.status === true) {
                    let utmdata = res.data.data;

                    // Define the custom order
                    const customOrder = {
                        "utm_campaign": 1,
                        "utm_source": 2,
                        "utm_medium": 3,
                        "utm_content": 4,
                        "utm_term": 5
                    };

                    utmdata.sort(function (a, b) {
                        const orderA = customOrder[a.label] || Number.MAX_SAFE_INTEGER;
                        const orderB = customOrder[b.label] || Number.MAX_SAFE_INTEGER;

                        if (orderA !== orderB) {
                            return orderA - orderB;
                        }

                        // If labels have the same order, sort lexicographically by label
                        return a.label.localeCompare(b.label);
                    });

                    setUtmData(utmdata);
                    setUpdateData(utmdata);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };



    const getutmstatus = () => {
        const formData = {
            "id": smartlinkid,
        }
        getutmstatusApi(formData).then((res) => {
            if (res.data.status == true) {
                setSwitchStatus(res.data.data.utm_status);

            }
        }
        ).catch((error) => {
            console.log(error);

        });
    }

    useEffect(() => {
        getutmstatus();
        getutmdata();
    }, [])












    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-body-form">
                        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Switch id="custom-switch" label="APPEND TO DESTINATION URL" checked={campaigntype} onClick={() => {
                                setShowText(!showText);
                                setCampaignType(!campaigntype);
                                updatecampaigntype();
                            }} />
                        </Form.Group> */}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="APPEND TO DESTINATION URL"
                                checked={switchStatus}
                                onChange={(e) => {
                                    updateUtmStatus();
                                }
                                }
                            />
                        </Form.Group>

                    </div>
                </div>

                {switchStatus &&
                    <>
                        {/* <div className="col-md-12">
                            <div className="content-body-form">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Source</Form.Label>
                                    <Form.Control type="text" value={campaigndata.campaign_source} onChange={
                                        (e) => {
                                            setCampaignData({ ...campaigndata, campaign_source: e.target.value });
                                        }
                                    } placeholder=" " />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Medium</Form.Label>
                                    <Form.Control type="text" value={campaigndata.campaign_medium} onChange={
                                        (e) => {
                                            setCampaignData({ ...campaigndata, campaign_medium: e.target.value });
                                        }
                                    } placeholder=" " />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Form.Group className="booking-custom-lable mb-3" controlId="formBasicEmail">
                                <Form.Label>Session</Form.Label>
                                <Form.Control type="text" value={campaigndata.campaign_session} onChange={
                                    (e) => {
                                        setCampaignData({ ...campaigndata, campaign_session: e.target.value });
                                    }
                                } placeholder=" " />
                            </Form.Group>
                        </div> */}
                        {/* {utmData && utmData.map((item, index) => {return (
                              <div className="col-md-12">
                              <Form.Group className="booking-custom-lable mb-3" controlId="formBasicEmail">
                                  <Form.Label>{item.label}</Form.Label>
                                  <Form.Control type="text" value={item.value}  placeholder=" " />
                              </Form.Group>
                          </div>

                        )})}
                      
                        <div className="col-md-12 mb-3 mt-4">
                            <div className='form-btn'>
                                <div className='form-btn-right'>
                                    <button type="button" onClick={updatecampainData} className="btn bg-green-dark action-btn btn-green">
                                        Update Parameters
                                    </button>
                                </div>
                            </div>
                        </div> */}
                        {utmData &&
                            utmData.map((item, index) => (
                                <div className="col-md-12" key={index}>
                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                        <Form.Control
                                            className="did-floating-input"
                                            type="text"
                                            value={updateData[index]?.value || item.value}
                                            placeholder=" "
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                        />
                                        <Form.Label className="did-floating-label">{item.label}</Form.Label>
                                    </Form.Group>
                                </div>
                            ))}

                        <div className="col-md-12 mb-3 mt-4">
                            <div className="form-btn">
                                <div className="form-btn-right">

                                    {
                                        loading ? (
                                            <SpinnerLoader />
                                        ) : (
                                            <button type="button" onClick={updateUtm} className="btn bg-green-dark action-btn btn-green">
                                                Update Parameters
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                    <div className="d-flex align-items-center ">

                                        <Form.Control type="text" placeholder="label" className="did-floating-input me-3" value={label} onChange={
                                            (e) => {
                                                // setParameters({ label: e.target.value })
                                                setLabel(e.target.value.toLowerCase());
                                            }
                                        } />

                                        <Form.Control type="text" placeholder="value" value={value} onChange={
                                            (e) => {
                                                // setParameters({ value: e.target.value });
                                                setValue(e.target.value.toLowerCase());
                                            }
                                        } className="did-floating-input me-3" />
                                         {saveutnLoding?(<SpinnerLoader/>):
                                        <button type="button" onClick={saveutmdata} className="btn bg-green-dark action-btn btn-green">
                                          Add
                                        </button>}
                                        <Form.Label className="did-floating-label">Parameter</Form.Label>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default UTMsParameters;