import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { TbLicense } from "react-icons/tb";

function Session() {
    return (
        <>
            <Accordion.Header><TbLicense />Session Name</Accordion.Header>
            <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Session Name</Form.Label>
                    <Form.Control type="text" placeholder="Session -  2/6/2023, 11:13:59 PM" />
                </Form.Group>
            </Accordion.Body>
        </>
    )
}

export default Session;