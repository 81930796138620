import React from "react";
import Loginbg from "../../assets/images/Login/login-bg.png";
import Logo from "../../assets/images/logo/logo.png";
import './Login.css';
import { Link } from 'react-router-dom';
import {FaArrowLeft} from "react-icons/fa";
import { useState } from 'react';
import { forgotPasswordApi } from '../../BackendApi/Api/userApi';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';

function Forgotpassword() {
    const [email, setEmail] = useState('');

    const forgetPassword = async () => {
        const data = {
            email: email
        }
        await forgotPasswordApi(data).then((res) => {
            console.log("res.data===========", res.data.status);
            if (res.data.status == true) {
                PNotify.success({
                    title: "Email sent",
                    text: res.data.message,
                });
            }
            else {
                PNotify.error({
                    title: "Email not found",
                    text: res.data.message,
                });

            }
        }
        ).catch((err) => {
            console.log(err);
            PNotify.error({
                title: "error",
                text: err,
            });
        }
        )
    }
    return (
        <div className="Login">
            <section className="Login-section vh-100">
                <div className="container h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-12">
                            <div className="Login-card" style={{ borderRadius: '1rem' }}>
                                <div className="row g-0 pt-2 pb-2">
                                    <div className="col-md-6 col-lg-6 d-none d-md-block">
                                        <div className="Login-img" >
                                            <img src={Loginbg} alt="login form" className="img-fluid" style={{ borderRadius: '1rem 0 0 1rem', }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 d-flex align-items-center">
                                        <div className="card-body ps-4 pe-4">
                                            <form>
                                                <div className="d-flex align-items-center mb-3 pb-1">
                                                    {/* <i className="fas fa-cubes fa-2x me-3" style="color: #ff6219;"></i> */}
                                                    <span className="h1 fw-bold mb-0"><img src={Logo} alt="logo" className="logo-icon" style={{width: '80%'}} /></span>
                                                </div>

                                                <h3 className=" login-head" style={{ letterSpacing: '1px' }}>Reset Password 🔒</h3>
                                                <div className="did-floating-label-content">
                                                    <input type="email" id="form2Example17" className="form-control did-floating-input dark-theam-input"  value={email} placeholder="email" onChange={(e) => setEmail(e.target.value)} />
                                                    <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example17">Enter Email</label>
                                                </div>

                                                {/* <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="form2Example17">Confirm New Password</label>
                                                    <input type="password" id="form2Example17" className="form-control form-control-lg" placeholder="********" />
                                                </div>

                                                <div className="form-check d-flex justify-content-start mb-4">
                                                    <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" defaultChecked />
                                                    <label className="form-check-label" htmlFor="form2Example3">
                                                        Remember me
                                                    </label>
                                                </div> */}

                                                <div className="pt-1 mb-4" onClick={forgetPassword}>
                                                    <Link to="/login" className="btn custom-login-btn btn-lg btn-block" type="button">Resend Password</Link>
                                                    {/* <button className="btn custom-login-btn btn-lg btn-block" type="button">Sign In</button> */}
                                                </div>
                                                <p style={{ color: '#393f81', textAlign: 'center' }}>
                                                    <Link to="/login" style={{ color: '#7367f0', textDecoration: 'none', fontWeight: 'bold' }}> <FaArrowLeft /> Back to log in</Link>
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Forgotpassword;