import React from "react";
import './Page404.css';
import { Link } from "react-router-dom";

function Page404() {
    return (
        <>
            <div className="page404">
                <div className="page404-box">
                    <div className="page404-content text-center">
                        <h1>Look like you're lost in Space </h1>
                        <div className="position-relative">
                            <div className="page404-img">
                                <img src={require ('../../landingassets/Images/404.png')} alt="404" className='img-page404' />
                            </div>
                            <h2>404</h2>
                        </div>
                        <div className="button-404">
                            <Link to="/">Go Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Page404;