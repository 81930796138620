import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { BsEyeFill, BsEyeSlashFill, BsArrowsFullscreen } from "react-icons/bs";
import { SmartLinkContext } from "../../Components/CreateSmartlink/SmartLinkcontext";
import { updatepasswordprotectionAPI, passwordprotectiondataApi } from "../../BackendApi/Api/smartsLinkApi";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { GrStatusGoodSmall } from "react-icons/gr";
import { Accordion } from 'react-bootstrap';
import { FaHeading, FaFacebookF, FaTabletAlt, FaMobileAlt } from "react-icons/fa";
import { MdColorLens, MdPassword } from "react-icons/md";
import { API_URL } from "../../BackendApi/Api/config";
import Slider from 'react-rangeslider';
import Modal from 'react-bootstrap/Modal';
import { MdOutlineDesktopMac } from "react-icons/md";
import { RiFontSansSerif } from "react-icons/ri";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";


function Schedule() {
    const [smartlinkeditdata, setSmartlinkEditData,IosTargeting, setIosTargeting,latest, setLatest,] = React.useContext(SmartLinkContext);

    const [loading, setLoading] = useState(false);

    const [switchStatus, setSwitchStatus] = useState(false);

    const [passwordInput, setPasswordInput] = useState(null);

    const [passwordType, setPasswordType] = useState("");

    const [backgroundType, setBackgroundType] = useState(1);
    const [BackgroundColor, setBackgroundColor] = useState('#3713ed');
    const [backgroundColor2, setBackgroundColor2] = useState('#f01a45');
    const [colorPosition, setColorPosition] = useState('leftright');
    const [headerPosition, setHeaderPosition] = useState('left');
    const [footerPosition, setFooterPosition] = useState('left');
    const [collapse, setCollapse] = useState(false);


    const [headerOption, setHeaderOption] = useState(1);

    const [footerOption, setFooterOption] = useState(1);

    const [footerText, setFooterText] = useState('All Rights Reserved');
    const [footerTextColor, setFooterTextColor] = useState('#7367f0');

    const [header_brand_logo, setHeaderBrand_logo] = useState('');
    const [footer_brand_logo, setFooterBrand_logo] = useState('');

    const [customHeaderLogoUrl, setCustomHeaderLogoUrl] = useState('');
    const [customFooterLogoUrl, setCustomFooterLogoUrl] = useState('');



    const [logo_light, setLogo_light] = useState('');
    const [logo_dark, setLogo_dark] = useState('');
    const [logo_full, setLogo_full] = useState('');

    const [brandHeaderLogoOption, setBrandHeaderLogoOption] = useState(2);
    const [brandFooterLogoOption, setBrandFooterLogoOption] = useState(2);

    const [customHeaderLogo, setCustomHeaderLogo] = useState('')
    const [customFooterLogo, setCustomFooterLogo] = useState('')

    const [previousCustomHeaderLogo, setPreviousCustomHeaderLogo] = useState('')
    const [previousCustomFooterLogo, setPreviousCustomFooterLogo] = useState('')


    const [customHeaderLogoSize, setCustomHeaderLogoSize] = useState(32);

    const [customFooterLogoSize, setCustomFooterLogoSize] = useState(32);

    const [deviceView, setDeviceView] = useState('view-desktop');


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const horizontalLabels = {
        0: '0',
        10: '10',
        20: '20',
        30: '30',
        40: '40',
        50: '50',
        60: '60',
        70: '70',
        80: '80',
        90: '90',
        100: '100'
    }

    const addClass = () => {
        setCollapse(true);
        setBackgroundType(2);
    }

    const removeClass = () => {
        setCollapse(false);
        setBackgroundType(1);
    }


    const handleFooterPosition = (e) => {
        const selectedPosition = e.target.value;
        setFooterPosition(selectedPosition);
    }

    const handleHeaderPosition = (e) => {
        const selectedPosition = e.target.value;
        setHeaderPosition(selectedPosition);
    }

    const handleValueChange = (e) => {
        const selectedValue = e.target.value;
        setColorPosition(selectedValue);
    };


    const gradientDirectionMap = {
        leftright: 'to right',
        rightleft: 'to left',
        topbottom: 'to bottom',
        bottomtop: 'to top',
    };

    const handlePasswordChange = (e) => {
        setPasswordInput(e.target.value);
    }

    const handleSwitchChange = () => {
        // setShowText(!showText);
        setSwitchStatus(!switchStatus);
    };

    const handleShowPassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
        } else {
            setPasswordType("password");
        }
    };


    const handleSelectHeaderCustomLogo = (e) => {
        setCustomHeaderLogo(e.target.files[0]);
        const file = e.target.files[0];

        // Check if a file was selected
        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setCustomHeaderLogoUrl(reader.result);
                setHeaderBrand_logo(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelectFooterCustomLogo = (e) => {
        setCustomFooterLogo(e.target.files[0]);
        const file = e.target.files[0];

        // Check if a file was selected
        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setCustomFooterLogoUrl(reader.result);
                setFooterBrand_logo(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const showHeaderOption = (value) => {
        setHeaderOption(value);
        if (value == 1) {
            if (brandHeaderLogoOption == 1) {
                setHeaderBrand_logo(logo_light);
            } else if (brandHeaderLogoOption == 2) {
                setHeaderBrand_logo(logo_dark);
            } else if (brandHeaderLogoOption == 3) {
                setHeaderBrand_logo(logo_full);
            }
        }
        else if (value == 2) {
            setHeaderBrand_logo(customHeaderLogoUrl);
        }

    }

    const showFooterOption = (value) => {
        setFooterOption(value);
        if (value == 1) {
            if (brandFooterLogoOption == 1) {
                setFooterBrand_logo(logo_light);
            } else if (brandFooterLogoOption == 2) {
                setFooterBrand_logo(logo_dark);
            } else if (brandFooterLogoOption == 3) {
                setFooterBrand_logo(logo_full);
            }
        }
        else if (value == 2) {
            setFooterBrand_logo(customFooterLogoUrl);
        }

    }

    const hnadleHeaderLogoChange = (value) => {
        if (value == 1) {

            setBrandHeaderLogoOption(1);
            setHeaderBrand_logo(logo_light);

        } else if (value == 2) {

            setBrandHeaderLogoOption(2);
            setHeaderBrand_logo(logo_dark);

        } else if (value == 3) {

            setBrandHeaderLogoOption(3);
            setHeaderBrand_logo(logo_full);

        }

    }

    const hnadleFooterLogoChange = (value) => {
        if (value == 1) {

            setBrandFooterLogoOption(1);
            setFooterBrand_logo(logo_light);

        } else if (value == 2) {

            setBrandFooterLogoOption(2);
            setFooterBrand_logo(logo_dark);

        } else if (value == 3) {

            setBrandFooterLogoOption(3);
            setFooterBrand_logo(logo_full);

        }

    }


    const handleHeaderHeightChange = (value) => {
        setCustomHeaderLogoSize(value)
    }

    const handleFooterHeightChange = value => {
        setCustomFooterLogoSize(value)
    }


    const getPasswordProtectionData = () => {

        const id = window.location.href.split('/')[window.location.href.split('/').length - 1]

        const data = {
            "id": id
        }

        passwordprotectiondataApi(data).then((res) => {
            if (res.data.status == true) {

                const passwordData = res.data.data;

                setSwitchStatus(passwordData.password_protection_status);
                setPasswordInput(passwordData.password_protection_password);

                if (passwordData.password_properties) {

                    const passProperties = passwordData.password_properties
                    if (passwordData.logo_light.startsWith('http') || passwordData.logo_light.startsWith('https')) {
                        setLogo_light(passwordData.logo_light);
                    } else {
                        setLogo_light(API_URL + 'brands/' + passwordData.logo_light);
                    }
                    if (passwordData.logo_dark.startsWith('http') || passwordData.logo_dark.startsWith('https')) {
                        setLogo_dark(passwordData.logo_dark);
                    } else {
                        setLogo_dark(API_URL + 'brands/' + passwordData.logo_dark);
                    }
                    if (passwordData.logo_full.startsWith('http') || passwordData.logo_full.startsWith('https')) {
                        setLogo_full(passwordData.logo_full);
                    } else {
                        setLogo_full(API_URL + 'brands/' + passwordData.logo_full);
                    }

                    // setLogo_dark(API_URL + 'brands/' + passwordData.logo_dark);
                    // setLogo_full(API_URL + 'brands/' + passwordData.logo_full);

                    setBackgroundType(passProperties.background_type);
                    if (passProperties.background_type == 2) {
                        setCollapse(true);
                    }

                    setBackgroundColor(passProperties.bgcolor1);
                    setBackgroundColor2(passProperties.bgcolor2);

                    setFooterPosition(passProperties.footer_logo_position);
                    setFooterText(passProperties.footer_text);
                    setFooterTextColor(passProperties.footer_text_color);
                    setHeaderPosition(passProperties.header_logo_position);

                    setHeaderOption(passProperties.header_logo_option);

                    setFooterOption(passProperties.footer_logo_option);

                    setBrandHeaderLogoOption(passProperties.header_brand_logo);
                    setBrandFooterLogoOption(passProperties.footer_brand_logo);

                    setCustomHeaderLogoUrl(API_URL + 'redirection/' + passProperties.header_custom_logo)
                    setCustomFooterLogoUrl(API_URL + 'redirection/' + passProperties.footer_custom_logo);

                    setCustomHeaderLogoSize(passProperties.header_logo_size);
                    setCustomFooterLogoSize(passProperties.footer_logo_size);

                    setPreviousCustomHeaderLogo(passProperties.header_custom_logo);
                    setPreviousCustomFooterLogo(passProperties.footer_custom_logo);

                    setColorPosition(passProperties.gradient_colorPosition);

                    if (passProperties.header_logo_option == 1) {

                        console.log("i am here dont worry")
                        console.log("logo option", passProperties.header_brand_logo)
                        if (passProperties.header_brand_logo == 1) {
                            console.log("i am here  1")
                            if (passProperties.logo_light.startsWith('http') || passProperties.logo_light.startsWith('https')) {
                                setHeaderBrand_logo(passwordData.logo_light)
                            } else {

                                setHeaderBrand_logo(API_URL + 'brands/' + passwordData.logo_light);
                            }
                        }
                        else if (passProperties.header_brand_logo == 2) {
                            console.log("i am here 2")
                            if (passProperties.logo_dark.startsWith('http') || passProperties.logo_dark.startsWith('https')) {
                                setHeaderBrand_logo(passwordData.logo_dark)
                            } else {

                                setHeaderBrand_logo(API_URL + 'brands/' + passwordData.logo_dark);;
                            }
                            
                        }
                        else if (passProperties.header_brand_logo == 3) {
                            console.log("i am here 3")
                            if (passProperties.logo_full.startsWith('http') || passProperties.logo_full.startsWith('https')) {
                                setHeaderBrand_logo(passwordData.logo_full)
                            } else {

                                setHeaderBrand_logo(API_URL + 'brands/' + passwordData.logo_full);;
                            }
                            // setHeaderBrand_logo(API_URL + 'brands/' + passwordData.logo_full);
                        }
                    }

                    if (passProperties.footer_logo_option == 1) {
                        if (passProperties.footer_brand_logo == 1) {
                            if (passProperties.logo_light.startsWith('http') || passProperties.logo_light.startsWith('https')) {
                                setFooterBrand_logo(passwordData.logo_light)
                            } else {

                                setFooterBrand_logo(API_URL + 'brands/' + passwordData.logo_light);;
                            }
                            // setFooterBrand_logo(API_URL + 'brands/' + passwordData.logo_light);

                        }

                        else if (passProperties.footer_brand_logo == 2) {
                            if (passProperties.logo_dark.startsWith('http') || passProperties.logo_dark.startsWith('https')) {
                                setFooterBrand_logo(passwordData.logo_dark)
                            } else {

                                setFooterBrand_logo(API_URL + 'brands/' + passwordData.logo_dark);
                            }
                        }
                        else if (passProperties.footer_brand_logo == 3) {
                            if (passProperties.logo_full.startsWith('http') || passProperties.logo_full.startsWith('https')) {
                                setFooterBrand_logo(passwordData.logo_full)
                            } else {

                                setFooterBrand_logo(API_URL + 'brands/' + passwordData.logo_full);;
                            }
                            // setFooterBrand_logo(API_URL + 'brands/' + passwordData.logo_full);

                        }
                    }

                    if (passProperties.header_logo_option == 2) {
                        if (passProperties.header_custom_logo.startsWith('http') || passProperties.header_custom_logo.startsWith('https')) {
                            setHeaderBrand_logo(passwordData.header_custom_logo)
                        } else {

                            setHeaderBrand_logo(API_URL + 'brands/' + passwordData.header_custom_logo);;
                        }
                        // setHeaderBrand_logo(API_URL + 'redirection/' + passProperties.header_custom_logo);
                    }

                    if (passProperties.footer_logo_option == 2) {
                        if (passProperties.footer_custom_logo.startsWith('http') || passProperties.footer_custom_logo.startsWith('https')) {
                            setFooterBrand_logo(passwordData.footer_custom_logo)
                        } else {

                            setFooterBrand_logo(API_URL + 'brands/' + passwordData.footer_custom_logo);;
                        }
                        // setFooterBrand_logo(API_URL + 'redirection/' + passProperties.footer_custom_logo);
                    }

                } else {
                    if (passwordData.logo_light.startsWith('http') || passwordData.logo_light.startsWith('https')) {
                        setLogo_light(passwordData.logo_light)
                    } else {

                        setLogo_light(API_URL + 'brands/' + passwordData.logo_light);;
                    }
                    if (passwordData.logo_dark.startsWith('http') || passwordData.logo_dark.startsWith('https')) {
                        setLogo_dark(passwordData.logo_dark)
                    } else {

                        setLogo_dark(API_URL + 'brands/' + passwordData.logo_dark);;
                    }
                    if (passwordData.logo_full.startsWith('http') || passwordData.logo_full.startsWith('https')) {
                        setLogo_full(passwordData.logo_full)
                    } else {

                        setLogo_full(API_URL + 'brands/' + passwordData.logo_full);;
                    }
                    // setLogo_light(API_URL + 'brands/' + passwordData.logo_light);
                    // setLogo_dark(API_URL + 'brands/' + passwordData.logo_dark);
                    // setLogo_full(API_URL + 'brands/' + passwordData.logo_full);
                    if (passwordData.logo_dark.startsWith('http') || passwordData.logo_dark.startsWith('https')) {
                        setHeaderBrand_logo(passwordData.logo_dark)
                    } else {

                        setHeaderBrand_logo(API_URL + 'brands/' + passwordData.logo_dark);;
                    }
                    if (passwordData.logo_dark.startsWith('http') || passwordData.logo_dark.startsWith('https')) {
                        setFooterBrand_logo(passwordData.logo_dark)
                    } else {

                        setFooterBrand_logo(API_URL + 'brands/' + passwordData.logo_dark);;
                    }
                    // setHeaderBrand_logo(API_URL + 'brands/' + passwordData.logo_dark);
                    // setFooterBrand_logo(API_URL + 'brands/' + passwordData.logo_dark);

                    setHeaderOption(1);
                    setFooterOption(1);

                    setBrandFooterLogoOption(2);
                    setBrandHeaderLogoOption(2);

                }

            }

        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getPasswordProtectionData();
    }, [])



    const updatepasswordprotection = () => {
        const id = window.location.href.split('/')[window.location.href.split('/').length - 1]
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        if (switchStatus == true && (passwordInput == '' || passwordInput == null || passwordInput == undefined)) {

            PNotify.error({
                title: 'Error',
                text: 'Please enter password',
            })
            return
        }

        const formData = new FormData();

        if (switchStatus == false) {
            formData.append("id", id);
            formData.append("password_protection_status", switchStatus);
        }
        else {
            formData.append("id", id);
            formData.append("password_protection_status", switchStatus);
            formData.append("password_protection_password", passwordInput);

            formData.append('background_type', backgroundType);
            formData.append('bgcolor1', BackgroundColor);
            formData.append('bgcolor2', backgroundColor2);
            formData.append('gradient_colorPosition', colorPosition);
            formData.append('gradient_gradientDirectionMap', gradientDirectionMap[colorPosition]);

            formData.append('previous_custom_footer_logo', previousCustomFooterLogo);
            formData.append('previous_custom_header_logo', previousCustomHeaderLogo);

            if (headerOption == 2 && (customHeaderLogo != null || customHeaderLogo != '' || customHeaderLogo != undefined)) {
                formData.append('header_custom_logo', customHeaderLogo);
            } else {
                formData.append('header_custom_logo', previousCustomHeaderLogo);

            }

            if (footerOption == 2 && (customFooterLogo != null || customFooterLogo != '' || customFooterLogo != undefined)) {
                formData.append('footer_custom_logo', customFooterLogo);
            } else {
                formData.append('footer_custom_logo', previousCustomFooterLogo);

            }

            formData.append('header_logo_position', headerPosition);
            formData.append('footer_logo_position', footerPosition);
            formData.append('footer_text', footerText);
            formData.append('footer_text_color', footerTextColor);

            formData.append('header_logo_option', headerOption);
            formData.append('footer_logo_option', footerOption);

            formData.append('header_brand_logo', brandHeaderLogoOption);
            formData.append('footer_brand_logo', brandFooterLogoOption);

            formData.append('header_logo_size', customHeaderLogoSize);
            formData.append('footer_logo_size', customFooterLogoSize);

            formData.append("updator_image", user.profile_image);
            formData.append("updator_name", user.fname + " " + user.lname)

        }

        setLoading(true)
        updatepasswordprotectionAPI(formData).then((res) => {
            if (res.data.status == true) {
                setSwitchStatus(res.data.data.password_protection_status)
                PNotify.success({
                    title: 'Success',
                    text: 'Password Protection Updated Successfully',
                });
                setLatest(!latest);
                // setPasswordInput(res.data.data.password_protection_password)
            }

        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }


    return (
        <>
            <Modal
                centered
                show={show}
                onHide={handleClose}
                className="modal-center-show"
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="show-preview-header">
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-desktop');
                                    }
                                }
                            ><MdOutlineDesktopMac /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-tablet');
                                    }
                                }
                            ><FaTabletAlt /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-mobile');
                                    }
                                }
                            ><FaMobileAlt /></button>
                        </div>
                    </div>
                    <div className="show-preview-body">
                        <div className="show-preview-content-2">
                            <div className={`preview-box-background ${deviceView}`}>
                                <div class="oEUMCt"></div>
                                <div
                                    className="preview-box"
                                    style={{
                                        ...(backgroundType == 1 ? { backgroundColor: BackgroundColor } : {}),
                                        ...(backgroundType != 1
                                            ? {
                                                backgroundImage: `linear-gradient(${gradientDirectionMap[colorPosition]}, ${BackgroundColor}, ${backgroundColor2})`,
                                            }
                                            : {}),
                                    }}
                                >
                                    <div className={`preview-web-header ${headerPosition}`}>
                                        <div className="brand-logo">
                                            <img src={header_brand_logo} alt="" style={{ height: customHeaderLogoSize + 'px' }} />
                                        </div>
                                    </div>
                                    <div className="preview-body-password">
                                        <div className="password-bottom-center">
                                            <div className="password-bottom-text mb-3">
                                                <h5>Enter Password</h5>
                                            </div>
                                            <div className="password-bottom-input">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control type="password" value={passwordInput} placeholder="******" />
                                                </Form.Group>
                                            </div>
                                            <div className="password-bottom-btn">
                                                <button type="button" className="btn bg-green-dark action-btn btn-green">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`preview-footer ${footerPosition}`}>
                                        <div className="footer-brand-logo">
                                            <img src={footer_brand_logo} style={{ height: customFooterLogoSize + 'px' }} alt="" />
                                        </div>
                                        <div class="web-footer-text" style={{ color: footerTextColor }}>{footerText}</div>
                                    </div>
                                </div>
                                <div class="oEUMCterd"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="row">
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Protect your short URL with a password</Form.Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="Password Enabled"
                                checked={switchStatus}
                                onChange={handleSwitchChange}
                            />

                        </Form.Group>
                    </div>
                </div>
                {switchStatus &&
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="fill-screen-btn form-btn-right mb-4">
                                    <button type="button" className="btn full-screen-btn bg-green-dark" onClick={handleShow}><BsArrowsFullscreen /> Device Preview</button>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <div className="accordion-list">
                                    <Accordion defaultActiveKey="2">
                                        <Accordion.Item eventKey="2" className="mb-2">
                                            <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdPassword /></div> <span className="tab-user-name" >Password</span></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="acc-content-body">
                                                    <div className="content-body-form">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                            <div className="input-group password-design">
                                                                <input type={passwordType} onChange={handlePasswordChange} value={passwordInput} name="password" className="form-control" placeholder="Password" />
                                                                <div className="input-group-btn">
                                                                    <button className="btn eye-btn" onClick={handleShowPassword} >
                                                                        {passwordType === "password" ? <BsEyeSlashFill /> : <BsEyeFill />}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <Form.Text className="text-muted mt-3">
                                                                If your visitors have  issue entering the correct password, you can allow them to contact you on the email address you use with us.
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="0" className="mb-2">
                                            <Accordion.Header className="tab-list-header"><div className="user-profile-box"><FaHeading /></div> <span className="tab-user-name" >Header</span></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="acc-content-body">
                                                    {/* <div className="select-brand">
                                                                    Select from Brand thumbnails
                                                                </div>
                                                                <div className="brand-image-preview mb-4">
                                                                    <img src={require('../../assets/images/logo/logo.png')} alt="" />
                                                            </div> */}
                                                    <div className="col-md-12">
                                                        <div className="content-body-form">
                                                            <Form>
                                                                {['radio'].map((type) => (
                                                                    <div key={`inline-${type}`} className="mb-3">


                                                                        <Form.Check
                                                                            inline
                                                                            label="Brand Logo"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-1`}
                                                                            checked={headerOption == 1}
                                                                            onChange={() => showHeaderOption(1)}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="Custom Logo"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-2`}
                                                                            onChange={() => showHeaderOption(2)}
                                                                            checked={headerOption == 2}
                                                                        />
                                                                        {/* <Form.Check
                                                                                    inline
                                                                                    label="Custom Text"
                                                                                    name="group1"
                                                                                    type={type}
                                                                                    id={`inline-${type}-3`}
                                                                                    onChange={() => showHeaderOption(3)}
                                                                                    defaultChecked={headerOption === 3}
                                                                                /> */}


                                                                    </div>
                                                                ))}
                                                            </Form>
                                                            {
                                                                headerOption == 1 ? (
                                                                    <>

                                                                        <Form>
                                                                            {['radio'].map((type) => (
                                                                                <div key={`inline-${type}`} className="mb-3">


                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="Logo light"
                                                                                        name="group5"
                                                                                        type={type}
                                                                                        id={`inline-${type}-3`}
                                                                                        checked={brandHeaderLogoOption == 1}
                                                                                        onChange={() => hnadleHeaderLogoChange(1)}
                                                                                    />
                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="Logo dark"
                                                                                        name="group5"
                                                                                        type={type}
                                                                                        id={`inline-${type}-4`}
                                                                                        onChange={() => hnadleHeaderLogoChange(2)}
                                                                                        checked={brandHeaderLogoOption == 2}
                                                                                    />
                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="Logo full"
                                                                                        name="group5"
                                                                                        type={type}
                                                                                        id={`inline-${type}-5`}
                                                                                        onChange={() => hnadleHeaderLogoChange(3)}
                                                                                        checked={brandHeaderLogoOption == 3}
                                                                                    />

                                                                                </div>
                                                                            ))}
                                                                        </Form>
                                                                    </>
                                                                ) : <></>
                                                            }



                                                            {
                                                                headerOption == 2 ? (
                                                                    <div className="image-uploder-preview mb-3" >
                                                                        <input type="file"
                                                                            accept="image/*"
                                                                            onChange={handleSelectHeaderCustomLogo}
                                                                            className="form-control"
                                                                        />
                                                                    </div>
                                                                ) : <></>
                                                            }


                                                            <div className="col-md-12">
                                                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                    <Form.Select className="did-floating-select" value={headerPosition} onChange={handleHeaderPosition}>
                                                                        <option value="left">Left</option>
                                                                        <option value="center">Center</option>
                                                                        <option value="right">Right</option>
                                                                    </Form.Select>
                                                                    <Form.Label className="did-floating-label">Logo Position</Form.Label>
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="content-body-form">
                                                                    <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1">
                                                                        <Form.Label>Logo Size</Form.Label>
                                                                        <Slider className="Duration-RangeSlider"
                                                                            min={0}
                                                                            max={100}
                                                                            value={customHeaderLogoSize}
                                                                            // onChangeStart={handleChangeStart}
                                                                            onChange={handleHeaderHeightChange}
                                                                            // onChangeComplete={handleChangeComplete}
                                                                            labels={horizontalLabels}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1" className="mb-2">
                                            <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdColorLens /></div> <span className="tab-user-name" >Background Color</span></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="acc-content-body">
                                                    <div className="col-md-12">
                                                        <Form>
                                                            {['radio'].map((type) => (
                                                                <div key={`inline-${type}`} className="mb-3">
                                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                        <div className="d-flex align-items-center flex-wrap">
                                                                            <div onClick={removeClass}>
                                                                                <Form.Check
                                                                                    label="Single color"
                                                                                    name="group20"
                                                                                    type="radio"
                                                                                    id="reverseradio935"
                                                                                    className="me-4"
                                                                                    checked={backgroundType == 1}
                                                                                />
                                                                            </div>
                                                                            <div onClick={addClass}>
                                                                                <Form.Check
                                                                                    label="Color gradient"
                                                                                    name="group20"
                                                                                    type="radio"
                                                                                    id="reverseradio936"
                                                                                    checked={backgroundType == 2}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Form.Group>

                                                                </div>
                                                            ))}
                                                        </Form>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                    <Form.Control className="did-floating-color" type="color" placeholder="theme colors" defaultValue={BackgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} />
                                                                    <Form.Label className="did-floating-label">Page Color</Form.Label>
                                                                </Form.Group>
                                                            </div>
                                                            {collapse && (
                                                                <>
                                                                    <div className="col-md-6">
                                                                        <Form.Group className="did-floating-label-content gradient" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={backgroundColor2} onChange={(e) => setBackgroundColor2(e.target.value)} />
                                                                            <Form.Label className="did-floating-label">Gradient Color</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-select" as="select" value={colorPosition} onChange={handleValueChange}>
                                                                                <option value="leftright">Left to Right</option>
                                                                                <option value="rightleft">Right to Left</option>
                                                                                <option value="topbottom">Top to Bottom</option>
                                                                                <option value="bottomtop">Bottom to Top</option>
                                                                            </Form.Control>
                                                                            <Form.Label className="did-floating-label">Gradient Color Position</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4" className="mb-2">
                                            <Accordion.Header className="tab-list-header"><div className="user-profile-box"><RiFontSansSerif /></div> <span className="tab-user-name" >Footer</span></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="acc-content-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="content-body-form">
                                                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlText">
                                                                    <Form.Control className="did-floating-input" type="text" value={footerText} onChange={(e) => setFooterText(e.target.value)} placeholder="" />
                                                                    <Form.Label className="did-floating-label">Content Text</Form.Label>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="content-body-form">
                                                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                    <Form.Select className="did-floating-select" value={footerPosition} onChange={handleFooterPosition}>
                                                                        <option value="left">Left</option>
                                                                        <option value="center">Center</option>
                                                                        <option value="right">Right</option>
                                                                    </Form.Select>
                                                                    <Form.Label className="did-floating-label">Logo Position</Form.Label>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="content-body-form">
                                                                <Form.Group className="did-floating-label-content gradient" controlId="formBasicEmail">
                                                                    <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={footerTextColor} onChange={(e) => setFooterTextColor(e.target.value)} />
                                                                    <Form.Label className="did-floating-label">Text Color</Form.Label>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="content-body-form">
                                                                <Form>
                                                                    {['radio'].map((type) => (
                                                                        <div key={`inline-${type}`} className="mb-3">


                                                                            <Form.Check
                                                                                inline
                                                                                label="Brand Logo"
                                                                                name="group96"
                                                                                type={type}
                                                                                id={`inline-${type}-96`}
                                                                                checked={footerOption == 1}
                                                                                onChange={() => showFooterOption(1)}
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Custom Logo"
                                                                                name="group96"
                                                                                type={type}
                                                                                id={`inline-${type}-97`}
                                                                                onChange={() => showFooterOption(2)}
                                                                                checked={footerOption == 2}
                                                                            />
                                                                            {/* <Form.Check
                                                                                    inline
                                                                                    label="Custom Text"
                                                                                    name="group1"
                                                                                    type={type}
                                                                                    id={`inline-${type}-3`}
                                                                                    onChange={() => showHeaderOption(3)}
                                                                                    defaultChecked={headerOption === 3}
                                                                                /> */}


                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                                {
                                                                    footerOption == 1 ? (
                                                                        <>

                                                                            <Form>
                                                                                {['radio'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">


                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Logo light"
                                                                                            name="group112"
                                                                                            type={type}
                                                                                            id={`inline-${type}-112`}
                                                                                            checked={brandFooterLogoOption == 1}
                                                                                            onChange={() => hnadleFooterLogoChange(1)}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Logo dark"
                                                                                            name="group112"
                                                                                            type={type}
                                                                                            id={`inline-${type}-113`}
                                                                                            onChange={() => hnadleFooterLogoChange(2)}
                                                                                            checked={brandFooterLogoOption == 2}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Logo full"
                                                                                            name="group112"
                                                                                            type={type}
                                                                                            id={`inline-${type}-114`}
                                                                                            onChange={() => hnadleFooterLogoChange(3)}
                                                                                            checked={brandFooterLogoOption == 3}
                                                                                        />

                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                        </>
                                                                    ) : <></>
                                                                }



                                                                {
                                                                    footerOption == 2 ? (
                                                                        <div className="image-uploder-preview mb-3" >
                                                                            <input type="file"
                                                                                accept="image/*"
                                                                                onChange={handleSelectFooterCustomLogo}
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    ) : <></>
                                                                }

                                                                {/* {
                                                                        headerOption == 3 ? (
                                                                            <div className="iFrame-uploder-preview">
                                                                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                                    <Form.Control className="did-floating-textarea" as="textarea" rows={3} value={iframeContent} onChange={(e) => { console.log(e.target.value); setIframeContent(e.target.value) }} />
                                                                                    <Form.Label className="did-floating-label">Enter iFrame URL</Form.Label>
                                                                                    <div className="mt-3">
                                                                                        <b>Want to show YouTube?</b>
                                                                                        <ol>
                                                                                            <li>Go back to your YouTube page,</li>
                                                                                            <li>Press Share option below the video,</li>
                                                                                            <li>Select the embed option,</li>
                                                                                            <li>Paste that code here.</li>
                                                                                        </ol>
                                                                                    </div>
                                                                                </Form.Group>
                                                                            </div>
                                                                        ) : <></>
                                                                    } */}

                                                                <div className="col-md-12">
                                                                    <div className="content-body-form">
                                                                        <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1">
                                                                            <Form.Label>Logo Size</Form.Label>
                                                                            <Slider className="Duration-RangeSlider"
                                                                                min={0}
                                                                                max={100}
                                                                                value={customFooterLogoSize}
                                                                                // onChangeStart={handleChangeStart}
                                                                                onChange={handleFooterHeightChange}
                                                                                // onChangeComplete={handleChangeComplete}
                                                                                labels={horizontalLabels}
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div
                                    className="preview-box-password"
                                    style={{
                                        ...(backgroundType === 1 ? { backgroundColor: BackgroundColor } : {}),
                                        ...(backgroundType !== 1
                                            ? {
                                                backgroundImage: `linear-gradient(${gradientDirectionMap[colorPosition]}, ${BackgroundColor}, ${backgroundColor2})`,
                                            }
                                            : {}),
                                    }}
                                >
                                    <div className="preview-header">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="three-dot">
                                                <span className="dot-red"><GrStatusGoodSmall /></span>
                                                <span className="dot-yellow"><GrStatusGoodSmall /></span>
                                                <span className="dot-green"><GrStatusGoodSmall /></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`preview-web-header ${headerPosition}`}>
                                        <div className="brand-logo">
                                            <img src={header_brand_logo} alt="" style={{ height: customHeaderLogoSize + 'px' }} />
                                        </div>
                                    </div>
                                    <div className="preview-body-password">
                                        <div className="password-bottom-center">
                                            <div className="password-bottom-text mb-3">
                                                <h5>Enter Password</h5>
                                            </div>
                                            <div className="password-bottom-input">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control type="password" value={passwordInput} placeholder="******" />
                                                </Form.Group>
                                            </div>
                                            <div className="password-bottom-btn">
                                                <button type="button" className="btn bg-green-dark action-btn btn-green">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`preview-footer ${footerPosition}`}>
                                        <div className="footer-brand-logo">
                                            <img src={footer_brand_logo} style={{ height: customFooterLogoSize + 'px' }} alt="" />
                                        </div>
                                        <div class="web-footer-text" style={{ color: footerTextColor }}>{footerText}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                <div className="col-md-12 mt-4">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            {
                                loading ? (
                                    <SpinnerLoader />
                                ) : (
                                    <button type="button" onClick={updatepasswordprotection} className="btn bg-green-dark action-btn btn-green">
                                        Update
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Schedule;
