import { useCallback } from 'react';
import { toPng, toJpeg, toSvg } from 'html-to-image';

export const downloadImage = ((format,refrence,image) => {
    console.clear();
    console.log("format======================>", format);
    if (refrence.current === null) {
        return
    }

    // create switch case for different formats

    switch (format) {

        case 'png':

            toPng(refrence.current, { cacheBust: true, })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = image
                    link.href = dataUrl
                    link.click()
                })
                .catch((err) => {
                    console.log(err)
                })
            break;

        case 'jpeg':
            toJpeg(refrence.current, { quality: 0.95 })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = image
                    link.href = dataUrl
                    link.click()
                })
                .catch((err) => {
                    console.log(err)
                })
            break;

        case 'svg':
            toSvg(refrence.current, { quality: 0.95 })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = image
                    link.href = dataUrl
                    link.click()
                })
                .catch((err) => {
                    console.log(err)
                })
            break;

    }


})
