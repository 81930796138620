import React, { useState, useRef, useEffect } from "react";
import { Modal, Container } from "react-bootstrap";
import axios from "axios";

import SpinnerLoader from "../../Components/SpinnerLoader/SpinnerLoader";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
const API_URL = process.env.REACT_APP_API_URL;

const CreateBinPosts = ({ closemodal, addgroupwa, columns }) => {

    const [groupName, setGroupName] = useState("");
    const [newloading, setNewLoading] = useState(false);

    const [columnsData, setColumnsData] = useState(columns);


    useEffect(() => {
        if (columns) {
            setColumnsData(columns);
        }
    }, [columns]);

    const handleCreateGroup = async () => {
        setNewLoading(true)

        const owner_id = JSON.parse(localStorage.getItem("user"))._id

        if (groupName == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please enter Group name',
                hide: true
            });
            setNewLoading(false)
            return
        }

        const maxOrder = columnsData.reduce((max, col) => Math.max(max, col.ColumnOrder || 0), 0);

        const newGroup = {

            owner_id: owner_id,
            column_title: groupName,
            ColumnOrder: maxOrder + 1,
            
        }
        const res = await axios.post(`${API_URL}/socialposts/createPostIdeaGroup`, newGroup);
        if (res.data.status) {
            PNotify.success({
                title: 'Success',
                text: 'Group created successfully',
                hide: true
            });

            const data = {
              ...res.data.data,
                "postIdeas": []
                    
            }
            addbackGroup(data)
            setNewLoading(false)
            closemodal()
        }
    }

    const addbackGroup = (data) => {
        addgroupwa(data)
    }


    return (
        <>
            <Modal.Body className="post-schedule-body">
                <Container>

                    <div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Group Name"
                            value={groupName}
                            onChange={(e) => {
                                setGroupName(e.target.value)
                            }}
                        />
                    </div>


                </Container>
                <div className="post-submit-btn mt-4" style={{ textAlign: 'right' }}>
                    {newloading ? <SpinnerLoader /> :
                        <button
                            type="submit"
                            className="btn bg-green-dark action-btn btn-green"
                            onClick={handleCreateGroup}
                        >Create Group</button>}
                </div>
            </Modal.Body>
        </>
    )
}

export default CreateBinPosts;