import axios from "axios";
import { API_URL } from "./config.js";
import {apiCall} from "./auth";
import {multipartApiCall} from "./auth";



//templatedataApi
export const templatedataApi = (formData) => apiCall(`${API_URL}template/templatedataApi`, "POST", formData);

//addsmartlink
export const addsmartlink = (formData) => apiCall(`${API_URL}smartlink/addsmartlink`, "POST", formData);

//getsmartlink
export const getsmartlink = (data) => apiCall(`${API_URL}smartlink/getallsmartlink`, "POST",data);

//smartlinkdata
export const smartlinkdataApi = (data) => apiCall(`${API_URL}smartlink/getsmartlinkbyid`, "POST",data);

//deletesmartlink
export const deletesmartlinkApi = (data) => apiCall(`${API_URL}smartlink/deletesmartlink`, "DElETE",data);

//updatesmartlinkApi
export const updatesmartlinkApi = (data) => multipartApiCall(`${API_URL}smartlink/updatesmartlink`, "POST",data);

//updateiostargatingAPI
export const updateiostargatingAPI = (data) => apiCall(`${API_URL}smartlink/updateiostargating`, "POST",data);

//updatecampaigntypeAPI
export const updatecampaigntypeAPI = (data) => apiCall(`${API_URL}smartlink/updatecampaigntype`, "POST",data);

//updatecampaigndata
export const updatecampaigndataAPI = (data) => apiCall(`${API_URL}smartlink/updatecampaigndata`, "POST",data);

//updatecampaigndata
export const countrylistapi = (data) => apiCall(`${API_URL}smartlink/getcountylist`, "POST",data);

//create geotargeting

export const addgeotargetingApi = (data)=> apiCall(`${API_URL}smartlink/addgeotargeting`, "POST",data);


//updategeotargetingAPI
export const updategeotargetingAPI = (data) => apiCall(`${API_URL}smartlink/updategeotargeting`, "POST",data);

//getgeotargetingAPI
export const getgeotargetingAPI = (data) => apiCall(`${API_URL}smartlink/getgeotargeting`, "POST",data);


export const deletegeotargetingApi = (data) => apiCall(`${API_URL}smartlink/deletegeotargeting`, "POST",data);

//getscheduleapi
export const getscheduleapi = (data) => apiCall(`${API_URL}smartlink/getscheduledata`, "POST",data);

//updateScheduleData
export const updateScheduleData = (data) => apiCall(`${API_URL}smartlink/updateScheduleData`, "POST",data);

//getlinkcloakingApi
export const getlinkcloakingApi = (data) => apiCall(`${API_URL}smartlink/getlinkcloaking`, "POST",data);

//getlinkcloakingApi
export const updatecloakingdataAPI = (data) => apiCall(`${API_URL}smartlink/updatecloakingdata`, "POST",data);

//getlinkcloakingApi
export const getpassworddataApi = (data) => apiCall(`${API_URL}smartlink/getpassworddata`, "POST",data);

//updatepasswordprotectionAPI
export const updatepasswordprotectionAPI = (data) => multipartApiCall(`${API_URL}smartlink/updatepasswordprotection`, "POST",data);

//updatepasswordprotectionAPI
export const gettrackingdataApi = (data) => apiCall(`${API_URL}smartlink/gettrackingdata`, "POST",data);

//updatepasswordprotectionAPI
export const savetrackdataAPI = (data) => apiCall(`${API_URL}smartlink/savetrackdata`, "POST",data);

export const getSmartlinkApi = (data) => apiCall(`${API_URL}smartlink/getsmartlink`, "POST",data);

//updateQrDesign
export const updatesmartqr = (formData) => multipartApiCall(`${API_URL}smartlink/updatesmartqr`, "POST", formData);

//get recent smartlink
export const getrecentsmartlink = (data) => apiCall(`${API_URL}smartlink/getrecentsmartlink`, "POST",data);

//verify backhalf
export const verifybackhalf = (data) => apiCall(`${API_URL}smartlink/verifybackhalf`, "POST", data);

//gettrackingdataApi
export const gettrackingdata = (data) => apiCall(`${API_URL}tracking/gettracking`, "POST", data);

//getrecients visits

export const getrecientvisits = (data) => apiCall(`${API_URL}tracking/recientVisits`, "POST", data);
//MatricksDivice visits

export const matricksDivice = (data) => apiCall(`${API_URL}tracking/matricksDivice`, "POST", data);
//matricksVisits visits

export const matricksVisits = (data) => apiCall(`${API_URL}tracking/matricksVisits`, "POST", data);

//getDivecevisits

export const getDivecevisits = (data) => apiCall(`${API_URL}tracking/findallDiveice`, "POST", data);

//saveCTA
export const saveCTA = (data) => apiCall(`${API_URL}smartlink/savecta`, "POST", data);

//getCTA
export const getCTA = (data) => apiCall(`${API_URL}smartlink/getcta`, "POST", data);
//getsmartlinkcountery

export const getsmartlinkVisitors = (data) => apiCall(`${API_URL}tracking/getSmartLikdata`, "POST", data);

//getsmartlinkcountery

export const getsmartlinkcountery = (data) => apiCall(`${API_URL}tracking/findallCountry`, "POST", data);

//getVisitesandclicks
export const getsmartLinksVisits = (data) => apiCall(`${API_URL}tracking/getsmartLinksVisits`, "POST", data);

// get flex matricks
export const getflexMatricks = (data) => apiCall(`${API_URL}tracking/getflexMatricks`, "POST", data);

//gert password status

export const passwordprotectiondataApi = (data) => apiCall(`${API_URL}smartlink/passwordprotectiondata`, "POST", data);

//save cta

export const saveCTAdata = (data) => multipartApiCall(`${API_URL}smartlink/savecta`, "POST", data);

//save redirection data

export const saverRedirectionApi = (data) => multipartApiCall(`${API_URL}smartlink/saveredirection`, "POST", data); 

//get redirection data

export const getRedirectionApi = (data) => apiCall(`${API_URL}smartlink/getredirection`, "POST", data);

//save utm data

export const saveutmdataApi = (data) => apiCall(`${API_URL}smartlink/saveutm`, "POST", data);

//get utm data

export const getutmdataApi = (data) => apiCall(`${API_URL}smartlink/getutm`, "POST", data);

//update utm data

export const updateutmdataApi = (data) => apiCall(`${API_URL}smartlink/updateutm`, "POST", data);

//get utm status

export const getutmstatusApi = (data) => apiCall(`${API_URL}smartlink/getutmstatus`, "POST", data);

