import React, { useState,  useEffect, useContext } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { FaAngleDown, FaAngleUp, FaYoutube } from "react-icons/fa6";
import { Button } from "react-bootstrap";
import { socialAccountContext } from "../../contexts/context/socialAccountContext";

import useSocialChannels from "../../contexts/actions/useSocialChannels";

const API_URL = process.env.REACT_APP_API_URL;

const Channels = () => {

    const user = JSON.parse(localStorage.getItem('user'));

    const { connectedAccount, } = useContext(socialAccountContext);
    const { getConnectedChannels } = useSocialChannels();
    const owner_id = user.owner_id

    const [channels, setChannels] = useState([]);
    const [loading2, setloading2] = useState(false)

    useEffect(() => {
        if (connectedAccount.accounts) {
            const facebookChannel = connectedAccount.accounts.filter(
                (channel) => channel.platform === "YOUTUBE"
            );
            setChannels(facebookChannel);
        }
    }, [connectedAccount]);

    const handlePostMessage = async (event) => {
        setloading2(true)
        if (event.data.type == "YOUTUBE_AUTH") {
            if (event.data.message.access_token) {
                getConnectedChannels()
            }
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const decoratedOnClick = () => {
        setIsOpen(!isOpen);
    };

    const handleYoutubeLogin = async () => {
        setloading2(true)

        const oauthUrl = `${API_URL}/auth/youtube?owner_id=${owner_id}`
        const width = 600;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        const popupWindow = window.open(
            oauthUrl,
            "Youtube Login",
            `width=${width},height=${height},left=${left},top=${top}`,
        );


        // Add message event listener to handle postMessage from popup window
        window.addEventListener("message", handlePostMessage);


        console.log("message")
        // Close the popup window when the main window is closed
        window.onbeforeunload = () => {

            popupWindow.close();
            setloading2(false)
            window.removeEventListener("message", handlePostMessage);
        };


        const checkPopupClosed = setInterval(() => {
            if (popupWindow.closed) {
                clearInterval(checkPopupClosed);
                setloading2(false)
                // Handle popup window closed
                // Optionally, you can trigger an action here (e.g., check for authorization code)
            }
        }, 1000);
    };

    return (

        <>
            <div className="channel-list">
                <div className="channel-box-wrapper">
                    <div className={`fQzPXW accordion-header ${isOpen ? 'active' : ''}`}>
                        <div className="channel-list-item">
                            <div className="channel-left-side">
                                <div className="social-icon">
                                    <img src={`${API_URL}/channels/1690434697370-youtube.png`} alt="facebook" />

                                </div>
                                <div className="social-details">
                                    <div className="social-name d-flex">
                                        <h4 className="m-0">Youtube</h4>
                                    </div>
                                    {/* <div className="social-description">
                                        Page or Group
                                    </div> */}
                                </div>
                            </div>
                            <div className="channel-right-side">
                                <div className="channel-right-side-btn">
                                    <Button onClick={handleYoutubeLogin} className="btn btn-sm float-right connect-user-btn">Connect</Button>
                                </div>
                                <div className="channel-right-side-btn bPqQjn">
                                    <Button onClick={decoratedOnClick} className="btn transparent-btn">
                                        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isOpen && (
                        <div className="accordion-collapse">
                            <div className="accordion-body-content">
                                <div className="connected-channels">
                                    <h3 className="accordion-title">Connected channels</h3>
                                    {channels ? channels.map((item, index) => {
                                        return (

                                            <div className="connected-channel-box" key={index}>
                                                <div className="connected-left-side">
                                                    <div className="channel-list-box-icon facebook-profile">
                                                        <img src={item.youtube_picture} alt="facebook" />
                                                        <div className="bdyGBn social-icon-view">
                                                            <FaYoutube />
                                                        </div>
                                                    </div>
                                                    <div className="channel-list-box-content">
                                                        <p className="user-name-id">{item.youtube_name}</p>
                                                        <p className="platform-name">{item.platform}</p>
                                                    </div>
                                                </div>
                                                <div className="connected-right-side">
                                                    <div className="connected-sign">
                                                        <FaCircleCheck />
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    }

                                    ) :
                                        <>
                                            <p>You dont have any connected channels</p>
                                        </>

                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>



            </div>



        </>
    )
}

export default Channels