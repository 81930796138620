import React, { useState } from 'react';
import { Tabs, Tab, Modal } from "react-bootstrap";
import { FaCalendarAlt, FaFileAlt, FaCheckSquare, FaArchive } from "react-icons/fa";

import ScheduledPosts from "../SocialContent/scheduledposts/scheduledposts"
import CompletedPosts from "../SocialContent/completedposts/completedposts"
import DraftPosts from "../SocialContent/draftedposts/draftedposts"
import ArchivePosts from "../SocialContent/archivedposts/archivedposts"
import { MdSchedule } from "react-icons/md";
import { TbSocial } from "react-icons/tb";
import { Button } from 'react-bootstrap';
import Bulkschedule from "../SocialContent/bulkschedule"

function ChannelPost({ accountData, type }) {
    const [show, setShow] = useState(false);
    const handleconfirmClose = () => {
        const shouldClose = window.confirm('Are you sure want to quit all your changes will be lost ?');
        if (shouldClose) {
            setShow(false);
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Modal show={show} onHide={handleconfirmClose} centered size="lg" className='Sidebar-Modal-end' backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><MdSchedule />Create Post</Modal.Title>
                </Modal.Header>
                {type === "ALL" ? <Bulkschedule closemodal={handleClose} method="all" accountdata={accountData} /> : <Bulkschedule closemodal={handleClose} method="channel" accountdata={accountData} />}
                
            </Modal>

            <div className="col-md-12">
                <div className="add-btn d-flex Add-margin">
                    <h5 className="tab-content-title"><div className="tab-content-icon"><MdSchedule /></div>{type} - Social Posts</h5>
                    <div className="tab-content-header-right d-flex">
                        <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow} ><TbSocial />New Post</Button>
                    </div>
                </div>
                <Tabs
                    defaultActiveKey="schedule"
                    id="justify-tab-example"
                    className="mb-3 flex-page-tabs-scroll"
                >
                    <Tab eventKey="schedule" title={<><FaCalendarAlt /> &nbsp; Scheduled</>} >
                        <ScheduledPosts type={type} accountdata={accountData} />
                    </Tab>

                    <Tab eventKey="draft" title={<><FaFileAlt /> &nbsp; Drafts</>} >
                        <DraftPosts type={type} accountdata={accountData} />
                    </Tab>

                    <Tab eventKey="completed" title={<><FaCheckSquare /> &nbsp; Completed</>} >
                        <CompletedPosts type={type} accountdata={accountData} />
                    </Tab>

                    <Tab eventKey="archive" title={<><FaArchive /> &nbsp; Archived</>} >
                        <ArchivePosts type={type} accountdata={accountData} />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

export default ChannelPost;