// // import React, { useState, useEffect } from 'react';
// // import { FaRegThumbsUp, FaRegComment, FaShareAlt } from 'react-icons/fa';

// // const API_URL = process.env.REACT_APP_API_URL;

// // const PostMetrics = ({ item, user_id, type }) => {
// //     const [metrics, setMetrics] = useState({
// //         likes: 0,
// //         comments: 0,
// //         shares: 0,
// //     });
// //     const [loading, setLoading] = useState(true);
// //     const [error, setError] = useState(null);
// //     const [allMetrics, setAllMetrics] = useState([]);
// //     useEffect(() => {
// //         const fetchMetrics = async () => {
// //             try {
// //                 let response, data;

// //                 const metricsArray = [];

// //                 if (type === "LINKEDIN" && user_id) {
// //                     response = await fetch(`${API_URL}/socialposts/getPostMetricsLinkedIn/${item?.linkedIn_postID}/${user_id}`);
// //                     data = await response.json();

// //                     metricsArray.push({
// //                         platform: "LINKEDIN",
// //                         likes: data.data.likeCount,
// //                         comments: data.data.commentCount,
// //                         shares: data.data.shares,
// //                     });

// //                   console.log("dddddddddddddddddddddd",data)
// //                 }
// //                 if (type === "YOUTUBE") {
// //                     response = await fetch(`${API_URL}/socialposts/getPostMetricsYT/${item?.youtube_postID}`);
// //                     data = await response.json();


// //                     metricsArray.push({
// //                         platform: "YOUTUBE",
// //                         likes: data.data.likeCount,
// //                         comments: data.data.commentCount,
// //                         shares: data.data.shares,
// //                     });

// //                   console.log("dddddddddddddddddddddd",data)
// //                 }

// //                 // if (!response.ok) {
// //                 //     console.warn(`Failed to fetch YouTube metrics: ${data.error}`);
// //                 // }



// //                 setAllMetrics(metricsArray);
// //                 setLoading(false);
// //             } catch (error) {
// //                 setError(error.message);
// //                 setLoading(false);
// //             }
// //         };

// //         if (!item) {
// //             fetchMetrics();
// //         }
// //     }, [item, user_id, type]);




// //     if (error) {
// //         return <p>Error: {error}</p>;
// //     }
// // console.log("type",item)
// // console.log("allMetrics",allMetrics)
// //     return (
// //         <div className='post-footer'>
// //             <div className='post-footer-inner'>
// //                 <div className='post-likes'>
// //                     <FaRegThumbsUp />
// //                     <p>{metrics.likes}</p>
// //                 </div>
// //                 <div className='post-comments'>
// //                     <FaRegComment />
// //                     <p>{metrics.comments}</p>
// //                 </div>
// //                 <div className='post-shares'>
// //                     <FaShareAlt />
// //                     <p>{metrics.shares}</p>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };

// // export default PostMetrics;

// import React, { useState, useEffect } from 'react';
// import { FaRegThumbsUp, FaRegComment, FaShareAlt, FaViadeo } from 'react-icons/fa';

// const API_URL = process.env.REACT_APP_API_URL;

// const PostMetrics = ({ item, user_id, type }) => {
//     const [metrics, setMetrics] = useState({
//         likes: 0,
//         comments: 0,
//         shares: 0,
//     });
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     const fetchMetrics = async (url, postData = null) => {
//         try {
//             const response = postData
//                 ? await fetch(url, {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json"
//                     },
//                     body: JSON.stringify(postData)
//                 })
//                 : await fetch(url);

//             const data = await response.json();
//             return data.data;
//         } catch (error) {
//             setError(error.message);
//             setLoading(false);
//             return null;
//         }
//     };

//     useEffect(() => {
//         const fetchData = async () => {
//             let url, postData;

//             switch (type) {
//                 case 'LINKEDIN':
//                     url = `${API_URL}/socialposts/getPostMetricsLinkedIn/${item?.linkedIn_postID}/${user_id._id}`;
//                     break;
//                 case 'YOUTUBE':
//                     if (item?.youtube_postID !== undefined) {
//                         url = `${API_URL}/socialposts/getPostMetricsYT/${item?.youtube_postID}`;
//                     }
//                     break;
//                 case 'FACEBOOK':
//                     url = `${API_URL}/socialposts/getfbpostmetrices`;
//                     postData = {
//                         postid: item?.facebook_postID,
//                         account_doc_id: user_id?._id
//                     };
//                     break;
//                 case 'INSTAGRAM':
//                     url = `${API_URL}/socialposts/getinstapostmetrices`;
//                     postData = {
//                         postid: item?.instagram_postID,
//                         account_doc_id: user_id?._id
//                     };
//                     break;
//                 case 'TWITTER':
//                     setMetrics({ likes: 0, comments: 0, shares: 0 });
//                     setLoading(false);
//                     return;
//                 default:
//                     return;
//             }

//             const data = await fetchMetrics(url, postData);

//             if (data) {
//                 setMetrics(({
//                     likes: data.aggregatedTotalLikes || data.likeCount || 0,
//                     comments: data.aggregatedTotalComments || data.commentCount || 0,
//                     shares: data.totalShares || data.shares || 0,
//                 }));
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, [type,]);


//     return (
//         <div className='post-footer'>
//             <div className='post-footer-inner'>
//                 <div className='post-likes'>
//                     <FaRegThumbsUp />
//                     <p>{metrics.likes}</p>
//                 </div>
//                 <div className='post-comments'>
//                     <FaRegComment />
//                     <p>{metrics.comments}</p>
//                 </div>
//                 <div className='post-shares'>
//                     <FaShareAlt />
//                     <p>{metrics.shares}</p>
//                 </div>
//                 {/* <div className='post-shares'>
//                     <FaViadeo />
//                     <p>{metrics.shares}</p>
//                 </div> */}
//             </div>
//         </div>
//     );
// };

// export default PostMetrics;
import React, { useState, useEffect } from 'react';
import { FaRegThumbsUp, FaRegComment, FaShareAlt, FaExternalLinkAlt } from 'react-icons/fa';
import { FaSquareViadeo, FaStreetView } from 'react-icons/fa6';
import { MdOutlinePreview } from 'react-icons/md';

const API_URL = process.env.REACT_APP_API_URL;

const PostMetrics = ({ item, user_id, type }) => {
    const [metrics, setMetrics] = useState({
        likes: 0,
        comments: 0,
        shares: 0,
        postLink: ""
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchMetrics = async (url, postData = null) => {
        try {
            const response = postData
                ? await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(postData)
                })
                : await fetch(url);

            const data = await response.json();
            return data.data;
        } catch (error) {
            setError(error.message);
            setLoading(false);
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            let url, postData;

            switch (type) {
                case 'LINKEDIN':
                    url = `${API_URL}/socialposts/getPostMetricsLinkedIn/${item?.linkedIn_postID}/${user_id._id}`;
                    break;
                case 'YOUTUBE':
                    if (item?.youtube_postID !== undefined) {
                        url = `${API_URL}/socialposts/getPostMetricsYT/${item?.youtube_postID}`;
                    }
                    break;
                case 'FACEBOOK':
                    url = `${API_URL}/socialposts/getfbpostmetrices`;
                    postData = {
                        postid: item?.facebook_postID,
                        account_doc_id: user_id?._id
                    };
                    break;
                case 'INSTAGRAM':
                    url = `${API_URL}/socialposts/getinstapostmetrices`;
                    postData = {
                        postid: item?.instagram_postID,
                        account_doc_id: user_id?._id
                    };
                    break;
                case 'TWITTER':
                    url = `${API_URL}/socialposts/getPostMetricsTwitter/${item?.twitter_postID}/${user_id._id}`;
                    // setMetrics({ likes: 0, comments: 0, shares: 0 });
                    // setLoading(false);
                    break;
                default:
                    return;
            }

            const data = await fetchMetrics(url, postData);
            if (data) {
                setMetrics({
                    likes: data.aggregatedTotalLikes || data.likeCount || 0,
                    comments: data.aggregatedTotalComments || data.commentCount || 0,
                    shares: data.totalShares || data.shares || 0,
                    postLink: data.postLink || ""
                });
                setLoading(false);
            }
        };

        if (item && user_id) {
            fetchData();
        }
    }, [item, user_id, type]);
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = metrics.postLink.match(urlRegex);


    return (
        <div className='post-footer'>
            <div className='post-footer-inner'>
                <div className='post-likes'>
                    <FaRegThumbsUp />
                    <p>{metrics.likes}</p>
                </div>
                <div className='post-comments'>
                    <FaRegComment />
                    <p>{metrics.comments}</p>
                </div>
                <div className='post-shares'>
                    <FaShareAlt />
                    <p>{metrics.shares}</p>
                </div>
                {metrics.postLink && (
                    <div className='post-link'>
                        <a style={{
                            color: "rgb(60 56 73)",
                            textDecoration: "none"
                        }} href={urls} target="_blank" rel="noopener noreferrer">
                            <MdOutlinePreview /> 
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PostMetrics;
