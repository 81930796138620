import React, { useContext, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { TbSquareHalf } from "react-icons/tb";
import Frame1 from "../../assets/images/icons/frame1.webp";
import Frame2 from "../../assets/images/icons/frame2.webp";
import Frame3 from "../../assets/images/icons/frame3.webp";
import Frame4 from "../../assets/images/icons/frame4.webp";
import Noframe from "../../assets/images/icons/noframe.png";
import Noframe2 from "../../assets/images/icons/no-frame-2.png";
import { TemplateContext } from "../../TemplateContext";


function Chooseframe() {

    const [pattern, setPattern, eye, setEye, logo, setLogo, frame, setFrame, patternColorType, setPatternColorType, patternDotsColor, setPatternDotsColor, patternGradientColor, setPatternGradientColor, eyeBallColor, setEyeBallColor, EyeCenterColor, setEyeCenterColor, backgroundcolortype, setBackgroundcolortype, backgroundcolorcode, setBackgroundcolorcode, height, setHeight, width, setWidth, eyeinside, setEyeinside,caption, setCaption] = React.useContext(TemplateContext);

    return (
        <>
            <Accordion.Header><TbSquareHalf />Choose frame</Accordion.Header>
            <Accordion.Body>
                <div className="row">
                    <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Choose frame</Form.Label>
                            <div className="d-flex align-items-center flex-wrap ">
                                <div className="  me-4">
                                    <button className="Qr-pattern"><img src={Frame1} onClick={
                                        () => {
                                            setFrame("qr-bg-col")
                                        }
                                    } alt="Qr" className="img-fluid" /></button>
                                </div>
                                <div className="  me-4">
                                    <button className="Qr-pattern"><img src={Frame2} alt="Qr" onClick={
                                        () => {
                                            setFrame("qr-bg-col2")
                                        }
                                    } className="img-fluid" /></button>
                                </div>
                                <div className="  me-4">
                                    <button className="Qr-pattern"><img src={Frame3} alt="Qr" onClick={
                                        () => {
                                            setFrame("qr-bg-col3")
                                        }
                                    } className="img-fluid" /></button>
                                </div>
                                <div className="  me-4">
                                    <button className="Qr-pattern"><img src={Noframe2} onClick={
                                        () => {
                                            setFrame("qr-bg-col4")
                                        }
                                    } alt="Qr" className="img-fluid" /></button>
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-12">
                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                            <input type="text" className="did-floating-input form-control" value={caption} onChange={
                                (e) => {
                                    setCaption(e.target.value)
                                }
                            } placeholder="" />
                            <Form.Label className="did-floating-label">Caption</Form.Label>
                        </Form.Group>
                    </div>
                </div>
            </Accordion.Body>
        </>
    )
}

export default Chooseframe;