import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid ,LabelList} from 'recharts';
import { gettrackingdata } from '../../BackendApi/Api/smartsLinkApi';
import Loader from '../Loader/Loader';

function ColumnChart({ tracking }) {

  return (
    <BarChart width={500} height={300} data={tracking}>
      <CartesianGrid />
      <XAxis dataKey="name" />
      <YAxis />
      <Bar dataKey="x" stackId="a" fill="#beb8f9" >
      <LabelList dataKey="x" position="top" />
      </Bar>
    </BarChart>
  );
}

export default ColumnChart;
