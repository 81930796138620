import React from 'react'
import { useContext } from 'react';
import { archivedPostsContext } from '../context/archivedPostsContext';
const API_URL = process.env.REACT_APP_API_URL;


const useArchivedDataActions = () => {
    const { archivedPostsdispatch } = useContext(archivedPostsContext);

    // Your logic here...
    const user = JSON.parse(localStorage.getItem("user"))
    const ownerid = user.owner_id;
    const createdby = user._id;

    const getarchieveddata = async () => {

        try {
            const data = {
                owner_id: ownerid,
                created_by: createdby
            }

            const response = await fetch(`${API_URL}/socialposts/getarchivedposts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();

            if (result.status) {

                console.log("scheduled posts data", result.data);

                archivedPostsdispatch({ type: "SET_ARCHIVED_POSTS", payload: result.data })

            }

        }
        catch (err) {
            console.log(err);
        }

    }

    return { getarchieveddata }; // Return an object with the functions you want to expose
};

export default useArchivedDataActions;

