import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../Components/Header/Header";
import './Campaign.css';
import { MdOutlineQrCode, MdEdit } from "react-icons/md";
import { TbHandClick, TbScan, TbLiveView, TbCornerDownRight } from "react-icons/tb";
import Active from "../assets/images/power.png";
import Power from "../assets/images/power.png";
import Completed from "../assets/images/right.png"
import Scheduled from "../assets/images/calendar.png"
import { MdCloudDownload } from "react-icons/md";
import { TbChartBar, TbTrash, TbEdit } from "react-icons/tb";
import { FiCopy } from "react-icons/fi";
import { Link } from "react-router-dom";
import Qr from "../assets/images/QR.webp";
import Facebook from "../assets/images/facebook.png";
import { Modal, Spinner } from "react-bootstrap";
import EditQrChannel from "../Components/EditQr/EditQrChannel";
import { Button } from "react-bootstrap";
import Linkedin from "../assets/images/linkedin.png";
import Twitter from "../assets/images/twitter.png";
import Pinterest from "../assets/images/pinterest.png";
import Email from "../assets/images/mail.png";
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { API_URL } from "../BackendApi/Api/config";
import { getCampaignApi, updateCampaignUtm, addcampaignUtm, getcampaignutm, addNewChannelApi, updateBackhalfTitleApi } from "../BackendApi/Api/campaignApi";
import { downloadImage } from "../Helpers/imageDownload";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import CopyToClipboard from "react-copy-to-clipboard";
import CopyCat from "../Components/CopyCat/CopyCat";
import { saveAs } from "file-saver";
import { BsLink45Deg } from "react-icons/bs";
import Form from 'react-bootstrap/Form';
import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader";
import { getChannelDataApi } from "../BackendApi/Api/channels";
import qrOptiions from "./defaultQR.json"
// import * as FileSaver from 'file-saver';
// import * as csv from 'csv-parser';


function ViewCampaign() {

    const userdata = JSON.parse(localStorage.getItem("user"));

    const [campaignData, setCampaignData] = useState([]);
    const [channelsData, setChannelsData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [channelID, setChannelID] = useState("");
    const [qrImage, setQrImage] = useState("");

    const [statusImage, setStatusImage] = useState("");
    const [statusText, setStatusText] = useState("");

    const [editableTitle, setEditableTitle] = useState("");

    const [smartlink_id, setSmartlink_id] = useState("");

    const [index, setIndex] = useState('');


    const currentDate = new Date();


    const imgRef = useRef(null);


    const navigate = (direction) => {
        window.history.go(direction);
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show02, setShow02] = useState(false);

    const handleClose02 = () => setShow02(false);
    const handleShow02 = () => setShow02(true);

    const [show04, setShow04] = useState(false);

    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);

    const [show05, setShow05] = useState(false);

    const handleClose05 = () => setShow05(false);
    const handleShow05 = () => setShow05(true);

    const [show06, setShow06] = useState(false);

    const handleClose06 = () => setShow06(false);
    const handleShow06 = () => setShow06(true);

    const [show07, setShow07] = useState(false);

    const handleClose07 = () => setShow07(false);
    const handleShow07 = () => setShow07(true);

    const [value, setValue] = useState('');
    const [labels, setLabel] = useState('');
    const [editlabel, setEditLabel] = useState('');
    const [editValue, setEditValue] = useState('');
    const [Loading, setLoading] = useState(false);
    const [backhalf_id, setbackhalf_id] = useState('');
    const [updateData, setUpdateData] = useState([]);
    const [utmData, setUtmData] = useState([]);
    const [loding2, setLoding2] = useState(false);

    const [channelList, setChannelList] = useState([]);
    const [channelListExtra, setChannelListExtra] = useState([]);


    const getChannelData = async () => {
        const user = JSON.parse(localStorage.getItem('user'));

        const url = window.location.href;
        const splitarray = url.split("/")
        const id = splitarray[splitarray.length - 1]

        console.log('campaign id', id)

        const data = {
            campaign_id: id
        }

        const response = await fetch(`${API_URL}campaign/getchannelsforcampaign`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        const res = await response.json();

        if (res.status) {

            setChannelList(res.data);
        }

    }
    const getCampaign = () => {
        const campaignId = window.location.pathname.split("/")[2];
        const data = {
            campaign_id: campaignId
        }
        getCampaignApi(data).then((res) => {
            if (res.code === 200) {

                const campdata = res.data.data

                setCampaignData(campdata);
                setChannelsData(campdata.channels);

                setSmartlink_id(campdata.smartlink_id);

                if (currentDate > new Date(campdata.end_date)) {
                    setStatusImage(Completed);
                    setStatusText("Completed");
                }
                else if (currentDate < new Date(campdata.start_date)) {
                    setStatusImage(Scheduled);
                    setStatusText("Scheduled");

                }
                else {
                    setStatusImage(Power);
                    setStatusText("Active");
                }
            }
            else {
                PNotify.error({
                    title: "Something went wrong",
                    text: "Please try again later",
                });
            }
        }).catch((err) => {
            PNotify.error({
                title: err,
                text: err,
            });
        })
    }

    useEffect(() => {

        getCampaign();
        getChannelData();


    }, [])

    const campaignId = window.location.pathname.split("/")[2];
    const addNewChannel = () => {
        setLoading(true)
        const selectedChannelData = channelList.filter((value) => {
            return value.status === true;
        });

        const campaignId = window.location.pathname.split("/")[2];

        const data = {

            campaign_id: campaignId,
            channels: selectedChannelData,
            smartlink_id: smartlink_id,
            qr_design: JSON.stringify(qrOptiions),
            owner_id: userdata.owner_id,
            created_by: userdata._id,
            destination_url: campaignData.destination_url,
            domain_name: channelsData[0].domain_name,
            updator_image: userdata.profile_image,
            updator_name: userdata.fname + "" + userdata.lname
        }


        addNewChannelApi(data).then((res) => {

            if (res.code === 200) {

                if (res.data.status === true) {
                    PNotify.success({
                        title: "success",
                        text: "Added Successfully",
                    });
                    setLoading(false)
                    getCampaign();

                    handleClose06();
                }
            }

        }).catch((ee) => {
            setLoading(false)
        }).finally((ee) => {
            setLoading(false)
        })

    }

    const [upadteTitleLoading, setUpdateTitleLoading] = useState(false)

    const updatetitle = () => {
        setUpdateTitleLoading(true)
        const data = {
            title: editableTitle,
            id: backhalf_id,
            campaign_id: campaignId,
            updator_image: userdata.profile_image,
            updator_name: userdata.fname + "" + userdata.lname
        }
        updateBackhalfTitleApi(data).then((res) => {

            if (res.code === 200) {
                if (res.data.status === true) {
                    PNotify.success({
                        title: "Updated Successfully",
                        text: "Updated Successfully",
                    });

                    setChannelsData((prevChannelsData) => {
                        const updatedChannelsData = [...prevChannelsData];
                        updatedChannelsData[index].backhalf.title = editableTitle;
                        return updatedChannelsData;
                    });

                    handleClose07();

                }
                else {
                    PNotify.error({
                        title: "Something went wrong",
                        text: "Please try again later",
                    });
                }
            }
        }).catch((err) => {
            PNotify.error({
                title: "Something went wrong",
                text: "Please try again later",
            });
        }).finally((err) => {
            setUpdateTitleLoading(false)
        });
    }


    const handleInputChange = (index, newValue) => {


        const updatedData = [...updateData];
        updatedData[index].value = newValue;
        setUpdateData(updatedData);

    };

    const updateChannelData = (index) => {

        // update cahnnel data with status false of this index
        const newChannelData = [...channelList];
        newChannelData[index].status = !newChannelData[index].status;
        setChannelList(newChannelData);

    }



    const generateCSV = () => {
        // Prepare the CSV data
        const csvData = [];
        csvData.push(["Campaign Name", "Platform", "Short URL", "Visits", "Clicks", "Scans", "Destination URL", "Campaign Status", "Description", "Start", "End", "Campaign Type", "Channel", "Goals", "Conversion Rate", "UTM_Source",
            "UTM_Medium", "UTM_Campaign", "UTM_Term", "UTM_Content", "Date Created", "Date Modified", "Owner/Manager"]);
        // console.log("channelsData", channelsData);
        // console.log("campaignData", campaignData);

        channelsData.forEach((channel, index) => {
            const utmLabels = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

            const extractedValues = {};
            utmLabels.forEach((label) => {
                const utmValue = channel.campaignUTM.find((utm) => utm.label === label);
                const value = utmValue ? utmValue.value : '';
                extractedValues[label] = value;
            });     
            const {
                campaign_name,
                channel_name,
                short_url,
                visits,
                clicks,
                scans,
                destination_url
            } = channel;
            csvData.push([
                campaignData.campaign_name,
                channel_name,
                channel.domain_name + "/" + channel.backhalf.backhalf,
                channel.backhalf.clicks + channel.backhalf.scans,
                channel.backhalf.clicks,
                channel.backhalf.scans,
                channel.backhalf.destination_url,
                statusText,
                campaignData.campaign_description,
                campaignData.start_date,
                campaignData.end_date,
                campaignData.campaign_type || '',
                channel.channel_name,
                campaignData.campaign_purpose,
                channel.conversion_rate || 0,
                extractedValues.utm_source,
                extractedValues.utm_medium,
                extractedValues.utm_campaign,
                extractedValues.utm_term,
                extractedValues.utm_content,
                campaignData.createdAt,
                campaignData.updatedAt,
                campaignData.created_by

            ]);

        });

        // Create a CSV string
        const csvString = csvData.map((row) => row.join(',')).join('\n');

        // Create a Blob from the CSV data
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });

        // Initiate file download using FileSaver.js
        saveAs(blob, 'campaign_metrics.csv');
    };

    const handleUpdateUtm = (e) => {
        e.preventDefault();
        setLoding2(true);
        const newUpdateData = [
            ...updateData,
            {
                campaign_id: campaignId,
                updator_image: userdata.profile_image,
                updator_name: `${userdata.fname}  ${userdata.lname}`,
            }
        ];
        updateCampaignUtm(newUpdateData)
            .then((res) => {
                if (res.code === 200) {
                    PNotify.success({
                        title: "Updated Successfully",
                        text: res.data.message,
                    });
                    setLoding2(false);

                } else {
                    throw new Error("Something went wrong");
                }
            })
            .catch((err) => {
                PNotify.error({
                    title: "Something went wrong",
                    text: err.message,
                });
                setLoding2(false);
            })
            .finally(() => {
                setLoding2(false);
            });
    };

    const handleAddCampaignUtm = async (e) => {
        e.preventDefault();

        if (["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"].includes(labels)) {
            PNotify.error({
                title: "This label is already used",
                text: "This label is already used",
            });
            return;
        }
        if (!labels) {
            PNotify.error({
                title: "Please enter label",
                text: "Please enter label",
            });
            return;
        }

        if (!value) {
            PNotify.error({
                title: "Please enter value",
                text: "Please enter value",
            });
            return;
        }

        setLoading(true);

        try {
            const data = {
                backhalf_id: backhalf_id,
                label: labels,
                value: value,
                type: 1,
                campaign_id: campaignId,
                updator_image: userdata.profile_image,
                updator_name: userdata.fname + "" + userdata.lname
            };

            const res = await addcampaignUtm(data);

            if (res.code === 200) {
                PNotify.success({
                    title: "Added Successfully",
                    text: res.data.message,
                });
                setLabel('');
                setValue('');
                setUtmData([...utmData, res.data.data]);
                setUpdateData([...updateData, res.data.data]);
            } else {
                throw new Error("Something went wrong");
            }
        } catch (err) {
            PNotify.error({
                title: "Something went wrong",
                text: err.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleManuplateChannelList = () => {

        const channelsId = []

        channelsData.forEach((channel) => {
            const channel_id = channel.backhalf.channel_id;
            channelsId.push(channel_id);
        })


        const newChannelsList = channelListExtra.filter((channel) => !channelsId.includes(channel._id));

        setChannelList(newChannelsList);
    }

    const getCampainUtm = (id) => {
        setLoading(true);

        const data = {
            "id": id,
        };

        getcampaignutm(data).then((res) => {
            if (res.code === 200) {
                const utmData2 = res.data.data;
                const customOrder = {
                    "utm_campaign": 1,
                    "utm_source": 2,
                    "utm_medium": 3,
                    "utm_content": 4,
                    "utm_term": 5,
                };

                utmData2.sort((a, b) => {
                    const orderA = customOrder[a?.label] || Number.MAX_SAFE_INTEGER;
                    const orderB = customOrder[b?.label] || Number.MAX_SAFE_INTEGER;
                    return orderA - orderB || a?.label?.localeCompare(b?.label);
                });
                setUtmData(utmData2);
                setUpdateData(utmData2);
            } else {
                throw new Error("Something went wrong");
            }
        })
            .catch((err) => {
                PNotify.error({
                    title: "Something went wrong",
                    text: err.message,
                });
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const totalClicks = channelsData.reduce((acc, curr) => {
        if (curr && curr.backhalf && typeof curr.backhalf.clicks === 'number') {
            return acc + curr.backhalf.clicks;
        }
        return acc;
    }, 0);

    const totalScans = channelsData.reduce((acc, curr) => {
        if (curr && curr.backhalf && typeof curr.backhalf.scans === 'number') {
            return acc + curr.backhalf.scans;
        }
        return acc;
    }, 0);
    let color;
    let backgroundColor;

    switch (campaignData.campaign_purpose) {
        case "Page Views":
            color = "#00DFF7";
            backgroundColor = "#D6F2F5";
        case "Product Sales":
            color = "#3b5999"
            backgroundColor = "#acc7ff";
            break;
        case "Affiliate Referrals":
            color = "#E5B700";
            backgroundColor = "#EEE4BC";
            break;
        case "Newsletter Subscribers":
            color = "#EB01FA";
            backgroundColor = "#F5D1F7";
            break;
        case "SaaS Subscribers":
            color = "#f5771f";
            backgroundColor = "#ffd4ba";
            break;
        case "Software Downloads":
            color = "#00F6B0";
            backgroundColor = "#C8F6E9";
            break;
        case "Brand Awareness":
            color = "#d68500";
            backgroundColor = "#ffedca";
            break;
            case "Event Ticket Sales":
                color = "#FF5733";  
                backgroundColor = "#FFD1C1";
                break;
        case "Other":
            color = "#29a71a";
            backgroundColor = "#b3ffaa";
            break;
        default:
            color = "#29a71a";
            backgroundColor = "#b3ffaa";
    }

    const truncatedUrl = campaignData.destination_url?.length > 120
    ? `${campaignData.destination_url.substring(0, 100)}...`
    : campaignData.destination_url;
    return (
        <>

            <Modal show={show02} onHide={handleClose02} centered className="sidebar-edit-qr Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="main-card-title"><TbEdit />Edit Campaign</h5></Modal.Title>
                </Modal.Header>

                <Modal.Body className='custum-modal-body'>
                    {/* <div className="row">
                        
                    </div> */}
                    <div className="channel-section">
                        {utmData.map((utm, utmIndex) => {
                            const isDisabled = ["utm_medium", "utm_source", "utm_campaign"].includes(utm?.label);
                            console.log("isDisabled", utmIndex, utm)
                            return (
                                <div key={utmIndex} className="col-md-12">
                                    <Form.Group className="did-floating-label-content">
                                        <Form.Control
                                            disabled={isDisabled}
                                            className={`did-floating-input mb-5 ${isDisabled ? 'disabled-input' : ''}`}
                                            type="text"
                                            value={updateData[utmIndex]?.value || utm?.value}
                                            onChange={(e) => handleInputChange(utmIndex, e.target.value)}
                                        />
                                        <Form.Label className="did-floating-label">{utm?.label}</Form.Label>
                                    </Form.Group>
                                </div>
                            );
                        })}

                    </div>


                    {utmData.length > 0 &&
                        <div className="col-md-12 mb-3 mt-4">
                            <div className="form-btn">
                                <div className="form-btn-right">

                                    {
                                        loding2 ? (
                                            <SpinnerLoader />
                                        ) : (
                                            <button type="button" onClick={handleUpdateUtm} className="btn bg-green-dark action-btn btn-green">
                                                Update Parameters
                                            </button>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    }
                    <div className="col-md-12">
                        <div className="content-body-form">
                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                <div className="d-flex align-items-center ">

                                    <Form.Control type="text" placeholder="label" className="did-floating-input me-3" value={labels} onChange={
                                        (e) => setLabel(e.target.value.toLowerCase())} />

                                    <Form.Control type="text" placeholder="value" value={value} onChange={(e) => setValue(e.target.value.toLowerCase())}
                                        className="did-floating-input me-3" />
                                    {
                                        Loading ? (
                                            <SpinnerLoader />
                                        ) :
                                            (<button type="button" onClick={handleAddCampaignUtm} className="btn bg-green-dark action-btn btn-green">
                                                Add
                                            </button>)
                                    }
                                    <Form.Label className="did-floating-label">Parameter</Form.Label>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

            {/* QR Code modal start here */}
            <Modal show={show} onHide={handleClose} centered className="qr-code-modal"  >

                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <img ref={imgRef} src={API_URL + `qrcodes/` + qrImage} alt="" className="img-fluid popup-image" />
                        <div className="qr-btn-list">
                            <button className="btn qr-modal-btn me-2 " onClick={handleClose}>Share Link</button>
                            <button className="btn qr-modal-btn" onClick={() => {
                                handleClose();
                                downloadImage('png', imgRef, qrImage)
                            }}>Download QR</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal end here */}

            <Modal show={show05} onHide={handleClose05} className="sidebar-edit-qr Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="main-card-title"><TbEdit />Edit Qr Design</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <EditQrChannel channlData={channelID} />
                </Modal.Body>
                {/* <Modal.Footer>
                    <button className="Active-bttn btn" onClick={handleClose05}> Confirm </button>
                </Modal.Footer> */}
            </Modal>
            {/* Delete Modal Start From Here */}
            <Modal show={show04} onHide={handleClose04} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose04}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={handleClose04}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Modal End Here */}


            <Modal show={show06} onHide={handleClose06} centered className="Sidebar-Modal-end"  >

                <Modal.Body className='custum-modal-body'>
                    <div className="channel-item mb-3">
                        <div className="channel_img_list">
                            {channelList && channelList.map((item, index) => {
                                return (
                                    <div className="channel-icon">
                                        <button className="channel-icon-btn"

                                            onClick={() => {

                                                updateChannelData(index)
                                            }}
                                            style={{
                                                backgroundColor: item.status ? "black" : "white",
                                                color: item.status ? "white" : "black"
                                            }}
                                        >
                                            <img src={API_URL + `channels/` + item?.channel_icon} alt="" />
                                            <div className="channel-name">
                                                {item.channel_name}
                                            </div>
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={addNewChannel} >{Loading ? <Spinner /> : "Create Campaign "} </button>
                </Modal.Footer>
            </Modal>

            <Modal show={show07} onHide={handleClose07} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Title</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Title" value={editableTitle}
                                    onChange={(e) => setEditableTitle(e.target.value)}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={
                        () => {
                            updatetitle()
                        }
                    } >{upadteTitleLoading ? <Spinner /> : "Update Title"}</button>
                </Modal.Footer>
            </Modal>




            <Header />

            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <div className="card dashboard-card">
                                <div className="d-flex align-items-center"><button className="Back-btn-icon" onClick={() => navigate(-1)}><IoArrowBackCircleOutline /> </button><div className="Back-btn-text"><h5>Back to Campaigns</h5></div></div>
                                <div className="main-page-body-content-body-container-header">
                                    <div className="campain-view-header">
                                        <div>
                                            <div className="campain-view-header-title">
                                                <h3>{campaignData.campaign_name}</h3>
                                            </div>
                                            <div>
                                                {(() => {
                                                    if (campaignData.campaign_purpose === "Other") {
                                                        return (
                                                            <div className="campain-view-header-subtitle">
                                                                {/* <p>Goal: {campaignData.custom_purpose}</p> */}
                                                                <div className="brand-Vanity-Tag mt-2">
                                                                    <p className="d-flex align-items-center">
                                                                        <h3 className="text-blue-dark">Goals</h3>
                                                                        <div className="vanity-tag smartlink-destination h4">
                                                                            <a target="_blank" style={{ color: color, backgroundColor: backgroundColor }}>{campaignData.custom_purpose}</a>

                                                                        </div>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div className="campain-view-header-subtitle">
                                                                {/* <p>Goal: {campaignData.campaign_purpose}</p> */}

                                                                <div className="brand-Vanity-Tag mt-2">
                                                                    <p className="d-flex align-items-center">
                                                                        <h3 className="text-blue-dark">Goals</h3>
                                                                        <div className="vanity-tag smartlink-destination h4">
                                                                            <a target="_blank" style={{ color: color, backgroundColor: backgroundColor }}>{campaignData.campaign_purpose}</a>
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                })()}
                                            </div>
                                            <div>

                                                <div className="brand-Vanity-Tag">
                                                    <p className="d-flex align-items-center">
                                                        <h3 className="text-blue-dark"> Destination</h3>
                                                        <div className="vanity-tag smartlink-destination h4">
                                                            <a target="_blank" style={{ color: color, backgroundColor: backgroundColor }}>Static URL</a>
                                                        </div>
                                                        <div >

                                                            <p className="mt-3 ms-2"> {truncatedUrl}
                                                            </p>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="campain-view-header-subtitle" >
                                                <p><TbLiveView />{totalScans + totalClicks} Visits </p>
                                                <p><TbHandClick /> {totalClicks} Clicks</p>
                                                <p><TbScan />{totalScans} Scans</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="campain-view-details">
                                                <img src={statusImage} />
                                                {statusText}
                                            </div>


                                            {/* {currentDate >= campaignData.start_date && currentDate <= campaignData.end_date ? (
                                                        <img src={Power} alt="" className="img-fluid" />
                                                    ) : campaignData.start_date > currentDate && campaignData.end_date > currentDate ? (

                                                        <img src={Scheduled} alt="" className="img-fluid" />
                                                    ) : campaignData.end_date < currentDate && campaignData.start_date < currentDate ? (

                                                        <img src={Completed} alt="" className="img-fluid" />
                                                    ) : null} */}

                                            <div className="campain-time">
                                                <p>{(new Date(campaignData.start_date)).toLocaleDateString("en-US", {
                                                    timeZone: "UTC",
                                                })}  {(new Date(campaignData.start_date)).toLocaleTimeString("en-US", {
                                                    timeZone: "UTC",
                                                })}</p>


                                                <p>{(new Date(campaignData.end_date)).toLocaleDateString("en-US", {
                                                    timeZone: "UTC",
                                                })}  {(new Date(campaignData.end_date)).toLocaleTimeString("en-US", {
                                                    timeZone: "UTC",
                                                })}</p>
                                            </div>
                                            <div>
                                                <p className="mb-1">Created By: {campaignData.created_by}</p>
                                            </div>
                                            <div className="download-csv">
                                                <button className="download-csv-btn" onClick={generateCSV}><MdCloudDownload />Download Metrics CSV</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="campain-list mobile-view-hide">
                                <div className="Domain-table">
                                    <ul>
                                        {channelsData && channelsData.map((item, index) => {
                                            const totalScans = item.backhalf.scans
                                            const totalClicks = item.backhalf.clicks
                                            return (
                                                <li>
                                                    <div className="Smartlink01" key={index}>
                                                        <div className="SmartLinks-table-view">
                                                            <div className="d-flex align-items-center">
                                                                <div className="views02">
                                                                    <div className="campaigns-table-image">
                                                                        <img src={API_URL + `channels/` + item?.channel_icon} alt="" className="img-fluid" />
                                                                    </div>
                                                                    <h5>{totalClicks + totalScans} <br /><span> Visits </span></h5>
                                                                    <div>
                                                                        <h6 className="ps-0">{totalClicks} Clicks</h6>
                                                                        <h6 className="ps-0">{totalScans} Scans</h6>
                                                                    </div>
                                                                    <div className="SmartLink-list">

                                                                        <div className="SmartLinks-table-image">
                                                                            <button className="clr-theme text-decoration-none" onClick={() => {
                                                                                handleShow();
                                                                                setQrImage(item.backhalf.qr_thumbnail);
                                                                            }}  >
                                                                                <img src={API_URL + `qrcodes/` + item.backhalf.qr_thumbnail} alt="" className="img-fluid" />
                                                                            </button>
                                                                        </div>
                                                                        <div className="Domain-table-content">
                                                                            <div className="mini-page-list-inner-item-content-title">
                                                                                <h5 className="page-title">
                                                                                    <div className='edit-title'
                                                                                        onClick={
                                                                                            () => {
                                                                                                setEditableTitle(item.backhalf.title ? item.backhalf.title : item.channel_name);
                                                                                                setIndex(index);
                                                                                                setbackhalf_id(item.backhalf._id);
                                                                                                handleShow07();

                                                                                            }
                                                                                        }
                                                                                    ><MdEdit />
                                                                                    </div>{item.backhalf.title ? item.backhalf.title : item.channel_name}</h5>
                                                                            </div>
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                <h4>{item.domain_name}/{item.backhalf.backhalf}</h4>
                                                                                <CopyCat text={`${item.domain_name}/${item.backhalf.backhalf}`} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="Domain-table-action">
                                                            <div className="button-group1 ">
                                                                <div className="d-flex">
                                                                    <button type="button" className="btn btn-layout me-3" onClick={
                                                                        () => {
                                                                            setChannelID(item)
                                                                            handleShow05()

                                                                        }
                                                                    }>
                                                                        <MdOutlineQrCode />
                                                                    </button>
                                                                    <Link to={`../channel/metrics/${item.backhalf.backhalf}`}>
                                                                        <button type="button" className="btn btn-chart me-3">
                                                                            <TbChartBar />
                                                                        </button>
                                                                    </Link>

                                                                    <button type="button" className="btn btn-edit me-3" onClick={(e) => {
                                                                        handleShow02(e);
                                                                        setChannelID(item);
                                                                        setbackhalf_id(item.backhalf._id);
                                                                        getCampainUtm(item.backhalf._id);
                                                                    }}
                                                                    >
                                                                        <TbEdit />
                                                                    </button>

                                                                </div>
                                                                {/* <div className="d-flex new-M">
                                                                            <Link to="/createflexlink">
                                                                                <button type="button" className="btn btn-edit me-3">
                                                                                    <TbEdit />
                                                                                </button>
                                                                            </Link>
                                                                            <button type="button" className="btn btn-delete" onClick={handleShow04}>
                                                                                <TbTrash />
                                                                            </button>
                                                                        </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            )
                                        })}

                                    </ul>

                                    <div class="form-btn">
                                        <div class="text-center">
                                            {statusText === "Completed" ? <button disabled type="button"
                                                onClick={
                                                    () => {
                                                        handleShow06();
                                                        // handleManuplateChannelList();
                                                    }}
                                                class="btn bg-green-dark action-btn btn-green">
                                                Add Channel
                                            </button> : <button type="button"
                                                onClick={
                                                    () => {
                                                        handleShow06();
                                                        // handleManuplateChannelList();
                                                    }}
                                                class="btn bg-green-dark action-btn btn-green">
                                                Add Channel
                                            </button>}

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="campain-list smartlink-mobile-show">
                                {channelsData ?

                                    <>
                                        {

                                            channelsData.map((item) => {
                                                const totalScans = item.backhalf.scans
                                                const totalClicks = item.backhalf.clicks

                                                return (
                                                    <div className="smartlink-view-card">

                                                        <div className="smartlink-view-card-body">
                                                            <div className="smartlink-view-card-body-inner">
                                                                <div >
                                                                    <div className="campaigns-table-image">
                                                                        <img src={API_URL + `channels/` + item?.channel_icon} alt="" className="img-fluid" />
                                                                    </div>
                                                                </div>
                                                                <div className="brand-link campagin-link">


                                                                    <div className="SmartLink-list">

                                                                        <div className="SmartLinks-table-image">
                                                                            <button className="clr-theme text-decoration-none" onClick={() => {
                                                                                handleShow();
                                                                                setQrImage(item.backhalf.qr_thumbnail);
                                                                            }}  >
                                                                                <img src={API_URL + `qrcodes/` + item.backhalf.qr_thumbnail} alt="" className="img-fluid" />
                                                                            </button>
                                                                        </div>
                                                                        <div className="Domain-table-content">
                                                                            <div className="d-flex">
                                                                                <h4>{item.domain_name}/{item.backhalf.backhalf}</h4>
                                                                                <CopyToClipboard text={`${item.domain_name}/${item.backhalf.backhalf}`}>
                                                                                    <button className="clr-theme text-decoration-none ms-2" >  <FiCopy /></button>
                                                                                </CopyToClipboard>
                                                                            </div>
                                                                            <div className="VanityVisits-campian">
                                                                                <h4>{totalClicks + totalScans} visits</h4>
                                                                                <div className="d-flex">
                                                                                    <p>{totalClicks} Clicks</p>
                                                                                    <p style={{ marginLeft: '10px' }}> {totalScans} Scans</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="smartlink-btn-list">
                                                                    <div className="Domain-table-action">
                                                                        <div className="button-group1 ">
                                                                            <div className="d-flex">
                                                                                <div className="d-flex">
                                                                                    <Link to={`../channel/metrics/${item.backhalf.backhalf}`}>
                                                                                        <button type="button" className="btn btn-chart me-3">
                                                                                            <TbChartBar />
                                                                                        </button>
                                                                                    </Link>
                                                                                    <button type="button" className="btn btn-layout me-3" onClick={
                                                                                        () => {
                                                                                            setChannelID(item)
                                                                                            handleShow05()
                                                                                        }
                                                                                    }>
                                                                                        <MdOutlineQrCode />
                                                                                    </button>
                                                                                    {/* <Link to="/createflexlink">
                                                                                    <button type="button" className="btn btn-edit me-3">
                                                                                        <TbEdit />
                                                                                    </button>
                                                                                </Link>
                                                                                <button type="button" className="btn btn-delete" onClick={handleShow04}>
                                                                                    <TbTrash />
                                                                                </button> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </> :
                                    <div>
                                        no active channels
                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ViewCampaign;