import React, { useContext, useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { TbWand } from "react-icons/tb";
import { TemplateContext } from "../../TemplateContext";


function Setcolor() {

    const [pattern, setPattern, eye, setEye, logo, setLogo, frame, setFrame, patternColorType, setPatternColorType, patternDotsColor, setPatternDotsColor, patternGradientColor, setPatternGradientColor, eyeBallColor, setEyeBallColor, EyeCenterColor, setEyeCenterColor, backgroundcolortype, setBackgroundcolortype, backgroundcolorcode, setBackgroundcolorcode, height, setHeight, width, setWidth, eyeinside, setEyeinside] = React.useContext(TemplateContext);



    const addClass = () => {
        document.querySelector('.gradient').classList.remove('hide-section');
        setPatternColorType("gradient");
    }

    const removeClass = () => {
        document.querySelector('.gradient').classList.add('hide-section');
        setPatternColorType("color");
    }

    const removeClass2 = () => {

        document.querySelector('.transparent').classList.add('remove-sec');
        setBackgroundcolortype("transparent");
    }

    const removeClass3 = () => {
        document.querySelector('.transparent').classList.remove('remove-sec');
        setBackgroundcolortype("solid");

    }


    useEffect(() => {

        console.log("this is v", patternColorType)


        if (patternColorType == "gradient") {
            addClass();
        }
    }, [patternColorType])



    return (
        <>
            <Accordion.Header><TbWand />Set Color</Accordion.Header>
            <Accordion.Body>
                <div className="row">
                    <div className="col-md-12">
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Set Colors</Form.Label>
                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <div onClick={removeClass}>
                                        <Form.Check
                                            inline
                                            label="Single color"
                                            name="group3"
                                            type={type}
                                            id={`inline-${type}-4`}
                                            defaultChecked={colorType == 1}
                                            
                                        />
                                        </div>
                                        <div onClick={addClass}>
                                        <Form.Check
                                            inline
                                            label="Color gradient"
                                            name="group3"
                                            type={type}
                                            defaultChecked={colorType == 2}
                                            id={`inline-${type}-5`}  
                                        />
                                        </div>
                                    </div>
                                ))}
                            </Form>
                        </Form.Group> */}

                        <Form>
                            {['radio'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <div className="d-flex align-items-center flex-wrap">
                                            <div onClick={removeClass}>
                                                <Form.Check
                                                    label="Single color"
                                                    name="group1"
                                                    type="radio"
                                                    id="reverseradio10"
                                                    className="me-4"
                                                    checked={patternColorType == "color"}
                                                />
                                            </div>
                                            <div onClick={addClass}>
                                                <Form.Check
                                                    label="Color gradient"
                                                    name="group1"
                                                    type="radio"
                                                    id="reverseradio11"
                                                    checked={patternColorType == "gradient"}
                                                />
                                            </div>
                                        </div>
                                    </Form.Group>

                                </div>
                            ))}
                        </Form>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Pattern Color</Form.Label>
                                    <Form.Control className="w-100" type="color" value={patternDotsColor || ""} placeholder="theme colors" onChange={
                                        (e) => {
                                            setPatternDotsColor(e.target.value);
                                        }
                                    } defaultValue="#7367f0" />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3 gradient hide-section" controlId="formBasicEmail">
                                    <Form.Label>Gradient Color </Form.Label>
                                    <Form.Control className="w-100" type="color" value={patternGradientColor || ""} onChange={
                                        (e) => {
                                            setPatternGradientColor(e.target.value);
                                        }
                                    } placeholder="theme colors" defaultValue="#000000" />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Eye Color</Form.Label>
                                    <Form.Control className="w-100" type="color" value={eyeBallColor || ""} onChange={
                                        (e) => {
                                            setEyeBallColor(e.target.value);
                                        }
                                    } placeholder="theme colors" defaultValue="#000" />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Eye Center Dot Color </Form.Label>
                                    <Form.Control className="w-100" type="color" value={EyeCenterColor || ""} onChange={
                                        (e) => {
                                            setEyeCenterColor(e.target.value);
                                        }
                                    } placeholder="theme colors" defaultValue="#000" />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Background Color</Form.Label>
                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="Solid Color"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-6`}
                                            defaultChecked
                                            onClick={removeClass3}
                                        />
                                        <Form.Check
                                            inline
                                            label="Transparent "
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-7`}
                                            onClick={removeClass2}
                                        />
                                    </div>
                                ))}
                            </Form>
                        </Form.Group>
                    </div>
                    <div className="col-md-12">
                        <Form.Group className="mb-3 transparent " controlId="formBasicEmail">
                            <Form.Control className="w-100" onChange={
                                (e) => {
                                    setBackgroundcolorcode(e.target.value);
                                }
                            } type="color" placeholder="theme colors" defaultValue="#e9ecef" />
                        </Form.Group>
                    </div>
                </div>
            </Accordion.Body>
        </>
    )
}

export default Setcolor;