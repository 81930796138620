import React ,{useContext, useEffect} from "react";
import { Form } from "react-bootstrap";
import { SmartLinkContext } from "../../Components/CreateSmartlink/SmartLinkcontext";
import { updateiostargatingAPI } from "../../BackendApi/Api/smartsLinkApi";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';


function IosTargeting() {


    const [smartlinkeditdata, setSmartlinkEditData, IosTargeting, setIosTargeting,latest, setLatest,,AndroidTargeting, setAndroidTargeting] = useContext(SmartLinkContext);

    const updateiostargating = () => {
        const IosTargetingData = {
            "id": smartlinkeditdata._id,
            "ios_targeting": IosTargeting
        }

        updateiostargatingAPI(IosTargetingData).then((res) => {
            if (res.data.status == true) {
                PNotify.success({
                    title: 'Success',
                    text: 'Ios Targeting Updated Successfully',
                });
                setLatest(!latest);
                setIosTargeting(res.data.data.ios_targeting);
            }
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        if(smartlinkeditdata){
            setIosTargeting(smartlinkeditdata.ios_targeting);   
        }
        
    }, [smartlinkeditdata])


    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="booking-custom-lable mb-3" controlId="formBasicEmail">
                            <Form.Label>Destination URL for iOS Devices</Form.Label>
                            <Form.Control type="text" value={IosTargeting} onChange={
                                (e) => {
                                    setIosTargeting(e.target.value);
                                }   
                            } placeholder="Enter long URL" />
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            <button type="button" onClick={updateiostargating} className="btn bg-green-dark action-btn btn-green">
                                Update Destination
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IosTargeting;