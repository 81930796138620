
import { createContext, useState, useReducer,useMemo } from "react";

import {socialAccountReducer} from '../reducers/socialAccountReducer'

const socialAccountContext = createContext();

// create a context for the user provider


const SocialAccountProvider = ({ children }) => {

    const initialState = {
      isFetching: true,
      accounts: []
      };


    const [acc, connectedAccountdispatch] = useReducer(socialAccountReducer, initialState);

    const connectedAccount = useMemo(() => acc, [acc]);
  
    return (
        <socialAccountContext.Provider value={{
            connectedAccount, connectedAccountdispatch 
        }}>
            {children}
        </socialAccountContext.Provider>
    );
};

export { socialAccountContext, SocialAccountProvider };

