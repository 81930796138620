import { useState, useEffect,useContext } from "react";
import Form from 'react-bootstrap/Form';
import React, { addWebhooks, getwebhooks } from "../../BackendApi/Api/webhooks";
import { useParams } from "react-router-dom"
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { SmartLinkContext } from "../CreateSmartlink/SmartLinkcontext";
function WebAnalytics() {
    const [smartlinkeditdata, setSmartlinkEditData,IosTargeting, setIosTargeting,latest, setLatest,] =useContext(SmartLinkContext);

    const [loading, setLoading] = useState(false);
    const [webhooksdata, setWebhooksData] = useState('');
    const [webhook_url, setWebhook_url] = useState( '');
    const params = useParams();

    useEffect(() => {
        if (params.id) {
            getwebhookurl();
        }
    }, [params.id]);


    const handleAddWebhooks = (e) => {
        e.preventDefault();
        setLoading(true);
        addWebhooks({ webhook_url: webhook_url, smartlink_id: params.id }).then((res) => {
            if (res.code === 200) {
                setLoading(false);
                PNotify.success({
                    title: 'Success',
                    text: res.data.message,
                });
                setLatest(!latest);
                getwebhookurl();
            }else{
                setLoading(false);
                PNotify.error({
                    title: 'Error',
                    text: res.data.message,
                });
            }
        }).catch((error) => {
            setLoading(false);
            PNotify.error({
                title: 'Error',
                text: error,
            })
        }).finally(() => {
            setLoading(false);
        });
    }

    const getwebhookurl = () => {
        setLoading(true);
        getwebhooks({ smartlink_id: params.id }).then((res) => {
            if (res.code === 200) {
                setLoading(false);
                setWebhooksData(res.data.data);
                setWebhook_url(res.data.data.webhook_url)
            }
        }).catch((error) => {
            setLoading(false);
            PNotify.error({
                title: 'Error',
                text: error,
            })
        }).finally(() => {
            setLoading(false);
        });
    }
    let webhooks = webhooksdata?.webhook_url
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Enter an external catch hook URL that will receive Smartlink details when a user is directed to their destination</Form.Label>
                        </Form.Group>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                            <Form.Control className="did-floating-input" type="text" value={webhook_url} onChange={(e) => setWebhook_url(e.target.value)} placeholder="" />
                            <Form.Label className="did-floating-label" >URL </Form.Label>
                            <div className="col-md-12 mb-3 mt-4">
                                <div className="form-btn">
                                    <div className="form-btn-right">

                                        {
                                        loading ? (
                                                <SpinnerLoader />
                                            ) : (

                                                    (webhooks ? <button
                                            type="button"
                                            onClick={handleAddWebhooks}
                                            className="btn bg-green-dark action-btn btn-green"
                                        >
                                            Update URL
                                        </button> : <button
                                            type="button"
                                            onClick={handleAddWebhooks}
                                            className="btn bg-green-dark action-btn btn-green"
                                        >
                                            Add URL
                                        </button>)
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </>
    )
};

export default WebAnalytics;