import React, { useState, useEffect } from 'react';

const CanvaPopup = () => {
  const [popup, setPopup] = useState(null);

  const openPopup = () => {
    const width = 800; // Adjust width as needed
    const height = 600; // Adjust height as needed
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const canvaUrl = 'https://www.canva.com/'; // Replace with your specific Canva link

    const newPopup = window.open(
      canvaUrl,
      'Canva Popup',
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
    );

    if (newPopup) {
      setPopup(newPopup);
    }
  };

  const closePopup = () => {
    if (popup) {
      popup.close();
      setPopup(null);
    }
  };

  useEffect(() => {
    if (popup) {
      const checkPopup = () => {
        if (popup.closed) {
          // Popup has been manually closed
          closePopup();
        }
      };

      // Set an interval to check if the popup has been closed manually
      const interval = setInterval(checkPopup, 500);

      return () => {
        clearInterval(interval);
        closePopup();
      };
    }
  }, [popup]);

  return (
    <div>
      <button onClick={openPopup}>Open Canva in Popup</button>
      {popup && <button onClick={closePopup}>Close Popup</button>}
    </div>
  );
};

export default CanvaPopup;
