import React from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft, FaExternalLinkAlt } from "react-icons/fa";
import { BsSpotify } from "react-icons/bs";
import { useBuilderContext } from "../../../builderContext";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import * as PNotify from '@pnotify/core';


export function EditSpotify() {
    const navigate = useHistory();
    const { editComponent } = useBuilderContext();



    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus

    const [status, setStatus] = React.useState("")


    const [link, setLink] = React.useState({
        link: "",
    });
    useEffect(() => {
        if (mydata) {
            setLink({
                link: mydata.link,
            });
        }

        if (showStatus) {
            setStatus(showStatus)
        }
        console.log("useEffect");
    }, []);



    const handleSaveClick = () => {

        const spotifyLinkPattern = /https:\/\/open\.spotify\.com\/(playlist|track|album|artist)\/[a-zA-Z0-9]+/;

        if (!spotifyLinkPattern.test(link.link)) {
            PNotify.error({
                title: 'Error',
                text: 'Please enter a valid Spotify link',
            });
        } else {

            const data = {
                link: link.link.replace('open.spotify.com', 'open.spotify.com/embed'),
            }

            editComponent(position, "SPOTIFY", data,status);
            navigate.push("/");
        }
       
    };
    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Spotify</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="position-relative">
                        <div className="form_block">
                            <input type="text" value={link.link} className="form-control did-floating-input" placeholder=""
                                onChange={
                                    (e) => {
                                        const value = e.target.value;
                                        setLink(() => ({
                                            link: value,
                                        }));
                                    }
                                } />
                            <label className="did-floating-label">Spotify Video URL</label>
                        </div>
                        <div className="link_target">
                            <button type="button" className="btn btn-sm">
                                <FaExternalLinkAlt />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={handleSaveClick} >
                    Save
                </button>
            </div>
        </>
    );
}