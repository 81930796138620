import React, { useEffect, useState } from "react";
import { Form, Tab, Nav } from "react-bootstrap";
import Preview from '../../assets/images/plan/themelogo.webp';
import { GrStatusGoodSmall } from "react-icons/gr";
import { saveCTA, getCTA, saveCTAdata } from '../../BackendApi/Api/smartsLinkApi'
import { data } from "../Chart/AreaChart";
import axios from "axios";
import { API_URL } from '../../BackendApi/Api/config';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import Accordion from 'react-bootstrap/Accordion';
import { BsCardImage, BsArrowsFullscreen } from "react-icons/bs";
import { TbColorFilter, TbDetails } from "react-icons/tb";
import { BiBorderAll } from "react-icons/bi";
import Modal from 'react-bootstrap/Modal';
import { MdOutlineDesktopMac } from "react-icons/md";
import { FaTabletAlt, FaMobileAlt } from "react-icons/fa";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { SmartLinkContext } from "../CreateSmartlink/SmartLinkcontext";

const fileTypes = ["JPG", "PNG", "GIF"];

function Vanitytags() {
    const [smartlinkeditdata, setSmartlinkEditData,IosTargeting, setIosTargeting,latest, setLatest,] = React.useContext(SmartLinkContext);

    const [loading, setLoading] = useState(false);
    const [showText1, setShowText1] = useState(false);
    const [position, setPosition] = useState('box-top');
    const [brandtitle, setBrandtitle] = useState('Your Brand');
    const [buttontext, setButtontext] = useState('Button');
    const [destinationurl, setDestinationurl] = useState('https://gglo.xyz');
    const [description, setDescription] = useState('Visit Our Website');

    const [BackgroundColor, setBackgroundColor] = useState('#c3cffb');
    const [TextColor, setTextColor] = useState('#7367f0');
    const [ButtonBackgroundColor, setButtonBackgroundColor] = useState('#7367f0');
    const [ButtonTextColor, setButtonTextColor] = useState('#ffffff');
    const [DescriptionTextColor, setDescriptionTextColor] = useState('#7367f0');
    const [thumbnail, setThumbnail] = useState('');

    const [ctaStatus, setCtaStatus] = useState(false);

    const [imagePreview, setImagePreview] = useState(Preview);


    const [deviceView, setDeviceView] = useState('view-desktop');


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [switchStatus, setSwitchStatus] = useState(false);

    const handleImageChange = (event) => {
        setThumbnail(event.target.files[0]);
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    console.log("last location path", window.location.pathname.split("/")[-1]);


    const getCtaData = () => {
        const url = window.location.href;
        const id = url.substring(url.lastIndexOf('/') + 1);

        const data = {
            id: id
        }

        getCTA(data).then((res) => {
            console.log("response code=====>", res.code);
            if (res.code === 200) {
                console.log(res.data);

                if (res.data.status == true) {

                    const data = res.data.data[0];
                    setPosition(data.position);
                    console.log("response data=====>", process.env.REACT_APP_API_URL + 'cta/' + data.thumbnail);
                    setImagePreview(API_URL + 'cta/' + data.thumbnail);
                    setBrandtitle(data.brand_title);
                    setButtontext(data.button_text);
                    setDestinationurl(data.destination_url);
                    setDescription(data.description);
                    setBackgroundColor(data.background_color);
                    setTextColor(data.text_color);
                    setButtonBackgroundColor(data.button_color);
                    setButtonTextColor(data.button_text_color);
                    setCtaStatus(data.cta_status);
                    setShowText1(data.cta_status);
                    setDescriptionTextColor(data.description_text_color);
                }
            }
        }
        ).catch((err) => {
            console.log(err);
        }
        )

    }


    const handleSaveCTA = () => {
        const url = window.location.href;
        const id = url.substring(url.lastIndexOf('/') + 1);

        const localData = JSON.parse(localStorage.getItem('user'));

        const formData = new FormData();

        if (ctaStatus == false) {
            formData.append("smartlink_id", id);
            formData.append("cta_status", ctaStatus);
            formData.append("owner_id", localData.owner_id);
            formData.append("created_by", localData._id);

        } else {

            formData.append("smartlink_id", id);
            if (thumbnail) {
                formData.append("thumbnail", thumbnail);
            }
            formData.append("position", position);
            formData.append("brand_title", brandtitle);
            formData.append("button_text", buttontext);
            formData.append("destination_url", destinationurl);
            formData.append("description", description);
            formData.append("background_color", BackgroundColor);
            formData.append("text_color", TextColor);
            formData.append("description_text_color", DescriptionTextColor);
            formData.append("button_color", ButtonBackgroundColor);
            formData.append("button_text_color", ButtonTextColor);
            formData.append("owner_id", localData.owner_id);
            formData.append("created_by", localData._id);
            formData.append("cta_status", ctaStatus);

            formData.append("updator_image", localData.profile_image);
            formData.append("updator_name", localData.fname + " " + localData.lname)
        }

        setLoading(true);
        saveCTA(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    PNotify.success({
                        title: "Success",
                        text: "CTA Saved Successfully",
                    });
                    setLatest(!latest);
                }
                else {
                    PNotify.error({
                        title: "Error",
                        text: "CTA Not Saved",
                    });
                }
            }
            else {
                PNotify.error({
                    title: "Error",
                    text: "CTA Not Saved",
                });
            }

        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        getCtaData()

    }, [])

    const handleSwitchChange = () => {
        setCtaStatus(!ctaStatus);
        setShowText1(!ctaStatus);
    }



    return (
        <>

            <Modal
                centered
                show={show}
                onHide={handleClose}
                className="modal-center-show"
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="show-preview-header">
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-desktop');
                                    }
                                }
                            ><MdOutlineDesktopMac /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-tablet');
                                    }
                                }
                            ><FaTabletAlt /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-mobile');
                                    }
                                }
                            ><FaMobileAlt /></button>
                        </div>
                    </div>
                    <div className="show-preview-body">
                        <div className="show-preview-content-2">
                            <div className={`preview-box-background ${deviceView}`}>
                                <div class="oEUMCt"></div>
                                <div className="preview-box-call-action">
                                    {/* <div className="preview-header">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="three-dot">
                                                <span className="dot-red"><GrStatusGoodSmall /></span>
                                                <span className="dot-yellow"><GrStatusGoodSmall /></span>
                                                <span className="dot-green"><GrStatusGoodSmall /></span>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className={`preview-body ${position}`}>
                                        <div className="preview">
                                            <div className="preview-design-button color-blue" style={{ backgroundColor: BackgroundColor }}>
                                                <div className="preview-design-img">
                                                    <img src={imagePreview} alt="preview-design" className="img-fluid" />
                                                </div>
                                                <div className="preview-design-text text-center">
                                                    <h5 className="mb-0"><span style={{ color: TextColor }}>{brandtitle}</span></h5>
                                                    <h5 className="mb-0"><span style={{ color: DescriptionTextColor }}>{description}</span></h5>
                                                </div>
                                                <div className="preview-design-btn">
                                                    <a href={destinationurl}>
                                                        <button type="button" className="btn preview-btn-color" style={{ backgroundColor: ButtonBackgroundColor, color: ButtonTextColor }}>
                                                            {buttontext}
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="oEUMCterd"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="row">
                <div className="col-md-3">
                    <div className="content-body-form">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Switch
                                id="custom-switch"
                                label="Call to Action Enabled"
                                checked={ctaStatus}
                                onChange={handleSwitchChange}
                            />

                        </Form.Group>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="content-body-form">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="Link Cloaking Enabled"
                                checked={switchStatus}
                                onChange={(e) =>
                                    setSwitchStatus(e.target.checked)
                                }
                            />
                        </Form.Group>
                    </div>
                </div>
                {showText1 &&
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="fill-screen-btn form-btn-right mb-4">
                                    <button type="button" className="btn full-screen-btn bg-green-dark" onClick={handleShow}><BsArrowsFullscreen /> Device Preview</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="accordion-list">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0" className="mb-2">
                                        <Accordion.Header className="tab-list-header"><div className="user-profile-box"><BsCardImage /></div> <span className="tab-user-name" >Thumbnail</span></Accordion.Header>
                                        <Accordion.Body>
                                            <div className="acc-content-body">
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className="mb-2">
                                        <Accordion.Header className="tab-list-header"><div className="user-profile-box"><BiBorderAll /></div> <span className="tab-user-name" >Position</span></Accordion.Header>
                                        <Accordion.Body>
                                            <div className="acc-content-body">
                                                <div className="row">
                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                                        <div className="Position-img">
                                                            <img src={require('../../assets/images/Position1.webp')} alt="position-1" className="img-fluid" onClick={
                                                                () => {
                                                                    setPosition('box-bottom-left');
                                                                }
                                                            } />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                                        <div className="Position-img">
                                                            <img src={require('../../assets/images/Position2.webp')} alt="position-2" className="img-fluid" onClick={() => {
                                                                setPosition('box-top-left');
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                                        <div className="Position-img">
                                                            <img src={require('../../assets/images/Position3.webp')} alt="position-3" className="img-fluid" onClick={() => {
                                                                setPosition('box-top-right');
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                                        <div className="Position-img">
                                                            <img src={require('../../assets/images/Position4.webp')} alt="position-4" className="img-fluid" onClick={
                                                                () => {
                                                                    setPosition('box-right-bottom');
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" className="mb-2">
                                        <Accordion.Header className="tab-list-header"><div className="user-profile-box"><TbDetails /></div> <span className="tab-user-name" >CTA</span></Accordion.Header>
                                        <Accordion.Body>
                                            <div className="acc-content-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="content-body-form">
                                                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                <Form.Control className="did-floating-input" type="text" placeholder={brandtitle} value={brandtitle} onChange={(e) => setBrandtitle(e.target.value)} />
                                                                <Form.Label className="did-floating-label">Brand Title</Form.Label>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="content-body-form">
                                                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                <Form.Control className="did-floating-input" type="text" placeholder={buttontext} value={buttontext} onChange={(e) => setButtontext(e.target.value)} />
                                                                <Form.Label className="did-floating-label">Button Text</Form.Label>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="content-body-form">
                                                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                <Form.Control className="did-floating-input" type="text" placeholder={destinationurl} value={destinationurl} onChange={(e) => setDestinationurl(e.target.value)} />
                                                                <Form.Label className="did-floating-label">Destination Url</Form.Label>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="content-body-form">
                                                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                                                <Form.Control className="did-floating-input" type="text" placeholder={description} value={description} onChange={(e) => setDescription(e.target.value)} />
                                                                <Form.Label className="did-floating-label">Description</Form.Label>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" className="mb-2">
                                        <Accordion.Header className="tab-list-header"><div className="user-profile-box"><TbColorFilter /></div> <span className="tab-user-name" >Color</span></Accordion.Header>
                                        <Accordion.Body>
                                            <div className="acc-content-body">
                                                <div className="row">
                                                    <div className='col-md-6'>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Background Color</Form.Label>
                                                            <Form.Control type="color" placeholder="theme colors" defaultValue={BackgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} className="w-100" />
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Text Color</Form.Label>
                                                            <Form.Control type="color" placeholder="theme colors" defaultValue={TextColor} onChange={(e) => setTextColor(e.target.value)} className="w-100" />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className='col-md-6'>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Button Background Color</Form.Label>
                                                            <Form.Control type="color" placeholder="theme colors" defaultValue={ButtonBackgroundColor} onChange={(e) => setButtonBackgroundColor(e.target.value)} className="w-100" />
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Button Text Color</Form.Label>
                                                            <Form.Control type="color" placeholder="theme colors" defaultValue={ButtonTextColor} onChange={(e) => setButtonTextColor(e.target.value)} className="w-100" />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className='col-md-6'>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Description Color</Form.Label>
                                                            <Form.Control type="color" placeholder="theme colors" defaultValue={DescriptionTextColor} onChange={(e) => setDescriptionTextColor(e.target.value)} className="w-100" />
                                                        </Form.Group>
                                                    </div>

                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12">
                            {/* <div className="content-body-form">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Preview</Form.Label>
                                </Form.Group>
                            </div> */}
                            <div className="preview-box-call-action">
                                <div className="preview-header">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="three-dot">
                                            <span className="dot-red"><GrStatusGoodSmall /></span>
                                            <span className="dot-yellow"><GrStatusGoodSmall /></span>
                                            <span className="dot-green"><GrStatusGoodSmall /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`preview-body ${position}`}>
                                    <div className="preview">
                                        <div className="preview-design-button color-blue" style={{ backgroundColor: BackgroundColor }}>
                                            <div className="preview-design-img">
                                                <img src={imagePreview} alt="preview-design" className="img-fluid" />
                                            </div>
                                            <div className="preview-design-text text-center">
                                                <h5 className="mb-0"><span style={{ color: TextColor }}>{brandtitle}</span></h5>
                                                <h5 className="mb-0"><span style={{ color: DescriptionTextColor }}>{description}</span></h5>
                                            </div>
                                            <div className="preview-design-btn">
                                                <a href={destinationurl}>
                                                    <button type="button" className="btn preview-btn-color" style={{ backgroundColor: ButtonBackgroundColor, color: ButtonTextColor }}>
                                                        {buttontext}
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="col-md-12 mt-4">
                    <div className='form-btn'>
                        <div className='form-btn-right'>
                            {
                                loading ? (
                                    <SpinnerLoader />
                                ) : (
                                    <button type="button" className="btn bg-green-dark action-btn btn-green" onClick={handleSaveCTA}>
                                        Update
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Vanitytags;