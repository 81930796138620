import React from 'react'
import { useContext } from 'react';
import { draftedPostsContext } from '../context/draftedPostsContext';
const API_URL = process.env.REACT_APP_API_URL;


const useDraftDataActions = () => {
    const { draftedPostsdispatch } = useContext(draftedPostsContext);

    // Your logic here...
    const user = JSON.parse(localStorage.getItem("user"))
    const ownerid = user.owner_id;
    const createdby = user._id;

    const getdrafteddata = async () => {

        try {
            const data = {
                owner_id: ownerid,
                created_by: createdby
            }

            const response = await fetch(`${API_URL}/socialposts/getdraftedposts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const result = await response.json();

            if (result.status) {

                console.log("scheduled posts data", result.data);

                draftedPostsdispatch({ type: "SET_DRAFTED_POSTS", payload: result.data })

            }

        }
        catch (err) {
            console.log(err);
        }

    }



    return { getdrafteddata }; // Return an object with the functions you want to expose
};

export default useDraftDataActions;

