import React, { useLayoutEffect } from "react";
import Loginbg from "../../assets/images/Login/login-bg.webp";
import Logo from "../../assets/images/logo/logo.png";
import './Login.css';
import { Link } from 'react-router-dom';
import { MdArrowBackIos } from "react-icons/md";
import { useState, useEffect } from 'react';
import { verifyTokenApi } from '../../BackendApi/Api/userApi';
import { updatePasswordByTokenApi } from '../../BackendApi/Api/userApi';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';

function ResetPassword() {

    const [token, setToken] = useState('');

    console.log("tooooookkkkeeenn===========", token);

    const [foundToken, setFoundToken] = useState(null);

    console.log("foundToken===========", foundToken);

    const [tokenData, setTokenData] = useState([]);
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');


    const verifyToken = async () => {
        const url = new URL(window.location.href);

        const params = new URLSearchParams(url.search);

        const extractedToken = params.get('token');

        console.log("extractedToken===========", extractedToken);

        setToken(extractedToken);

        const data = {
            token: extractedToken
        }
        await verifyTokenApi(data).then((res) => {
            console.log("res.data.data[0].email===========", res);
            console.log("res.data===========", res.data.data);

            if (res.code == 200) {
                setEmail(res.data.data[0].email);
                setFoundToken(true);
            } else if (res.data.data[0].reset_token == null) {
                alert("Token expired");
            }
            else {
                setFoundToken(false);
            }
        }).catch((err) => {
            console.log(err);
            setFoundToken(false);
        })
    }

    const updatePassword = async () => {

        if (newPassword !== confirmNewPassword) {
            return PNotify.error({
                title: "Error",
                text: "Password and confirm password should be same",
            });
        }
        // else {
            const data = {
                email: email,
                newPassword: newPassword
            }
            await updatePasswordByTokenApi(data).then((res) => {
                if (res.code == 200) {
                    PNotify.success({
                        title: "Success",
                        text: "Password updated successfully",
                    });
                }
                else {
                    PNotify.error({
                        title: "Error",
                        text: "Something went wrong",
                    });
                }
            }).catch((err) => {
                console.log(err);
                PNotify.error({
                    title: "Error",
                    text: err,
                });
            })
        // }
    }






    useLayoutEffect(() => {

        verifyToken();

    }, []);

    return (
        <>
            {foundToken==true ?
                <div className="Login">
                    <section className="Login-section vh-100">
                        <div className="container h-100">
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col col-xl-12">
                                    <div className="Login-card" style={{ borderRadius: '1rem' }}>
                                        <div className="row g-0 pt-2 pb-2">
                                            <div className="col-md-6 col-lg-6 d-none d-md-block">
                                                <div className="Login-img" >
                                                    <img src={Loginbg} alt="login form" className="img-fluid" style={{ borderRadius: '1rem 0 0 1rem' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6 d-flex align-items-center">
                                                <div className="card-body ps-4 pe-4 text-black">
                                                    <form>
                                                        <div className="d-flex align-items-center mb-3 pb-1">
                                                            {/* <i className="fas fa-cubes fa-2x me-3" style="color: #ff6219;"></i> */}
                                                            <span className="h1 fw-bold mb-0"><img src={Logo} alt="logo" className="logo-icon" /></span>
                                                        </div>

                                                        <h3 className=" login-head" style={{ letterSpacing: '1px' }}>Reset Password 🔒</h3>
                                                        <div className="form-outline mb-4">
                                                            <label className="form-label" htmlFor="form2Example17">New Password</label>
                                                            <input type="password" id="form2Example17" className="form-control form-control-lg" value={newPassword} placeholder="*********"
                                                                onChange={(e) => setNewPassword(e.target.value)} />
                                                        </div>

                                                        <div className="form-outline mb-4">
                                                            <label className="form-label" htmlFor="form2Example17">Confirm New Password</label>
                                                            <input type="password" id="form2Example17" className="form-control form-control-lg" value={confirmNewPassword} placeholder="********"
                                                                onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                                        </div>

                                                        <div className="pt-1 mb-4" onClick={updatePassword}>
                                                            <Link to="/login" className="btn custom-login-btn btn-lg btn-block" type="button">Change Password</Link>
                                                            {/* <button className="btn custom-login-btn btn-lg btn-block" type="button">Sign In</button> */}
                                                        </div>
                                                        <p style={{ color: '#393f81', textAlign: 'center' }}>
                                                            <Link to="/login" style={{ color: '#7367f0', textDecoration: 'none', fontWeight: 'bold' }}> <MdArrowBackIos /> Back to log in</Link>
                                                        </p>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                : " verifying token please wait..."}
        </>
    );
}

export default ResetPassword;