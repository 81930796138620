import React from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";

import { useBuilderContext } from "../../../builderContext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import SpinnerLoader from "../../../Components/SpinnerLoader/SpinnerLoader";

export function EditSubHeading() {
    const { editComponent } = useBuilderContext();

const [loading, setLoading] = useState(false);

    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus;
  
    const [status, setStatus] = useState("")
    console.log("mydata", mydata);


    const[headding, setHeadding] = React.useState({
        headding: "",
        });

    useEffect(() => {
        if (mydata) {
            setHeadding({
                headding: mydata.headding,
            });
        }
        if (showStatus) {
            setStatus(showStatus)
        }
    }, []);

    

    const handleSaveClick = () => {
        setLoading(true);
        editComponent(position, "TITLE", headding, status);
        if(headding.headding){
            PNotify.success({
                text: 'Sub Heading updated successfully',
                delay: 3000

            })
            setLoading(false);
        } else {
            PNotify.error({
                text: 'Sub Heading is required',
                delay: 3000
            })
            setLoading(false);
        }
    };
    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Sub Heading</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="form-group did-floating-label-content">
                        <textarea className="form-control did-floating-textarea" value={headding.headding} id="headding" rows="3" placeholder="Placeholder"
                         onChange={
                            (e) => {
                                const value = e.target.value;
                                setHeadding(() => ({
                                    headding: value,
                                }));
                            }
                        } />
                        <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label ">Text</label>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                {loading ? <SpinnerLoader /> :
                    <button className="btn publish-btn" onClick={handleSaveClick} >
                        Update
                    </button>
                }
            </div>
        </>
    );
}