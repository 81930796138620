import React, { useEffect, useState } from "react";
import './PageBuilder.css';
import BuilderLeft from "./BuilderLeft";
import BuilderRight from "./BuilderRight";
import Header from "../Components/Header/Header";
import { getFlowPageData } from "../BackendApi/Api/flowpageApi"
import { useBuilderContext } from "../builderContext";
import { SmartLinkContext } from "../Components/CreateSmartlink/SmartLinkcontext";
import { TbEditCircle } from "react-icons/tb";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useLocation } from "react-router-dom";

function FlexpageTemplate() {

    const location = useLocation();




    const { setMinipage, color, setColor, buttonColor, setButtonColor, fontFamily, setFontFamily, addBgclass, setBgclass, setSmartlink_url, setQrImage } = useBuilderContext();

    const [smartlinkeditdata, setSmartlinkEditData] = React.useContext(SmartLinkContext);

    // const [color, setColor] = useState('');

    const changeColor = (color_white) => {
        setColor(color_white);
    }

    // const [buttonColor, setButtonColor] = useState('');

    const changeButtonColor = (color_white) => {
        setButtonColor(color_white);
    }

    // const [fontFamily, setFontFamily] = useState('');


    const changeFontFamily = (font_family) => {
        setFontFamily(font_family);
    }

    // const { addBgclass, setBgclass } = useState('');

    const changeBgclass = (bg_class) => {
        setBgclass(bg_class);
    }

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);

        // Access individual query parameters
        const brandId = queryParams.get('brandId');

        console.log('brandId:', brandId);
    })


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>

            <Modal show={show} onHide={handleClose} className="page_builder_modal">
                <Modal.Body className="p-0">
                    <div className="bulider_right">
                        <BuilderRight changeColor={changeColor} changeButtonColor={changeButtonColor} changeFontFamily={changeFontFamily} changeBgclass={changeBgclass} />
                    </div>
                </Modal.Body>
            </Modal>

            
                <Header />

                <div className="main-container mobile-hide">
                    <div className="main-page-body-content">
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">
                                <div className="page_builder_app">
                                    <div className="bulider_right fOCfTk">
                                        <BuilderRight changeColor={changeColor} changeButtonColor={changeButtonColor} changeFontFamily={changeFontFamily} changeBgclass={changeBgclass} />
                                    </div>
                                    <div className="bulider_left kzboard">
                                        <BuilderLeft color={color} buttonColor={buttonColor} fontFamily={fontFamily} addBgclass={addBgclass} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-container mobile-show">
                    <div className="main-page-body-content">
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">
                                <div className="page_builder_app">
                                    {/* <div className="bulider_right fOCfTk">
                                            <BuilderRight changeColor={changeColor} changeButtonColor={changeButtonColor} changeFontFamily={changeFontFamily} changeBgclass={changeBgclass} />
                                        </div> */}
                                    <div className="bulider_left kzboard">
                                        <BuilderLeft color={color} buttonColor={buttonColor} fontFamily={fontFamily} addBgclass={addBgclass} />
                                    </div>

                                    <div className="fix_button">
                                        <button className="edit_builder_btn" onClick={handleShow}>
                                            <TbEditCircle />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        </>
    );
}

export default FlexpageTemplate;