import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Header.css';

function Header() {

    const [collapse, setCollapse] = useState(false);
    const toggle = () => {
        document.querySelector(".sidebar-container").classList.toggle('new-class-name');
        document.querySelector(".stop").classList.toggle('new-class-name');
        setCollapse(!collapse);
    }

    


    return (

        <div className="Header header-display">
            <Navbar bg="white" expand="lg" className='shadow-sm header-custom'>
                <Container fluid className='custom-container header-custom-view'>
                    <Navbar id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <img src={require ('../../assets/images/logo/logo.png')} alt="logo" className="logo01" />
                        </Nav>
                    </Navbar>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggle} className="custom-toggle" />
                </Container>
            </Navbar>
        </div>
    );
}

export default Header;
