import React from "react";
import Loginbg from "../../assets/images/Login/login-bg.png";
import Logo from "../../assets/images/logo/logo.png";
import { Form } from 'react-bootstrap';
import { InputGroup, FormControl, Dropdown } from 'react-bootstrap';
import './Login.css';
import { Link } from 'react-router-dom';
import { useState } from "react";
import { UserRegistereApi } from "../../BackendApi/Api/userApi";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
// import qrOptiions from "../../Campaigns/defaultQR.json";
import qrOptiions from "./defaultQr.json";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { FaArrowLeft } from "react-icons/fa";
const API_URL = process.env.REACT_APP_API_URL;

function Createaccount() {

    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [refrrel, setRefrrel] = useState(false);
    const [refCode, setRefCode] = useState('');
    const [appliedRefCode, setAppliedRefCode] = useState('');
    const [refCodeError, setRefCodeError] = useState(false);
    const [verifyingRefCode, setVerifyingRefCode] = useState(false);

    const [isRefCodeApplied, setIsRefCodeApplied] = useState(false);

    const userRegister = async (e) => {
        e.preventDefault();

        if (refrrel) {
            if (!isRefCodeApplied) {
                PNotify.error({
                    title: 'Oh No!',
                    text: 'Please apply ref code',
                });
                return false;
            }


        }

        //check validation 
        if (fname === "") {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter first name',
            });
            return false;
        }
        if (lname === "") {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter last name',
            });
            return false;
        }
        if (email === "") {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter email',
            });
            return false;
        }
        // check email validation
        const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        if (!emailRegx.test(email)) {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter valid email',
            });
            return false;
        }
        // if (company === "") {
        //     PNotify.error({
        //         title: 'Oh No!',
        //         text: 'Please enter company name',
        //     });
        //     return false;
        // }

        if (password === "") {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter password',
            });
            return false;
        }
        if (confirmPassword === "") {
            PNotify.error({
                title: 'Oh No!',
                text: 'Please enter confirm password',
            });
            return false;
        }
        if (password !== confirmPassword) {
            PNotify.error({
                title: 'Oh No!',
                text: 'Password and confirm password not match',
            });
            return false;
        }

        if (password.length < 6) {
            PNotify.error({
                title: 'Oh No!',
                text: 'Password must be minimum 6 characters long',
            });
            return false;
        }

        const usrData = {
            fname: fname,
            lname: lname,
            email: email,
            password: password,
            // company: company,
            styling: JSON.stringify(qrOptiions),
            role: "0"
        }

        setLoading(true);
        const res = await UserRegistereApi(usrData);
        console.log(res)
        if (res.code == 200) {
            // if (res.data.status == true) {
            PNotify.success({
                title: 'Success!',
                text: res.data.message,
            });
            window.location.href = "/login";
        } else {
            PNotify.error({
                title: 'Oh No!',
                text: res.data.message,
            });
        }
        // } else {
        //     PNotify.error({
        //         title: 'Oh No!',
        //         text: res.data.message,
        //     });
        // }
        setLoading(false);
    }

    const verifyingRefCodeFunc = async () => {
        try {

            setVerifyingRefCode(true);

            const result = await fetch(`${API_URL}/user/referralCodeVerify`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    referral_code: refCode
                }),
            });

            const res = await result.json();

            if (res.status) {
                PNotify.success({
                    title: 'Success!',
                    text: 'Referral code applied successfully',
                });

                setIsRefCodeApplied(true);
                setAppliedRefCode(refCode);

            } else {
                PNotify.error({
                    title: 'Oh No!',
                    text: 'Invalid referral code',
                });
                setRefCodeError(true);
            }
        } catch (error) {
            console.log(error);
            setIsRefCodeApplied(false);
            PNotify.error({
                title: 'Oh No!',
                text: error.message,
            })

        }finally{
            setVerifyingRefCode(false);
        }   
    }
    return (

        <div className="Login">
            <section className="Login-section ">
                <div className="container ">
                    <div className="row d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <div className="col col-xl-12">
                            <div className="Login-card" style={{ borderRadius: '1rem' }}>
                                <div className="row g-0 pt-1 pb-1">
                                    <div className="col-md-6 col-lg-6 d-none d-md-block">
                                        <div className="Login-img" >
                                            <img src={Loginbg} alt="login form" className="img-fluid" style={{ borderRadius: '1rem 0 0 1rem', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 d-flex align-items-center">
                                        <div className="card-body ps-4 pe-4">
                                            <form>
                                                <div className="d-flex align-items-center mb-3 pb-1">
                                                    {/* <i className="fas fa-cubes fa-2x me-3" style="color: #ff6219;"></i> */}
                                                    <span className="h1 fw-bold mb-0"><img src={Logo} alt="logo" className="logo-icon" style={{ width: '80%' }} /></span>
                                                </div>

                                                <h3 className=" login-head mb-3" style={{ letterSpacing: '1px' }}>An easier marketing effort begins here</h3>

                                                {/* <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: '1px' }}>Please sign in to your account and start the adventure</h5> */}

                                                <div className="did-floating-label-content">
                                                    <input type="text" id="form2Example17" className="form-control did-floating-input dark-theam-input" placeholder=""
                                                        value={fname} onChange={(e) => setFname(e.target.value)} />
                                                    <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example17">First name</label>
                                                </div>

                                                <div className="did-floating-label-content">
                                                    <input type="text" id="form2Example17" className="form-control did-floating-input dark-theam-input" placeholder=""
                                                        value={lname} onChange={(e) => setLname(e.target.value)} />
                                                    <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example17">Last name</label>
                                                </div>

                                                <div className="did-floating-label-content">
                                                    <input type="email" id="form2Example27" className="form-control did-floating-input dark-theam-input" placeholder="john.doe@gmail.com"
                                                        value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example27">Email</label>
                                                </div>

                                                {/* <div className="did-floating-label-content">
                                                    <input type="email" id="form2Example27" className="form-control did-floating-input dark-theam-input" placeholder="john.doe@gmail.com"
                                                        value={company} onChange={(e) => setCompany(e.target.value)} />
                                                    <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example27">Organization</label>
                                                </div> */}

                                                {/* <div className="did-floating-label-content">
                                                    <input
                                                        type="text"
                                                        id="form2Example27"
                                                        className="form-control did-floating-input dark-theam-input"
                                                        placeholder="john.doe@gmail.com"
                                                        value={company}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            // Use a regular expression to allow only alphanumeric characters and spaces
                                                            const sanitizedValue = inputValue.replace(/[^A-Za-z0-9 ]/g, '');

                                                            setCompany(sanitizedValue);
                                                        }}
                                                    />
                                                    <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example27">
                                                        Organization
                                                    </label>
                                                </div> */}


                                                <div className="did-floating-label-content">
                                                    <input type="password" id="form2Example27" className="form-control did-floating-input dark-theam-input" placeholder="*******"
                                                        value={password} onChange={(e) => setPassword(e.target.value)} />
                                                    <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example27">Password</label>
                                                </div>
                                                <div className="did-floating-label-content">
                                                    <input type="password" id="form2Example27" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} className="form-control did-floating-input dark-theam-input" placeholder="*******" />
                                                    <label className="form-label did-floating-label dark-theam-lable" htmlFor="form2Example27">Confirm Password</label>
                                                </div>
                                                <div className="referal  d-flex  gap-3">
                                                    <h5>Do you have a referal code?</h5>
                                                    <Form className="d-flex justify-content-start mb-3 gap-4">
                                                        <Form.Check
                                                            type="radio"
                                                            id="flexRadioDefault1"
                                                            name="flexRadioDefault"
                                                            label="Yes"
                                                            checked={refrrel ? true : false}
                                                            onClick={() => {
                                                                setRefrrel(true)
                                                                setIsRefCodeApplied(false)
                                                                setAppliedRefCode('')
                                                                setRefCodeError(false)
                                                            }}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            id="flexRadioDefault2"
                                                            name="flexRadioDefault"
                                                            label="No"
                                                            checked={!refrrel ? true : false}
                                                            onClick={() => {
                                                                setRefrrel(false)
                                                                setIsRefCodeApplied(false)
                                                                setAppliedRefCode('')
                                                                setRefCodeError(false)
                                                            }
                                                            }

                                                        />
                                                    </Form>
                                                </div>

                                                {refrrel &&
                                                    <InputGroup className="referal-code mb-3">
                                                        <FormControl
                                                            type="text"
                                                            disabled={verifyingRefCode}
                                                            placeholder="Enter Referal code"
                                                            value={refCode}
                                                            onChange={
                                                                (e) => {
                                                                    const upperCasedValue = e.target.value.toUpperCase(); // Convert to uppercase
                                                                    setRefCode(upperCasedValue);
                                                                    setIsRefCodeApplied(false);
                                                                    setAppliedRefCode('');
                                                                    setRefCodeError(false);
                                                                
                                                                }
                                                            }

                                                            aria-label="Text input with segmented dropdown button"
                                                        />
                                                        {
                                                            isRefCodeApplied ?
                                                                <Button className="referal-code-verify text-white">Applied</Button>
                                                                :
                                                                <Button
                                                                    disabled={verifyingRefCode || refCode == '' ? true : false}
                                                                    className="referal-code-verify text-white"
                                                                    onClick={() => verifyingRefCodeFunc()}
                                                                >Apply</Button>
                                                        }

                                                    </InputGroup>
                                                }

                                                <div className="form-check d-flex justify-content-start mb-3 gap-1">
                                                    <div>
                                                        <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" defaultChecked />
                                                        <label className="form-check-label" htmlFor="form2Example3">
                                                            I agree to
                                                        </label>
                                                    </div>
                                                    <a href="#!" style={{ color: '#7367f0', textDecoration: 'none', fontWeight: 'bold' }}> privacy policy & terms </a>
                                                </div>

                                                <div className="pt-1 mb-3">

                                                    {
                                                        loading ? (
                                                            <button className="btn custom-login-btn btn-lg btn-block"  >
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                Loading...
                                                            </button>
                                                        ) : <button disabled={verifyingRefCode} className="btn custom-login-btn btn-lg btn-block" type="submit" onClick={userRegister}>Sign Up</button>

                                                    }

                                                </div>
                                                <p style={{ color: '#ffffff', textAlign: 'center' }}>Already have an account?
                                                    <Link to="/login" style={{ color: '#7367f0', textDecoration: 'none', fontWeight: 'bold' }}> Sign in instead </Link></p>

                                                {/* <a className="small text-muted" href="#!">Forgot password?</a>
                                                
                                                <a href="#!" className="small text-muted">Terms of use.</a>
                                                <a href="#!" className="small text-muted">Privacy policy</a> */}
                                            </form>

                                            <div className="back-to-home">
                                                <Link to="/" style={{ color: '#7367f0', textDecoration: 'none', fontWeight: 'bold' }}><FaArrowLeft /> Back to home</Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Createaccount;