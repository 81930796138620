import React, { useContext, useState } from "react";
import { Form, Spinner } from "react-bootstrap";

import moment from 'moment';
import Moment from 'react-moment';

import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TbCopy, TbTrash } from "react-icons/tb";
import { MdEdit, MdClear } from "react-icons/md";
import us from "../../assets/images/country/us.webp";
import india from "../../assets/images/country/india.webp";
import con01 from "../../assets/images/country/con01.webp";
import con02 from "../../assets/images/country/con02.webp";
import con03 from "../../assets/images/country/con03.webp";
import Modal from "react-bootstrap/Modal";
import { SmartLinkContext } from "../../Components/CreateSmartlink/SmartLinkcontext";
import { countrylistapi } from "../../BackendApi/Api/smartsLinkApi";
import { updategeotargetingAPI, addgeotargetingApi, deletegeotargetingApi } from "../../BackendApi/Api/smartsLinkApi";
import { getgeotargetingAPI } from "../../BackendApi/Api/smartsLinkApi";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { Tooltip } from 'react-tooltip'
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import Table from 'react-bootstrap/Table';
import CopyToClipboard from "react-copy-to-clipboard";
import CopyCat from "../CopyCat/CopyCat";


function GeoTargeting() {


    const [loading, setLoading] = useState(false);

    const [show03, setShow03] = React.useState(false);

    const [smartlinkeditdata, setSmartlinkEditData, IosTargeting, setIosTargeting, latest, setLatest, AndroidTargeting, setAndroidTargeting] = useContext(SmartLinkContext);
    const [selectedcountry, setselectedcountry] = React.useState("All Countries");
    const [selectedcountryCode, setselectedcountryCode] = React.useState("ALL");
    const [longUrl, setlongUrl] = React.useState("");
    const [osType, setosType] = React.useState("All OS's");
    const [geotargetingId, setGeotargetingId] = useState();
    const [index, setIndex] = useState(0);

    const [destinationUrl, setDestinationUrl] = useState('');

    const handleClose03 = () => setShow03(false);
    const handleShow03 = () => setShow03(true);

    const [countrylist, setCountrylist] = React.useState([]);
    const [geotargetingdata, setgeotargetingdata] = React.useState([]);

    const [OsList, setOsList] = React.useState([]);

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value);
    };

    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value);
    };

    const os = ["All OS's", "android", "ios", "windows", "mac", "linux"];



    const countrylistdata = () => {
        countrylistapi({}).then((res) => {
            if (res.data.status == true) {
                const apiData = res.data.data;
                setCountrylist(apiData.countryList);
            }
        }
        ).catch((error) => {
            console.log(error);
        });

    }

    React.useEffect(() => {
        countrylistdata();
        if (smartlinkeditdata) {
            setDestinationUrl(smartlinkeditdata.long_url);
            getgeotargetingdata();

        }
    }, [smartlinkeditdata])


    const [geoSaveLoding, setSaveLoding] = useState(false)
    const updateGeoTargeting = () => {
        setSaveLoding(true)
        if (!selectedcountry || selectedcountry == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please select country',
                delay: 2000
            });
            setSaveLoding(false)
        }
        if (longUrl == "") {
            return PNotify.error({
                title: 'Error',
                text: 'Please enter url',
                delay: 2000

            });
            setSaveLoding(false)
        }

        if (!osType || osType == "") {
            return PNotify.error({
                title: 'Error',
                text: 'Please select os type',
                delay: 2000

            });
            setSaveLoding(false)
        }
        if ((endTime && startTime == "") || (startTime && endTime == "")) {
            PNotify.error({
                title: 'Error',
                text: 'Please select both start and end time',
                delay: 2000

            });
            setSaveLoding(false)
            return;
        }
        const formData = {
            "id": geotargetingId,
            "country": selectedcountry,
            "long_url": longUrl,
            "os_type": osType,
            "start_time": startTime,
            "end_time": endTime
        }

        updategeotargetingAPI(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == true) {
                    PNotify.success({
                        title: 'Success',
                        text: 'GeoTargeting Updated Successfully',
                    });
                    const newGeotargetingData = [...geotargetingdata];
                    newGeotargetingData[index] = res.data.data;
                    setgeotargetingdata(newGeotargetingData);
                    PNotify.success({
                        title: 'Success',
                        text: 'GeoTargeting Updated Successfully',
                    });
                    setLatest(!latest);
                    handleClose03();
                    // setSmartlinkEditData(res.data.data);
                }
            }

        }
        ).catch((error) => {
            console.log(error);
        }).finally(() => {
            // Whether success or failure, always set loading state to false
            setSaveLoding(false);
        });


    }

    const getgeotargetingdata = () => {

        const id = {
            "id": smartlinkeditdata._id
        }

        getgeotargetingAPI(id).then((res) => {
            if (res.data.status == true) {
                setgeotargetingdata(res.data.data);
            }
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    console.log("geotargetingdata", geotargetingdata);

    const validateUrl = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    };

    const addgeotargeting = () => {
        if (!selectedcountry || selectedcountry == "") {
            PNotify.error({
                title: 'Error',
                text: 'Please select country',
                delay: 2000
            });
        }
        if (longUrl == "") {
            return PNotify.error({
                title: 'Error',
                text: 'Please enter url',
                delay: 2000

            });
        }

        if (!osType || osType == "") {
            return PNotify.error({
                title: 'Error',
                text: 'Please select os type',
                delay: 2000

            });
        }
        if ((endTime && startTime == "") || (startTime && endTime == "")) {
            PNotify.error({
                title: 'Error',
                text: 'Please select both start and end time',
                delay: 2000

            });
            return;
        }
        // if(endTime==""){
        //     PNotify.error({
        //         title: 'Error',
        //         text: 'Please select end time',
        //         delay: 2000

        //     });
        // }
        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const geotargetingdata = {
            "smartlink_id": smartlinkeditdata._id,
            "country": selectedcountry,
            "countryCode": selectedcountryCode,
            "long_url": longUrl,
            "os_type": osType,
            "start_time": startTime,
            "end_time": endTime,
            "updator_image": user.profile_image,
            "updator_name": user.fname + " " + user.lname

        }

        setLoading(true);
        addgeotargetingApi(geotargetingdata).then((res) => {
            console.log("res", res.data.message);
            if (res.code === 200) {
                getgeotargetingdata();
                PNotify.success({
                    title: 'Success',
                    text: 'GeoTargeting Added Successfully',
                });
                setLatest(!latest);
            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: res.data.message,
                });

            }
        }).catch((error) => {
            alert(error);
            PNotify.error({
                title: 'Error',
                text: error.message,
            });
        }).finally(() => {
            setLoading(false);
        })

    }

    const deleteGeoTargetiong = (id) => {
        const data = {
            "id": id
        }

        deletegeotargetingApi(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status == false) {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                    });
                    // setSmartlinkEditData(res.data.data);
                }
                else if (res.data.status == true) {
                    getgeotargetingdata();
                    PNotify.success({
                        title: 'Success',
                        text: 'GeoTargeting Deleted Successfully',
                    });
                    // setSmartlinkEditData(res.data.data);
                }
                else {
                    PNotify.error({
                        title: 'Error',
                        text: 'Something went wrong',
                    })

                }
            }

        }
        ).catch((error) => {

        })
    }
    const getTimeFORMATampm = (time) => {
        // Ensure 'time' is a valid string
        if (typeof time !== 'string') {
            return 'Invalid time format';
        }

        // Split the time into hours and minutes
        const [hours, minutes] = time.split(':');

        // Parse the hours as an integer
        const hoursInt = parseInt(hours, 10);

        if (isNaN(hoursInt) || hoursInt < 0 || hoursInt > 23) {
            return 'Invalid hour';
        }

        if (minutes < 0 || minutes > 59) {
            return 'Invalid minutes';
        }

        // Determine AM or PM
        const ampm = hoursInt < 12 ? 'AM' : 'PM';

        // Convert to 12-hour format
        const formattedHours = (hoursInt % 12) || 12;

        // Return the formatted time
        return `${formattedHours}:${minutes} ${ampm}`;
    };

    return (
        <>
            {/* new domain modal start here */}
            <Modal show={show03} onHide={handleClose03} className="sidebar-edit-qr Sidebar-Modal-end" >
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="main-card-title">Edit GeoTargeting</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                    <Form.Select aria-label="Default select example" className="did-floating-select" defaultValue={selectedcountry}
                                        onChange={
                                            (e) => {

                                                setselectedcountry(e.target.value);
                                                setselectedcountryCode(e.target && e.target.options[e.target.selectedIndex].getAttribute('country_code'));

                                            }
                                        }>
                                        <option selected disabled>Select Countries</option>
                                        {
                                            countrylist && countrylist.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.label} country_code={item.value}>{item.label}</option>
                                                )
                                            }
                                            )
                                        }
                                    </Form.Select>
                                    <Form.Label className="did-floating-label">Countries</Form.Label>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control className="did-floating-input" type="text" value={longUrl} onChange={
                                        (e) => {
                                            setlongUrl(e.target.value);
                                        }
                                    } placeholder=" " />
                                    <Form.Label className="did-floating-label">Destination / Long URL</Form.Label>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="content-body-form">
                                <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                    <Form.Select aria-label="Default select example" className="did-floating-select" defaultValue={osType} onChange={
                                        (e) => {
                                            setosType(e.target.value);
                                        }
                                    }>
                                        <option selected disabled>Select OS Type</option>
                                        {os && os.map((item, index) => {

                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        })
                                        }
                                    </Form.Select>
                                    <Form.Label className="did-floating-label">OS Type</Form.Label>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="content-body-form col-md-6">
                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">

                                <Form.Control
                                    type="time"
                                    className="did-floating-input"
                                    value={startTime}
                                    onChange={handleStartTimeChange}
                                    placeholder=" "
                                />
                                <button className="btn btn-outline-danger" type="button" onClick={() => setStartTime('')}>Clear</button>

                                <Form.Label className="did-floating-label">Start Time</Form.Label>
                            </Form.Group>
                        </div>


                        <div className="content-body-form col-md-6">
                            <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                    type="time"
                                    format="12-hour"
                                    className="did-floating-input"
                                    value={endTime}
                                    onChange={handleEndTimeChange}
                                    placeholder=" "
                                />
                                <button className="btn btn-outline-danger" type="button" onClick={() => setEndTime('')}>Clear</button>
                                <Form.Label className="did-floating-label">End Time</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={
                        () => {
                            updateGeoTargeting();

                        }
                    }> {geoSaveLoding ? <Spinner /> : " Save"} </button>
                </Modal.Footer>
            </Modal>
            {/* new domain modal end here */}

            <div className="row" >
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                            <Form.Select aria-label="Default select example " className="did-floating-select" onClick={
                                (e) => {
                                    setselectedcountry(e.target.value);
                                    setselectedcountryCode(e.target && e.target.options[e.target.selectedIndex].getAttribute('country_code'));
                                }
                            }>
                                {/* <option selected disabled>Select Countries</option> */}
                                {
                                    countrylist && countrylist.map((item, index) => {
                                        return (
                                            <option key={index} value={item.label} country_code={item.value}>{item.label}</option>
                                        )
                                    }
                                    )
                                }
                            </Form.Select>
                            <Form.Label className="did-floating-label">Countries</Form.Label>
                        </Form.Group>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                            <Form.Select aria-label="Default select example" className="did-floating-select " onChange={
                                (e) => {
                                    setosType(e.target.value);
                                }
                            }>
                                {/* <option selected disabled>Select OS Type</option> */}
                                {os && os.map((item, index) => {

                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                })
                                }
                            </Form.Select>
                            <Form.Label className="did-floating-label">OS Type</Form.Label>
                        </Form.Group>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="content-body-form">
                        <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                            <Form.Control type="text" className="did-floating-input" value={longUrl} onChange={
                                (e) => {
                                    setlongUrl(e.target.value);
                                }
                            } placeholder=" " />
                            <Form.Label className="did-floating-label">Destination / Long URL</Form.Label>
                        </Form.Group>
                    </div>
                </div>


                <div className="content-body-form col-md-6">
                    <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">

                        <Form.Control
                            type="time"
                            className="did-floating-input input-clear-date"
                            value={startTime}
                            onChange={handleStartTimeChange}
                            placeholder=" "
                        />
                        <button className="btn input-clear-btn" type="button" onClick={() => setStartTime('')}>< MdClear /></button>

                        <Form.Label className="did-floating-label">Start Time</Form.Label>
                    </Form.Group>
                </div>


                <div className="content-body-form col-md-6">
                    <Form.Group className="did-floating-label-content" controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                            type="time"
                            format="12-hour"
                            className="did-floating-input input-clear-date"
                            value={endTime}
                            onChange={handleEndTimeChange}
                            placeholder=" "
                        />
                        <button className="btn input-clear-btn" type="button" onClick={() => setEndTime('')}><MdClear /></button>
                        <Form.Label className="did-floating-label">End Time</Form.Label>
                    </Form.Group>
                </div>

            </div>
            <div className="row mt-4">
                <div className="col-md-12 ">
                    <div className='form-btn'>
                        <div className='form-btn-right'>

                            {
                                loading ? (
                                    <SpinnerLoader />
                                ) : (
                                    <button type="button" onClick={addgeotargeting} className="btn bg-green-dark action-btn btn-green">
                                        Add Targeting
                                    </button>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="Update-GeoTargeting-list">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="GeoTargeting-list-left">
                                <h5>Active Routes</h5>

                            </div>
                        </div>

                        <div className="targeting-table-pagination">

                            <Table responsive="xl">
                                <thead>
                                    <tr>
                                        <th>Country</th>
                                        <th>Device</th>
                                        <th>start Time</th>
                                        <th>end Time</th>
                                        <th>Destination</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        geotargetingdata && geotargetingdata.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{item.country || ""}</td>
                                                    <td>{item.os_type || ""}</td>
                                                    <td>{item.start_time ? getTimeFORMATampm(item.start_time) : "-----"}</td>
                                                    <td>{item.end_time ? getTimeFORMATampm(item.end_time) : "-----"}</td>
                                                    <td className="long-url-table-icon">{item.long_url || ""}
                                                        <div className="GeoTargeting-list-icon">
                                                            <CopyCat text={item.long_url}/>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="GeoTargeting-list-edit">

                                                            <button type="button" className="btn btn-editing" onClick={
                                                                (e) => {
                                                                    setselectedcountry(item.country);
                                                                    setselectedcountryCode(item.countryCode);
                                                                    setlongUrl(item.long_url);
                                                                    setosType(item.os_type);
                                                                    setGeotargetingId(item._id);
                                                                    setIndex(index);
                                                                    setStartTime(item.start_time);
                                                                    setEndTime(item.end_time);

                                                                    handleShow03();
                                                                }

                                                            }>
                                                                <MdEdit />
                                                            </button>
                                                            <button type="button" onClick={() => deleteGeoTargetiong(item._id)} className="btn btn-editing">
                                                                <TbTrash color="red" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>


                        </div>


                        <div>
                            <h6>Otherwise go to:</h6><p>{destinationUrl}</p>
                        </div>


                    </div>
                </div>
            </div>

            {/* <Tooltip anchorSelect=".btn-copy" place="top">
                Copy Url
            </Tooltip> */}
        </>

    )
}

export default GeoTargeting;