import { createContext, useState, useReducer, useMemo } from "react";

import { completedPostsReducer } from '../reducers/completedPostsReducer'


export const completedPostsContext = createContext();


export const CompletedPostsProvider = ({ children }) => {

    const initialState = {
        isFetching: true,
        posts: []
    };

    const [completedPosts, completedPostsdispatch] = useReducer(completedPostsReducer, initialState);

    const completedPostsData = useMemo(() => completedPosts, [completedPosts]);

    return (
        <completedPostsContext.Provider value={{
            completedPostsData, completedPostsdispatch
        }}>
            {children}
        </completedPostsContext.Provider>
    );

};
