import React from "react"; 
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft, FaExternalLinkAlt } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { useBuilderContext } from "../../../builderContext";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import *  as PNotify from '@pnotify/core';


export function EditYoutube() {

    const navigate = useHistory();
    const { editComponent } = useBuilderContext();



    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus

    const [status, setStatus] = useState("")


    const[link, setLink] = React.useState({
        link: "",
        });
    useEffect(() => {
        if (mydata) {
            if (mydata.link) {
            setLink({
                link: mydata.link,
            });
        }
        }

        if (showStatus) {
            setStatus(showStatus)
        }
        console.log("useEffect");
    }, []);

    
    

    const handleSaveClick = () => {

        const regex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/g;
        if (!regex.test(link.link)) {
            PNotify.error({
                title: 'Error',
                text: 'Please enter a valid youtube link',
            });
        } else {
            const data = {
                link: link.link.replace("watch?v=", "embed/"),
            }
            editComponent(position, "YOUTUBELINK", data,status);
            navigate.push("/");
        }

    };
    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Youtube</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="position-relative">
                        <div className="form_block">
                            <input type="text" value={link.link} className="form-control did-floating-input" placeholder="" 
                             onChange={(e) => {

                                let youtubeLink = e.target.value;

                                const url = new URL(e.target.value);

                                if (url.searchParams.has("si")) {

                                    const urlParts = youtubeLink.split('/');
                                    const videoId = urlParts[urlParts.length - 1].split('?')[0];

                                    youtubeLink = `https://www.youtube.com/watch?v=${videoId}`
                                   
                                }


                                setLink({ link: youtubeLink });
                            }
                            } /> 
                            <label className="did-floating-label">YouTube Video URL</label>
                        </div>
                        <div className="link_target">
                            <button type="button" className="btn btn-sm">
                                <FaExternalLinkAlt />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={handleSaveClick} >
                    Save
                </button>
            </div>
        </>
    );
}