import React, { useState } from 'react';
import { ImMagicWand } from "react-icons/im";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
const API_URL = process.env.REACT_APP_API_URL;

const AiPosts = ({ insertpost }) => {
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };



    const generatePosts = async () => {
        setLoading(true);
        const data = {
            description: inputValue,
        };
        if (inputValue == "") {
            PNotify.error({
                title: 'Error',
                text:"Please provide a prompt",
            })
            setLoading(false)
            return
        }
        try {
            const response = await fetch(`${API_URL}/socialposts/getaiposts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            console.log('response : ', result);

            if (result.status === true) {
                if (result.data.posts[0] && typeof result.data.posts[0] === 'string') {
                    setResult(result.data.posts[0]);
                } else {
                    generatePosts();
                }
            }
        } catch (error) {
            console.error('Error generating posts:', error);
        } finally {
            setLoading(false);
        }
    };


    const insertpostofai = async () => {

        insertpost(result);

        setResult('');
    }


    return (
        <>
            <div class="modal-header">
                <div class="delete-modal-title modal-title h4">Generate with AI</div>

            </div>

            <div className='modal-body'>
                <div className='ai-input-box'>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Enter your prompt"
                        className='effect-1'
                    />
                    <span class="focus-border"></span>
                </div>
                <div className='ai-btn-box'>
                    <button className='ai-btn' onClick={generatePosts} disabled={loading}>
                        <div className='ai-btn-icon'><ImMagicWand /></div>
                        {loading ? 'Generating...' : 'Generate'}
                    </button>
                </div>
                {loading && <p>Loading...</p>}
                {result &&
                    <>
                        <p className='ai-result'>
                            {result}
                        </p>
                        <button className='ai-btn-insert' onClick={insertpostofai}>Insert</button>
                    </>
                }

            </div>
        </>
    );
};

export default AiPosts;
