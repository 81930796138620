import React from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";

import { useBuilderContext } from "../../../builderContext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function EditSubHeading() {
    const { editComponent } = useBuilderContext();



    const location = useLocation();
    const mydata = location.state?.data;
    const position = location.state?.position;
    const showStatus = location.state?.showStatus

    const [status, setStatus] = useState("")
    console.log("mydata", mydata);


    const[headding, setHeadding] = React.useState({
        headding: "",
        });

    useEffect(() => {
        if (mydata) {
            setHeadding({
                headding: mydata.headding,
            });
        }
        if (showStatus) {
            setStatus(showStatus)
        }
        console.log("useEffect");
    }, []);

    

    const handleSaveClick = () => {
        editComponent(position, "TITLE", headding,status);
    };
    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Sub Heading</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="form-group did-floating-label-content">
                        <textarea className="form-control did-floating-textarea" value={headding.headding} id="headding" rows="3" placeholder="Placeholder"
                         onChange={
                            (e) => {
                                const value = e.target.value;
                                setHeadding(() => ({
                                    headding: value,
                                }));
                            }
                        } />
                        <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label ">Text</label>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={handleSaveClick} >
                    Save
                </button>
            </div>
        </>
    );
}