import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MdTitle } from "react-icons/md";
import { FaChevronRight, FaLongArrowAltLeft } from "react-icons/fa";
import Editor from 'react-simple-wysiwyg';
import { useBuilderContext } from "../../../builderContext";
import { useHistory } from "react-router-dom";
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';
import SunEditor from "suneditor-react";
export function TextList() {
    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdTitle />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Text</h2>
                            <p className="add-block-btn-text-desc">Add a Text to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}



export function TextAdd() {

    const navigate = useHistory();
    const { addComponent } = useBuilderContext();



    const [html, setHtml] = useState({
        html: "",
    });

    const editor = useRef();

    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    useEffect(() => {
        // Update the SunEditor content when the html state changes
        if (editor.current) {
            editor.current.setContents(html.html);
        }
    }, [html])

    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Text</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="form-group mb-2">
                        <label htmlFor="exampleInputEmail1">Text</label>
                        {/* <Editor value={html.html} onChange={(e) => {
                            setHtml({
                                html: e.target.value,
                            });
                        }} /> */}
                        <div>
                            <SunEditor setAllPlugins={true}
                                getSunEditorInstance={getSunEditorInstance}
                                onChange={(content) => {
                                    setHtml({
                                        html: content,
                                    });
                                }}

                                setOptions={{
                                    height: "150px",
                                    plugins: plugins, // Add your plugin configuration here

                                    buttonList: [
                                        ['undo', 'redo'],
                                        ['font', 'fontSize', 'formatBlock'],
                                        ['paragraphStyle', 'blockquote'],
                                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                        ['fontColor', 'hiliteColor',],
                                        ['removeFormat', 'link'],
                                        ['outdent', 'indent'],
                                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                                        ['fullScreen', 'showBlocks', 'codeView'],
                                        ['preview', 'print'],
                                    ],
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={
                    ()=>{
                        addComponent("TEXT",html);
                        navigate.push("/");
                    }
                }>
                    Add
                </button>
            </div>
        </>
    );
}