import React ,{useState,useContext} from "react";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Eye1 from "../../assets/images/icons/1.webp";
import Eye2 from "../../assets/images/icons/2.webp";
import Eye3 from "../../assets/images/icons/3.webp";
import Eye4 from "../../assets/images/icons/4.webp";
import Eye5 from "../../assets/images/icons/5.webp";
import { MdRemoveRedEye } from "react-icons/md";
import { TemplateContext } from "../../TemplateContext";

function Chooseeye() {

    const [pattern, setPattern ,eye,setEye,logo,setLogo,frame,setFrame,patternColorType, setPatternColorType,patternDotsColor, setPatternDotsColor,patternGradientColor, setPatternGradientColor,eyeBallColor, setEyeBallColor,EyeCenterColor, setEyeCenterColor,backgroundcolortype, setBackgroundcolortype,backgroundcolorcode, setBackgroundcolorcode,height, setHeight,width, setWidth,eyeinside, setEyeinside] = React.useContext(TemplateContext);


    return (
        <>
            <Accordion.Header><MdRemoveRedEye />Choose eye</Accordion.Header>
            <Accordion.Body>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Eyes Patterns</Form.Label>
                    <div className="d-flex align-items-center flex-wrap ">
                        <div className="  me-4">
                            <button className="Qr-pattern"><img src={Eye1} alt="Qr" onClick={
                                () => {
                                    setEye("square");
                                    setEyeinside("square");
                                }
                            } className="img-fluid" /></button>
                        </div>
                        <div className="  me-4">
                            <button className="Qr-pattern"><img src={Eye2} alt="Qr" onClick={
                                () => {
                                    setEye('square')
                                    setEyeinside("dot");
                                }   
                            } className="img-fluid" /></button>
                        </div>
                        <div className="  me-4">
                            <button className="Qr-pattern"><img src={Eye3} alt="Qr"  onClick={
                                () => {
                                    setEye("rounded");
                                    setEyeinside("square");
                                }   
                            }  className="extra-rounded" /></button>
                        </div>
                        <div className="  me-4">
                            <button className="Qr-pattern"><img src={Eye4} alt="Qr" onClick={
                                () => {
                                    setEye("rounded");
                                    setEyeinside("dot");
                                }
                            } className="img-fluid" /></button>
                        </div>
                        <div className="  me-4">
                            <button className="Qr-pattern"><img src={Eye5} alt="Qr" onClick={
                                () => {
                                    setEye("extra-rounded");
                                    setEyeinside("square");
                                }
                            }  className="img-fluid" /></button>
                        </div>
                    </div>
                </Form.Group>
            </Accordion.Body>
        </>
    )
}

export default Chooseeye;