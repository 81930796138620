import React, { useLayoutEffect, useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { API_URL } from "../../BackendApi/Api/config";
import { useBuilderContext } from "../../builderContext";
import Loader2 from "../../Components/Loader/Loader2";

// Import your custom components here
import BuilderHeader from "../../PageBuilder/Components/TemplateView/BuilderHeader";
import BuilderTitle from "./flexComponents/BuilderTitle";
import BuilderIcon from "./flexComponents/BuilderIcon";
import BuilderButton from "./flexComponents/BuilderButton";
import BuilderText from "./flexComponents/BuilderText";
import ImageText from "./flexComponents/ImageText";
import YoutubeLink from "./flexComponents/YoutubeLink";
import Spotify from "./flexComponents/Spotify";
import ImageGrid from "./flexComponents/ImageGrid";
import Testimonials from "./flexComponents/Testimonials";
import ContactUs from "./flexComponents/ContactUs";
import Event from "./flexComponents/Event";
import YoutubeIframe from "./flexComponents/YoutubeIframe";
import { useParams } from "react-router-dom";

function BuilderLeft() {

    let {
        setMinipage, color, setColor, buttonColor, setButtonColor,
        fontFamily, setFontFamily, setSmartlink_url, setQrImage,
        myComponents, backgroundType, setBackgroundType,
        BackgroundColor, setBackgroundColor, backgroundColor2,
        setBackgroundColor2, colorPosition, setColorPosition,
        title, setTitle, setDescription, description
    } = useBuilderContext();
    console.log("page is running",)

    const [loading, setLoading] = useState(true);
    const [smartlink_id, setSmartlink_id] = useState('');
    const [tracking_id, setTracking_id] = useState('');
    const [og_image, setOg_image] = useState("");
    const [show404, setShow404] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const { username } = useParams();
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const apiCallMade = useRef(false);
    const backgroundRef = useRef({});
    useEffect(() => {
        if (!apiCallMade.current) {
            fetchPageData();
            apiCallMade.current = true;
        }
    }, [username]);

    const fetchPageData = async () => {
        try {
            console.log("Fetching data for username:", username);
            const url = new URL(window.location.href);
            const hostname = url.hostname;

            let subdomain = (hostname.split('.')[1] === "localhost" || hostname.split('.')[1] === "withastral")
                ? hostname.split('.')[0]
                : null;

            console.log("subdomain", subdomain);

            const headers = subdomain ? { 'Subdomain': subdomain, type: "flexPage" } : { type: "flexPage" };

            // Extract query parameters
            const queryParams = url.searchParams.toString();
            const apiUrlWithParams = queryParams ? `${API_URL}${username}?${queryParams}` : `${API_URL}${username}`;

            const response = await axios.get(apiUrlWithParams, { headers });
            if (response.status === 200 && response.data.status) {
                const resData = response.data.data;
                setTracking_id(resData.tracking_id);
                setMinipage(resData.page_json);
                setColor(resData.color);
                setButtonColor(resData.buttonColor);
                setFontFamily(resData.fontFamily);
                setSmartlink_id(resData.smartlink_id);
                setBackgroundColor(resData.background_color1);
                setBackgroundColor2(resData.background_color2);
                setColorPosition(resData.background_Position);
                setTitle(resData.title);
                setDescription(resData.description);
                setOg_image(resData.og_image);
                setBackgroundType(resData.background_type);
                setLoading(false);
                setInitialLoadComplete(true);

                // Set backgroundType after initial load
                if (initialLoad) {
                    setBackgroundType(resData.background_type);
                    setInitialLoad(false); // Update flag to prevent re-triggering
                }
            } else {
                setLoading(false);
                setShow404(true);
            }
        } catch (err) {
            console.error("Error fetching page data:", err);
            setLoading(false);
            setShow404(true);
        }
    };





    const title1 = "Sample Digital Card";
    const description1 = "This is a sample digital card description.";
    const og_image1 = "https://th.bing.com/th/id/OIP.sqQnyn3zwW_Zh0U6amTuawHaLH?rs=1&pid=ImgDetMain";
    const generateMetaTags = () => {
        const metaTags = [
            { name: "title", content: `Digital Card Of ${title1}` },
            { name: "description", content: description1 },
            { property: "og:title", content: `Digital Card Of ${title1}` },
            { property: "og:description", content: description1 },
            { property: "og:url", content: og_image1 },//`${API_URL}ogimages/${og_image}`
            { name: "twitter:card", content: "summary" },
            { name: "twitter:site", content: "@astral" },
            { name: "twitter:title", content: title1 },
            { name: "twitter:description", content: description1 },
        ];

        if (og_image) {
            metaTags.push({ property: "og:image", content: og_image1 });//`${API_URL}ogimages/${og_image}`
            metaTags.push({ name: "twitter:image", content: og_image1 });//`${API_URL}ogimages/${og_image}`
        }

        return metaTags;
    };

    const directionMap = {
        "topbottom": "to bottom",
        "bottomtop": "to top",
        "rightleft": "to left",
        "leftright": "to right"
    };
    
    // Default to 'to right' if colorPosition doesn't match any key
    const resolvedDirection = directionMap[colorPosition] || "to right";
    const backgroundStyle = useMemo(() => {
        if (backgroundType === "1") {
            return { backgroundColor: BackgroundColor };  // Changed from background to backgroundColor
        } else if (backgroundType === "2") {
            return { backgroundImage: `linear-gradient( ${resolvedDirection},${BackgroundColor}, ${backgroundColor2})` };
        } else {
            return { backgroundColor: BackgroundColor };  // Changed from background to backgroundColor
        }
    }, [backgroundType, BackgroundColor, backgroundColor2, colorPosition]);
    console.log("backgroundColor2", backgroundColor2)
    console.log("bgType", typeof backgroundType)
    console.log("backgroundType", backgroundType)
    console.log("BackgroundColorsingle", BackgroundColor)
    console.log("backgroundRef.current", backgroundStyle)
    console.log("myComponent32s", myComponents)
    
    return (
        <>
            <Helmet>
                {generateMetaTags().map((tag, index) => (
                    <meta key={index} {...tag} />
                ))}
            </Helmet>

            {loading ? (
                <Loader2 />
            ) : show404 ? (
                <div className="page404">
                    <div className="page404-box">
                        <div className="page404-content text-center">
                            <h1>Look like you're lost in Space </h1>
                            <div className="position-relative">
                                <div className="page404-img">
                                    <img src={require('../../landingassets/Images/404.png')} alt="404" className='img-page404' />
                                </div>
                                <h2>404</h2>
                            </div>
                            <div className="button-404" onClick={() => { window.location.href = "https://withastral.com"; }}>
                                <a>Go Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className={"preview-box-background"}
                    style={backgroundStyle}
                >

                    <div className={`page_builder_design_body home-preview-body`}  >
                        <div className={`builder-box-design public_flex_design goWHMr ${color}`} style={{ fontFamily: fontFamily }}>
                            <div className="builder_content">
                                {myComponents.map((component, index) => (
                                    <React.Fragment key={index}>
                                        {component.type === "HEADER" ? <BuilderHeader data={component.properties} /> :
                                            component.type === "TITLE" && component?.showStatus === true ? <BuilderTitle data={component.properties} /> :
                                                component.type === "ICONS" && component?.showStatus === true ? <BuilderIcon data={component.properties} /> :
                                                    component.type === "BUTTON" && component?.showStatus === true ? <BuilderButton data={component.properties} buttonColor={buttonColor} /> :
                                                        component.type === "TEXT" && component?.showStatus === true ? <BuilderText data={component.properties} /> :
                                                            component.type === "IMAGETEXT" && component?.showStatus === true ? <ImageText data={component.properties} /> :
                                                                component.type === "YOUTUBELINK" && component?.showStatus === true ? <YoutubeLink selectedbtn={component.selectedValue} data={component.properties}  smartlink_id={smartlink_id}/> :
                                                                    component.type === "SPOTIFY" && component?.showStatus === true ? <Spotify data={component.properties} /> :
                                                                        component.type === "IMAGEGRID" && component?.showStatus === true ? <ImageGrid data={component.properties}  /> :
                                                                            component.type === "YOUTUBEIFRAME" && component?.showStatus === true ? <YoutubeIframe selectedbtn={component.selectedValue} data={component.properties} smartlink_id={smartlink_id} /> :
                                                                                component.type === "EVENT" && component?.showStatus === true ? <Event data={component.properties} /> :
                                                                                    component.type === "CONTACTUS" && component?.showStatus === true ? <ContactUs data={component.properties} /> :
                                                                                        component.type === "TESTIMONIAL" && component?.showStatus === true ? <Testimonials data={component.properties} /> :
                                                                                            null
                                        }
                                    </React.Fragment>
                                ))}
                                <div className="company_name">
                                    <h5>Powered with Astral</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BuilderLeft;