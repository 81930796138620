
export function scheduledPostsReducer(state, action) {
    switch (action.type) {
      case 'SET_SCHEDULED_POSTS':
        return {
          ...state,
          posts: [...action.payload],
        }
      
      
      default:

        return state
        
    }
  }
  