import { createContext } from "react";
import { useState } from "react";

export const CampaignContext = createContext();

export const CampaignProvider = (props) => {

    const [individualCampaign, setIndividualCampaign] = useState({});

    return (
        <CampaignContext.Provider value={{individualCampaign,setIndividualCampaign}}>
            {props.children}
        </CampaignContext.Provider>
    );
}

