import React, { useState, useEffect } from 'react';
import "../../landingassets/Css/Style.css"
import Header from "../../LandingComponent/Header/Header";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Slider from "../../LandingComponent/Slider/Slider";
import { FaQuoteRight, FaFacebookF, FaTwitter, FaYoutube, FaWhatsapp } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { TbComponents, TbTemplate } from "react-icons/tb";
import { MdOutlineQrCode, MdPages } from "react-icons/md";
import 'swiper/css/pagination';

function Home() {  

  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos) {
        setIsScrolled(true); // Scrolling down
      } else {
        setIsScrolled(false); // Scrolling up
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);



  return (
    <>
      <div className="body-section">

        <section className={`header-section ${isScrolled ? 'onscroll-class' : ''}`}>
          <Header />
        </section>

        <section className="hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="hero-content">
                  <div className="hero-title">
                    <h1 className="h1">Attract, Grow & Maximize Connections everywhere.</h1>
                  </div>
                  <div className="hero-description">
                    <p>Improve reach & conversion with your audience by publishing branded, trackable, dynamic shortURLs, QR codes, and Flexible landing pages in a simple easy to use unified platform</p>
                  </div>
                  <div className="hero-btn-group">
                    <a href="#" className="btn btn-color">Start your Free Trial</a>
                    {/* <a href="#" className="btn btn-outline">Sign in</a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="images-content-wrap">
                  <div className="hero-image">
                    <img src={require('../../landingassets/Images/hero-img.png')} alt="hero" />
                  </div>
                  <div className="img-smartlink">
                    <img src={require('../../landingassets/Images/smartlink.png')} alt="smartlink" />
                  </div>
                  <div className="img-qrcode">
                    <img src={require('../../landingassets/Images/qr-code.png')} alt="qrcode" />
                  </div>
                  <div className="img-visitor">
                    <img src={require('../../landingassets/Images/visitor.png')} alt="visitor" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-section custom-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-content">
                  {/* <div className="gradient-small-title">
                    <h6 className="h6">Our story</h6>
                  </div> */}
                  <div className="about-title main-title">
                    <h2 className="h2">Why are we building Astral?</h2>
                  </div>
                  <div className="description">
                    <p>Forging genuine connection with your audience can feel like catching a shooting star. It's not just about one grand gesture but a constellation of small, impactful, and convenient interactions that illuminate your brand's essence. This is what will allow prospects to gravitate naturally towards you.</p>
                    <p>Enter Astral: your compass in marketing engagement. We recognized the endless cycle of piecing together fragmented marketing tools. We understand the pain of a 1000 paper cuts.</p>
                    <p>We built Astral as a unified platform of smart interconnected tools that's easy to use and track. Our mission is to elevate & maximize the impact of your efforts and understand your connections.</p>
                    <p>Let's make your marketing truly out-of-this-world! 🌠 🚀</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-image">
                  <img src={require('../../landingassets/Images/about-bg.png')} alt="about" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="client-section custom-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="client-bg-box">
                  <div className="client-title">
                    <h6 className="h6">Trusted By Users And Teams Of All Sizes</h6>
                  </div>
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                      320: {
                        slidesPerView: 2,
                      },
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                      },
                      1300: {
                        slidesPerView: 5,
                        spaceBetween: 30,
                      }
                    }}
                  >
                    <SwiperSlide>
                      <div className="slider-image">
                        <img src={require('../../landingassets/Images/image 119.png')} alt="client" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slider-image">
                        <img src={require('../../landingassets/Images/image 120.png')} alt="client" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slider-image">
                        <img src={require('../../landingassets/Images/image 121.png')} alt="client" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slider-image">
                        <img src={require('../../landingassets/Images/image 122.png')} alt="client" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slider-image">
                        <img src={require('../../landingassets/Images/image 123.png')} alt="client" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slider-image">
                        <img src={require('../../landingassets/Images/image 124.png')} alt="client" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="our-details-section custom-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="gradient-small-title">
                  <h6 className="h6">Why Love Astral</h6>
                </div>
                <div className="our-details main-title">
                  <h2 className="h2">Be the most able marketer, Astral helps you avoid those &#8220;oh shit" moments.</h2>
                </div>
                <div className="our-details-slider">
                  <Slider />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="branding-section custom-padding">
          <div className="container">
            <div className="row align-items-center custom-padding">
              <div className="col-lg-6">
                <div className="branding-details">
                  {/* <div className="gradient-small-title">
                    <h6 className="h6">How it works</h6>
                  </div> */}
                  <div className="about-title main-title">
                    <h2 className="h2">< TbComponents /> Flexlinks</h2>
                  </div>
                  <div className="description">
                    <p>Custom branded short URLs personalized & easy-to-remember web links that can change where they lead even after you've shared them. They boost your brand's visibility and make it simple for people to remember your web addresses. Plus, you can track how popular they are and tweak where they go.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="branding-image">
                  <img src={require('../../landingassets/Images/smartlink-template.png')} alt="branding" />
                </div>
              </div>
            </div>
            <div className="row align-items-center custom-padding">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="branding-image">
                  <img src={require('../../landingassets/Images/qrcode.png')} alt="branding" />
                </div>
              </div>
              <div className="col-lg-6 order-md-1">
                <div className="branding-details">
                  {/* <div className="gradient-small-title">
                    <h6 className="h6">How it works</h6>
                  </div> */}
                  <div className="about-title main-title">
                    <h2 className="h2">< MdOutlineQrCode /> QR Codes</h2>
                  </div>
                  <div className="description">
                    <p>Make connections look good by matching your brand & products perfectly with a smart QR. You can change colors, add logos, captions, and borders, making them unique and eye-catching. This not only boosts recognition but also enhances customer engagement. It's a powerful tool for connecting offline and online marketing efforts seamlessly.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center custom-padding">
              <div className="col-lg-6">
                <div className="branding-details">
                  {/* <div className="gradient-small-title">
                    <h6 className="h6">How it works</h6>
                  </div> */}
                  <div className="about-title main-title">
                    <h2 className="h2"><TbTemplate />Brand Templates</h2>
                  </div>
                  <div className="description">
                    <p>A time-saving gem that lets you create presets for colors, text styles, logos, and even social media URLs. When making new Flexlinks and QR codes, these templates ensure consistency and quick customization. Say goodbye to repetitive design work and hello to a seamless, branded experience.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="branding-image">
                  <img src={require('../../landingassets/Images/Brand-Template.png')} alt="branding" />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="branding-image">
                  <img src={require('../../landingassets/Images/flex-page.png')} alt="branding" />
                </div>
              </div>
              <div className="col-lg-6 order-md-1">
                <div className="branding-details">
                  {/* <div className="gradient-small-title">
                    <h6 className="h6">How it works</h6>
                  </div> */}
                  <div className="about-title main-title">
                    <h2 className="h2"><MdPages /> Flex Pages</h2>
                  </div>
                  <div className="description">
                    <p>Your go-to solution for creating sleek, fast, branded mini web pages. Think of them like your business or personal Link In Bio or Landing Page. With Flex Pages, you can effortlessly add buttons, images, galleries, videos, and more. They're user-friendly, shareable, and perfect for showcasing your content in a clean and stylish way.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className='testimonial-section custom-padding custom-padding2'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='testimonial-box'>
                  <div className='rating-icon-list'>
                    <img src={require('../../landingassets/Images/rating.png')} alt='rating' />
                    <FaQuoteRight />
                  </div>
                  <div className='testimonial-content'>
                    Tech Ai Saves Us Thousands Of Dollars A Week. We Create Content Briefs And Write 50-100 Unique SEO-Driven Pieces Per Month. With Tech.Ai, We Now Craft Briefs, Outlines, And Full First.
                  </div>
                  <div className='profile-details'>
                    <div className='profile-image-home'>
                      <img src={require('../../landingassets/Images/profile.jpg')} alt='profile' />
                    </div>
                    <div className='profile-name'>
                      <h6>Jaquon Hart</h6>
                      <span>CEO, ABC Company</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='testimonial-box'>
                  <div className='rating-icon-list'>
                    <img src={require('../../landingassets/Images/rating.png')} alt='rating' />
                    <FaQuoteRight />
                  </div>
                  <div className='testimonial-content'>
                    Tech Ai Saves Us Thousands Of Dollars A Week. We Create Content Briefs And Write 50-100 Unique SEO-Driven Pieces Per Month. With Tech.Ai, We Now Craft Briefs, Outlines, And Full First.
                  </div>
                  <div className='profile-details'>
                    <div className='profile-image-home'>
                      <img src={require('../../landingassets/Images/profile.jpg')} alt='profile' />
                    </div>
                    <div className='profile-name'>
                      <h6>Jaquon Hart</h6>
                      <span>CEO, ABC Company</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='testimonial-box'>
                  <div className='rating-icon-list'>
                    <img src={require('../../landingassets/Images/rating.png')} alt='rating' />
                    <FaQuoteRight />
                  </div>
                  <div className='testimonial-content'>
                    Tech Ai Saves Us Thousands Of Dollars A Week. We Create Content Briefs And Write 50-100 Unique SEO-Driven Pieces Per Month. With Tech.Ai, We Now Craft Briefs, Outlines, And Full First.
                  </div>
                  <div className='profile-details'>
                    <div className='profile-image-home'>
                      <img src={require('../../landingassets/Images/profile.jpg')} alt='profile' />
                    </div>
                    <div className='profile-name'>
                      <h6>Jaquon Hart</h6>
                      <span>CEO, ABC Company</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='testimonial-box'>
                  <div className='rating-icon-list'>
                    <img src={require('../../landingassets/Images/rating.png')} alt='rating' />
                    <FaQuoteRight />
                  </div>
                  <div className='testimonial-content'>
                    Tech Ai Saves Us Thousands Of Dollars A Week. We Create Content Briefs And Write 50-100 Unique SEO-Driven Pieces Per Month. With Tech.Ai, We Now Craft Briefs, Outlines, And Full First.
                  </div>
                  <div className='profile-details'>
                    <div className='profile-image-home'>
                      <img src={require('../../landingassets/Images/profile.jpg')} alt='profile' />
                    </div>
                    <div className='profile-name'>
                      <h6>Jaquon Hart</h6>
                      <span>CEO, ABC Company</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <div className='clear'></div>


        <section className='contact-section custom-padding'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='contact-box'>
                  <div className='join-button'>
                    <a href="#" className="btn btn-color">Get Started</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className='footer-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='footer-box'>
                  <div className='footer-logo'>
                    <img src={require('../../landingassets/Images/logo.png')} alt='logo' />
                  </div>
                  <div className='contact-details'>
                    Astral is an all in one platform to attract, track & manage connections and engagement.
                  </div>
                  <div className="support"><a href="hello@withastral.tech">hello@withastral.tech</a></div>
                  <div className='footer-end'>
                    <div className='footer-copyright'>
                      <p>Copyright © 2023 Astral</p>
                    </div>
                    <div className='footer-social'>
                      <ul>
                        <li className='footer-icon'>
                          <a href="#"><FaFacebookF /></a>
                        </li>
                        <li className='footer-icon'>
                          <a href="#"><FaTwitter /></a>
                        </li>
                        <li className='footer-icon'>
                          <a href="#"><FaYoutube /></a>
                        </li>
                        <li className='footer-icon'>
                          <a href="#"><RiWhatsappFill /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Home;