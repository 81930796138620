import React from "react";
import { Link } from "react-scroll";
import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";

function BuilderButton(props) {
    console.log("my button props", props);

    const saveClicks = (smartlink_id, unique_id) => {
        saveComponentsClicks({
            unique_id: unique_id,
            smartlink_id: smartlink_id,

        });
    }

    return (
        <>
            <div className="button_content_box">
                <div className="small_favicon">
                    <img src={props.data.icon} />
                </div>
                <a href={props.data.link} target="_blank">
                    <button onClick={() => { saveClicks(props.smartlink_id, props.data.unique_id) }} className="btn link-btn-view bg_aqw" style={{ backgroundColor: props.buttonColor }}>{props.data.lable}</button>
                </a>
                <p>{props.data.url}</p>
            </div>
        </>
    );
}

export default BuilderButton;