import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import InputGroup from 'react-bootstrap/InputGroup';
import { HiLink } from "react-icons/hi";

import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';

const API_URL = process.env.REACT_APP_API_URL + "/";

const SocialChannelsList = ({ channelCategory, socialChannels }) => {

    const url = window.location.href;
    const urlSplit = url.split('/');
    const urlbrandId = urlSplit[urlSplit.length - 1];


    const userdata = localStorage.getItem("user");
    const user = JSON.parse(userdata);
    const [channels, setChannels] = useState([]);

    useEffect(() => {
        const filtered = socialChannels.filter((item) => item.channel_category === channelCategory);
        setChannels(filtered);
    }, [socialChannels]);

    const updateChannelStatus = async (id, status, index) => {

        console.log(id, status, index)
        setChannels((prev) => {
            const newchannels = [...prev];
            newchannels[index].isActive = !newchannels[index].isActive;
            return newchannels;

        })

        try {

            let data = JSON.stringify({
                "social_channel_id": id,
                "status": status,
                brand_id: urlbrandId,
                updator_name: user.fname + "" + user.lname,
                updator_image: user.profile_image
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_URL}branding/updatesocialchannelstatus`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const response = await axios.request(config)

            console.log(response.data);

            if (response.data.status) {
                console.log("updated channel status");

            }
            else {
                setChannels((prev) => {
                    const newchannels = [...prev];
                    newchannels[index].isActive = !newchannels[index].isActive;
                    return newchannels;

                })
            }

        }
        catch (error) {
            console.log(error);
        }
    };

    const updateChannelUrl = async (id, url) => {

        if (url == '') {
            PNotify.error({
                title: 'Error',
                text: 'Please enter url',
                delay: 1000,
            });
            // alert("Please enter url");
            return;
        }

        try {

            let data = JSON.stringify({
                "social_channel_id": id,
                "url": url,
                brand_id: urlbrandId,
                updator_name: user.fname + "" + user.lname,
                updator_image: user.profile_image
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_URL}branding/updatesocialchannelsurl`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const response = await axios.request(config)

            console.log(response.data);

            if (response.data.status) {
                // console.log("updated channel status");
                PNotify.success({
                    title: 'success',
                    text: 'updated channel status',
                    delay: 1000,
                });
            }
            else {
                console.log("failed to update channel status");
            }

        }
        catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {channels &&
                channels.map((item, index) => {
                    return (
                        <>
                            {/* <li className="qr-template-list-bg">
                                <div className="row">
                                    <div className='col-lg-10'>
                                        <div className="d-flex align-items-center justify-content-between custom-input">
                                            <InputGroup controlId="formBasicEmail" className="mb-3 me-5">
                                                <InputGroup.Text id="inputGroup-sizing-lg">
                                                    <img src={API_URL + `channels/` + item.channel_icon} alt="" />

                                                </InputGroup.Text>
                                                <Form.Control aria-label="Large"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    placeholder="Other URL"
                                                    value={item.url}
                                                    onChange={(e) => {
                                                        setChannels((prev) => {
                                                            const newchannels = [...prev];
                                                            newchannels[index].url = e.target.value;
                                                            return newchannels;
                                                        })
                                                    }}
                                                />
                                            </InputGroup>

                                            <button
                                                className='btn btn-primary btn-sm'
                                                onClick={
                                                    () => {
                                                        updateChannelUrl(item._id, item.url);
                                                    }
                                                }
                                            >Update</button>
                                        </div>
                                    </div>
                                    <div className='col-lg-2'>
                                        <div className="Domain-table-status">
                                            <Form.Check 
                                                checked={item.isActive}
                                                type="switch"
                                                id="custom-switch"
                                                onChange={
                                                    () => {

                                                        const id = item._id
                                                        const status = item.isActive

                                                        updateChannelStatus(id, status, index);

                                                    }
                                                }

                                            />
                                        </div>

                                    </div>
                                </div>

                            </li> */}
                            <li style={{
                                listStyleType: 'none',
                                marginBottom: '15px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#f8f9fa',
                                    borderRadius: '8px',
                                    padding: '15px'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexGrow: 1,
                                        marginRight: '15px'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: '15px',
                                            width: '150px'  // Adjust this width as needed
                                        }}>
                                            <div style={{
                                                width: '40px',
                                                height: '40px',
                                                marginRight: '10px'
                                            }}>
                                                <img
                                                    src={`${API_URL}channels/${item.channel_icon}`}
                                                    alt={`${item.channel_name} icon`}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            </div>
                                            <span style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {item.channel_name}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Enter URL"
                                            value={item.url}
                                            onChange={(e) => {
                                                setChannels((prev) => {
                                                    const newChannels = [...prev];
                                                    newChannels[index].url = e.target.value;
                                                    return newChannels;
                                                });
                                            }}
                                            style={{
                                                width: '300px',
                                                padding: '10px',
                                                border: '1px solid #ced4da',
                                                borderRadius: '4px',
                                                fontSize: '14px'
                                            }}
                                        />
                                        <button
                                            onClick={() => updateChannelUrl(item._id, item.url)}
                                            style={{
                                                backgroundColor: '#007bff',
                                                color: 'white',
                                                border: 'none',
                                                padding: '10px 15px',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                transition: 'background-color 0.3s',
                                                marginLeft: '10px'
                                            }}
                                        >
                                            Update
                                        </button>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 10px'
                                    }}>
                                        <span style={{
                                            marginRight: '10px',
                                            fontSize: '14px',
                                            color: item.isActive ? '#4CAF50' : '#757575'
                                        }}>
                                            {item.isActive ? 'Active' : 'Inactive'}
                                        </span>
                                        <label style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            width: '60px',
                                            height: '34px'
                                        }}>
                                            <input
                                                type="checkbox"
                                                checked={item.isActive}
                                                onChange={() => updateChannelStatus(item._id, item.isActive, index)}
                                                style={{
                                                    opacity: 0,
                                                    width: 0,
                                                    height: 0
                                                }}
                                            />
                                            <span style={{
                                                position: 'absolute',
                                                cursor: 'pointer',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundColor: item.isActive ? '#4CAF50' : '#ccc',
                                                transition: '.4s',
                                                borderRadius: '34px',
                                            }}>
                                                <span style={{
                                                    position: 'absolute',
                                                    content: '""',
                                                    height: '26px',
                                                    width: '26px',
                                                    left: item.isActive ? '30px' : '4px',
                                                    bottom: '4px',
                                                    backgroundColor: 'white',
                                                    transition: '.4s',
                                                    borderRadius: '50%',
                                                }}></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </li>
                        </>
                    );
                })}
        </>
    );
};

export default SocialChannelsList;