import React, { createContext, useState } from "react";
export const TemplateContext = createContext();




export const TemplateProvider = (props) => {


    const [pattern, setPattern] = useState("rounded");
    const [eye, setEye] = useState("square");
    const [eyeinside, setEyeinside] = useState("square");
    const [logo, setLogo] = useState(undefined);
    const [frame, setFrame] = useState("");
    const [patternColorType, setPatternColorType] = useState("color"); // this is use to set radio button value which is "color" or "gradient"
    const [patternDotsColor, setPatternDotsColor] = useState("#000000"); // this is use to set color code of pattern dots
    const [patternGradientColor, setPatternGradientColor] = useState(""); // this is use to set gradient color code of pattern
    const [eyeBallColor, setEyeBallColor] = useState("#000000"); // this is use to set color code of eye ball
    const [EyeCenterColor, setEyeCenterColor] = useState("#000000"); // this is use to set color code of eye center
    const [backgroundcolortype, setBackgroundcolortype] = useState("solid"); // this is use to set radio button value which is "solid" or "transparent"
    const [backgroundcolorcode, setBackgroundcolorcode] = useState("#ffffff"); // this is use to set color code of background color
    const [height, setHeight] = useState(512);
    const [width, setWidth] = useState(512);
    const [caption, setCaption] = useState("Scan Me");

    const resetTemplateData = () => {
        setPattern("rounded");
        setEye("square");
        setEyeinside("square");
        setLogo(undefined);
        setFrame("");
        setPatternColorType("color");
        setPatternDotsColor("#000000");
        setPatternGradientColor("");
        setEyeBallColor("#000000");
        setEyeCenterColor("#000000");
        setBackgroundcolortype("solid");
        setBackgroundcolorcode("#ffffff");
        setHeight(512);
        setWidth(512);
        setCaption("Scan Me");
    }


    return (
        <TemplateContext.Provider value={[pattern, setPattern ,eye,setEye,logo,setLogo,frame,setFrame,patternColorType, setPatternColorType,patternDotsColor, setPatternDotsColor,patternGradientColor, setPatternGradientColor,eyeBallColor, setEyeBallColor,EyeCenterColor, setEyeCenterColor,backgroundcolortype, setBackgroundcolortype,backgroundcolorcode, setBackgroundcolorcode,height, setHeight,width, setWidth,eyeinside, setEyeinside,caption, setCaption,resetTemplateData]}>
            {props.children}
        </TemplateContext.Provider>
    );
}

