import React from "react";
import { MdSmartButton } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import BuilderButton from "../TemplateView/BuilderButton";
import { useBuilderContext } from "../../../builderContext";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export function ButtonLinkList() {

    return (
        <>
            <div className="add_block_list_item">
                <button className="add-block-button-wrapper">
                    <div className="d-flex">
                        <div className="add-block-btn-icon">
                            <MdSmartButton />
                        </div>
                        <div className="add-block-btn-text">
                            <h2 className="add-block-btn-text-title">Button Link</h2>
                            <p className="add-block-btn-text-desc">Add a button to your post</p>
                        </div>
                    </div>
                    <div className="right_arrow">
                        <FaChevronRight />
                    </div>
                </button>
            </div>
        </>
    );
}


export function ButtonLinkAdd() {
    const navigate = useHistory();
    const { addComponent } = useBuilderContext();

    const [btnpropties, setBtnpropties] = React.useState({
        lable: "Button",
        link: "#"
    });





    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Add Button Link</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="form-group did-floating-label-content">
                        <div className="form_block">
                            <input type="text" className="form-control did-floating-input" placeholder=""
                                onChange={
                                    (e) => {
                                        setBtnpropties({ ...btnpropties, lable: e.target.value })
                                    }
                                } />
                            <label htmlFor="exampleInputEmail1" className="gvofes did-floating-label">Label</label>
                        </div>
                    </div>
                    <div className="form-group did-floating-label-content">
                        <div className="form_block">
                            <input type="text" className="form-control did-floating-input" placeholder=""
                                onChange={
                                    (e) => {
                                        setBtnpropties({ ...btnpropties, link: e.target.value })
                                    }
                                } />
                            <label className="gvofes did-floating-label">Link</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="add-block-btn">
                <button className="btn publish-btn" onClick={
                    () => {
                        const unique_id = Date.now() + uuidv4();

                        const buttonprops = btnpropties;

                        buttonprops.unique_id = unique_id;

                        addComponent("BUTTON", buttonprops);


                        navigate.push("/");
                    }
                }>
                    Add
                </button>
            </div>
        </>
    );
}