import React, { useEffect, useState, useContext } from 'react';
import { Tabs, Tab, Modal } from "react-bootstrap";
import { FaCalendarAlt, FaFileAlt, FaCheckSquare, FaArchive } from "react-icons/fa";

import ScheduledPosts from "../SocialContent/scheduledposts/scheduledposts"
import CompletedPosts from "../SocialContent/completedposts/completedposts"
import DraftPosts from "../SocialContent/draftedposts/draftedposts"
import ArchivePosts from "../SocialContent/archivedposts/archivedposts"
import { MdSchedule } from "react-icons/md";
import { TbSocial } from "react-icons/tb";
import { Button } from 'react-bootstrap';
import Bulkschedule from "../SocialContent/bulkschedule"

import { socialAccountContext } from "../contexts/context/socialAccountContext";

import { useParams, useHistory, useLocation } from 'react-router-dom';


function ChannelPost() {


    const { id } = useParams();

    const history = useHistory();

    const { connectedAccount } = useContext(socialAccountContext);
    const location = useLocation();


    const [accountData, setAccountData] = useState({});
    const [type, setType] = useState("");
    const [show, setShow] = useState(false);
    const handleconfirmClose = () => {
        const shouldClose = window.confirm('Are you sure want to quit all your changes will be lost ?');
        if (shouldClose) {
            setShow(false);
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        if (connectedAccount.accounts) {

            if (id == "all") {
                setType("ALL");
                setAccountData({});
            }
            else {
                const account = connectedAccount.accounts.find((account) => account._id == id);

                if (account) {
                    setAccountData(account);
                    setType(account.platform);
                }
                else {
                    history.push("/social_design/calendar");
                }
            }
            
        }
    }, [connectedAccount.accounts, id, history]);

    useEffect(() => {

        const urlParams = new URLSearchParams(location.search);
        const source = urlParams.get('source');

        console.log("this is the source", source);
        if (source === 'canva_redirect') {
            handleShow();
        }

    }, []);

    return (
        <>
            <Modal show={show} onHide={handleconfirmClose} centered size="lg" className='Sidebar-Modal-end' backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><MdSchedule />Create Post</Modal.Title>
                </Modal.Header>
                {type === "ALL" ? <Bulkschedule closemodal={handleClose} method="all" accountdata={accountData} /> : <Bulkschedule closemodal={handleClose} method="channel" accountdata={accountData} />}

            </Modal>

            <div className="col-md-12">
                <div className="add-btn d-flex Add-margin">
                    <h5 className="tab-content-title"><div className="tab-content-icon"><MdSchedule /></div>{type} - Social Posts</h5>
                    <div className="tab-content-header-right d-flex">
                        <Button className="btn btn-sm float-right add-user-btn" onClick={handleShow} ><TbSocial />New Post</Button>
                    </div>
                </div>
                <Tabs
                    defaultActiveKey="schedule"
                    id="justify-tab-example"
                    className="mb-3 flex-page-tabs-scroll"
                >
                    <Tab eventKey="schedule" title={<><FaCalendarAlt /> &nbsp; Scheduled</>} >
                        <ScheduledPosts type={type} accountdata={accountData} />
                    </Tab>

                    <Tab eventKey="draft" title={<><FaFileAlt /> &nbsp; Drafts</>} >
                        <DraftPosts type={type} accountdata={accountData} />
                    </Tab>

                    <Tab eventKey="completed" title={<><FaCheckSquare /> &nbsp; Completed</>} >
                        <CompletedPosts type={type} accountdata={accountData} />
                    </Tab>

                    <Tab eventKey="archive" title={<><FaArchive /> &nbsp; Archived</>} >
                        <ArchivePosts type={type} accountdata={accountData} />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

export default ChannelPost;