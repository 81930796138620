import {createContext,useState} from 'react';

export const sessionContext = createContext();

export const SessionProvider = ({children}) => {

    const [sessionName, setSessionName] = useState('');
    const [sessionDescription, setSessionDescription] = useState('');
    const [SessionData, setSessionData] = useState([]);
    const [godZilla, setGodZilla] = useState('');

    return (
        <sessionContext.Provider value={{sessionName, setSessionName,sessionDescription,setSessionDescription,SessionData,setSessionData,godZilla, setGodZilla}}>
            {children}
        </sessionContext.Provider>
    );
}



