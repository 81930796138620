import React from "react";
import Form from 'react-bootstrap/Form';
import Profile from "../../assets/images/Avatar03.webp";
import { MdAlternateEmail, MdEdit, MdOutlineLocationOn } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { TbCalendarPlus } from "react-icons/tb";
import { useState } from "react";
import ReactDOM from 'react-dom'
import TimezoneSelect from 'react-timezone-select'
import { UserUpdateApi } from "../../BackendApi/Api/userApi";
import timezone from './timezone.json'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { API_URL } from "../../BackendApi/Api/config";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';


function TabData1() {
    const data = localStorage.getItem("user");
    const proimg = API_URL + "profile/" + JSON.parse(data).profile_image

    const [selectedImage, setSelectedImage] = useState(proimg ||
        'http://i.pravatar.cc/500?img=7'
    );
    const [profileImage, setProfileImage] = useState(null);

    const handleImageChange = (event) => {
        const selectedFile = event.target.files[0];
    
        // Check if a file is selected
        if (selectedFile) {
            // Check if the file size is less than or equal to 5 MB (in bytes)
            const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB in bytes
            if (selectedFile.size > maxSizeInBytes) {
                // Handle the case where the file size exceeds 5 MB
              PNotify.error({
                text: 'File size should be less than 5 MB',
              })
                return;
            }
    
            // Set the profile image
            setProfileImage(selectedFile);
    
            // Set the selected image URL
            setSelectedImage(URL.createObjectURL(selectedFile));
        }
    };
    





    const email = JSON.parse(data).email;
    const joindate = JSON.parse(data).joindate;




    const [fname, setFname] = useState(JSON.parse(data).fname);
    const [lname, setLname] = useState(JSON.parse(data).lname);
    const [phone, setPhone] = useState(JSON.parse(data).phone);
    const [time_zone, setTimezone] = useState(JSON.parse(data).time_zone);
    const [date_formate, setDateFormat] = useState(JSON.parse(data).date_formate);
    const [time_format, setTimeFormat] = useState(JSON.parse(data).time_format);
    const [city, setCity] = useState(JSON.parse(data).city);
    const [loading, setLoading] = useState(false);

    // console.log('time_zone', time_zone);
    // console.log("date_formate", date_formate);
    // console.log('time_format', time_format);

    const getDate = (date) => {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return (`${mo}-${da}-${ye}`);
    }


    const handleProfileUpdate = (e) => {
        setLoading(true);
        e.preventDefault();
        const userData = {
            email: email,
            fname: fname,
            lname: lname,
            phone: phone,
            time_zone: time_zone,
            date_formate: date_formate,
            time_format: time_format,
            city: city
        }

        const formData = new FormData();
        formData.append("email", email);
        formData.append("fname", fname);
        formData.append("lname", lname);
        formData.append("phone", phone);
        formData.append("time_zone", time_zone);
        formData.append("date_formate", date_formate);
        formData.append("time_format", time_format);
        formData.append("city", city);
        formData.append("profile_image", profileImage);

        UserUpdateApi(formData).then((response) => {
            if (response.code === 200) {


                localStorage.setItem("user", JSON.stringify(response.data.data));
                PNotify.success({
                    title: "Success",
                    text: "Profile Updated Successfully",
                });

            }
            else {
                PNotify.error({
                    title: "Error",
                    text: response.message,
                });
            }
        }).catch((error) => {
            PNotify.error({
                title: "Error",
                text: error,
            });
        }).finally(() => {
            setLoading(false);
        })
    }


    return (
        <div>
            <div className="profiledesign">
                <div className="avatar-upload">
                    <div className="avatar-edit">
                        <input
                            type="file"
                            id="imageUpload"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleImageChange}
                        />
                        <label for="imageUpload" className="edit-profile-icon">
                            <MdEdit />
                        </label>
                    </div>
                    <div className="avatar-preview">
                        <div
                            className="imagePreview-profile"
                            id="imagePreview"
                            style={{ backgroundImage: `url('${selectedImage}')` }}
                        ></div>
                    </div>
                </div>
                <div className="setting-profile-content">
                    <div className="setting-profile-name">
                        <h3>{fname} {lname}</h3>
                    </div>
                    <div className="d-flex flex-lg-column flex-xl-row flex-md-column flex-sm-column flex-column">
                        <div className="setting-profile-email">
                            <h6><MdAlternateEmail /><span className="ms-1">{email}</span></h6>
                        </div>
                        <div className="setting-profile-address">
                            <h6><MdOutlineLocationOn /><span className="ms-1">{city}</span></h6>
                        </div>
                        <div className="setting-profile-joindate">
                            <h6><TbCalendarPlus /><span className="ms-1">Joined {getDate(joindate)}</span></h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row custom-pt-20">
                <div className="col-md-6">
                    <div className="content-body-form">
                        <form>
                            <div className="form-group did-floating-label-content">
                                <input type="text" className="form-control did-floating-input" id="exampleFormControlInput1" placeholder={fname} value={fname} onChange={(e) => setFname(e.target.value)} />
                                <label for="exampleFormControlInput1" className="form-label did-floating-label">First Name</label>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="content-body-form">
                        <form>
                            <div className="form-group did-floating-label-content">
                                <input type="text" className="form-control did-floating-input" id="exampleFormControlInput2" value={lname} onChange={(e) => setLname(e.target.value)} />
                                <label for="exampleFormControlInput2" className="form-label did-floating-label">Last Name</label>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="content-body-form">
                        <form>
                            <div className="form-group did-floating-label-content">
                                <input type="email" disabled className="form-control did-floating-input" id="exampleFormControlInput3" placeholder={email} value={email} />
                                <label for="exampleFormControlInput3" className="form-label did-floating-label">Email</label>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="content-body-form">
                        <form>
                            <div className="form-group did-floating-label-content">
                                <input type="text" className="form-control did-floating-input" id="exampleFormControlInput4" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                <label for="exampleFormControlInput4" className="form-label did-floating-label">Phone Number</label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Form.Group className="booking-custom-lable did-floating-label-content" controlId="formBasicEmail">
                        <Form.Select aria-label="Default select example" className="custom-select-box did-floating-select" value={time_zone} onChange={(e) => setTimezone(e.target.value)}>
                            {timezone.map((zone) => (
                                <option value={zone.id} id={zone.id}>{zone.Timezone}</option>
                            ))}
                        </Form.Select>
                        <Form.Label className="did-floating-label">Time Zone</Form.Label>
                    </Form.Group>
                </div>
                {/* <div className="col-md-6"></div> */}
                <div className="col-md-6">
                    <div className="content-body-form">
                        <form>
                            <div className="form-group did-floating-label-content">
                                <input type="text"
                                    className="form-control did-floating-input"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)} />
                                <label className="form-label did-floating-label">City</label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-12">
                    <Form.Group className="booking-custom-lable did-floating-label-content" controlId="formBasicDate">
                        <Form.Select aria-label="Default select example" className="custom-select-box did-floating-select" value={date_formate} onChange={(e) => setDateFormat(e.target.value)}>
                            <option value='1'>DD/MM/YY</option>
                            <option value="2">MM/DD/YY</option>
                            <option value="3">YY/MM/DD</option>
                        </Form.Select>
                        <Form.Label className="did-floating-label">Date Format</Form.Label>
                    </Form.Group>
                </div>
                <div className="mb-3 col-xl-6 col-lg-6 col-12">
                    <Form.Group className="booking-custom-lable did-floating-label-content" controlId="formBasicTime">
                        <Form.Select aria-label="Default select example" className="custom-select-box did-floating-select" value={time_format} onChange={(e) => setTimeFormat(e.target.value)}>
                            <option value="12">12 Hours</option>
                            <option value="24">24 Hours</option>
                        </Form.Select>
                        <Form.Label className="did-floating-label">Time Format</Form.Label>
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className='form-btn'>
                        <div className='form-btn-right'>


                            {
                                loading ?
                                    <button className="btn bg-green-dark action-btn btn-green" >
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        Loading...
                                    </button> :
                                    <button type="submit" className="btn bg-green-dark action-btn btn-green" onClick={handleProfileUpdate}>
                                        Update
                                    </button>

                            }









                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabData1;