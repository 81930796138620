import React from "react";
import './Footer.css';
import { BsFacebook, BsWhatsapp } from 'react-icons/bs';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { Link } from "react-router-dom";

function Footer() {
    return (
        <>
            <section className="footer-sec">
                <div className="container">
                    <div className="footer-inner">
                        <div className="footer-logo">
                            <Link to="/">
                                <img src={require('../../assets/images/logo/logo.png')} alt="logo" className='img-fluid' />
                            </Link>
                        </div>
                        <div className="footer-text">Astral is an all in one platform to create, manage and track connections and engagement.</div>
                        <div className="support"><a href="mailto:Support@gmail.com">hello@withastral.tech</a></div>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-bottom-content">
                            <div className="copyright">Copyright © 2023 Astral</div>
                            <div className="social-media-icons-list">
                                <div className="footer-icon-item">
                                    <a href="#"><BsFacebook /></a>
                                </div>
                                <div className="footer-icon-item">
                                    <a href="#"><FaTwitter /></a>
                                </div>
                                <div className="footer-icon-item">
                                    <a href="#"><FaLinkedinIn /></a>
                                </div>
                                <div className="footer-icon-item">
                                    <a href="#"><BsWhatsapp /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;