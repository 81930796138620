import React, { useEffect } from "react";
import BuilderTitle from "./Components/TemplateView/BuilderTitle";
import BuilderIcon from "./Components/TemplateView/BuilderIcon";
import BuilderButton from "./Components/TemplateView/BuilderButton";
import BuilderText from "./Components/TemplateView/BuilderText";
import ImageText from "./Components/TemplateView/ImageText";
import YoutubeLink from "./Components/TemplateView/YoutubeLink";
import Spotify from "./Components/TemplateView/Spotify";
import ImageGrid from "./Components/TemplateView/ImageGrid";
import { FiCopy } from "react-icons/fi";
import { useState, useRef } from "react";
import { BsArrowsFullscreen } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import { MdOutlineDesktopMac } from "react-icons/md";
import { FaTabletAlt, FaMobileAlt } from "react-icons/fa";

import { ButtonLinkAdd, ButtonLinkList } from "./Components/AddBlockList/ButtonLinkList";
import { useBuilderContext } from "../builderContext";
import BuilderHeader from "./Components/TemplateView/BuilderHeader";

import { publishMinipage } from "../BackendApi/Api/flowpageApi";

import { createFlexTemplateApi, updateFlexTemplateApi } from "../BackendApi/Api/brandApi";

import { toJpeg, toBlob, toPng } from 'html-to-image';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useLocation } from "react-router-dom";


import html2pdf from 'html2pdf.js';

import domToImage from 'dom-to-image';


// import domtoimage from 'dom-to-image';

import html2canvas from 'html2canvas';

import { saveAs } from 'file-saver';

// import { useScreenshot } from 'use-react-screenshot'
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import Iframe from "./Components/TemplateView/Iframe";


function BuilderLeft({ color, buttonColor, fontFamily }) {

    const location = useLocation();

    const user = JSON.parse(localStorage.getItem('user'));

    const { myComponents, smartlink_url, qrImage } = useBuilderContext();

    const [deviceView, setDeviceView] = useState('view-desktop');

    const [templateType, setTemplateType] = useState(null);

    const [templateId, setTemplateId] = useState(null);

    const [templateTitle, setTemplateTitle] = useState(null);

    const [brandId, setBrandId] = useState(null);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const imageRef = useRef(null);

    const captureWebpageAsImage = () => {
        const elementToCapture = document.querySelector('.builder-box-design');
        const scrollY = window.scrollY;

        // Save the current style of the body's scrollbar
        const originalBodyOverflowStyle = document.body.style.overflow;

        // Hide the scrollbar by setting body overflow to 'hidden'

        elementToCapture.style.overflow = 'hidden';
        // document.body.style.overflow = 'hidden';

        // Create a new div element to act as a wrapper for the content
        const wrapper = document.createElement('div');
        wrapper.style.overflow = 'hidden';
        wrapper.style.position = 'absolute';
        wrapper.style.top = `${-scrollY}px`;
        wrapper.appendChild(elementToCapture.cloneNode(true));

        // Append the wrapper to the body
        document.body.appendChild(wrapper);

        toPng(elementToCapture, { scrollY, quality: 2.0 })
            .then((dataUrl) => {
                // Restore the original body's overflow style
                document.body.style.overflow = originalBodyOverflowStyle;

                // Remove the wrapper element
                document.body.removeChild(wrapper);

                // Generate a unique filename for the screenshot
                const fileName = `screenshot_${new Date().toISOString()}.png`;

                // Create a link element to download the image
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = fileName;

                // Trigger the download
                link.click();
            })
            .catch((error) => {
                console.error('Error capturing screenshot:', error);
                // Make sure to restore the original body's overflow style even in case of an error
                document.body.style.overflow = originalBodyOverflowStyle;
                // Remove the wrapper element if there's an error
                document.body.removeChild(wrapper);
            });
    };




    const publish = () => {

        var element = document.getElementById("capture");
        element.classList.remove("page_builder_design_body");


        const elementToCapture = imageRef.current;
        const scrollY = window.scrollY;

        elementToCapture.style.overflow = 'hidden';

        // create image from toPng
        toJpeg(elementToCapture, { scrollY, quality: 3.0 })
            .then(dataUrl => {

                element.classList.add("page_builder_design_body");
                elementToCapture.style.overflow = 'scroll';

                const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, '');

                // // Convert base64 string to Blob object
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], { type: 'image/jpeg' });

                // saveAs(blob, 'screenshot.jpeg');

                console.log("blob", blob.type);

                const formData = new FormData();
                formData.append('template_name', templateTitle);
                formData.append('brand_id', brandId);
                formData.append('template_json', JSON.stringify(myComponents));
                formData.append('template_thumbnail', blob);
                formData.append('background_color', color);
                formData.append('button_color', buttonColor);
                formData.append('font_family', fontFamily);
                formData.append("owner_id", user.owner_id);
                formData.append("created_by", user._id);
                formData.append("updator_name", user.fname + "" + user.lname);
                formData.append("updator_image", user.profile_image);
                createFlexTemplateApi(formData).then((res) => {
                    if (res.data.status == true) {
                        PNotify.success({
                            title: 'Success',
                            text: 'Page Published Successfully',
                        });
                    }
                })

            })
    }


    const update = () => {

        var element = document.getElementById("capture");
        element.classList.remove("page_builder_design_body");

        const elementToCapture = imageRef.current;
        const scrollY = window.scrollY;

        elementToCapture.style.overflow = 'hidden';

        // create image from toPng
        toJpeg(elementToCapture, { scrollY, quality: 3.0 })
            .then(dataUrl => {

                element.classList.add("page_builder_design_body");
                elementToCapture.style.overflow = 'scroll';

                const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, '');

                // // Convert base64 string to Blob object
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], { type: 'image/jpeg' });

                // saveAs(blob, 'screenshot.jpeg');

                console.log("blob", blob);

                const formData = new FormData();
                // formData.append('template_name', "screenshot");
                formData.append('id', templateId);
                formData.append('template_json', JSON.stringify(myComponents));
                formData.append('template_thumbnail', blob);
                formData.append('background_color', color);
                formData.append('button_color', buttonColor);
                formData.append('font_family', fontFamily);
                formData.append("updator_name", user.fname + "" + user.lname);
                formData.append("updator_image", user.profile_image);
                formData.append('brand_id', brandId);
                // formData.append("owner_id", user.owner_id);
                // formData.append("created_by", user._id);

                updateFlexTemplateApi(formData).then((res) => {
                    if (res.data.status == true) {
                        PNotify.success({
                            title: 'Success',
                            text: 'Page Published Successfully',
                        });
                    }
                })

            })

    }

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);

        // Access individual query parameters
        const paramsBrandId = queryParams.get('brandId');

        const paramsTemplateaId = queryParams.get('templateId');

        const paramsTemplateTitle = queryParams.get('title');


        console.log("paramsTemplateaId", paramsTemplateaId);

        console.log("paramsBrandId", paramsBrandId);


        if (paramsBrandId) {
            setTemplateType('new');
            setBrandId(paramsBrandId);
        }

        if (paramsTemplateaId) {
            setTemplateType('edit');
            setTemplateId(paramsTemplateaId);
        }

        if (paramsTemplateTitle) {
            setTemplateTitle(paramsTemplateTitle);
        }
    })



    return (
        <>

            <Modal
                centered
                show={show}
                onHide={handleClose}
                className="modal-center-show"
            >
                <Modal.Body>
                    <div className="show-preview-header">
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-desktop');
                                    }
                                }
                            ><MdOutlineDesktopMac /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-tablet');
                                    }
                                }
                            ><FaTabletAlt /></button>
                        </div>
                        <div className="show-preview-item">
                            <button className="btn full-screen-btn-1"
                                onClick={
                                    () => {
                                        setDeviceView('view-mobile');
                                    }
                                }
                            ><FaMobileAlt /></button>
                        </div>
                    </div>
                    <div className="show-preview-body">
                        <div className="show-preview-content-2">
                            <div className={`preview-box-background ${deviceView}`}>
                                <div class="oEUMCt"></div>
                                <div className={`page_builder_design_body ${color}`}  >
                                    <div className={`builder-box-design goWHMr ${color}`} style={{ fontFamily: fontFamily }} >
                                        <div className="builder_content"  >



                                            {myComponents.map((component, index) => (
                                                <>
                                                    {
                                                        component.type === "HEADER" ? <BuilderHeader data={component.properties} /> :
                                                            component.type === "TITLE" && component.showStatus === true ? <BuilderTitle data={component.properties} /> :
                                                                component.type === "ICONS" && component.showStatus === true ? <BuilderIcon data={component.properties} /> :
                                                                    component.type === "BUTTON" && component.showStatus === true ? <BuilderButton data={component.properties} buttonColor={buttonColor} /> :
                                                                        component.type === "TEXT" && component.showStatus === true ? <BuilderText data={component.properties} /> :
                                                                            component.type === "IMAGETEXT" && component.showStatus === true ? <ImageText data={component.properties} /> :
                                                                                component.type === "YOUTUBELINK" && component.showStatus === true ? <YoutubeLink data={component.properties} /> :
                                                                                    component.type === "SPOTIFY" && component.showStatus === true ? <Spotify data={component.properties} /> :
                                                                                        component.type === "IMAGEGRID" && component.showStatus === true ? <ImageGrid data={component.properties} /> :
                                                                                            component.type === "IFRAME" && component.showStatus === true ? <Iframe data={component.properties} /> :
                                                                                                null


                                                    }
                                                </>
                                            ))}
                                            <div className="company_name">
                                                <h5>Powered with Astral</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="oEUMCterd"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="page_builder_design_header">
                <div className="page_builder_design_header_container">
                    <div className="publish_btn_container">
                        <div className="d-flex align-items-center justify-content-between ms-4">
                            {!templateType == 'new' && (<><p className="m-0">{smartlink_url}</p>
                                <CopyToClipboard text={smartlink_url}>
                                    <button className="btn btn-sm btn-copy"><FiCopy /></button>
                                </CopyToClipboard></>)}
                            {templateType === 'new' && <p className="m-0">{templateTitle}</p>}
                        </div>
                        <div className="fill-screen-btn form-btn-right">
                            <button type="button" className="btn full-screen-btn bg-green-dark" onClick={handleShow}><BsArrowsFullscreen /> Device Preview</button>
                        </div>

                        {templateType === 'new' ? <button className="btn btn-sm publish-btn" onClick={publish}> Save Template</button> : null}
                        {templateType === 'edit' ? <button className="btn btn-sm publish-btn" onClick={update}>Update</button> : null}


                        {/* <button className="publish-btn" onClick={publish}>Publish</button> */}

                    </div>
                </div>
            </div>



            <div className="page_builder_design_body" id="capture" >
                <div className={`builder-box-design goWHMr ${color}`} style={{ fontFamily: fontFamily }} ref={imageRef}>
                    <div className="builder_content"  >



                        {myComponents.map((component, index) => (
                            <>
                                {
                                    component.type === "HEADER" && component.showStatus === true ? <BuilderHeader data={component.properties} /> :
                                        component.type === "TITLE" && component.showStatus === true ? <BuilderTitle data={component.properties} /> :
                                            component.type === "ICONS" && component.showStatus === true ? <BuilderIcon data={component.properties} /> :
                                                component.type === "BUTTON" && component.showStatus === true ? <BuilderButton data={component.properties} buttonColor={buttonColor} /> :
                                                    component.type === "TEXT" && component.showStatus === true ? <BuilderText data={component.properties} /> :
                                                        component.type === "IMAGETEXT" && component.showStatus === true ? <ImageText data={component.properties} /> :
                                                            component.type === "YOUTUBELINK" && component.showStatus === true ? <YoutubeLink data={component.properties} /> :
                                                                component.type === "SPOTIFY" && component.showStatus === true ? <Spotify data={component.properties} /> :
                                                                    component.type === "IMAGEGRID" && component.showStatus === true ? <ImageGrid data={component.properties} /> :
                                                                        component.type === "IFRAME" && component.showStatus === true ? <Iframe data={component.properties} /> :
                                                                            null


                                }
                            </>
                        ))}
                        <div className="company_name">
                            <h5>Made by WithAstral</h5>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default BuilderLeft;