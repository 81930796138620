import React from "react";

function BuilderText(props) {
    console.log("my text display props", props);
    return (
        <>
            <div className="text_content_box">
                
                <div dangerouslySetInnerHTML={{ __html: props.data.html }} />
            </div>
        </>
    );
}

export default BuilderText;