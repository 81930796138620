
// import React from "react";
// import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";
// import YouTubePlayer from "../youtubeEmbeded";

// function YoutubeLink(props) {

//     const saveClicks = (smartlink_id, unique_id) => {
//         saveComponentsClicks({
//             unique_id: unique_id,
//             smartlink_id: smartlink_id,

//         });
//     }
//     console.log("YoutubeIframe",props)
//     return (
//         <>
//             {/* <div className="youtube_video_box mb-3" >
//                 <iframe width="560" height="315" src={props.data.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//             </div> */}
//             <YouTubePlayer
//         iframeHtml={props.data.Link} 
//         youtubeEmbedUrl={""} 
//       />
//         </>
//     );
// }


// export default YoutubeLink;






// import React, { useEffect, useState, useCallback, useMemo } from "react";
// import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";

// function YoutubeIframe({ data, smartlink_id, selectedbtn }) {
//     const [player, setPlayer] = useState(null);
//     const [videoError, setVideoError] = useState(false);
//     const [channelId, setChannelId] = useState(null);
//     const getYoutubeUrlFromIframe = useCallback((iframeHtml) => {
//         const regex = /src="([^"]+)"/;
//         const match = iframeHtml.match(regex);
//         return match ? match[1] : null;
//     }, []);

//     let youtubeLink = getYoutubeUrlFromIframe(data.Link);
//     console.log("data", data)
//     const videoId = useMemo(() => {
//         const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube.com\/embed\/)([^"&?\/\s]{11})/;
//         const match = youtubeLink.match(regex);
//         return match ? match[1] : null;
//     }, [youtubeLink]);

//     const playerId = useMemo(() => `player-${videoId}-${data.unique_id}`, [videoId, data.unique_id]);

//     useEffect(() => {
//         let iframeApiLoaded = false;
//         let playerInstance = null;

//         const loadYouTubeApi = () => {
//             if (!window.YT) {
//                 const tag = document.createElement('script');
//                 tag.src = "https://www.youtube.com/iframe_api";
//                 const firstScriptTag = document.getElementsByTagName('script')[0];
//                 firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
//             } else {
//                 onYouTubeIframeAPIReady();
//             }
//         };

//         const onYouTubeIframeAPIReady = () => {
//             iframeApiLoaded = true;
//             playerInstance = new window.YT.Player(playerId, {
//                 height: '390',
//                 width: '640',
//                 videoId: videoId,
//                 playerVars: {
//                     autoplay: 0,
//                     controls: 1,
//                     playsinline: 1,
//                 },
//                 events: {
//                     onStateChange: onPlayerStateChange,
//                     onError: onPlayerError
//                 }
//             });
//             setPlayer(playerInstance);
//         };

//         const onPlayerError = (event) => {
//             console.error("YouTube player error:", event);
//             setVideoError(true);
//         };
//         async function getChannelId(videoId) {
//             const apiKey = "AIzaSyAEJRIkuDDb57pTAoqszQ__5gmRKO5h9_0"; // Replace with your API key
//             const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${apiKey}`;

//             try {
//                 const response = await fetch(url);
//                 const data = await response.json();

//                 if (data.items && data.items.length > 0) {
//                     const channelId = data.items[0].snippet.channelId;
//                     return channelId;
//                 } else {
//                     console.error("No video data found.");
//                 }
//             } catch (error) {
//                 console.error("Error fetching channel ID:", error);
//             }
//             return null; // Return null if no channel ID is found or an error occurs
//         }
//         async function fetchChannelId() {
//             const id = await getChannelId(videoId);
//             setChannelId(id);
//         }

//         if (videoId) {
//             fetchChannelId();
//         }
//         function onPlayerStateChange(event) {
//             console.log("window.YT.PlayerState", window.YT.PlayerState)
//             switch (event.data) {
//                 case window.YT.PlayerState.UNSTARTED:
//                     saveComponentsClicks({
//                         unique_id: data.unique_id,
//                         smartlink_id: smartlink_id,
//                     });
//                     console.log("Video isiframe 00000000000");
//                     break;
//                 case window.YT.PlayerState.PLAYING:
//                     console.log("Video is playing");
//                     break;
//                 case window.YT.PlayerState.PAUSED:
//                     console.log("Video is paused");
//                     break;
//                 case window.YT.PlayerState.ENDED:
//                     console.log("Video has ended");
//                     break;
//                 // You can handle other states such as buffering, video cued, etc.
//             }
//         }
//         if (window.YT && window.YT.Player) {
//             onYouTubeIframeAPIReady();
//         } else {
//             const previousOnYouTubeIframeAPIReady = window.onYouTubeIframeAPIReady;
//             window.onYouTubeIframeAPIReady = () => {
//                 if (previousOnYouTubeIframeAPIReady) {
//                     previousOnYouTubeIframeAPIReady();
//                 }
//                 onYouTubeIframeAPIReady();
//             };
//             loadYouTubeApi();
//         }

//         return () => {
//             if (iframeApiLoaded && playerInstance) {
//                 playerInstance.destroy();
//             }
//         };

//     }, [videoId, playerId, data.unique_id, smartlink_id]);


//     if (!videoId) {
//         return <div>Invalid YouTube URL</div>;
//     }

//     return (
//         <div className="youtube_video_box mb-3">
//             <div id={playerId}></div>
//             {selectedbtn == true && channelId && (
//                 <a href={`https://www.youtube.com/channel/${channelId}`} >Subscribe</a>
//             )}// har video ka ek alag subscribe hoga
//         </div>
//     );
// }

// export default YoutubeIframe;




import React, { useEffect, useState, useCallback, useMemo } from "react";
import { saveComponentsClicks } from "../../../BackendApi/Api/flowpageApi";

function YoutubeIframe({ data, smartlink_id, selectedbtn }) {
    const [player, setPlayer] = useState(null);
    const [videoError, setVideoError] = useState(false);
    const [channelId, setChannelId] = useState(null);

    const getYoutubeUrlFromIframe = useCallback((iframeHtml) => {
        const regex = /src="([^"]+)"/;
        const match = iframeHtml.match(regex);
        return match ? match[1] : null;
    }, []);

    const youtubeLink = getYoutubeUrlFromIframe(data.Link);

    const videoId = useMemo(() => {
        const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube.com\/embed\/)([^"&?\/\s]{11})/;
        const match = youtubeLink ? youtubeLink.match(regex) : null;
        return match ? match[1] : null;
    }, [youtubeLink]);

    const playerId = useMemo(() => `player-${videoId}-${data.unique_id}`, [videoId, data.unique_id]);

    useEffect(() => {
        let iframeApiLoaded = false;
        let playerInstance = null;

        const loadYouTubeApi = () => {
            if (!window.YT) {
                const tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            } else {
                onYouTubeIframeAPIReady();
            }
        };

        const onYouTubeIframeAPIReady = () => {
            iframeApiLoaded = true;
            playerInstance = new window.YT.Player(playerId, {
                height: '390',
                width: '640',
                videoId: videoId,
                playerVars: {
                    autoplay: 0,
                    controls: 1,
                    playsinline: 1,
                },
                events: {
                    onStateChange: onPlayerStateChange,
                    onError: onPlayerError
                }
            });
            setPlayer(playerInstance);
        };

        const onPlayerError = (event) => {
            console.error("YouTube player error:", event);
            setVideoError(true);
        };

        const getChannelId = async (videoId) => {
            const apiKey = "AIzaSyAEJRIkuDDb57pTAoqszQ__5gmRKO5h9_0"; // Replace with your API key
            const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${apiKey}`;

            try {
                const response = await fetch(url);
                const data = await response.json();

                if (data.items && data.items.length > 0) {
                    return data.items[0].snippet.channelId;
                } else {
                    console.error("No video data found.");
                }
            } catch (error) {
                console.error("Error fetching channel ID:", error);
            }
            return null; // Return null if no channel ID is found or an error occurs
        };

        const fetchChannelId = async () => {
            if (videoId) {
                const id = await getChannelId(videoId);
                setChannelId(id);
            }
        };

        fetchChannelId();

        const onPlayerStateChange = (event) => {
            console.log("window.YT.PlayerState", window.YT.PlayerState);
            switch (event.data) {
                case window.YT.PlayerState.UNSTARTED:
                    saveComponentsClicks({
                        unique_id: data.unique_id,
                        smartlink_id: smartlink_id,
                    });
                    console.log("Video is unstarted");
                    break;
                case window.YT.PlayerState.PLAYING:
                    console.log("Video is playing");
                    break;
                case window.YT.PlayerState.PAUSED:
                    console.log("Video is paused");
                    break;
                case window.YT.PlayerState.ENDED:
                    console.log("Video has ended");
                    break;
                // You can handle other states such as buffering, video cued, etc.
            }
        };

        if (window.YT && window.YT.Player) {
            onYouTubeIframeAPIReady();
        } else {
            const previousOnYouTubeIframeAPIReady = window.onYouTubeIframeAPIReady;
            window.onYouTubeIframeAPIReady = () => {
                if (previousOnYouTubeIframeAPIReady) {
                    previousOnYouTubeIframeAPIReady();
                }
                onYouTubeIframeAPIReady();
            };
            loadYouTubeApi();
        }

        return () => {
            if (iframeApiLoaded && playerInstance) {
                playerInstance.destroy();
            }
        };

    }, [videoId, playerId, data.unique_id, smartlink_id]);

    if (!videoId) {
        return <div>Invalid YouTube URL</div>;
    }

    return (
        <div className="youtube_video_box mb-3" style={{position:"relative"}}>
            <div id={playerId} ></div>
            {selectedbtn == true && channelId && (
                <div style={{position:"absolute",top:"182px",left:5,background:"white",width:"30%",padding:5,borderRadius:30,alignItems:"center",justifyItems:"center"}}>
                <a style={{textDecoration:"none",color:"black",marginLeft:13,textAlign:"center"}}  href={`https://www.youtube.com/channel/${channelId}`} target="_blank" rel="noopener noreferrer">
                    Subscribe
                </a>
                </div>
            )}
        </div>
    );
}

export default YoutubeIframe;
