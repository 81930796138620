import React from "react";
import { FaTrash, FaExternalLinkAlt } from "react-icons/fa";
import { BsFacebook, BsList } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsTiktok } from "react-icons/bs";
import { BsSnapchat } from "react-icons/bs";
import { BsPaypal } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { BsPinterest, BsGithub } from "react-icons/bs";


function ListItem({ item, index, handleUrlChange, deleteHandler }) {



    // FACEBOOK
    // INSTAGRAM
    // WHATSAPP
    // TWITTER

    // LINKEDIN
    // YOUTUBE
    // TIKTOK
    // SNAPCHAT
    // PAYPAL
    // TELEGRAM
    // PINTEREST
    const [Url, setUrl] = React.useState(item.url);



    const changeSocialUrl = (id, Url) => {
        handleUrlChange(id, Url);
    };

    const onChangeHabndler = (value) => {
        setUrl(value);
        changeSocialUrl(item.id, value);
    }




    return (
        <>
            <div className="icons_list_item cvtPmc">
                <div className="icons_list_item_icon">
                    <div className="icons_list_left">
                        <BsList />
                        {item.type == "FACEBOOK" ? <BsFacebook /> :
                            item.type == "INSTAGRAM" ? <BsInstagram /> :
                                item.type == "WHATSAPP" ? <BsWhatsapp /> :
                                    item.type == "TWITTER" ? <BsTwitter /> :
                                        item.type == "LINKEDIN" ? <BsLinkedin /> :
                                            item.type == "YOUTUBE" ? <BsYoutube /> :
                                                item.type == "TIKTOK" ? <BsTiktok /> :
                                                    item.type == "SNAPCHAT" ? <BsSnapchat /> :
                                                        item.type == "PAYPAL" ? <BsPaypal /> :
                                                            item.type == "TELEGRAM" ? <BsTelegram /> :
                                                                item.type == "PINTEREST" ? <BsPinterest /> :
                                                                    item.type == "GITHUB" ? <BsGithub /> : ""
                        }
                    </div>
                    <div className="icons_list_right">
                        <button type="button" className="delete_btn" onClick={() => { deleteHandler(item.id) }}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="icons_list_item_text">
                        <div className="icons_list_item_text_title">
                            {item.type == "FACEBOOK" ? "Facebook" :
                                item.type == "INSTAGRAM" ? "Instagram" :
                                    item.type == "WHATSAPP" ? "Whatsapp" :
                                        item.type == "TWITTER" ? "Twitter" :
                                            item.type == "LINKEDIN" ? "Linkedin" :
                                                item.type == "YOUTUBE" ? "Youtube" :
                                                    item.type == "TIKTOK" ? "Tiktok" :
                                                        item.type == "SNAPCHAT" ? "Snapchat" :
                                                            item.type == "PAYPAL" ? "Paypal" :
                                                                item.type == "TELEGRAM" ? "Telegram" :
                                                                    item.type == "PINTEREST" ? "Pinterest" : ""
                            }
                        </div>
                    </div>
                    <div className="form_block">
                        <input type="text" className="form-control" placeholder={
                            item.type == "FACEBOOK" ? "https://www.facebook.com/" :
                                item.type == "INSTAGRAM" ? "https://www.instagram.com/" :
                                    item.type == "WHATSAPP" ? "https://www.whatsapp.com/" :
                                        item.type == "TWITTER" ? "https://www.twitter.com/" :
                                            item.type == "LINKEDIN" ? "https://www.linkedin.com/" :
                                                item.type == "YOUTUBE" ? "https://www.youtube.com/" :
                                                    item.type == "TIKTOK" ? "https://www.tiktok.com/" :
                                                        item.type == "SNAPCHAT" ? "https://www.snapchat.com/" :
                                                            item.type == "PAYPAL" ? "https://www.paypal.com/" :
                                                                item.type == "TELEGRAM" ? "https://www.telegram.com/" :
                                                                    item.type == "PINTEREST" ? "https://www.pinterest.com/" : ""
                        }
                            onChange={(e) => {
                                onChangeHabndler(e.target.value)
                            }}
                            value={Url}
                        />
                    </div>
                    <div className="link_target">
                        <button type="button" className="btn btn-sm" >
                            <FaExternalLinkAlt />
                        </button>
                    </div>
                </div>
            </div>


        </>
    );
}

export default ListItem;