import {apiCall} from './auth.js';
import {API_URL} from './config.js';
import {multipartApiCall} from "./auth";

export const createCampaignApi = (formData) => apiCall(`${API_URL}campaign/addcampaign`, "POST", formData);
export const getCampaignApi = (formData) => apiCall(`${API_URL}campaign/getcampaignbyid`, "POST", formData);
export const updateCampaignApi = (formData) => apiCall(`${API_URL}campaign/updatecampaign`, "POST", formData);
export const deleteCampaignApi = (formData) => apiCall(`${API_URL}campaign/deletecampaign`, "POST", formData);
export const getAllCampaignApi = (formData) => apiCall(`${API_URL}campaign/getallcampaign`, "POST", formData); 
export const updateChannelQr = (formData) => multipartApiCall(`${API_URL}campaign/updatechannelqr`, "POST", formData);   
export const updateCampaignUtm = (formData) => apiCall(`${API_URL}campaign/updatecampaignutm`, "POST", formData);
export const addcampaignUtm = (formData) => apiCall(`${API_URL}campaign/addcampaignutm`, "POST", formData); 
export const getcampaignutm = (formData) => apiCall(`${API_URL}campaign/getcampaignutm`, "POST", formData);
export const addNewChannelApi = (formData) => apiCall(`${API_URL}campaign/addnewchannel`, "POST", formData);
export const updateBackhalfTitleApi = (formData) => apiCall(`${API_URL}campaign/updatebackhalftitle`, "POST", formData);