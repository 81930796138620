import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useBuilderContext } from "../../../builderContext";
import { uploadHeaderImage } from "../../../BackendApi/Api/flowpageApi"
import { API_URL } from "../../../BackendApi/Api/config";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';


export function EditHeader() {

    const { addComponent, myComponents, editComponent } = useBuilderContext();

    const headerData = myComponents[0];
    console.log('humpd data', myComponents[0].properties.title)
    const showStatus = myComponents[0].showStatus
   

    const [activeFormat, setActiveFormat] = useState("H1");
    const [status, setStatus] = useState("H1");

    const [loading, setLoading] = useState(false);



    const fileInputRef = useRef(null);
    const profileImageRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewBackgroundImage, setPreviewBackgroundImage] = useState(null);
    const [previewProfileImage, setPreviewProfileImage] = useState(null);

    const [ProfileImage, setProfileImage] = useState(null);
    const [BackgroundImage, setBackgroundImage] = useState(null);

    const handleInputChange = (type,file) => {

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (type == "profile") {
                    setPreviewProfileImage(reader.result);
                }
                if (type == "background") {
                    setPreviewBackgroundImage(reader.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };



    const [headerProperties, setHeaderProperties] = React.useState({
        title: myComponents[0].properties?.title || "",
        description: myComponents[0].properties?.description || "",
        headerFormat: myComponents[0].properties?.headerFormat || "",
        profileImage: myComponents[0].properties?.profileImage || "",
        altText: myComponents[0].properties?.altText || "",
        backgroundImage: myComponents[0].properties?.backgroundImage || "",
    });

    useEffect(() => {
        console.log("headerProperties", headerProperties);
        console.log("headerData", headerData);
        if (showStatus) {
            setStatus(showStatus)
        }
    }, [headerProperties,status]);


    const addHeaderImageData = async () => {
        setLoading(true);
        const data = new FormData();
        data.append('profileImage', ProfileImage);
        data.append('backgroundImage', BackgroundImage);

        try {
            const res = await uploadHeaderImage(data);
            console.log(res.data);
            if (res.code === 200) {
                if (res.data.status) {
                    const headerProp = {
                        title: headerProperties.title,
                        description: headerProperties.description,
                        headerFormat: headerProperties.headerFormat,
                        profileImage: headerProperties.profileImage,
                        altText: headerProperties.altText,
                        backgroundImage: headerProperties.backgroundImage
                    };

                    if (res.data.data.backgroundImage) {
                        headerProp.backgroundImage = res.data.data.backgroundImage;
                    }
                    if (res.data.data.profileImage) {
                        headerProp.profileImage = res.data.data.profileImage;
                    }

                    editComponent(0, "HEADER", headerProp, status);
                }
            } else {
                PNotify.error({
                    title: 'Error',
                    text: res.data.message,
                });
            }
        } catch (err) {
            PNotify.error({
                title: 'Error',
                text: err.message,
            });
        } finally {
            setLoading(false);
        }
    };



    const handleFormatChange = (field,value) => {

        const index = 0;
        headerProperties[field] = value;
        editComponent(index, "HEADER", headerProperties, status);

    }


    const handleTextChange = (field, value) => {
        
        const index = 0;
        headerProperties[field] = value;
        editComponent(index, "HEADER", headerProperties, status);
    }



    return (
        <>
            <div className="add_block">
                <div className="styles__Header-sc-82jpo5-0 cDIdp">
                    <div>
                        <Link to="/">
                            <button type="button" aria-label="Go back to layout view">
                                <FaLongArrowAltLeft />
                            </button>
                        </Link>
                        <label htmlFor color="grayDarker" className="style__Help-bufferapp-ui__sc-153wil8-6 ePxaWM">Edit Header</label>
                    </div>
                </div>
                <div className="icons_list_item cvtPmc">
                    <div className="position-relative">
                        <div className="image-input-container mb-4">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={
                                    (e)=>{
                                        handleInputChange("profile",e.target.files[0]);
                                        setProfileImage(e.target.files[0]);
                                    }}
                                ref={profileImageRef}
                                style={{ display: 'none' }}
                            />
                            <div className="preview_image_box">
                                { previewProfileImage ? (
                                    <img src={previewProfileImage} alt="Selected" className="preview-image" />
                                ) : (
                                    <img
                                        src={require('../../../assets/images/image_default.png')} // Replace this with the URL or path to your default image
                                        alt="Default"
                                        className="default-image"
                                    />
                                )}
                            </div>
                            <div className="image-input" onClick={() => profileImageRef.current.click()}>
                                <span className="icon">📷</span>
                                <span className="text">
                                    Upload Image (PNG, JPG or GIF)
                                </span>
                            </div>
                        </div>
                        <div className="did-floating-label-content form-group">
                            <input type="text" className="did-floating-input form-control" placeholder="" />
                            <label htmlFor="exampleFormControlTextarea1" className="did-floating-label">Alt Text</label>
                        </div>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="did-floating-label-content form-group">
                        <textarea className="form-control did-floating-textarea" value={headerProperties.title} id="headding" rows="3" placeholder=""
                            onChange={
                                (e) => {
                                    handleTextChange("title", e.target.value)
                                }
                            }
                        ></textarea>
                        <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">Your business or personal name</label>
                    </div>
                </div>
                <div className="form_box cvtPmc">
                    <div className="did-floating-label-content form-group">  
                        <textarea className="form-control did-floating-textarea" value={headerProperties.description} id="headding" rows="3" placeholder=""
                            onChange={
                                (e) => {
                                    handleTextChange("description", e.target.value)
                                }
                            }
                        ></textarea>
                        <label htmlFor="headding" className="style__Label-bufferapp-ui__sc-153wil8-5 gvofes did-floating-label">A few words about you or your business</label>
                    </div>
                </div>
                <p aria-label="Header format" class="style__Paragraph-bufferapp-ui__sc-153wil8-1 fcYUrQ styles__Label-sc-m6lsw-0 cJUVbA" color="grayDarker">Header Format</p>

                <div className="header-format-box cvtPmc">
                    <p className="header-format-title">Without banner</p>
                    <div className="header-format-item">
                        <button type="button"
                            className={`btn format-image-btn ${activeFormat === "H1" ? "active" : ""}`}
                            // className="btn format-image-btn" 
                            onClick={
                                (e) => {
                                    //onclick active class add

                                    handleFormatChange("headerFormat","H1")
                                }
                            }>
                            <img src={require('../../../assets/images/header-layout-no-image-horizontal.png')} alt="Header Format 1" />
                        </button>
                        <button type="button"
                            // className="btn format-image-btn"
                            className={`btn format-image-btn ${activeFormat === "H2" ? "active" : ""}`}
                            onClick={
                                (e) => {
                                     handleFormatChange("headerFormat","H2")
                                }
                            }>
                            <img src={require('../../../assets/images/header-layout-no-image-vertical.png')} alt="Header Format 2" />
                        </button>
                    </div>
                    <p className="header-format-title">With banner</p>
                    <div className="header-format-item">
                        <button type="button"
                            className={`btn format-image-btn ${activeFormat === "H3" ? "active" : ""}`}
                            // className="btn format-image-btn"
                            onClick={
                                (e) => {
                                     handleFormatChange("headerFormat","H3")
                                }
                            }>
                            <img src={require('../../../assets/images/header-layout-image-full-vertical.png')} alt="Header Format 3" />
                        </button>
                        <button type="button"
                            className={`btn format-image-btn ${activeFormat === "H4" ? "active" : ""}`}
                            // className="btn format-image-btn"
                            onClick={
                                (e) => {
                                     handleFormatChange("headerFormat","H4")
                                }
                            }>
                            <img src={require('../../../assets/images/header-layout-image-half-vertical.png')} alt="Header Format 4" />
                        </button>
                    </div>
                    <p className="header-format-title">Background Image</p>
                    <div className="icons_list_item m-0">
                        <div className="position-relative">
                            <div className="image-input-container mb-2">
                                <input
                                    type="file"
                                    accept="image/*"
                                
                                    onChange={
                                        (e)=>{
                                            handleInputChange("background",e.target.files[0]);
                                            setBackgroundImage(e.target.files[0]);
                                        }}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                                <div className="preview_image_box">
                                    {previewBackgroundImage ? (
                                        <img src={previewBackgroundImage} alt="Selected" className="preview-image" />
                                    ) : (
                                        <img
                                            src={require('../../../assets/images/image_default.png')} // Replace this with the URL or path to your default image
                                            alt="Default"
                                            className="default-image"
                                        />
                                    )}
                                </div>
                                <div className="image-input" onClick={() => fileInputRef.current.click()}>
                                    <span className="icon">📷</span>
                                    <span className="text">
                                        Upload Image (PNG, JPG or GIF)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-block-btn">
                <button className="btn publish-btn"
                onClick={addHeaderImageData} >
                    Update
                </button>
            </div>
        </>
    );
}