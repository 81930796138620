import React from "react";
import { SiInstagram } from "react-icons/si";
import { BsWhatsapp } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsTiktok } from "react-icons/bs";
import { BsSnapchat } from "react-icons/bs";
import { BsPaypal } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { BsPinterest } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";


import { FaYoutube, FaTwitter } from "react-icons/fa";

function BuilderIcon(props) {

    // const socials = props.data.items;

   
    // {
    //     "social-1": {
    //         "id": "social-1",
    //         "type": "FACEBOOK",
    //         "url": ""
    //     }
    // }


    const socials = Object.values(props.data.items);


    console.log('MY PROPS icons=========', socials);
    return (
        <>
            <div className="social_item_list">
               {socials.map((item, index) => (
                <div key={index}>
                {
                    item.type== "FACEBOOK" ?<a href={item.url}> <BsFacebook /></a> :

                    item.type== "INSTAGRAM" ? <a href={item.url}><SiInstagram /></a> :

                    item.type== "WHATSAPP" ? <a href={item.url}><BsWhatsapp /></a> :

                    item.type== "TWITTER" ? <a href={item.url}><FaTwitter /></a> :

                    item.type== "LINKEDIN" ? <a href={item.url}><BsLinkedin /></a> :

                    item.type== "YOUTUBE" ? <a href={item.url}><FaYoutube /></a> :

                    item.type== "TIKTOK" ? <a href={item.url}><BsTiktok /></a> :

                    item.type== "SNAPCHAT" ? <a href={item.url}><BsSnapchat /></a> :

                    item.type== "PAYPAL" ? <a href={item.url}><BsPaypal /></a> :

                    item.type== "TELEGRAM" ? <a href={item.url}><BsTelegram /></a> :

                    item.type== "PINTEREST" ? <a href={item.url}><BsPinterest /></a> :""

               }
               </div>
               ))}
            </div>
        </>
    );
}

export default BuilderIcon;