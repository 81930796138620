import { createContext, useState } from "react";

const BrandContext = createContext();

// create a context for the user provider


const BrandProvider = ({ children }) => {

  const [brandDataSaved, setBrandDataSaved] = useState(false);
  const [brandEditStatus, setBrandEditStatus] = useState(false);
  const [company_name, setCompanyName] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [brandEmail, setBrandEmail] = useState('');
  const [brandPhone, setBrandPhone] = useState('');
  const [logo_light, setLogoLight] = useState('');
  const [logo_dark, setLogoDark] = useState('');
  const [logo_full, setLogoFull] = useState('');
  const [logo_symbolic, setLogoSymbolic] = useState('');
  const [primary_color, setPrimaryColor] = useState('#000000');
  const [secondary_color, setSecondaryColor] = useState('#B9B3F8');
  const [additional_color_1, setAdditionalColor1] = useState('#000000');
  const [additional_color_2, setAdditionalColor2] = useState('#000000');
  const [brand_id, setBrandId] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [pinterestUrl, setPinterestUrl] = useState('');
  const [tiktokUrl, setTiktokUrl] = useState('');
  const [otherUrl, setOtherUrl] = useState('');
  const [brand_Type, setBrand_type] = useState('');

  return (
    <BrandContext.Provider value={{
      brandDataSaved, setBrandDataSaved, company_name, setCompanyName,address, setAddress, description, setDescription, brandEmail, setBrandEmail, brandPhone, setBrandPhone, logo_light, setLogoLight, logo_dark, setLogoDark,
      logo_full, setLogoFull, primary_color, setPrimaryColor, secondary_color, setSecondaryColor, brand_id, setBrandId, setBrand_type, brand_Type,
      facebookUrl, setFacebookUrl, twitterUrl, setTwitterUrl, instagramUrl, setInstagramUrl, websiteUrl, setWebsiteUrl, pinterestUrl, setPinterestUrl, tiktokUrl, setTiktokUrl, otherUrl, setOtherUrl,
      brandEditStatus, setBrandEditStatus, logo_symbolic, setLogoSymbolic, additional_color_1, setAdditionalColor1, additional_color_2, setAdditionalColor2
    }}>
      {children}
    </BrandContext.Provider>
  );
};

export { BrandContext, BrandProvider };