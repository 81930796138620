import React, { useEffect, useContext } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { isAuthenticated } from "../BackendApi/Api/auth";
import { UserContext } from "../UserContext";
import { getOwner } from '../BackendApi/Api/userApi'

const PrivateRouteNoSidebar = ({ component: Component, ...rest }) => {
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const { setSubscriptionStatus, setStripe_customer_id, setOwnerEmail } = useContext(UserContext);
    const userIsAuthenticated = isAuthenticated();
    const user = JSON.parse(localStorage.getItem('user'));

    const GetOwnerDetails = async () => {
        try {
            if (!user) {
                setLoading(false);
                history.push('/login');
            } else {
                const res = await getOwner({});
                if (res.data.status) {
                    const hurata = res.data.data;
                    const subscription = JSON.parse(hurata.subscription_details);
                    setSubscriptionStatus(subscription.status);
                    setStripe_customer_id(hurata.stripe_customer_id);
                    setOwnerEmail(hurata.email);
                }
            }
        } catch (error) {
            console.error("Error fetching owner details:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        GetOwnerDetails();
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!userIsAuthenticated) {
                    return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
                }

                if (loading) {
                    return <div>Authenticating User.....</div>; // Or a proper loading component
                }

                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRouteNoSidebar;