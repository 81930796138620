
export function flexLinksReducers(state, action) {
    switch (action.type) {
        case 'SET_FLEXLINK_DATA':

            console.log('SET_FLEXLINK_DATA', action.payload)
            return {
                ...state,
                flexLinks: action.payload,
            }

        case 'IS_FETCHING':
            return {
                ...state,
                isFetching: action.payload
            }

        default:

            return state

    }
}
