import React, { useEffect, useContext, useState, useRef } from "react";
import Qr from "../../assets/images/Qr01.webp";
import QRCodeStyling from 'qr-code-styling';
import { Accordion, Button } from "react-bootstrap";
import DomainType from "../Createtemplate/DomainType";
import SessionName from "../Createtemplate/SessionName";
import Choosepattern from "../Createtemplate/Choosepatterns";
import Chooseeye from "../Createtemplate/Chooseeye";
import Addlogo from "../Createtemplate/AddLogo";
import Setcolor from "../Createtemplate/SetColor";
import Chooseframe from "../Createtemplate/Chooseframe";
import { MdQrCode } from "react-icons/md";
import Qr01 from "../../assets/images/QR.webp";
import { AiOutlineClose } from "react-icons/ai";
import { SmartLinkContext } from "../../Components/CreateSmartlink/SmartLinkcontext";
import { getTemplateApi } from "../../BackendApi/Api/template";
import { API_URL } from "../../BackendApi/Api/config";
import { toJpeg } from 'html-to-image';
import { updateTemplate } from "../../BackendApi/Api/template";
import { TemplateContext } from "../../TemplateContext";
import { updatesmartqr } from "../../BackendApi/Api/smartsLinkApi";
import { downloadImage } from "../../Helpers/imageDownload";
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { useLocation } from 'react-router-dom';
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { useBuilderContext } from "../../builderContext";




function Qrtemplates({ pageType }) {

    const location = useLocation();

    const [showText, setShowText] = useState(false);
    const [qrLoded, setQrLoded] = useState(false);
    // const [smartlinkeditdata, setSmartlinkEditData,IosTargeting, setIosTargeting,AndroidTargeting, setAndroidTargeting,qrdata , setQrData] = useContext(SmartLinkContext);

    const [smartlinkeditdata, setSmartlinkEditData, schedulesdata, setSchedulesData,latest, setLatest, linkcloakingdata, setLinkCloakingData, passwordprotection, setPasswordProtection, trackingdata, setTrackingData, qrdata, setQrData, viewType, ] = useContext(SmartLinkContext);
    const { company_slug } = useBuilderContext();
    const [pattern, setPattern, eye, setEye, logo, setLogo, frame, setFrame, patternColorType, setPatternColorType, patternDotsColor, setPatternDotsColor, patternGradientColor, setPatternGradientColor, eyeBallColor, setEyeBallColor, EyeCenterColor, setEyeCenterColor, backgroundcolortype, setBackgroundcolortype, backgroundcolorcode, setBackgroundcolorcode, height, setHeight, width, setWidth, eyeinside, setEyeinside, caption, setCaption, resetTemplateData] = useContext(TemplateContext);

    const [loading, setLoading] = useState(false);


    const qrCodeRef = useRef(null);
    const divRef = useRef(null);

    // const [brand_id, setBrand_id] = useState("");

    const [currentStyling, setCurrentStyling] = useState(null);
    const [templateName, setTemplateName] = useState("");




    useEffect(() => {

        console.log("company_slug111111", company_slug)
        // console.log("please show my smartlinkeditdata", (smartlinkeditdata.styling));



        if (smartlinkeditdata !== null && smartlinkeditdata !== undefined && smartlinkeditdata.styling !== null && smartlinkeditdata.styling !== undefined) {

            console.log("please show my smartlinkeditdata", (smartlinkeditdata.styling));

            var smartlinkJsonObj = JSON.parse(smartlinkeditdata.styling);

            console.log("smartlinkJsonObj", smartlinkJsonObj);

            if (smartlinkJsonObj.dotsOptions === null || smartlinkJsonObj.dotsOptions === undefined) {
                // smartlinkeditdata.styling.dotsOptions.type = "dots";
                setPattern("dots");
            }
            else {
                if (smartlinkJsonObj.type !== null) {
                    setPattern(smartlinkJsonObj.dotsOptions.type);
                }
                else {
                    setPattern("dots");
                }


                if (smartlinkJsonObj.dotsOptions.gradient) {

                    console.log("i am inside gradien be happy");

                    console.log("this is my dot1 color", smartlinkJsonObj.dotsOptions.gradient.colorStops[0].color)

                    console.log("this is my dot2 color", smartlinkJsonObj.dotsOptions.gradient.colorStops[1].color)

                    console.log("this is my pattern", smartlinkJsonObj.dotsOptions.type)

                    setPatternColorType("gradient");
                    setPattern(smartlinkJsonObj.dotsOptions.type)
                    setPatternDotsColor(smartlinkJsonObj.dotsOptions.gradient.colorStops[0].color);
                    setPatternGradientColor(smartlinkJsonObj.dotsOptions.gradient.colorStops[1].color);
                } else {
                    setPatternColorType("color");
                    setPatternDotsColor(smartlinkJsonObj.dotsOptions.color);
                }
                // setPatternDotsColor(smartlinkJsonObj.dotsOptions.color);
                // setPatternGradientColor(smartlinkJsonObj.dotsOptions.color);

            }

            if (smartlinkJsonObj.cornersSquareOptions === null || smartlinkJsonObj.cornersSquareOptions === undefined) {
                setEye("square");
            }
            else {
                if (smartlinkJsonObj.cornersSquareOptions.type !== null) {
                    setEye(smartlinkJsonObj.cornersSquareOptions.type);
                }
                else {
                    setEye("square");
                }

                setEyeBallColor(smartlinkJsonObj.cornersSquareOptions.color);

            }

            if (smartlinkJsonObj.cornersDotOptions === null || smartlinkJsonObj.cornersDotOptions === undefined) {
                setEyeinside("dot");
            }
            else {
                if (smartlinkJsonObj.cornersDotOptions.type !== null) {
                    setEyeinside(smartlinkJsonObj.cornersDotOptions.type);
                }
                else {
                    setEyeinside("dot");
                }
                setEyeCenterColor(smartlinkJsonObj.cornersDotOptions.color);


            }
            setLogo(smartlinkJsonObj.image);
            if (smartlinkJsonObj.frameOptions !== null && smartlinkJsonObj.frameOptions !== undefined) {
                if (smartlinkJsonObj.frameOptions.type !== null) {
                    setFrame(smartlinkJsonObj.frameOptions.type);
                }
                else {
                    setFrame("qr-bg-col");
                }
            }
            else {
                setFrame("qr-bg-col");
            }

            if (smartlinkJsonObj.backgroundOptions !== null && smartlinkJsonObj.backgroundOptions !== undefined) {
                //set background color property
                if (smartlinkJsonObj.backgroundOptions.hasOwnProperty("color")) {
                    // setBackgroundcolortype("solid");
                    setBackgroundcolorcode(smartlinkJsonObj.backgroundOptions.color);
                } else {
                    // setBackgroundcolortype("transparent");
                    setBackgroundcolorcode("#ffffff");
                }

            }
            else {
                setBackgroundcolorcode("#ffffff");
            }

            setCaption(smartlinkeditdata.caption);
            setQrLoded(true);
        }
    }, [smartlinkeditdata]);




    useEffect(() => {

        console.log("color type is")




        if (smartlinkeditdata !== null && smartlinkeditdata !== undefined && smartlinkeditdata.styling !== null && smartlinkeditdata.styling !== undefined && qrLoded === true) {




            if (patternColorType == "color") {
                var patternColorTypeValue = {
                    "type": pattern,
                    "color": patternDotsColor
                };
            } else {
                var patternColorTypeValue = {
                    "type": pattern,
                    "gradient": {
                        "type": "linear",
                        "rotation": 0,
                        "colorStops": [
                            {
                                "offset": 0,
                                "color": patternDotsColor
                            },
                            {
                                "offset": 1,
                                "color": patternGradientColor
                            }
                        ]
                    }
                };
            }

            if (backgroundcolortype === "solid") {
                var backgroundcolortypeValue = {
                    "color": backgroundcolorcode
                };
            } else {
                var backgroundcolortypeValue = {
                    "color": "#00000000"
                };
            }

            var url =company_slug + "?method=scan";

            const qrJson = {
                width: width,
                height: height,
                data: url,
                margin: 0,
                qrOptions: {
                    typeNumber: 0,
                    mode: 'Byte',
                    errorCorrectionLevel: 'Q',
                },
                imageOptions: {
                    hideBackgroundDots: true,
                    imageSize: 0.4,
                    margin: 0,
                },
                dotsOptions: patternColorTypeValue,
                backgroundOptions: backgroundcolortypeValue,
                image: logo,
                dotsOptionsHelper: {
                    colorType: { single: true, gradient: false },
                    gradient: { linear: true, radial: false, color1: '#6a1a4c', color2: '#6a1a4c', rotation: '0' },
                },
                cornersSquareOptions: {
                    type: eye,
                    color: eyeBallColor,
                    gradient: null,
                },
                cornersSquareOptionsHelper: {
                    colorType: { single: true, gradient: false },
                    gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
                },
                cornersDotOptions: {
                    type: eyeinside,
                    color: EyeCenterColor,
                    gradient: null,
                },
                cornersDotOptionsHelper: {
                    colorType: { single: true, gradient: false },
                    gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
                },
                backgroundOptionsHelper: {
                    colorType: { single: true, gradient: false },
                    gradient: { linear: true, radial: false, color1: '#ffffff', color2: '#ffffff', rotation: '0' },
                },
            };


            // const stylingjsondata = ;
            const qrCode = new QRCodeStyling(qrJson);


            console.log("qrJson", qrJson);
            qrCode.append(qrCodeRef.current);
            qrCode.update();

        }


        //   return () => {
        //     qrCode.destroy();
        //   };
    }, [pattern, eye, eyeinside, logo, patternColorType, patternDotsColor, patternGradientColor, eyeBallColor, EyeCenterColor, backgroundcolortype, backgroundcolorcode, height, width, frame, smartlinkeditdata, qrLoded]);


    useEffect(() => {
        if (currentStyling !== null && currentStyling !== undefined) {
            var jsonParsed = JSON.parse(currentStyling);
            console.log("this is my current styling data", jsonParsed);

            setPattern(jsonParsed.dotsOptions.type);
            setEye(jsonParsed.cornersSquareOptions.type);
            setEyeinside(jsonParsed.cornersDotOptions.type);
            setLogo(jsonParsed.image);
            if (jsonParsed.frameOptions.type !== null) {
                setFrame(jsonParsed.frameOptions.type);
            }
            else {
                setFrame("qr-bg-col");
            }
            //check key exist or not in object

            if (jsonParsed.dotsOptions.hasOwnProperty("gradient")) {

                setPatternColorType("gradient");
                setPattern(jsonParsed.dotsOptions.type);
                setPatternDotsColor(jsonParsed.dotsOptions.gradient.colorStops[0].color);
                setPatternGradientColor(jsonParsed.dotsOptions.gradient.colorStops[1].color);
            } else {
                setPatternColorType("color");
                setPattern(jsonParsed.dotsOptions.type);
                setPatternDotsColor(jsonParsed.dotsOptions.color);
            }

            //set background color property
            if (jsonParsed.backgroundOptions.hasOwnProperty("color")) {
                // setBackgroundcolortype("solid");
                setBackgroundcolorcode(jsonParsed.backgroundOptions.color);
            } else {
                // setBackgroundcolortype("transparent");
                setBackgroundcolorcode("#00000000");
            }



            setEyeBallColor(jsonParsed.cornersSquareOptions.color);
            setEyeCenterColor(jsonParsed.cornersDotOptions.color);
            // setBackgroundcolorcode(styling.backgroundOptions.color);

        }
    }, [currentStyling]);


    useEffect(() => {
        console.log("my smartlinkeditdata", smartlinkeditdata);
        if (smartlinkeditdata !== undefined) {
            getTemplateData();
        }
    }, [smartlinkeditdata]);

    const [templateData, setTemplateData] = useState([]);



    const getTemplateData = () => {

        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const created_by = user._id;
        const owner_id = user.owner_id;
        const role = user.role;




        const data = {
            brand_id: smartlinkeditdata.brand_id,
        }

        getTemplateApi(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    console.log(res.data.data);
                    setTemplateData(res.data.data);
                }
                else {
                    PNotify.error({
                        title: 'Oh No!',
                        text: 'Template not created',
                    });
                }

            }
            else {
                PNotify.error({
                    title: 'Oh No!',
                    text: 'Something went wrong',
                });
            }
        })
            .catch((err) => {
                console.log(err);
            });
    };

    const getdateTime = (date) => {
        if (date) {
            var date = new Date(date);
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var dt = date.getDate();

            if (dt < 10) {
                dt = '0' + dt;
            }

            if (month < 10) {
                month = '0' + month;
            }

            return (dt + '/' + month + '/' + year);
        }
        else {
            return "";
        }

    }

    const updateqr = () => {


        console.log("my gradient type is ", patternColorType)

        if (patternColorType == "color") {
            var patternColorTypeValue = {
                "type": pattern,
                "color": patternDotsColor
            };
        } else {
            var patternColorTypeValue = {
                "type": pattern,
                "gradient": {
                    "type": "linear",
                    "rotation": 0,
                    "colorStops": [
                        {
                            "offset": 0,
                            "color": patternDotsColor
                        },
                        {
                            "offset": 1,
                            "color": patternGradientColor
                        }
                    ]
                }
            };
        }


        if (backgroundcolortype === "solid") {
            var backgroundcolortypeValue = {
                "color": backgroundcolorcode
            };
        } else {
            var backgroundcolortypeValue = {
                "color": "#00000000"
            };
        }


        const dataUrl = company_slug + "?method=scan";

        const qrCode = {
            width: width,
            height: height,
            data: dataUrl.data,
            margin: 0,
            qrOptions: {
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'Q',
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0,
            },
            dotsOptions: patternColorTypeValue,
            backgroundOptions: backgroundcolortypeValue,
            image: logo,
            dotsOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#6a1a4c', color2: '#6a1a4c', rotation: '0' },
            },
            cornersSquareOptions: {
                type: eye,
                color: eyeBallColor,
                gradient: null,
            },
            cornersSquareOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            cornersDotOptions: {
                type: eyeinside,
                color: EyeCenterColor,
                gradient: null,
            },
            cornersDotOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' },
            },
            backgroundOptionsHelper: {
                colorType: { single: true, gradient: false },
                gradient: { linear: true, radial: false, color1: '#ffffff', color2: '#ffffff', rotation: '0' },
            },
            frameOptions: {
                type: frame,
            },
        };

        var qrToCapture = divRef.current


        console.log("frame", frame)

        if (frame === "qr-bg-col4") {
            console.log("say cheese")

            qrToCapture = qrCodeRef.current.children[0];

        }




        toJpeg(qrToCapture, { quality: 1.0 })
            .then(dataUrl => {
                const base64Data = dataUrl.replace(/^data:image\/jpeg;base64,/, '');

                // // Convert base64 string to Blob object
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'image/jpeg' });

                const userdata = localStorage.getItem("user");
                const user = JSON.parse(userdata);
                const userid = user._id;

                const formdata = new FormData();
                formdata.append("id", smartlinkeditdata._id);
                formdata.append("styling", JSON.stringify(qrCode));
                formdata.append("image", blob);
                formdata.append("caption", caption);
                formdata.append("updator_image", user.profile_image);
                formdata.append("updator_name", user.fname + " " + user.lname)

                setLoading(true);
                updatesmartqr(formdata).then((res) => {
                    if (res.code === 200) {
                        if (res.data.status === true) {
                            PNotify.success({
                                title: 'Success',
                                text: 'Template Updated Successfully',
                            });
                            setLatest(!latest);
                            //resetTemplateData();
                            // window.location.reload();
                        }
                        else {
                            PNotify.error({
                                title: 'Oh No!',
                                text: 'Template not created',
                            });
                        }

                    }
                    else {
                        PNotify.error({
                            title: 'Oh No!',
                            text: 'Something went wrong',
                        });
                    }
                }).catch((err) => {
                    console.log("err", err);
                }).finally(() => {
                    setLoading(false);
                })

            });


    }



    return (
        <>
            <div className="row">
                <div className={pageType == 'flex' ? 'col-xl-12 col-lg-12 order-2' : 'col-xl-8 col-lg-12'}>
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <Accordion defaultActiveKey="" className="Create-Template-Accordion">
                                {/* <Accordion.Item eventKey="1">
                                    <DomainType />
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <SessionName />
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="3">
                                    <Choosepattern />
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Chooseeye />
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Addlogo />
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Setcolor />
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Chooseframe />
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        {/* <div className="col-md-12 p-0">
                            <div className="form-group mb-3">
                                <input type="text" className="form-control" value={templateName} onChange={
                                    (e) => {
                                        setTemplateName(e.target.value);
                                    }
                                } placeholder="Enter a name for your new template" />
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <div className={(location.pathname === '/minipage') ? 'col-xl-12 col-lg-12' : 'col-xl-4 col-lg-12'}> */}
                <div className={pageType == 'flex' ? 'col-xl-12 col-lg-12 order-1' : 'col-xl-4 col-lg-12'}>
                    <div className="Qr-image">
                        <image src={Qr} alt="" className="img-fluid" />
                    </div>
                    <div ref={divRef} className={
                        frame === "qr-bg-col" ? "qr-box qr-bg-col" : frame === "qr-bg-col2" ? "qr-box qr-bg-col2" : frame === "qr-bg-col3" ? "qr-box qr-bg-col3" : frame === "qr-bg-col4" ? "qr-box qr-bg-col4" : "qr-box"
                    } >
                        <div className="canvas-title2"><h2>{caption}</h2></div>
                        <div className="Qr-image" >
                            <div ref={qrCodeRef} id="qrCode">

                            </div>
                        </div>
                        <div className="canvas-title "><h2>{caption}</h2></div>
                        <div className="canvas-title3 "><h2>{caption}</h2></div>

                    </div>
                    <div className="text-center mt-3 mb-3">
                        <button className="btn bg-green-dark action-btn btn-green download-qr-btn" onClick={() => {
                            downloadImage('png', divRef, 'template')
                        }}>PNG</button>
                        <button className="btn bg-green-dark action-btn btn-green download-qr-btn" onClick={() => {
                            downloadImage('jpeg', divRef, 'template')
                        }}>JPEG</button>
                        <button className="btn bg-green-dark action-btn btn-green download-qr-btn" onClick={() => {
                            downloadImage('svg', divRef, 'template')
                        }}>SVG</button>

                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-md-12">
                    <div className='form-btn mt-2'>
                        {
                            loading ? (
                                <SpinnerLoader />
                            ) :
                                (
                                    <button type="button" onClick={updateqr} className="btn bg-green-dark action-btn btn-green">
                                        Update QR
                                    </button>
                                )
                        }
                    </div>
                </div>
                <div className="col-md-12">
                    <div className='Template-cards mt-3 mb-3'>
                        <h5 className="tab-content-title"><div className="tab-content-icon"><MdQrCode /></div>TEMPLATES</h5>
                    </div>
                    <div className="row002">
                        {
                            templateData && templateData.map((item) => {
                                return (

                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6">
                                        <div className="qr-template-view">
                                            <div className="qr-template-view" onClick={
                                                () => {
                                                    setCurrentStyling(item.styling);
                                                    setTemplateName(item.template_name);
                                                }
                                            } >
                                                <div className="Template-card">
                                                    <div className="Template-card-image">
                                                        <img src={API_URL + `qrcodes/` + item.thumbnail} ealt="" className="img-fluid" />
                                                    </div>

                                                    {pageType == 'flex' ? "" : <>
                                                        <div className="Template-card-name">
                                                            <h5>{item.template_name}</h5>
                                                        </div>
                                                        {/* <div className="Template-card-date">
                                                            <h6>{getdateTime(item.created_on)}</h6>
                                                        </div> */}
                                                    </>
                                                    }
                                                </div>
                                                {/* <div className="Template-card-delete-btn">
                                                    <div className="Template-card-delete-btn-icon">
                                                        <button type="button" className="btn btn-danger"><AiOutlineClose /></button>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Qrtemplates;