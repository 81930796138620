import React from "react";
import "./Createbrand.css";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { MdFacebook } from "react-icons/md";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import { FaPinterest, FaTiktok } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6"
import { HiLink } from "react-icons/hi";
import { useState, useContext, useEffect } from "react";
import { BrandContext } from "../../brandContext";
import { getBrandByIdApi } from '../../BackendApi/Api/brandApi'
import { updateBrandApi } from '../../BackendApi/Api/brandApi'
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import Loader2 from "../Loader/Loader2";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { TbTrash } from "react-icons/tb";
import Socialchannelslist  from './socialchannelslist'

const API_URL = process.env.REACT_APP_API_URL+'/';


function Socials({ newchannel }) {
    const userdata = localStorage.getItem("user");
    const user = JSON.parse(userdata);
    const [BrandId, setBrandId] = useState('');
    const [facebookUrl, setFacebookUrl] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');
    const [instagramUrl, setInstagramUrl] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [pinterestUrl, setPinterestUrl] = useState('');
    const [tiktokUrl, setTiktokUrl] = useState('');
    const [otherUrl, setOtherUrl] = useState('');

    const [loading, setLoading] = useState(true);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [socialChannels, setSocialChannels] = useState([])


    const [social, setSocial] = useState([]);
    const [chat, setChat] = useState([]);
    const [advertising, setAdvertising] = useState([]);
    const [outreach, setOutreach] = useState([]);
    const [online, setOnline] = useState([]);
    const [out_home_media, setOutHomeMedia] = useState([]);
    const [print_material, setPrintMaterial] = useState([]);
    const [television, setTelevision] = useState([]);

    useEffect(() => {
        if (newchannel) {
            setSocialChannels((prev)=>[...prev, newchannel])
        }
    }, [newchannel])


    const getBrandData = () => {
        setLoading(true);
        const url = window.location.href;
        const urlSplit = url.split('/');
        const urlbrandId = urlSplit[urlSplit.length - 1];

        const data = {
            id: urlbrandId
        }
        getBrandByIdApi(data).then((res) => {
            setBrandId(res.data.data._id);
            setFacebookUrl(res.data.data.facebook_url);
            setTwitterUrl(res.data.data.twitter_url);
            setInstagramUrl(res.data.data.instagram_url);
            setWebsiteUrl(res.data.data.website_url);
            setPinterestUrl(res.data.data.pinterest_url);
            setTiktokUrl(res.data.data.tiktok_url);
            setOtherUrl(res.data.data.other_url);
            setSocialChannels(res.data.data.social_channels)

            const social = res.data.data.social_channels

            social.forEach((item) => {
                switch (item.channel_category) {
                    case "social":
                        setSocial((prevSocial) => [...prevSocial, item]);
                        break;
                    case "chat":
                        setChat((prevChat) => [...prevChat, item]);
                        break;
                    case "advertising":
                        setAdvertising((prevAdvertising) => [...prevAdvertising, item]);
                        break;
                    case "outreach":
                        setOutreach((prevOutreach) => [...prevOutreach, item]);
                        break;
                    case "online":
                        setOnline((prevOnline) => [...prevOnline, item]);
                        break;

                    case "out_home_media":
                        setOutHomeMedia((prevOutHomeMedia) => [...prevOutHomeMedia, item]);
                        break;
                    case "print_material":
                        setPrintMaterial((prevPrintMaterial) => [...prevPrintMaterial, item]);
                        break;
                    case "television":
                        setTelevision((prevTelevision) => [...prevTelevision, item]);
                        break;
                    default:
                        // Handle unknown category
                        break;
                }
            });

            setLoading(false);

        }).catch((err) => {
            setLoading(false);

            PNotify.error({
                title: 'Error',
                text: 'Brand Not Found',
            })
        })
    }

    const updateBrand = async (e) => {
        e.preventDefault();

        // setLoading(true);
        setSpinnerLoading(true);

        const data = {
            facebook_url: facebookUrl,
            twitter_url: twitterUrl,
            instagram_url: instagramUrl,
            website_url: websiteUrl,
            pinterest_url: pinterestUrl,
            tiktok_url: tiktokUrl,
            other_url: otherUrl,
            id: BrandId,
            updator_image: user.profile_image,
            updator_name: user.fname+""+user.lname
        }
        console.log(data);
        updateBrandApi(data).then((response) => {
            if (response.code === 200) {
                setLoading(false);
                PNotify.success({
                    title: 'Success',
                    text: 'Brand Updated Successfully',
                });
            }
            else {
                setLoading(false);
                PNotify.error({
                    title: 'Error',
                    text: 'Brand Not Updated',
                });
            }
        }).catch((error) => {
            setLoading(false);
            PNotify.notice({
                title: 'Notice',
                text: error,
            })

        }).finally(() => {
            setSpinnerLoading(false);
        })
    }

    useEffect(() => {
        getBrandData();
    }, []);


    return (
        <div>
            {loading && <Loader2 />}
            {/* <div className='row'>
                <div className='col-md-12'>
                    <div className='brand-socials-input'>
                        <InputGroup controlId="formBasicEmail" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-lg"><MdFacebook /></InputGroup.Text>
                            <Form.Control aria-label="Large" aria-describedby="inputGroup-sizing-sm" placeholder="Facebook URL" value={facebookUrl} onChange={(e) => setFacebookUrl(e.target.value)} />
                        </InputGroup>
                        <InputGroup controlId="formBasicEmail" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-lg"><FaSquareXTwitter /></InputGroup.Text>
                            <Form.Control aria-label="Large" aria-describedby="inputGroup-sizing-sm" placeholder="X (formerly twitter) URL" value={twitterUrl} onChange={(e) => setTwitterUrl(e.target.value)} />
                        </InputGroup>
                        <InputGroup controlId="formBasicEmail" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-lg"><AiFillInstagram /></InputGroup.Text>
                            <Form.Control aria-label="Large" aria-describedby="inputGroup-sizing-sm" placeholder="Instagram URL" value={instagramUrl} onChange={(e) => setInstagramUrl(e.target.value)} />
                        </InputGroup>
                        <InputGroup controlId="formBasicEmail" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-lg"><TbWorld /></InputGroup.Text>
                            <Form.Control aria-label="Large" aria-describedby="inputGroup-sizing-sm" placeholder="Website URL" value={websiteUrl} onChange={(e) => setWebsiteUrl(e.target.value)} />
                        </InputGroup>
                        <InputGroup controlId="formBasicEmail" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-lg"><FaPinterest /></InputGroup.Text>
                            <Form.Control aria-label="Large" aria-describedby="inputGroup-sizing-sm" placeholder="Pinterest URL" value={pinterestUrl} onChange={(e) => setPinterestUrl(e.target.value)} />
                        </InputGroup>
                        <InputGroup controlId="formBasicEmail" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-lg"><FaTiktok /></InputGroup.Text>
                            <Form.Control aria-label="Large" aria-describedby="inputGroup-sizing-sm" placeholder="TikTok URL" value={tiktokUrl} onChange={(e) => setTiktokUrl(e.target.value)} />
                        </InputGroup>
                        <InputGroup controlId="formBasicEmail" className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-lg"><HiLink /></InputGroup.Text>
                            <Form.Control aria-label="Large" aria-describedby="inputGroup-sizing-sm" placeholder="Other URL" value={otherUrl} onChange={(e) => setOtherUrl(e.target.value)} />
                        </InputGroup>
                    </div>
                </div>
            </div> */}

            <div className="Domain-table mt-0">
                <div className="channel-name-list">
                    Social Media
                </div>
                <ul>
                    
                    <Socialchannelslist socialChannels={socialChannels} channelCategory="social" />

                </ul>
            </div>

            <div className="Domain-table mt-0">
                <div className="channel-name-list">
                    Chat Platforms
                </div>
                <ul>
                    <Socialchannelslist socialChannels={socialChannels} channelCategory="chat" />

                </ul>
            </div>

            <div className="Domain-table mt-0">
                <div className="channel-name-list">
                    Advertising
                </div>
                <ul>
                    <Socialchannelslist socialChannels={socialChannels} channelCategory="advertising" />

                </ul>
            </div>

            <div className="Domain-table mt-0">
                <div className="channel-name-list">
                    Direct Outreach
                </div>
                <ul>
                    <Socialchannelslist socialChannels={socialChannels} channelCategory="outreach" />

                </ul>
            </div>

            <div className="Domain-table mt-0">
                <div className="channel-name-list">
                    Online
                </div>
                <ul>
                    <Socialchannelslist socialChannels={socialChannels} channelCategory="online" />

                </ul>
            </div>

            <div className="Domain-table mt-0">
                <div className="channel-name-list">
                    Television
                </div>
                <ul>
                    <Socialchannelslist socialChannels={socialChannels} channelCategory="television" />

                </ul>
            </div>

            <div className="Domain-table mt-0">
                <div className="channel-name-list">
                    Out of Home Media
                </div>
                <ul>
                    <Socialchannelslist socialChannels={socialChannels} channelCategory="out_home_media" />

                </ul>
            </div>

            <div className="Domain-table mt-0">
                <div className="channel-name-list">
                    Print Material
                </div>
                <ul>
                    <Socialchannelslist socialChannels={socialChannels} channelCategory="print_material" />

                </ul>
            </div>

            {/*             
            <div className='row'>
                <div className='col-md-12'>
                    <div className='brand-button'>

                        {
                            spinnerLoading ? <SpinnerLoader /> : <button className="btn bg-green-dark action-btn btn-green" onClick={updateBrand}> Update </button>
                        }
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Socials;