import React from "react";
import { SiInstagram } from "react-icons/si";
import { BsWhatsapp } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsTiktok } from "react-icons/bs";
import { BsSnapchat } from "react-icons/bs";
import { BsPaypal } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { BsPinterest } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";


import { FaYoutube, FaTwitter } from "react-icons/fa";

function BuilderIcon(props) {

    // const socials = props.data.items;

   
    // {
    //     "social-1": {
    //         "id": "social-1",
    //         "type": "FACEBOOK",
    //         "url": ""
    //     }
    // }


    const socials = Object.values(props.data.items);


    console.log('MY PROPS icons=========', socials);
    return (
        <>
            <div className="social_item_list">
               {socials.map((item, index) => (
                <>
                {
                    item.type== "FACEBOOK" ?<a href={item.url} target="blank"> <BsFacebook /></a> :

                    item.type== "INSTAGRAM" ? <a href={item.url} target="blank"><SiInstagram /></a> :

                    item.type== "WHATSAPP" ? <a href={item.url} target="blank"><BsWhatsapp /></a> :

                    item.type== "TWITTER" ? <a href={item.url} target="blank"><FaTwitter /></a> :

                    item.type== "LINKEDIN" ? <a href={item.url} target="blank"><BsLinkedin /></a> :

                    item.type== "YOUTUBE" ? <a href={item.url} target="blank"><FaYoutube /></a> :

                    item.type== "TIKTOK" ? <a href={item.url} target="blank"><BsTiktok /></a> :

                    item.type== "SNAPCHAT" ? <a href={item.url} target="blank"><BsSnapchat /></a> :

                    item.type== "PAYPAL" ? <a href={item.url} target="blank"><BsPaypal /></a> :

                    item.type== "TELEGRAM" ? <a href={item.url} target="blank"><BsTelegram /></a> :

                    item.type== "PINTEREST" ? <a href={item.url} target="blank"><BsPinterest /></a> :""

               }
               </>
               ))}
            </div>
        </>
    );
}

export default BuilderIcon;