import React from "react";
import './Loader.css';

function Loader2() {
    return (
        <div className="overlay">
            <div className="paceloader">
                <div className="ring"></div>
                <div className="ring"></div>
                <div className="ring"></div>
                <div>
                    <img src={require ('../../assets/images/logo/Popuplogo.png')} alt="pace-logo" style={{ height: "auto" }} />
                </div>
            </div>
        </div>
    );
}

export default Loader2;
